import React from "react";
import styles from "../../styles/components/components.module.css";

const UseFormikInputField = ({
  label_font_size,
  labelName,
  itemName,
  formikError,
  formikTouched,
  placeholder,
  inputValue,
  onChangeFn,
  onBlurFn,
  Searchinput = false,
  input = true,
  TextArea,
  inputDisabled = false,
  readOnly = false,
  inputWidth = "w-100",
  errMsgContainerClass = " justify-content-end justify-content-lg-start",
}) => {
  return (
    <>
      <label
        htmlFor={itemName}
        className={
          formikError && formikTouched
            ? `red_color ${label_font_size} fs_15`
            : ` ${label_font_size} secondary_color fs_15`
        }
      >
        {labelName}
      </label>
      <div
        className={
          formikError && formikTouched
            ? ` ${inputWidth} border_radius_5px error_border focus_none `
            : `${inputWidth} border_radius_5px input_border focus_none `
        }
      >
        {input && (
          <>
            <input
              readOnly={readOnly}
              name={itemName}
              style={{ width: "500px", maxWidth: "262px" }}
              className={`${styles.input_text} ${inputWidth}  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
              id={itemName}
              placeholder={placeholder}
              value={inputValue}
              onChange={onChangeFn}
              onBlur={onBlurFn}
              disabled={inputDisabled}
            />
          </>
        )}
        {Searchinput && (
          <>
            <input
              readOnly={readOnly}
              name={itemName}
              className={`${styles.input_text} ${inputWidth}  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
              id={itemName}
              placeholder={placeholder}
              value={inputValue}
              onChange={onChangeFn}
              onBlur={onBlurFn}
              disabled={inputDisabled}
            />
          </>
        )}
        {TextArea && (
          <>
            <textarea
              readOnly={readOnly}
              className={`${styles.text_area_input} ${inputWidth} border_none border_radius_5px background_none focus_none  ps-2 resize_none`}
              placeholder={placeholder}
              id={itemName}
              name={itemName}
              value={inputValue}
              onChange={onChangeFn}
              onBlur={onBlurFn}
              disabled={inputDisabled}
            ></textarea>
          </>
        )}
      </div>
      <div className={`${styles.error_height} ${errMsgContainerClass}`}>
        {formikError && formikTouched && (
          <span className="red_color fs_12 fw_400">{formikError}</span>
        )}
      </div>
    </>
  );
};

export default UseFormikInputField;
