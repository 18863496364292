import React, { useState } from "react";
import styles from "../../../styles/edit-property/edit-property.module.css";
// import UseFormikRadioInput from "../../../form/useFormikRadioField";
import UseFormikInputField from "../../form/useFormikInputField";
import UseFormikSelectField from "../../form/useFormikSelectField";
import UseFormikRadioInput from "../../form/useFormikRadioField";
import { removeUnderScore } from "../../helper/helper";
const ResidentialPlotFeature = ({
  formik,
  setCompareAminities,
  propertyDetails,
  isClickedOnAme, 
  setIsClickedOnAme
}) => {
  const aminitiesList = [
    { name: "basic_amenities", label: "Basic Amenities", status: false },
    {
      name: "electricity_line",
      label: "Electricity Line",
      status: false,
    },
    { name: "highway_access", label: "Highway Access", status: false },
    { name: "main_road_access", label: "Main Road Access", status: false },
    {
      name: "underground_drainage_system",
      label: "Underground Drainage System",
      status: false,
    },
  ];
  const initialAminities = propertyDetails?.property_features?.amenities;
  const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);
  // const handleCheckboxChange = (index) => {
  //   const updatedSuitableFor = [...suitableFor];
  //   updatedSuitableFor[index].status = !updatedSuitableFor[index].status;
  //   setSuitableFor(updatedSuitableFor);
  //   formik.setFieldValue("property_features.amenities", updatedSuitableFor);
  // };
  const aminitiesAreEqual = (currentAminities, initialAminities) => {
    const getCurrentAminitieStatus = currentAminities?.filter((item) => item?.status === true)?.length;
    const getInitialAminitieStatus = initialAminities?.filter((item) => item?.status === true )?.length;
    const isAmentiesMatches = currentAminities?.every((currentItem, index) => initialAminities[index]?.status === currentItem?.status);
    if (isAmentiesMatches) {
      setCompareAminities(true);
    } else {
      setCompareAminities(false);
    }
  };
  const updatedAminitiesList = aminitiesList?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {
      return backendAminity.name === aminity.name;
    });

    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
    return aminity;
  });
  const [amenities, setAmenities] = useState(updatedAminitiesList);
  const handleCheckboxChange = (index) => {
    const newUpdatedAminitiesList = [...amenities];
    newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
    setAmenities(newUpdatedAminitiesList);
    const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
    formik.setFieldValue("property_features.amenities", filteredAmenities);
  };
  if(isClickedOnAme){
    aminitiesAreEqual(amenities, formik?.initialValues?.property_features?.amenities);
  }
  
  return (
    <>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Property Features</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={
              formik?.values?.property_features?.floors_allowed_for_construction
            }
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={
              formik?.errors?.property_features?.floors_allowed_for_construction
            }
            formikTouched={
              formik?.touched?.property_features
                ?.floors_allowed_for_construction
            }
            itemName="property_features.floors_allowed_for_construction"
            labelName="Floors Allowed"
          />
        </div>

        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.no_of_open_sides}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.no_of_open_sides}
            formikTouched={formik?.touched?.property_features?.no_of_open_sides}
            itemName="property_features.no_of_open_sides"
            labelName="No of Open Sides"
          />
        </div>
        <UseFormikSelectField
          label="Facing"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.facing"
          formik={formik}
          formikError={formik?.errors?.property_features?.facing}
          formikTouched={formik?.touched?.property_features?.facing}
          defaultValue={
            formik?.values?.property_features?.facing && {
              label: removeUnderScore(
                formik?.values?.property_features?.facing
              ),
              value: formik?.values?.property_features?.facing,
            }
          }
        />
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.boundary_wall_made"}
          title={"Boundary Wall made"}
        />
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.gated_colony"}
          title={"Gated colony"}
        />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center mt-3">
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.corner_site"}
          title={"Corner site"}
        />
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.any_construction_done"}
          title={"Any construction Done"}
        />
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Property Amenities</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        {/* <div className="row g-0">
          {suitableFor?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => handleCheckboxChange(index)}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.name ? removeUnderScore(item?.name) : "--"}
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}
        <div className="row g-0">
          {amenities?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </>
  );
};

export default ResidentialPlotFeature;
