import React, { useEffect, useState } from "react";
import { residentialFindOneAction } from "../../redux/action/edit-property-actions/residential";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { editPgAction, pgFindOneAction } from "../../redux/action/property-actions/pgListAction";
import LocationAndSearch from "../../components/map/locationSearch";
import { Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "../../styles/property-details.module.css";
import Leftarrowicon from "../../assets/icons/left-arrow-icon";
import Primarybutton from "../../components/ui/buttons/primary-button";
import PropertyBasicDetails from "../../components/edit-property/property-basic-details";
import { amountTypeRegex, convertValuesToIntegers, defaultAddress, objectsAreEqual, removeUnderScore } from "../../components/helper/helper";
import moment from "moment";
import UseFormikInputField from "../../components/form/useFormikInputField";
import EditPgFeatures from "../../components/edit-pg/edit-pg-features";
import EditPgRules from "../../components/edit-pg/edit-pg-rules";
import EditPgCommonAmenities from "../../components/edit-pg/edit-pg-common-amenities";
import EditPgFoodProvided from "../../components/edit-pg/edit-pg-food-provided";
import successToast from "../../components/helper-functions/successToast";
import errorToast from "../../components/helper-functions/errorToast";
import isEqual from "lodash.isequal";
import Modal_property from "../../components/ui/modals/modal_property";
import PropertyLocation from "../../components/map/property-location";
import UseFormikSelectField from "../../components/form/useFormikSelectField";
const PgEditView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [modalButton, setModalButton] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [pgDetails, setPgDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const [singlePhotos, setSinglePhotos] = useState([]);
  const [doublePhotos, setDoublePhotos] = useState([]);
  const [triplePhotos, setTriplePhotos] = useState([]);
  const [fourPhotos, setFourPhotos] = useState([]);
  const [otherPhotos, setOtherPhotos] = useState([]);
  const [initSingleShareAme, setInitSingleShareAme] = useState([]);
  const [initDoubleShareAme, setInitDoubleShareAme] = useState([]);
  const [initTripleShareAme, setInitTripleShareAme] = useState([]);
  const [initFourShareAme, setInitFourShareAme] = useState([]);
  const [initOtherShareAme, setInitOtherShareAme] = useState([]);
  const [isDouble, setIsDouble] = useState(false);
  const [isOneAmeMathces, setIsOneAmeMathces] = useState(true);
  const [isTwoAmeMatches, setIsTwoAmeMatches] = useState(true);
  const [isTripleAmeMatches, setIsTripleAmeMatches] = useState(true);
  const [isFourAmeMatches, setIsFourAmeMatches] = useState(true);
  const [isOtherMatches, setIsOtherMatches] = useState(true);
  const [initialValues, setInitialValues] = useState({
    pg_id: "",
    post_type: "pg",
    pg_details: {
      address: "",
      name: "",
      location: { lat: null, long: null },
      pg_name: "",
    },
    pg_operation_since: "",
    notice_period: "",
    preferred_gender: "",
    single_sharing: {
      enabled: false,
      number: null,
      rent: null,
      security_deposite: null,
      amenities: [],
      photos: [],
    },
    double_sharing: {
      enabled: false,
      number: null,
      rent: null,
      security_deposite: null,
      amenities: [],
      photos: [],
    },
    triple_sharing: {
      enabled: false,
      number: null,
      rent: null,
      security_deposite: null,
      amenities: [],
      photos: [],
    },
    four_sharing: {
      enabled: false,
      number: null,
      rent: null,
      security_deposite: null,
      amenities: [],
      photos: [],
    },
    other: {
      enabled: false,
      number: null,
      rent: null,
      security_deposite: null,
      amenities: [],
      photos: [],
    },
    g_location:{
      address_line_1:"",
      address_line_2:"",
      area:"",
      city:"",
      postCode: null,
      state:"",
      country:"",
      lat: null,
      lng: null
    },
    "pg_rules":{
      "veg_only":true,
      "no_smoking":false,
      "drinking_alcohol_not_allowed":false,
      "entry_of_opposite_gender_not_allowed":false,
      "entry_of_visitors_not_allowed":false
  },
    common_area_amenities: {},
    food_provided: {},
    food_type_provided: "",
    food_charges: "",
    g_location: "",
    formetted_address: "",
    description: "",
    terms_and_condition: true,
    lat: null,
    lng: null,
    searchAddress: "",
  });
  const initPgDetails = {...pgDetails}
  console.log(initPgDetails, "initPgDetails");
  useEffect(() => {
    setLoading(true);
    dispatch(
      pgFindOneAction({ property_id: params?.id }, onFindSuccess, onFindError)
    );
  }, [reload]);
  const onFindSuccess = (data) => {
    console.log(data, "pgdata");
    setLoading(false);
    const pgDetailsData = data?.data;
    console.log(pgDetailsData, "pgDetailsData");
    setPgDetails(pgDetailsData);
    setSinglePhotos(pgDetailsData?.single_sharing?.photos ?? []);
    setDoublePhotos(pgDetailsData?.double_sharing?.photos ?? []);
    setTriplePhotos(pgDetailsData?.triple_sharing?.photos ?? []);
    setFourPhotos(pgDetailsData?.four_sharing?.photos ?? null);
    setOtherPhotos(pgDetailsData?.other?.photos ?? null);
    setInitSingleShareAme([JSON.parse(JSON.stringify(pgDetailsData?.single_sharing?.amenities))] ?? [])
    setInitDoubleShareAme([...pgDetailsData?.double_sharing?.amenities] ?? [])
    setInitTripleShareAme([...pgDetailsData?.triple_sharing?.amenities] ?? [])
    setInitFourShareAme([...pgDetailsData?.four_sharing?.amenities] ?? [])
    setInitOtherShareAme([...pgDetailsData?.other?.amenities] ?? [])
    setInitialValues({
      pg_id: pgDetailsData?.id,
      post_type: "pg",
      pg_details: {
        address: pgDetailsData?.g_location?.address_line_2,
        name: pgDetailsData?.g_location?.city,
        location: {
          lat: pgDetailsData?.pg_details?.lat > 0 ? pgDetailsData?.pg_details?.lat : defaultAddress?.lat,
          long: pgDetailsData?.pg_details?.long > 0 ? pgDetailsData?.pg_details?.long : defaultAddress?.lng,
        },
        pg_name: pgDetailsData?.pg_details?.pg_name,
      },
      pg_operation_since: pgDetailsData?.pg_operation_since,
      notice_period: pgDetailsData?.notice_period
        ? pgDetailsData?.notice_period
        : "",
      preferred_gender: pgDetailsData?.preferred_gender
        ? pgDetailsData?.preferred_gender
        : "",
      single_sharing: {
        enabled: pgDetailsData?.single_sharing?.enabled,
        number: pgDetailsData?.single_sharing?.number > 0 ? pgDetailsData?.single_sharing?.number : null,
        rent:
          pgDetailsData?.single_sharing?.rent > 0
            ? pgDetailsData?.single_sharing?.rent
            : null,
        security_deposite:
          pgDetailsData?.single_sharing?.security_deposite > 0
            ? pgDetailsData?.single_sharing?.security_deposite
            : null,
        amenities: pgDetailsData?.single_sharing?.amenities ?? [],
        photos: pgDetailsData?.single_sharing?.photos ?? [],
      },
      double_sharing: {
        enabled: pgDetailsData?.double_sharing?.enabled,
        number: pgDetailsData?.double_sharing?.number > 0 ? pgDetailsData?.double_sharing?.number : null,
        rent: pgDetailsData?.double_sharing?.rent > 0 ? pgDetailsData?.double_sharing?.rent : null,
        security_deposite:
          pgDetailsData?.double_sharing?.security_deposite > 0
            ? pgDetailsData?.double_sharing?.security_deposite
            : null,
        amenities: pgDetailsData?.double_sharing?.amenities ?? [],
        photos: pgDetailsData?.double_sharing?.photos ?? [],
      },
      triple_sharing: {
        enabled: pgDetailsData?.triple_sharing?.enabled,
        number:
          pgDetailsData?.triple_sharing?.number > 0
            ? pgDetailsData?.triple_sharing?.number
            : null,
        rent:
          pgDetailsData?.triple_sharing?.rent > 0
            ? pgDetailsData?.triple_sharing?.rent
            : null,
        security_deposite:
          pgDetailsData?.triple_sharing?.security_deposite > 0
            ? pgDetailsData?.triple_sharing?.security_deposite
            : null,
        amenities: pgDetailsData?.triple_sharing?.amenities ?? [],
        photos: pgDetailsData?.triple_sharing?.photos ?? [],
      },
      four_sharing: {
        enabled: pgDetailsData?.four_sharing?.enabled,
        number:
          pgDetailsData?.four_sharing?.number > 0
            ? pgDetailsData?.four_sharing?.number
            : null,
        rent:
          pgDetailsData?.four_sharing?.rent > 0
            ? pgDetailsData?.four_sharing?.rent
            : null,
        security_deposite:
          pgDetailsData?.four_sharing?.security_deposite > 0
            ? pgDetailsData?.four_sharing?.security_deposite
            : null,
        amenities: pgDetailsData?.four_sharing?.amenities ?? [],
        photos: pgDetailsData?.four_sharing?.photos ?? [],
      },
      other: {
        enabled: pgDetailsData?.other?.enabled,
        number:
          pgDetailsData?.other?.number > 0
            ? pgDetailsData?.other?.number
            : null,
        rent:
          pgDetailsData?.other?.rent > 0 ? pgDetailsData?.other?.rent : null,
        security_deposite:
          pgDetailsData?.other?.security_deposite > 0
            ? pgDetailsData?.other?.security_deposite
            : null,
        amenities: pgDetailsData?.other?.amenities,
        photos: pgDetailsData?.other?.photos ?? [],
      },
      g_location:{
        address_line_1: pgDetailsData?.g_location?.address_line_1 ? pgDetailsData?.g_location?.address_line_1 : defaultAddress?.address_line_1,
        address_line_2: pgDetailsData?.g_location?.address_line_2 ? pgDetailsData?.g_location?.address_line_2 : defaultAddress?.address_line_2,
        area: pgDetailsData?.g_location?.area ? pgDetailsData?.g_location?.area : defaultAddress?.area,
        city: pgDetailsData?.g_location?.city ? pgDetailsData?.g_location?.city : defaultAddress?.city,
        postCode: pgDetailsData?.g_location?.postCode ? pgDetailsData?.g_location?.postCode : defaultAddress?.postCode,
        state: pgDetailsData?.g_location?.state ? pgDetailsData?.g_location?.state : defaultAddress?.state,
        country: pgDetailsData?.g_location?.country ? pgDetailsData?.g_location?.country : defaultAddress?.country,
        lat: pgDetailsData?.g_location?.lat ? parseFloat(pgDetailsData?.g_location?.lat) : defaultAddress?.lat,
        lng: pgDetailsData?.g_location?.lat ?  parseFloat(pgDetailsData?.g_location?.lng) : defaultAddress?.lng
      },
      // pg_rules: pgDetailsData?.pg_rules,
      pg_rules: {
        veg_only: pgDetailsData?.pg_rules?.veg_only ?? false,
        no_smoking: pgDetailsData?.pg_rules?.no_smoking ?? false,
        drinking_alcohol_not_allowed: pgDetailsData?.pg_rules?.drinking_alcohol_not_allowed ?? false,
        entry_of_opposite_gender_not_allowed: pgDetailsData?.pg_rules?.entry_of_opposite_gender_not_allowed ?? false,
        entry_of_visitors_not_allowed: pgDetailsData?.pg_rules?.entry_of_visitors_not_allowed ?? false,
      },
      // common_area_amenities: pgDetailsData?.common_area_amenities,
      common_area_amenities: {
        Kitchen_for_self_cooking: pgDetailsData?.common_area_amenities?.Kitchen_for_self_cooking ?? false,
        RO: pgDetailsData?.common_area_amenities?.RO ?? false,
        Fridge: pgDetailsData?.common_area_amenities?.Fridge ?? false,
        Microwave: pgDetailsData?.common_area_amenities?.Microwave ?? false,
        Lift: pgDetailsData?.common_area_amenities?.Lift ?? false,
        Gymnasium: pgDetailsData?.common_area_amenities?.Gymnasium ?? false,
        PowerBackup: pgDetailsData?.common_area_amenities?.PowerBackup ?? false,
        WiFi: pgDetailsData?.common_area_amenities?.WiFi ?? false,
        TV: pgDetailsData?.common_area_amenities?.TV ?? false,
        Parking: pgDetailsData?.common_area_amenities?.Parking ?? false,
      },
      // food_provided: pgDetailsData?.food_provided,
      food_provided: {
        Breakfast: pgDetailsData?.food_provided?.Breakfast ?? false,
        Lunch: pgDetailsData?.food_provided?.Lunch ?? false,
        Dinner: pgDetailsData?.food_provided?.Dinner ?? false,
      },
      food_type_provided: pgDetailsData?.food_type_provided ? pgDetailsData?.food_type_provided : null,
      food_charges: pgDetailsData?.food_charges ? pgDetailsData?.food_charges : null,
      g_location: pgDetailsData?.g_location,
      formetted_address: pgDetailsData?.formetted_address,
      description: pgDetailsData?.description ?? "",
      terms_and_condition: true,
      lat: pgDetailsData?.g_location?.lat ? parseFloat(pgDetailsData?.g_location?.lat) : defaultAddress?.lat,
      lng: pgDetailsData?.g_location?.lat ?  parseFloat(pgDetailsData?.g_location?.lng) : defaultAddress?.lng,
      searchAddress: pgDetailsData?.formetted_address,
    });
  };
  const onFindError = (data) => {
    setLoading(false);
  };

  let propertyId = null;
  let usetId = null;
  if (pgDetails && pgDetails?.id) {
    propertyId = (pgDetails?.id).split("-");
  }
  const propertyBasicDetails = [
    {
      label: "Property ID",
      value: propertyId && propertyId[0].toUpperCase(),
      display: true,
    },
    {
      label: "Property Type",
      value: pgDetails?.main_property_type === "pg" && "PG",
      display: true,
    },
    {
      label: "Property Price",
      value: "",
      display: false,
    },
    {
      label: "Subscription Plan",
      value: "",
      display: pgDetails?.subscription ? true : false,
    },
    {
      label: "Validity",
      value: "",
      display: pgDetails?.subscription ? true : false,
    },
    {
      label: "Posted By",
      value:
        pgDetails?.name +
        `(${removeUnderScore(pgDetails?.owner_type ?? "--")})`,
      display: true,
    },
    {
      label: "Posted Date & Time",
      value: moment(pgDetails?.createdAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
    {
      label: "Updated Date & Time",
      value: moment(pgDetails?.updatedAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
  ];

  const handleDateChange = (e) => {
    formik.setFieldValue("pg_operation_since", e.target.value);
  };
  const preferredGender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Both", label: "Both" },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      // single_sharing: Yup.object().shape({
      //   number: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     console.log(isEnabled, "isEnabled1");
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   }),
      //   rent: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //     console.log(isEnabled, "isEnabled");
      //   })
      // }),
      
      // single_sharing: Yup.object().shape({
      //   number: Yup.string().test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     console.log(isEnabled, "isEnabled1");
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   }),
      //   rent: Yup.string().test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //     console.log(isEnabled, "isEnabled");
      //   })
      // }),
      
      
      
      // double_sharing: Yup.object().shape({
      //   number: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     console.log(isEnabled, "isEnabled1");
      //     if(isDouble){
      //       console.log("entered", "entered");
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   }),
      //   rent: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   })
      // }),


      // triple_sharing: Yup.object().shape({
      //   number: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   }),
      //   rent: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //     console.log(isEnabled, "isEnabled");
      //   })
      // }),
      // four_sharing: Yup.object().shape({
      //   number: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   }),
      //   rent: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   })
      // }),
      // other: Yup.object().shape({
      //   number: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   }),
      //   rent: Yup.string().matches(amountTypeRegex, "enter a valid number").test("isEnabled", "Required", function(value){
      //     const isEnabled = this.parent.enabled;
      //     if(isEnabled){
      //       return value;
      //     } else{
      //       return true;
      //     }
      //   })
      // }),
      pg_details: Yup.object({ pg_name: Yup.string().required("Required") }),
      notice_period: Yup.string().required("Required"),
      // searchAddress: Yup.string().required("Required"),
      g_location: Yup.object({
        city: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
      }),
    }),
    
    // validationSchema: Yup.object({
    //   g_location: Yup.object({
    //     lat: Yup.string().required("Select your location in the map"),
    //     lng: Yup.string()
    //       .required("Select your location in the map")
    //       .required("Required"),
    //   }),
    //   pg_details: Yup.object({ pg_name: Yup.string().required("Required") }),
    //   preferred_gender: Yup.string().required("Required"),
    //   notice_period: Yup.string().required("Required"),
    //   // single_sharing: Yup.object({ number: Yup.number() }),
    //   single_sharing: Yup.object().shape({
    //     number: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //     rent: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //   }),
    //   double_sharing: Yup.object().shape({
    //     number: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //     rent: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //   }),
    //   triple_sharing: Yup.object().shape({
    //     number: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //     rent: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //   }),
    //   four_sharing: Yup.object().shape({
    //     number: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //     rent: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //   }),
    //   other: Yup.object().shape({
    //     number: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //     rent: Yup.string().typeError("Enter valid number").test("isSingleEnabled", "Required", function(value){
    //       const isSingle = this.parent.enabled
    //       if(isSingle){
    //         return value;
    //       } else {
    //         return true;
    //       }
    //     }),
    //   }),
    //   // isRoomSharing : Yup.string().nullable().test("atLeastOneTrue", "At least one room sharing must be true", function(value))
    // }),
    onSubmit: (values) => {
      console.log(values, "aksjbdsjada");
      if (values["pg_operation_since"] != null) {
        const inputDate = new Date(values["pg_operation_since"]);
        if (isNaN(inputDate.getTime())) {
          console.error(
            "Invalid date format for pg_operation_since:",
            values["pg_operation_since"]
          );
        } else {
          const formattedDate = inputDate.toISOString();
          values["pg_operation_since"] = formattedDate;
        }
      }
      dispatch(
        editPgAction(
          {
            ...values,
            pg_details: {
              address: values?.g_location?.address_line_2,
              name: values?.g_location?.city,
              location: {
                lat: values?.g_location?.lat > 0 ? values?.g_location?.lat : defaultAddress?.lat,
                long: values?.g_location?.lng > 0 ? values?.g_location?.lng : defaultAddress?.lng,
              },
              pg_name: values?.pg_details?.pg_name,
            },
            single_sharing: {
              ...values?.single_sharing,
              number: parseInt(values?.single_sharing?.number),
              rent: parseInt(values?.single_sharing?.rent),
              security_deposite: parseInt(values?.single_sharing?.security_deposite),
              photos: singlePhotos ?? []
            },
            double_sharing: {
              ...values?.double_sharing,
              number: parseInt(values?.double_sharing?.number),
              rent: parseInt(values?.double_sharing?.rent),
              security_deposite: parseInt(values?.double_sharing?.security_deposite),
              photos: doublePhotos ?? []
            },
            triple_sharing: {
              ...values?.triple_sharing,
              number: parseInt(values?.triple_sharing?.number),
              rent: parseInt(values?.triple_sharing?.rent),
              security_deposite: parseInt(values?.triple_sharing?.security_deposite),
              photos: triplePhotos ?? []
            },
            four_sharing: {
              ...values?.four_sharing,
              number: parseInt(values?.four_sharing?.number),
              rent: parseInt(values?.four_sharing?.rent),
              security_deposite: parseInt(values?.four_sharing?.security_deposite),
              photos: fourPhotos ?? []
            },
            other: {
              ...values?.other,
              number: parseInt(values?.other?.number),
              rent: parseInt(values?.other?.rent),
              security_deposite: parseInt(values?.other?.security_deposite),
              photos: otherPhotos ?? []
            },
          },
          onEditSuccess,
          onEditError
        )
      );
    },
  });
  console.log(isDouble, "isDouble");
  const onEditSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.data);
    console.log(data?.data?.data, "datapg")
  };
  const isCommonMatches = objectsAreEqual(initialValues?.common_area_amenities, formik?.values?.common_area_amenities);
  const isPgRulesMatches = objectsAreEqual(initialValues?.pg_rules, formik?.values?.pg_rules);
  const isFoodProvidedMatches = objectsAreEqual(initialValues?.food_provided, formik?.values?.food_provided);

  function valuesCompareFn(values) {
    const singleSharing = isEqual(convertValuesToIntegers(initialValues?.single_sharing), convertValuesToIntegers(formik?.values?.single_sharing));
    const doubleSharing = isEqual(convertValuesToIntegers(formik?.values?.double_sharing), convertValuesToIntegers(initialValues?.double_sharing));
    const tripleSharing = isEqual(convertValuesToIntegers(formik?.values?.triple_sharing), convertValuesToIntegers(initialValues?.triple_sharing));
    const fourSharing = isEqual(convertValuesToIntegers(formik?.values?.four_sharing), convertValuesToIntegers(initialValues?.four_sharing));
    const otherSharing = isEqual(convertValuesToIntegers(formik?.values?.other), convertValuesToIntegers(initialValues?.other));
    // console.log(singleSharing, "compsingleval");
    const pg_rules = isEqual(formik?.initialValues?.pg_rules, formik?.values?.pg_rules);
    const commonAme = isEqual(initialValues?.common_area_amenities, formik?.values?.common_area_amenities);
    const foodPrvieded = isEqual(formik?.initialValues?.food_provided, formik?.values?.food_provided);
    const foodTypeProvided = isEqual(formik?.initialValues?.food_type_provided, formik?.values?.food_type_provided);
    const foodCharges = isEqual(formik?.initialValues?.food_charges, formik?.values?.food_charges);
    const noticePeriod = isEqual(formik?.initialValues?.notice_period, formik?.values?.notice_period);
    // console.log(pg_rules, commonAme, foodPrvieded, foodTypeProvided, foodCharges,  "remeame");

    const singlePhotoComp = isEqual(pgDetails?.single_sharing?.photos ?? true, singlePhotos ?? true);
    const doublePhotoComp = isEqual(pgDetails?.double_sharing?.photos ?? true, doublePhotos ?? true);
    const triplePhotoComp = isEqual(pgDetails?.triple_sharing?.photos ?? true, triplePhotos ?? true);
    const fourPhotoComp = isEqual(pgDetails?.four_sharing?.photos ?? true, fourPhotos ?? true);
    const otherPhotoComp = isEqual(pgDetails?.other?.photos ?? true, otherPhotos ?? true);
    const pgName = isEqual(pgDetails?.pg_details?.pg_name ?? true, values?.pg_details?.pg_name ?? true);
    const lat = isEqual(initialValues?.g_location?.lat, values?.g_location?.lat);
    const description = isEqual(initialValues?.description, values?.description);
    const pgDate = isEqual(initialValues?.pg_operation_since, values?.pg_operation_since);
    const preferredGender = isEqual(initialValues?.preferred_gender, values?.preferred_gender)
    // console.log(singlePhotoComp, doublePhotoComp, triplePhotoComp, fourPhotoComp, otherPhotoComp, pgDate, preferredGender, "singlePhotocompare");
    if(singleSharing && doubleSharing && tripleSharing && fourSharing && otherSharing && isCommonMatches && isPgRulesMatches && isFoodProvidedMatches && doublePhotoComp && singlePhotoComp && triplePhotoComp && fourPhotoComp && otherPhotoComp && pgName && lat && noticePeriod && isTwoAmeMatches && isOneAmeMathces && isTripleAmeMatches && isFourAmeMatches && isOtherMatches && description && foodTypeProvided && foodCharges && pgDate && preferredGender){
      return true;
    } else {
      return false;
    }
  } 
  useEffect(()=>{
    valuesCompareFn(formik?.values)
  },[loading]);
  useEffect(() => {
    if (initialValues?.pg_details?.location?.lat) {
      setMapLoading(true);
    }
  }, [initialValues?.pg_details?.location?.lat]);
  useEffect(() => {
    setIsDouble(formik?.values?.double_sharing?.enabled)
  }, [formik?.values?.double_sharing?.enabled]);
  
  console.log(formik?.values, "pgvalues");
  return (
    <div>
      <div className="font_family_poppins" style={{ overflow: "hidden" }}>
        <p className="fw_400 fs_16 text_color_grey ms-5 mt-3 ">
          ADMIN / PROPERTIES / LIVE PROPERTIES / - VIEW
        </p>
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className={` ${styles.border_color_gray} rounded-4 m-4 `}>
              <Modal_property
              show={show}
              mainPropertyType={pgDetails?.main_property_type}
              handleclose={() => setShow(false)}
              background={modalButton === "Approve" ? "green" : "red"}
              btnText={modalButton === "Approve" ? "approve" : "reject"}
              propertyStatus={propertyStatus}
              navigateUrl={"/property-project/pg-requests"}
            />
              <div className={`d-flex justify-content-between my-2 `}>
                <div
                  className="d-flex align-items-center ms-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  <Leftarrowicon />
                  <Primarybutton
                    btnText={"BACK"}
                    className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
                  />
                </div>
                <div>
                  {!valuesCompareFn(formik?.values) ? (
                    <button className="btn btn-primary me-3" type="submit">
                      submit
                    </button>
                  ) : location.pathname.includes("live-pg")
                  ? null : (
               // : location.pathname.includes("live-property-requests") &&
               //   propertyDetails.status === "approved" ? null : (
                    <>
                      <Primarybutton
                        btnText={"APPROVE"}
                        type="button"
                        onClick={() => {
                          setModalButton("Approve");
                          setShow(true);
                          setPropertyStatus("approved");
                        }}
                        className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
                      />
                      <Primarybutton
                        btnText={"REJECT"}
                        type="button"
                        onClick={() => {
                          setModalButton("Reject");
                          setShow(true);
                          setPropertyStatus("rejected");
                        }}
                        className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="border-bottom"></div>
              <div
                className="scrolling px-3"
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "70vh",
                }}
              >
                <PropertyBasicDetails
                  propertyBasicDetails={propertyBasicDetails}
                />
                <div className={` my-3 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>
                    Property Location
                  </h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                </div>
                <div className="row position-relative">
                {/* <div className="col-7">
                  {mapLoading ? (
                    <LocationAndSearch formik={formik} />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center col-7 w-100"
                      style={{ height: "20rem" }}
                    >
                      <Spinner />
                    </div>
                  )}
                </div> */}
                  <div className="col-5">
                    {/* <div>{formik.values.formetted_address}</div> */}
                    {/* <div>{formik.values.project_or_society_name}</div> */}
                    {formik?.values && <PropertyLocation formik={formik} />}
                    <div className="mt-3">
                    <UseFormikInputField
                      placeholder="Enter Project/Society Name (optional)"
                      inputValue={formik?.values?.pg_details?.pg_name}
                      onChangeFn={formik.handleChange}
                      onBlurFn={formik.handleBlur}
                      formikError={formik.errors?.pg_details?.pg_name}
                      formikTouched={formik.touched?.pg_details?.pg_name}
                      itemName="pg_details.pg_name"
                      labelName="PG Name"
                    />
                  </div>
                  <label className={`secondary_color fs_15`}>PG Operation Since</label>
                  <div className={` border_radius_5px input_border focus_none mb-3`}>
                   <input
                     type="date"
                     name="pg_operation_since"
                     className={`${styles.input_text} w-100 px-2 custom-date-picker  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
                     id={"pg_operation_since"}
                     value={formik?.values?.pg_operation_since?.split("T")[0]}
                     onChange={handleDateChange}
                   />
                  </div>
                  <div>
                 <UseFormikSelectField
                  label="Preferred Gender"
                  customStyles={{ width: "100%", marginBottom: "0px" }}
                  name="preferred_gender"
                  formik={formik}
                  formikError={formik?.errors?.preferred_gender}
                  formikTouched={formik?.touched?.preferred_gender}
                  defaultValue={
                    formik?.values?.preferred_gender && {
                    label: removeUnderScore(formik?.values?.preferred_gender),
                    value: formik?.values?.preferred_gender,
                   }
                  }
                  options={preferredGender}
                 />
                </div>
                  </div>
                </div>
                <div className="pg_features mt-4">
                  <div className={` my-3 d-flex align-items-center`}>
                    <h5 className={`light_blue_color fs_18 me-3`}>
                      PG Features
                    </h5>
                    <hr className={`py-1 flex-grow-1 border-primary`} />
                  </div>
                  <EditPgFeatures
                    formik={formik}
                    pgDetails={pgDetails}
                    singlePhotos={singlePhotos}
                    setSinglePhotos={setSinglePhotos}
                    doublePhotos={doublePhotos}
                    setDoublePhotos={setDoublePhotos}
                    triplePhotos={triplePhotos}
                    setTriplePhotos={setTriplePhotos}
                    fourPhotos={fourPhotos}
                    setFourPhotos={setFourPhotos}
                    otherPhotos={otherPhotos}
                    setOtherPhotos={setOtherPhotos}
                    setIsTwoAmeMatches={setIsTwoAmeMatches}
                    setIsOneAmeMathces={setIsOneAmeMathces}
                    setIsTripleAmeMatches={setIsTripleAmeMatches}
                    setIsFourAmeMatches={setIsFourAmeMatches}
                    setIsOtherMatches={setIsOtherMatches}
                  />
                </div>
                <div className="pg_rules mt-5">
                  <EditPgRules formik={formik}/>
                </div>
                <div className="pg_rules mt-3">
                  <EditPgCommonAmenities formik={formik}/>
                </div>
                <div className="pg_rules mt-3 mb-3">
                  <EditPgFoodProvided formik={formik}/>
                </div>
                <div className="discription">
                 <div className={`d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>
                   Description
                  </h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                  </div>
                  <UseFormikInputField
                    label_font_size=""
                    labelName=""
                    itemName={"description"}
                    inputValue={formik.values.description}
                    onChangeFn={formik.handleChange}
                    onBlurFn={formik.handleBlur}
                    formikError={formik.errors.description}
                    formikTouched={formik.touched.description}
                    placeholder="Describe your project"
                    input={false}
                    TextArea={true}
                  />
               </div>
              </div>
            </div>
            {/* <button className="btn btn-primary" type="submit">submit</button> */}
          </form>
        )}
      </div>
    </div>
  );
};

export default PgEditView;
