import React from "react";
import moment from "moment";
import { nFormatter } from "../helper-functions/helperFunctions";
const ProjectFeatures = ({ data, plan }) => {
  console.log(data, "sd");
  const basic_details = [
    {
      title: "Project Type",
      data: data?.project_type,
    },
    {
      title: "Number Of Units",
      data: data?.no_of_units,
    },
    {
      title: "Status of Project Construction",
      data: data?.status_of_projects_construction,
    },
    {
      title: "Subscription Plan",
      data: data?.subscribed_at,
    },
    {
      title: "Posted Date & Time",
      data: moment(data?.createdAt).format("DD MMM YYYY, HH:MM A"),
    },
    {
      title: "Posted By",
      data: data?.name,
    },
    {
      title: "Project Price",
      data: data?.no_of_units,
    },
  ];

  return (
    <>
      <div className=" mx-4 font_family_poppins row g-0">
        {basic_details?.map((item) => (
          <>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <div className="me-4">
                <h6 className="fs_14 fw_400 text_color_grey text-capitalize">
                  {item?.title}
                </h6>
                <h6 className="fs_14 fw_400 text_color_darkblue text-capitalize">
                  {item?.data ? item?.data : "--"}
                </h6>
              </div>
            </div>
          </>
        ))}

        {/* <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="me-4">
            <h6 className="fs_14 fw_400 text_color_grey">Project Type</h6>
            <h6 className="fs_14 fw_400 text_color_darkblue text-capitalize">
              {data?.project_type}
            </h6>
          </div>
        </div>

        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="me-4">
            <h6 className="fs_14 fw_400 text_color_grey">Number Of Units</h6>
            <h6 className="fs_14 fw_400 text_color_darkblue">
              {data?.no_of_units}
            </h6>
          </div>
        </div>

        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="me-4">
            <h6 className="fs_14 fw_400 text_color_grey">
              Status of Project Construction
            </h6>
            <h6 className="fs_14 fw_400 text_color_darkblue text-capitalize">
              {data?.status_of_projects_construction}
            </h6>
          </div>
        </div>

        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="mx-4">
            <h6 className="fs_14 fw_400 text_color_grey ">Validity</h6>
            <h6 className="fs_14 fw_400 text_color_darkblue">
              {data?.SubscriptionPlanDetail?.plan_duration}
            </h6>
          </div>
        </div>

        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="me-4">
            <h6 className="fs_14 fw_400 text_color_grey">Subscription Plan</h6>
            <h6 className="fs_14 fw_400 text_color_darkblue text-capitalize">
              {data?.SubscriptionPlanDetail?.plan_type}
            </h6>
          </div>
        </div>

        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="me-4">
            <h6 className="fs_14 fw_400 text_color_grey">Posted Date & Time</h6>
            <h6 className="fs_14 fw_400 text_color_darkblue">{dateAndTime}</h6>
          </div>
        </div>

        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="me-4">
            <h6 className="fs_14 fw_400 text_color_grey">Posted By</h6>
            <h6 className="fs_14 fw_400 text_color_darkblue">
              {data?.user_id}(
              <span className="text-capitalize"> {data?.owner_type}</span>)
            </h6>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
          <div className="me-4">
            <h6 className="fs_14 fw_400 text_color_grey">Project Price</h6>
            <h6 className="fs_14 fw_400 text_color_darkblue">
              <span className="me-2">&#8377;</span>{" "}
              {nFormatter(
                data?.project_features?.map((item) => item?.expected_price)
              )}
            </h6>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProjectFeatures;
