import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LocationAndSearch from "../../../../components/map/locationSearch";
import OfficeAddPropertyPhoto from "../../../../components/edit-property/commercial/office-space/office-add-property-photo";
import {
  amountTypeRegex,
  convertValuesToIntegers,
  defaultAddress,
  inputRegex,
  removeUnderScore,
} from "../../../../components/helper/helper";
import CommercialPlotFeatures from "../../../../components/edit-property/commercial/commercial-plot/commercial-plot-features";
import ResidentialPlotPriceDetails from "../../../../components/edit-property/residential/residential-plot-price-details";
import PlotRentPriceDetails from "../../../../components/edit-property/residential/plot-rent-price-details";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  commercialFindOneAction,
  editCommercialPlotAction,
} from "../../../../redux/action/edit-property-actions/commercial";
import moment from "moment";
import PropertyBasicDetails from "../../../../components/edit-property/property-basic-details";
import isEqual from "lodash.isequal";
import Leftarrowicon from "../../../../assets/icons/left-arrow-icon";
import Primarybutton from "../../../../components/ui/buttons/primary-button";
import Modal_property from "../../../../components/ui/modals/modal_property";
import styles from "../../../../styles/property-details.module.css";
import successToast from "../../../../components/helper-functions/successToast";
import errorToast from "../../../../components/helper-functions/errorToast";
import { Spinner } from "react-bootstrap";
import UseFormikInputField from "../../../form/useFormikInputField";
import PropertyLocation from "../../../map/property-location";
const CommercialPlot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [mainPropertyType, setMainPropertyType] = useState("");
  const [modalButton, setModalButton] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [amitities, setAmitities] = useState();
  const [mapLoading, setMapLoading] = useState(false);
  const [multiImage, setMultiImage] = useState([]);
  const [isClickedOnAme, setIsClickedOnAme] = useState(false);
  const [compareAminities, setCompareAminities] = useState(true);
  const [initialValues, setInitialValues] = useState({
    property_id: "",
      post_type: "",
      property_type: "",
      property_details: {
        name: "",
        address: "",
        location: {
          lat: null,
          long: null,
        },
      },
      property_features: {
        no_of_open_sides: null,
        floors_allowed_for_construction: null,
        any_construction_done: false,
        corner_site: false,
        boundary_wall_made: false,
        facing: "",
        suitable_for: [],
      },
      price_details: {
        plot_length: null,
        plot_width: null,
        plot_area: null,
        expected_price: null,
        deposite_price: null,
        rent_price: null,
      },
      g_location: {
        address_line_1:"",
        address_line_2:"",
        area:"",
        city:"",
        postCode:"",
        state:"",
        country:"",
        lat:null,
        lng:null,
      },
      property_photos: [],
      description: "",
      terms_and_condition: true,
      lat: null,
      lng: null,
      project_or_society_name: "",
      formetted_address: "",
      searchAddress: "",
  });
  
  const [commercialPlot, setCommercialPlot] = useState("");
  let propertyId = null;
  let usetId = null;
  if (commercialPlot && commercialPlot?.id) {
    propertyId = (commercialPlot?.id).split("-");
    usetId = (commercialPlot?.User?.id).split("-");
  }

  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    dispatch(
      commercialFindOneAction(
        { property_id: params.id, property_type: params?.type },
        onFindSuccess,
        onFindError
      )
    );
  }, [reload]);
  const onFindSuccess = (data) => {
    const propertyDetailsData = data?.data;
    setLoading(false);
    setCommercialPlot(propertyDetailsData);
    setMultiImage(data?.data?.property_photos);
    setMainPropertyType(data?.data?.main_property_type);
    setAmitities(data?.data?.property_features?.suitable_for);
    setInitialValues({
      property_id: propertyDetailsData?.id,
      post_type: propertyDetailsData?.post_type,
      property_type: propertyDetailsData?.property_type,
      property_details: {
        name: propertyDetailsData?.property_details?.name,
        address: propertyDetailsData?.property_details?.address ? propertyDetailsData?.property_details?.address : defaultAddress?.address_line_2,
        location: {
          lat: propertyDetailsData?.property_details?.location?.lat ? propertyDetailsData?.property_details?.location?.lat : defaultAddress?.lat,
          long: propertyDetailsData?.property_details?.location?.long ? propertyDetailsData?.property_details?.location?.long : defaultAddress?.lng,
        },
      },
      property_features: {
        no_of_open_sides: propertyDetailsData?.property_features?.no_of_open_sides > 0 ? propertyDetailsData?.property_features?.no_of_open_sides : "",
        floors_allowed_for_construction: propertyDetailsData?.property_features?.floors_allowed_for_construction > 0
         ? propertyDetailsData?.property_features?.floors_allowed_for_construction : "",
        any_construction_done:
          propertyDetailsData?.property_features?.any_construction_done,
        corner_site: propertyDetailsData?.property_features?.corner_site,
        boundary_wall_made:
          propertyDetailsData?.property_features?.boundary_wall_made,
        facing: propertyDetailsData?.property_features?.facing,
        suitable_for: propertyDetailsData?.property_features?.suitable_for || [],
      },
      price_details: {
        plot_length: propertyDetailsData?.price_details?.plot_length > 0 ? propertyDetailsData?.price_details?.plot_length : "",
        plot_width: propertyDetailsData?.price_details?.plot_width > 0 ? propertyDetailsData?.price_details?.plot_width : "",
        plot_area: propertyDetailsData?.price_details?.plot_area > 0 ? propertyDetailsData?.price_details?.plot_area : "",
        expected_price: propertyDetailsData?.price_details?.expected_price > 0 ? propertyDetailsData?.price_details?.expected_price : "",
        deposite_price: propertyDetailsData?.price_details?.deposite_price > 0 ? propertyDetailsData?.price_details?.deposite_price : "",
        rent_price: propertyDetailsData?.price_details?.rent_price > 0 ? propertyDetailsData?.price_details?.rent_price : "",
      },
      g_location: {
        address_line_1: propertyDetailsData?.g_location?.address_line_1 ? propertyDetailsData?.g_location?.address_line_1 : defaultAddress?.address_line_1,
        address_line_2: propertyDetailsData?.g_location?.address_line_2 ? propertyDetailsData?.g_location?.address_line_2 : defaultAddress?.address_line_2,
        area: propertyDetailsData?.g_location?.area ? propertyDetailsData?.g_location?.area : defaultAddress?.area,
        city: propertyDetailsData?.g_location?.city ? propertyDetailsData?.g_location?.city : defaultAddress?.city,
        postCode: propertyDetailsData?.g_location?.postCode ? propertyDetailsData?.g_location?.postCode : defaultAddress?.postCode,
        state: propertyDetailsData?.g_location?.state ? propertyDetailsData?.g_location?.state : defaultAddress?.state,
        country: propertyDetailsData?.g_location?.country ? propertyDetailsData?.g_location?.country : defaultAddress?.country,
        lat: propertyDetailsData?.g_location?.lat ? propertyDetailsData?.g_location?.lat : defaultAddress?.lat,
        lng: propertyDetailsData?.g_location?.lng ? propertyDetailsData?.g_location?.lng : defaultAddress?.lng,
      },
      property_photos: propertyDetailsData?.property_photos ?? [],
      description: propertyDetailsData?.description,
      terms_and_condition: true,
      lat: propertyDetailsData?.g_location?.lat ? propertyDetailsData?.g_location?.lat : defaultAddress?.lat,
      lng: propertyDetailsData?.g_location?.lng ? propertyDetailsData?.g_location?.lng : defaultAddress?.lng,
      project_or_society_name: propertyDetailsData?.project_or_society_name,
      formetted_address: propertyDetailsData?.formetted_address ?? "",
      searchAddress: propertyDetailsData?.formetted_address ?? "",
    })
  };
  const onFindError = (data) => {
    setLoading(false);
  };

  const propertyBasicDetails = [
    {
      label: "Property ID",
      value: propertyId && propertyId[0].toUpperCase(),
      display: true,
    },
    {
      label: "Property Type",
      value:
        commercialPlot?.property_type === "commercial_plot" &&
        "Commercial Plot",
      display: true,
    },
    {
      label: "Property Price",
      value: commercialPlot?.post_type === "sale" ? "₹ " + commercialPlot?.price_details?.expected_price : "₹ " + commercialPlot?.price_details?.rent_price,
      display: true,
    },
    {
      label: "Subscription Plan",
      value: "",
      display: commercialPlot?.subscription ? true : false,
    },
    {
      label: "Validity",
      value: "",
      display: commercialPlot?.subscription ? true : false,
    },
    {
      label: "Posted By",
      value: commercialPlot?.name + ` (${removeUnderScore(commercialPlot?.owner_type)})`,
      display: true,
    },
    {
      label: "Posted Date & Time",
      value: moment(commercialPlot?.createdAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
    {
      label: "Updated Date & Time",
      value: moment(commercialPlot?.updatedAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      // g_location: Yup.object({
      //   lat: Yup.string().required("Select your location in the map"),
      //   lng: Yup.string()
      //     .required("Select your location in the map")
      //     .required("Required"),
      // }),
      property_features: Yup.object({
        floors_allowed_for_construction: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
        no_of_open_sides: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
      }),
      price_details: Yup.object({
        plot_length: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        plot_width: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        plot_area: Yup.string()
          .matches(amountTypeRegex, "Enter a valid number")
          .required("Required"),
        expected_price: Yup.string()
          .matches(amountTypeRegex, "Enter a valid number")
          .test("postTypeSale", "Required", function (value) {
            if (commercialPlot?.post_type === "sale") {
              return value;
            } else {
              return true;
            }
          }),

        rent_price: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .test("postTypeRent", "Required", function (value) {
            if (commercialPlot?.post_type === "rent") {
              return value;
            } else {
              return true;
            }
          }),
        deposite_price: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
      }),
      // searchAddress: Yup.string().required("Required"),
      g_location: Yup.object({
        city: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
      }),
    }),
    onSubmit: (values) => {
      console.log(values, "aksjbdsjada");
      const updatedSuitableFor = values.property_features.suitable_for.map((item) => ({ name: item.name, status: item.status }));
      dispatch(editCommercialPlotAction({
        ...values,
            price_details: {
              plot_area: parseInt(values?.price_details?.plot_area),
              plot_length: parseInt(values?.price_details?.plot_length),
              plot_width: parseInt(values?.price_details?.plot_width),
              rent_price: parseInt(values?.price_details?.rent_price),
              deposite_price: parseInt(values?.price_details?.deposite_price),
            },
            property_features: {
              ...values?.property_features,
              floors_allowed_for_construction: parseInt(values?.property_features?.floors_allowed_for_construction),
              no_of_open_sides: parseInt(values?.property_features?.no_of_open_sides),
              suitable_for: updatedSuitableFor,
            },
            property_photos: [...multiImage]
      }, onEditSuccess, onEditError));
      setIsClickedOnAme(true);
    },
  });
  const onEditSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
  };
  useEffect(() => {
    if (initialValues?.lat) {
      setMapLoading(true);
    }
  }, [initialValues?.lat]);

  const initValuesWithoutAmenitiesFea = { ...initialValues?.property_features }
  delete initValuesWithoutAmenitiesFea?.suitable_for;

  const formikValuesWithoutAmenitiesFea = { ...formik?.values?.property_features }
  delete formikValuesWithoutAmenitiesFea?.suitable_for;
  function valuesCompareFn(values) {
    const propertyFeature = isEqual(initValuesWithoutAmenitiesFea, formikValuesWithoutAmenitiesFea);
    const priceDetails = isEqual(convertValuesToIntegers(formik?.initialValues?.price_details),convertValuesToIntegers(values?.price_details));
    const propertyPhotos = isEqual(formik?.initialValues?.property_photos, multiImage);
    const lat = isEqual(formik?.initialValues?.g_location?.lat, values?.g_location?.lat);
    const description = isEqual(initialValues?.description, values?.description);
    const gLocation = isEqual(initialValues?.g_location,values?.g_location);
    console.log(priceDetails,propertyFeature,propertyPhotos,lat,"priceDetails");
    if (propertyFeature && priceDetails && propertyPhotos && lat && gLocation && compareAminities && description) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      {loading ? <div className="vh-100 d-flex justify-content-center align-items-center"><Spinner /></div>
      :
      <form onSubmit={formik.handleSubmit}>
        <div className={` ${styles.border_color_gray} rounded-4 m-4 `}>
          <Modal_property
            show={show}
            mainPropertyType={mainPropertyType}
            handleclose={() => setShow(false)}
            background={modalButton === "Approve" ? "green" : "red"}
            btnText={modalButton === "Approve" ? "approve" : "reject"}
            propertyStatus={propertyStatus}
            navigateUrl={
              location.pathname.includes("live-property-requests")
                ? "/property-project/live-property-requests"
                : "/property-project/property-requests"
            }
          />
          <div className={`d-flex justify-content-between my-2 `}>
            <div
              className="d-flex align-items-center ms-4"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <Leftarrowicon />
              <Primarybutton
                btnText={"BACK"}
                className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
              />
            </div>
            <div>
              {!valuesCompareFn(formik?.values) ? (
                <button
                  className="btn btn-primary me-3"
                  type="submit"
                  // disabled={valuesCompareFn(formik?.values)}
                >
                  submit
                </button>
              ) : location.pathname.includes("live-property-requests")
              ? null : (
           // : location.pathname.includes("live-property-requests") &&
           //   propertyDetails.status === "approved" ? null : (
                <>
                  <Primarybutton
                    btnText={"APPROVE"}
                    type="button"
                    onClick={() => {
                      setModalButton("Approve");
                      setShow(true);
                      setPropertyStatus("approved");
                    }}
                    className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
                  />
                  <Primarybutton
                    btnText={"REJECT"}
                    type="button"
                    onClick={() => {
                      setModalButton("Reject");
                      setShow(true);
                      setPropertyStatus("rejected");
                    }}
                    className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
                  />
                </>
              )}
            </div>
          </div>
          <div className="border-bottom"></div>
          <div
            className="scrolling mx-3"
            style={{ overflowY: "auto", overflowX: "hidden", height: "70vh" }}
          >
            <PropertyBasicDetails propertyBasicDetails={propertyBasicDetails} />
            <div className={` my-3 d-flex align-items-center`}>
              <h5 className={`light_blue_color fs_18 me-3`}>
                Property Location
              </h5>
              <hr className={`py-1 flex-grow-1 border-primary`} />
            </div>
            <div className="row position-relative">
              {/* <div className="col-7">
                {mapLoading ? (
                  <LocationAndSearch formik={formik} />
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center col-7 w-100"
                    style={{ height: "20rem" }}
                  >
                    <Spinner />
                  </div>
                )}
              </div> */}
              <div className="col-5">
              {formik?.values && <PropertyLocation formik={formik} />}
              </div>
            </div>
            <CommercialPlotFeatures 
             formik={formik} 
             setCompareAminities={setCompareAminities} 
             propertyDetails={commercialPlot}
             setIsClickedOnAme={setIsClickedOnAme}
             isClickedOnAme={isClickedOnAme}
             amenitiesType={"commercial_plot"}
             />
            {commercialPlot?.post_type === "sale" ? (
              <ResidentialPlotPriceDetails formik={formik} />
            ) : (
              <PlotRentPriceDetails formik={formik} />
            )}
            <OfficeAddPropertyPhoto
              multiImage={multiImage}
              setMultiImage={setMultiImage}
            />
            <div className="discription">
                 <div className={`mt-5 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>
                   Description
                  </h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                  </div>
                  <UseFormikInputField
                    label_font_size=""
                    labelName=""
                    itemName={"description"}
                    inputValue={formik.values.description}
                    onChangeFn={formik.handleChange}
                    onBlurFn={formik.handleBlur}
                    formikError={formik.errors.description}
                    formikTouched={formik.touched.description}
                    placeholder="Describe your project"
                    input={false}
                    TextArea={true}
                  />
        </div>
          </div>
        </div>
      </form>
      }
      
    </div>
  );
};

export default CommercialPlot;
