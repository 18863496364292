import { LOACTION_SEARCH, LOCATION_UPDATE, PROPERTY_DATA_EXPORT, REQUEST_CALLBACK_EXPORT, SEARCH_AUTOCOMPLETE } from "../../type";

export const requestCallBackDataAction = (data, onSuccess, onError) => {
  return {
    type: REQUEST_CALLBACK_EXPORT,
    data,
    onSuccess,
    onError,
  };
};
export const propertyDataAction = (data, onSuccess, onError) => {
  return {
    type: PROPERTY_DATA_EXPORT,
    data,
    onSuccess,
    onError,
  };
};

export const LoactionSearchAction = (data,  data1, onSuccess, onError) => {
  return {
    type: LOACTION_SEARCH,
    data,
    data1,
    onSuccess,
    onError,
  };
};


export const locationUpdateAction = (
  selectType,
  data,
  selectedOptions = []
) => {
  return {
    type: LOCATION_UPDATE,
    selectType,
    data,
    selectedOptions,
  };
};


export const searchAutocompleteAction = (
  page_no = 0,
  data,
  onSuccess,
  onError
) => {
  return {
    type: SEARCH_AUTOCOMPLETE,
    page_no,
    data,
    onSuccess,
    onError,
  };
};