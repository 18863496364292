import React from "react";
import UseFormikInputField from "../../form/useFormikInputField";
import styles from "../../../styles/edit-property/edit-property.module.css";
import UseFormikRadioInput from "../../form/useFormikRadioField";
// import UseFormikSelectField from "../../../form/useFormikSelectField";
const AgricultureLandFeatures = ({ formik }) => {
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h3 className={`light_blue_color fs_20 me-4`}>Property Features</h3>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
        <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.floors_allowed_for_construction}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.floors_allowed_for_construction}
            formikTouched={formik?.touched?.property_features?.floors_allowed_for_construction}
            itemName="property_features.floors_allowed_for_construction"
            labelName="Floors Allowed for Construction"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.no_of_open_sides}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.no_of_open_sides}
            formikTouched={formik?.touched?.property_features?.no_of_open_sides}
            itemName="property_features.no_of_open_sides"
            labelName="No of Open Sides"
          />
        </div>
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.boundary_wall_made"}
          title={"Boundary Wall made"}
        />
        <UseFormikRadioInput
          formik={formik}
          title="Corner site"
          name="property_features.corner_site"
        />
      </div>
    </div>
  );
};

export default AgricultureLandFeatures;
