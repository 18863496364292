import { all } from "redux-saga/effects";
import {
  editProjectApartmentWatch,
  editProjectPlotWatch,
  editProjectVillaWatch,
  projectFindOneWatch,
} from "../../project-saga/residentialProjectsSaga";

export default function* editProjectRootSaga() {
  yield all([
    projectFindOneWatch(),
    editProjectApartmentWatch(),
    editProjectVillaWatch(),
    editProjectPlotWatch(),
  ]);
}
