import axios from "axios";
import { url } from "../config";

export const api = axios.create({
  baseURL: url,
  timeout: 60000,
  headers: { "Content-Type": "application/json" },
});
console.log(localStorage.getItem("accessToken"));
api.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem("accessToken")) {
      if (!excludeAccessToken(config)) {
        config.headers["x-access-token"] = localStorage.getItem("accessToken");
      }
    }
    return config;
  },
  async (error) => {}
);

api.interceptors.response.use(
  async (config) => {
    console.log(config?.data?.data,"dfkjsdbnfkjsdfb");
    if (
      config.data.data === "No token provided!" ||
      config.data.data === "Admin not found" ||
      config.data.message === "Authentication failed"
    ) {
      localStorage.clear();
      window.location.reload();
    }
    return config;
  },
  async (error) => {
    return error.response;
  }
);
function excludeAccessToken(config) {
  return config.url.includes("/core/auth/login");
}
