import React from "react";

const RightArrow = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30"
        height="30"
        rx="5"
        transform="matrix(-1 0 0 1 30 0)"
        fill="#1D72DB"
      />
      <path
        d="M12.5 10L17.5 15L12.5 20"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RightArrow;
