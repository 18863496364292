import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  amountTypeRegex,
  convertValuesToIntegers,
  defaultAddress,
  inputRegex,
  removeUnderScore,
} from "../../../../components/helper/helper";
import LocationAndSearch from "../../../../components/map/locationSearch";
import WearhouseFeatures from "../../../../components/edit-property/commercial/weathouse/wearhouse-features";
import OfficeSpaceSellPriceDetails from "../../../../components/edit-property/commercial/office-space/office-space-sell-price-details";
import OfficeSpaceRentPriceDetails from "../../../../components/edit-property/commercial/office-space/office-space-rent-price-details";
import OfficeAddPropertyPhoto from "../../../../components/edit-property/commercial/office-space/office-add-property-photo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  commercialFindOneAction,
  editWareHouseAction,
} from "../../../../redux/action/edit-property-actions/commercial";
import moment from "moment";
import PropertyBasicDetails from "../../../../components/edit-property/property-basic-details";
import Modal_property from "../../../../components/ui/modals/modal_property";
import Leftarrowicon from "../../../../assets/icons/left-arrow-icon";
import Primarybutton from "../../../../components/ui/buttons/primary-button";
import isEqual from "lodash.isequal";
import styles from "../../../../styles/property-details.module.css";
import { Spinner } from "react-bootstrap";
import successToast from "../../../../components/helper-functions/successToast";
import errorToast from "../../../../components/helper-functions/errorToast";
import UseFormikInputField from "../../../form/useFormikInputField";
import PropertyLocation from "../../../map/property-location";
const EditWearhouse = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [mainPropertyType, setMainPropertyType] = useState("");
  const [modalButton, setModalButton] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [amitities, setAmitities] = useState();
  const [mapLoading, setMapLoading] = useState(false);
  const [multiImage, setMultiImage] = useState([]);
  const [propertyDetails, setpropertyDetails] = useState("");
  const [compareAminities, setCompareAminities] = useState(true);
  const [isClickedOnAme, setIsClickedOnAme] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const [initialValues, setInitialValues] = useState({
    property_id: "",
    post_type: "",
    property_type: "",
    property_details: {
      name: "",
      address: "",
      location: {
        lat: null,
        long: null,
      },
      building_name: "",
    },
    property_features: {
      washrooms: null,
      number_of_open_sides: null,
      width_of_road_facing_the_plot: null,
      total_floors: null,
      floors_allowed_for_construction: null,
      facing: "",
      status: "",
      transaction: "new",
      suitable_for: [],
    },

    price_details: {
      super_area: null,
      carpet_area: null,
      expected_price: null,
      deposite_price: null,
      rent_price: null,
    },
    g_location: {
      address_line_1: "",
      address_line_2: "",
      area: "",
      city: "",
      postCode: "",
      state: "",
      country: "",
      lat: null,
      lng: null,
    },
    property_photos: [],
    description: "",
    terms_and_condition: true,
    lat: null,
    lng: null,
    formetted_address: "",
    searchAddress: "",
  });
  let propertyId = null;
  if (propertyDetails && propertyDetails?.id) {
    propertyId = (propertyDetails?.id).split("-");
  }
  useEffect(() => {
    setLoading(true);
    dispatch(
      commercialFindOneAction(
        { property_id: params?.id, property_type: params?.type },
        onFindSuccess,
        onFindError
      )
    );
  }, [reload]);
  const onFindSuccess = (data) => {
    const propertyDetailsData = data?.data;
    setLoading(false);
    setpropertyDetails(propertyDetailsData);
    setMultiImage(data?.data?.property_photos);
    setMainPropertyType(data?.data?.main_property_type);
    setAmitities(data?.data?.property_features?.suitable_for);
    setInitialValues({
      property_id: propertyDetailsData?.id,
      post_type: propertyDetailsData?.post_type,
      property_type: propertyDetailsData?.property_type,
      property_details: {
        name: propertyDetailsData?.property_details?.name,
        address: propertyDetailsData?.property_details?.address
          ? propertyDetailsData?.property_details?.address
          : defaultAddress?.address_line_2,
        location: {
          lat: propertyDetailsData?.property_details?.location?.lat
            ? propertyDetailsData?.property_details?.location?.lat
            : defaultAddress?.lat,
          long: propertyDetailsData?.property_details?.location?.long
            ? propertyDetailsData?.property_details?.location?.long
            : defaultAddress?.lng,
        },
        building_name: propertyDetailsData?.property_details?.building_name,
      },
      property_features: {
        washrooms:
          propertyDetailsData?.property_features?.washrooms > 0
            ? propertyDetailsData?.property_features?.washrooms
            : "",
        number_of_open_sides:
          propertyDetailsData?.property_features?.number_of_open_sides > 0
            ? propertyDetailsData?.property_features?.number_of_open_sides
            : "",
        width_of_road_facing_the_plot:
          propertyDetailsData?.property_features
            ?.width_of_road_facing_the_plot > 0
            ? propertyDetailsData?.property_features
                ?.width_of_road_facing_the_plot
            : "",
        total_floors:
          propertyDetailsData?.property_features?.total_floors > 0
            ? propertyDetailsData?.property_features?.total_floors
            : "",
        floors_allowed_for_construction:
          propertyDetailsData?.property_features
            ?.floors_allowed_for_construction > 0
            ? propertyDetailsData?.property_features
                ?.floors_allowed_for_construction
            : "",
        facing: propertyDetailsData?.property_features?.facing,
        status: propertyDetailsData?.property_features?.status,
        suitable_for:
          propertyDetailsData?.property_features?.suitable_for || [],
      },

      price_details: {
        super_area:
          propertyDetailsData?.price_details?.super_area > 0
            ? propertyDetailsData?.price_details?.super_area
            : "",
        carpet_area:
          propertyDetailsData?.price_details?.carpet_area > 0
            ? propertyDetailsData?.price_details?.carpet_area
            : "",
        expected_price:
          propertyDetailsData?.price_details?.expected_price > 0
            ? propertyDetailsData?.price_details?.expected_price
            : "",
        deposite_price:
          propertyDetailsData?.price_details?.deposite_price > 0
            ? propertyDetailsData?.price_details?.deposite_price
            : "",
        rent_price:
          propertyDetailsData?.price_details?.rent_price > 0
            ? propertyDetailsData?.price_details?.rent_price
            : "",
      },
      g_location: {
        address_line_1: propertyDetailsData?.g_location?.address_line_1
          ? propertyDetailsData?.g_location?.address_line_1
          : defaultAddress?.address_line_1,
        address_line_2: propertyDetailsData?.g_location?.address_line_2
          ? propertyDetailsData?.g_location?.address_line_2
          : defaultAddress?.address_line_2,
        area: propertyDetailsData?.g_location?.area
          ? propertyDetailsData?.g_location?.area
          : defaultAddress?.area,
        city: propertyDetailsData?.g_location?.city
          ? propertyDetailsData?.g_location?.city
          : defaultAddress?.city,
        postCode: propertyDetailsData?.g_location?.postCode
          ? propertyDetailsData?.g_location?.postCode
          : defaultAddress?.postCode,
        state: propertyDetailsData?.g_location?.state
          ? propertyDetailsData?.g_location?.state
          : defaultAddress?.state,
        country: propertyDetailsData?.g_location?.country
          ? propertyDetailsData?.g_location?.country
          : defaultAddress?.country,
        lat: propertyDetailsData?.g_location?.lat
          ? propertyDetailsData?.g_location?.lat
          : defaultAddress?.lat,
        lng: propertyDetailsData?.g_location?.lng
          ? propertyDetailsData?.g_location?.lng
          : defaultAddress?.lng,
      },
      property_photos: propertyDetailsData?.property_photos,
      description: propertyDetailsData?.description,
      terms_and_condition: true,
      lat: propertyDetailsData?.g_location?.lat
        ? propertyDetailsData?.g_location?.lat
        : defaultAddress?.lat,
      lng: propertyDetailsData?.g_location?.lng
        ? propertyDetailsData?.g_location?.lng
        : defaultAddress?.lng,
      project_or_society_name:
        propertyDetailsData?.property_details?.building_name,
      formetted_address: propertyDetailsData?.formetted_address,
      searchAddress: propertyDetailsData?.formetted_address ?? "",
    });
  };
  const onFindError = (data) => {
    setLoading(false);
  };
  
  const propertyBasicDetails = [
    {
      label: "Property ID",
      value: propertyId && propertyId[0].toUpperCase(),
      display: true,
    },
    {
      label: "Property Type",
      value: propertyDetails?.property_type === "warehouse" && "Warehouse",
      display: true,
    },
    {
      label: "Property Price",
      value: propertyDetails?.price_details?.expected_price,
      display: true,
    },
    {
      label: "Subscription Plan",
      value: "",
      display: propertyDetails?.subscription ? true : false,
    },
    {
      label: "Validity",
      value: "",
      display: propertyDetails?.subscription ? true : false,
    },
    {
      label: "Posted By",
      value:
        propertyDetails?.name +
        ` (${removeUnderScore(propertyDetails?.owner_type)})`,
      display: true,
    },
    {
      label: "Posted Date & Time",
      value: moment(propertyDetails?.createdAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
    {
      label: "Updated Date & Time",
      value: moment(propertyDetails?.updatedAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      // g_location: Yup.object({
      //   lat: Yup.string().required("Select your location in the map"),
      //   lng: Yup.string()
      //     .required("Select your location in the map")
      //     .required("Required"),
      // }),
      property_features: Yup.object({
        total_floors: Yup.string().matches(inputRegex, "Enter a valid number"),
        washrooms: Yup.string().matches(inputRegex, "Enter a valid number"),
        number_of_open_sides: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
        floors_allowed_for_construction: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
        width_of_road_facing_the_plot: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
      }),
      price_details: Yup.object({
        super_area: Yup.string().matches(inputRegex, "Enter a valid number"),
        carpet_area: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        expected_price: Yup.string()
          .matches(amountTypeRegex, "Enter a valid number")
          .test("postTypeSale", "Required", function (value) {
            if (propertyDetails?.post_type === "sale") {
              return value;
            } else {
              return true;
            }
          }),

        rent_price: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .test("postTypeRent", "Required", function (value) {
            if (propertyDetails?.post_type === "rent") {
              return value;
            } else {
              return true;
            }
          }),
        deposite_price: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
      }),
      // searchAddress: Yup.string().required("Required"),
      g_location: Yup.object({
        city: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
      }),
    }),
    onSubmit: (values) => {
      console.log(values, "aksjbdsjada");
      dispatch(
        editWareHouseAction(
          {
            ...values,
            price_details: {
              super_area: parseInt(values?.price_details?.super_area),
              carpet_area: parseInt(values?.price_details?.carpet_area),
              expected_price: parseInt(values?.price_details?.expected_price),
              deposite_price: parseInt(values?.price_details?.deposite_price),
              rent_price: parseInt(values?.price_details?.rent_price),
            },
            property_features: {
              ...values?.property_features,
              washrooms: parseInt(values?.property_features?.washrooms),
              floors_allowed_for_construction: parseInt(values?.property_features?.floors_allowed_for_construction),
              total_floors: parseInt(values?.property_features?.total_floors),
              number_of_open_sides: parseInt(values?.property_features?.number_of_open_sides),
              width_of_road_facing_the_plot: parseInt(values?.property_features?.width_of_road_facing_the_plot),
            },
            property_photos: [...multiImage],
    //         "property_id": values?.property_id,
    //         "post_type": values?.post_type,
    // "property_type": "warehouse",
    // "property_details": {
    //     "name": "Mandya",
    //     "address": "abcd",
    //     "location": {
    //         "lat": "1.25455",
    //         "long": "52.55"
    //     },
    //     "building_name": ""
    // },
    // "property_features": {
    //     "washrooms": 1,
    //     "number_of_open_sides": 2,
    //     "width_of_road_facing_the_plot": "22",
    //     "total_floors": 5,
    //     "floors_allowed_for_construction": 2,
    //     "facing": "south",
    //     "status": "ready_to_move",
    //     "transaction": "new",
    //     "suitable_for": [
    //         {
    //             "name": "godown_for_any_dry_storage",
    //             "status": true
    //         },
    //         {
    //             "name": "godown_for_cold_storage",
    //             "status": false
    //         },
    //         {
    //             "name": "godown_with_climate_control",
    //             "status": true
    //         }
    //     ]
    // },
    // "price_details": {
    //     "super_area": 22,
    //     "carpet_area": 121.00,
    //     "expected_price": 232.00,
    //     "deposite_price": 6542,
    //     "rent_price": 11234
    // },
    // "formetted_address": "Vijayanagar, Karnataka 570008, India",
    // "g_location": {
    //     "address_line_1": "sxcsdc",
    //     "address_line_2": "csdc",
    //     "area": "csdcsd",
    //     "city": "csdcsd",
    //     "postCode": "1234",
    //     "state": "csdc",
    //     "country": "csdcsdc",
    //     "lat": "",
    //     "lng": ""
    // },
    // "property_photos": [],
    // "description": "nothing to add",
    // "terms_and_condition": true
          },
          onEditSuccess,
          onEditError
        )
      );
      setIsClickedOnAme(true);
    },
  });
  const onEditSuccess = (data) => {
    setReload(!reload);
    successToast(data?.message);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
  };

  useEffect(() => {
    if (initialValues?.lat) {
      setMapLoading(true);
    }
  }, [initialValues?.lat]);

  const initValuesWithoutAmenitiesFea = { ...initialValues?.property_features }
  delete initValuesWithoutAmenitiesFea?.suitable_for;

  const formikValuesWithoutAmenitiesFea = { ...formik?.values?.property_features }
  delete formikValuesWithoutAmenitiesFea?.suitable_for;

  function valuesCompareFn(values) {
    const projectOrSociety = isEqual(initialValues?.property_details?.building_name ?? "", formik?.values?.property_details?.building_name ?? "");
    const propertyFeature = isEqual(initValuesWithoutAmenitiesFea, formikValuesWithoutAmenitiesFea);
    const priceDetails = isEqual(convertValuesToIntegers(formik?.initialValues?.price_details), convertValuesToIntegers(values?.price_details));
    const propertyPhotos = isEqual((formik?.initialValues?.property_photos), multiImage);
    const lat = isEqual(formik?.initialValues?.g_location?.lat, values?.g_location?.lat);
    const gLocation = isEqual(initialValues?.g_location,values?.g_location);
    const description = isEqual(initialValues?.description, values?.description);
    // console.log(priceDetails, propertyFeature, propertyPhotos, lat, "priceDetails");
    if (propertyFeature && priceDetails && propertyPhotos && lat && gLocation && projectOrSociety && compareAminities && description) {
      return true;
    } else {
      return false;
    }
  };
  console.log(formik?.errors, "errorswear");
  return (
    <div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center col-7 w-100"
          style={{ height: "20rem" }}
        >
          <Spinner />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className={` ${styles.border_color_gray} rounded-4 m-4 `}>
            <Modal_property
              show={show}
              mainPropertyType={mainPropertyType}
              handleclose={() => setShow(false)}
              background={modalButton === "Approve" ? "green" : "red"}
              btnText={modalButton === "Approve" ? "approve" : "reject"}
              propertyStatus={propertyStatus}
              navigateUrl={
                location.pathname.includes("live-property-requests")
                  ? "/property-project/live-property-requests"
                  : "/property-project/property-requests"
              }
            />
            <div className={`d-flex justify-content-between my-2 `}>
              <div
                className="d-flex align-items-center ms-4"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <Leftarrowicon />
                <Primarybutton
                  btnText={"BACK"}
                  className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
                />
              </div>
              <div>
                {!valuesCompareFn(formik?.values) ? (
                  <button
                    className="btn btn-primary me-3"
                    type="submit"
                  >
                    submit
                  </button>
                ) : location.pathname.includes("live-property-requests")
                ? null : (
             // : location.pathname.includes("live-property-requests") &&
             //   propertyDetails.status === "approved" ? null : (
                  <>
                    <Primarybutton
                      btnText={"APPROVE"}
                      type="button"
                      onClick={() => {
                        setModalButton("Approve");
                        setShow(true);
                        setPropertyStatus("approved");
                      }}
                      className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
                    />
                    <Primarybutton
                      btnText={"REJECT"}
                      type="button"
                      onClick={() => {
                        setModalButton("Reject");
                        setShow(true);
                        setPropertyStatus("rejected");
                      }}
                      className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="border-bottom"></div>
            <div
              className="scrolling px-3"
              style={{ overflowY: "auto", overflowX: "hidden", height: "70vh" }}
            >
              <PropertyBasicDetails
                propertyBasicDetails={propertyBasicDetails}
              />
              <div className={` my-3 d-flex align-items-center`}>
                <h5 className={`light_blue_color fs_18 me-3`}>
                  Property Location
                </h5>
                <hr className={`py-1 flex-grow-1 border-primary`} />
              </div>
              <div className="row position-relative">
                {/* <div className="col-7">
                  {mapLoading ? (
                    <LocationAndSearch formik={formik} />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center col-7 w-100"
                      style={{ height: "20rem" }}
                    >
                      <Spinner />
                    </div>
                  )}
                </div> */}
                <div className="col-5">
                {formik?.values && <PropertyLocation formik={formik} />}
                  <div className="mt-3">
                    <UseFormikInputField
                      placeholder="Enter Project/Society Name (optional)"
                      inputValue={
                        formik?.values?.property_details?.building_name
                      }
                      onChangeFn={formik.handleChange}
                      onBlurFn={formik.handleBlur}
                      itemName="property_details.building_name"
                      labelName="Project/Society Name"
                    />
                  </div>
                </div>
              </div>
              <WearhouseFeatures
                formik={formik}
                setCompareAminities={setCompareAminities}
                propertyDetails={propertyDetails}
                setIsClickedOnAme={setIsClickedOnAme}
                isClickedOnAme={isClickedOnAme}
              />
              {propertyDetails?.post_type === "sale" ? (
                <OfficeSpaceSellPriceDetails formik={formik} />
              ) : (
                <OfficeSpaceRentPriceDetails formik={formik} />
              )}
              <OfficeAddPropertyPhoto
                multiImage={multiImage}
                setMultiImage={setMultiImage}
              />
              <div className="discription">
                 <div className={`mt-5 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>
                   Description
                  </h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                  </div>
                  <UseFormikInputField
                    label_font_size=""
                    labelName=""
                    itemName={"description"}
                    inputValue={formik.values.description}
                    onChangeFn={formik.handleChange}
                    onBlurFn={formik.handleBlur}
                    formikError={formik.errors.description}
                    formikTouched={formik.touched.description}
                    placeholder="Describe your project"
                    input={false}
                    TextArea={true}
                  />
            </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditWearhouse;
