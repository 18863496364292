import React from "react";
import { removeUnderScore } from "../helper/helper";
import UseFormikSelectField from "../form/useFormikSelectField";

const EditPgRules = ({ formik }) => {
  const pgRules = { ...formik?.values?.pg_rules };
  const pgRulesArr = [];
  console.log(pgRules, "pgRulesobject");
  for (const key in pgRules) {
    if (pgRules?.hasOwnProperty(key)) {
      pgRulesArr.push({ key, value: pgRules[key] });
    }
  }
  const handleCheckboxChange = (key) => {
    const updatedSuitableFor = { ...pgRules };
    if (updatedSuitableFor.hasOwnProperty(key)) {
      updatedSuitableFor[key] = !updatedSuitableFor[key];
    }
    formik.setFieldValue(`pg_rules.${key}`, updatedSuitableFor[key]);
  };
  console.log(pgRulesArr, "pgRulesArr");
  console.log(formik?.initialValues?.pg_rules, "initialpgrule");
  console.log(formik?.values?.pg_rules, "formikpgrules");
  return (
    <div>
      <div className={` my-3 d-flex align-items-center pt-3`}>
        <h5 className={`light_blue_color fs_18 me-3`}>PG Rules</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row g-0">
        {pgRulesArr?.map((item, index) => {
          return (
            <div className="col-3 mb-3">
              <div className="d-flex align-items-center">
                <div className="mt-1">
                  <input
                    className=""
                    type="checkbox"
                    name={`pg_rules.${item?.key}`}
                    checked={item.value}
                    onChange={() => handleCheckboxChange(item?.key)}
                    // value={item?.key}
                    style={{ width: "17px", height: "17px" }}
                  />
                </div>
                <div className="ms-2 d-inline-block fs_15 secondary_color">
                  {item?.key ? removeUnderScore(item?.key) : "--"}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <UseFormikSelectField
          label="Notice Period"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="notice_period"
          formik={formik}
          formikError={formik?.errors?.notice_period}
          formikTouched={formik?.touched?.notice_period}
          defaultValue={
            formik?.values?.notice_period && {
              label: removeUnderScore(formik?.values?.notice_period),
              value: formik?.values?.notice_period,
            }
          }
          options={noticPeriodOption}
        />
      </div>
    </div>
  );
};

export default EditPgRules;
export const noticPeriodOption = [
  { value: "1_week", label: "1 Week" },
  { value: "15_days", label: "15 Days" },
  { value: "1_Month", label: "1 Month" },
  { value: "2_month", label: "2 Months" },
  { value: "No_notice_period", label: "No Notice Period" },
];