import React from "react";

const ArrowUp = ({width = "16", height = "9"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8L8 1L1 8"
        stroke="#50BF97"
        strokewidth="2"
        strokelinecap="round"
        strokelinejoin="round"
      />
    </svg>
  );
};

export default ArrowUp;
