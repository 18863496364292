import {
  LIVE_PROJECT_LIST_ACTION,
  PROJECTREQUESTFINDALL,
  UPLOAD_PROJECT_DOCUMENT,
} from "../../type";

export const projectRequestListAction = (data, onSuccess, onError) => {
  return {
    type: PROJECTREQUESTFINDALL,
    data,
    onSuccess,
    onError,
  };
};
export const liveProjectRequestListAction = (data, onSuccess, onError) => {
  return {
    type: LIVE_PROJECT_LIST_ACTION,
    data,
    onSuccess,
    onError,
  };
};
export const uploadProjectDocAction = (document, onSuccess, onError) => {
  return {
    type: UPLOAD_PROJECT_DOCUMENT,
    document,
    onSuccess,
    onError,
  };
};
