import React, { useEffect, useState } from "react";
import Select from "react-select";
import { InputContainer } from "../form/inputField";
import styles from "../../styles/components/components.module.css";

const UseFormikSelectField = ({
  label = "",
  disabled = false,
  defaultValue,
  name = "",
  options = [],
  type = "",
  placeholder = "Select",
  id = "",
  customStyles = {},
  lableClassName = "secondary_color fs_15",
  resetStatus = false,
  formikTouched,
  formikError,
  formik,
  errMsgContainerClass = "mt-1 justify-content-end justify-content-lg-start",
  onMenuScroll = () => {},
  selectedOptionData = () => {}
}) => {
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });

  const [changed, whenChanged] = useState(false);

  useEffect(() => {
    whenChanged(false);
  }, [resetStatus]);

  return (
    <>
      <InputContainer customStyles={customStyles}>
        <label htmlFor="email" className={lableClassName}>
          {label}
        </label>

        <Select
          instanceId={name}
          isDisabled={disabled}
          defaultValue={defaultValue}
          value={
            changed === false
              ? defaultValue
              : options.find((c) => c.value === selectedOption)
          }
          name={name}
          id={id}
          styles={selectStyles}
          type={type}
          error={formikError && formikTouched}
          placeholder={placeholder}
          options={
            options.length > 0
              ? options
              : name == "facing" || name == "property_features.facing"
              ? facingOption
              : name == "furnishingStatus" ||
                name == "furnishing_status" ||
                name == "property_features.furnishing_status" ||
                name == "common_features.furnishing_status"
              ? furnishOption
              : name == "status" ||
                name == "property_features.status" || 
                name == "common_features.status"
              ? statusOption
              : name == "transaction"
              ? transacOption
              : options
          }
          onChange={(selectedOption) => {
            selectedOptionData(selectedOption);
            {
              formik.setFieldValue(name, selectedOption.value);
              whenChanged(true);
              setSelectedOption({
                label: selectedOption.label,
                value: selectedOption.value,
              });
            }
          }}
          onMenuScrollToBottom={onMenuScroll}
        />

        <div className={`${styles.error_height} ${errMsgContainerClass}`}>
          {formikError && formikTouched && (
            <span className="red_color fs_12 fw_400">{formikError}</span>
          )}
        </div>
      </InputContainer>
    </>
  );
};

export default UseFormikSelectField;

export const selectStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#464646",
    fontWeight: "400",
    fontSize: "14px",
    // background: "#F4F8FB",
    // match with the menu
    borderRadius: "5px",
    width: "100%",
    // Overwrittes the different states of border
    borderColor: state.isFocused
      ? state.selectProps.error
        ? "red"
        : "#1D72DB"
      : state.selectProps.error
      ? "red"
      : "#DBE9F2",

    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: "2",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    backgroundColor: state.isSelected ? "#50bf97" : null,
    borderBottom: "1px solid #F5F5F5",
    "&:hover": {
      backgroundColor: state.isFocused ? "#ACBBC5" : "#ACBBC5",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#BCC7CE",
      fontSize: "13px",
      fontWeight: "300",
      opacity: "0.9",
      position: "absolute",
    };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    svg: {
      fill: state.selectProps.iconColor ?? "#50BF97",
    },
  }),

  multiValue: (provided) => ({
    ...provided,
    background: "#EDF4F9",
  }),
};

export const facingOption = [
  { label: "North", value: "north" },
  { label: "South", value: "south" },
  { label: "East", value: "east" },
  { label: "West", value: "west" },
  { label: "North-East", value: "north_east" },
  { label: "North-West", value: "north_west" },
  { label: "South-East", value: "south_east" },
  { label: "South-West", value: "south_west" },
];
export const statusOption = [
  { value: "under_construction", label: "Under Construction" },
  { value: "ready_to_move", label: "Ready to move" },
];
export const furnishOption = [
  { value: "furnished", label: "Furnished" },
  { value: "unfurnished", label: "Unfurnished" },
  { value: "semi_furnished", label: "Semi-Furnished" },
];
export const transacOption = [
  { label: "New", value: "new" },
  { label: "Resale", value: "resale" },
];
export const paymentOption = [
  { value: "upi", label: "UPI Payment" },
  { value: "netBanking", label: "Net Banking" },
  { value: "Debit_Credit_card", label: "Debit/Credit-card" },
];
