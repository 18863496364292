import { LOCATION_UPDATE, REMOVE_CITY_NAME_DATA } from "../../redux/type"
import { locationInitData } from "../constants/profileData";
export const locationDataReducer = (state = locationInitData, action) => {
  switch (action.type) {
    case LOCATION_UPDATE:
      console.log(action, "aaaaaaaaaaaaaaaaaaaaaaaaa");
      localStorage.setItem(
        "city_name",
        action.selectedOptions[0]?.value?.city ?? ""
      );
      return {
        ...state,
        loading: false,
        success: true,
        error: true,
        selectType: action.selectType,
        selectedOptions: action.selectedOptions,
        data: { ...state.data, ...action.data },
      };
    case REMOVE_CITY_NAME_DATA:
      return {
        // ...state,
        loading: false,
        success: true,
        error: true,
        selectedOptions: [],
        data: {},
      };

    default:
      return state;
  }
};
