import { api } from "../api";

export const PgRequestApi = (data, params) => {

  return api.get(
    `/api/admin/properties/pg-pending-list?page_no=${data.page_no}&search=${data.search}`,
    params
  );
};
export const livePgListApi = (data, params) => {

  return api.get(
    `/api/admin/properties/pg-approved-list?page_no=${data.page_no}&search=${data.search}`,
    params
  );
};
export const pgFindOneApi = (data) => {
  return api.get(`/api/admin/properties/pg-view?property_id=${data?.property_id}`,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    });
};

export const editPgApi = (params) => {
  return api.post("/api/admin/properties/edit-pg", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};