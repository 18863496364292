import { call, takeLatest } from "redux-saga/effects";
import { PROPERTY_UPDATE, PROPERTY_VIEW } from "../../type";
import { propertyUpdateApi, propertyViewApi } from "../../api/property-api/propertyViewApi";

export function* propertyViewWorker(action) {
  console.log(action, "aaaaa");
  try {
    const response = yield call(propertyViewApi, action.id);
    console.log(response, "aaaaa");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
      console.log(response);
    } else {
      yield action.onError(response.data);
    }
    console.log(response);
  } catch (error) {
    yield action.onError(error.response);
    console.log(error.response);
  }
}

export function* propertyViewWatcher() {
  yield takeLatest(PROPERTY_VIEW, propertyViewWorker);
}

export function* propertyUpdateWorker(action) {
  try {
    const response = yield call(propertyUpdateApi, action.data);

    if (response.status === 200) {
      yield action.onSuccess(response.data);
      console.log(response);
    } else {
      yield action.onError(response.data);
    }
    console.log(response,"nnn");
  } catch (error) {
    yield action.onError(error.response);
    console.log(error.response);
  }
}

export function* propertyUpdateWatcher() {
  yield takeLatest(PROPERTY_UPDATE, propertyUpdateWorker);
}
