import React from "react";
import UseFormikPriceInputField from "../../../form/useFormikPriceInputField";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikInputField from "../../../form/useFormikInputField";
import DeleteIcon from "../../../../assets/icons/delete-icon-without-backgrround.png";
const FeaturesVariation = ({formik, index}) => {
  const handelDeleteVarition = (deleteIndex) => {
    const variation = formik?.values?.area_and_price_details;
    const updateVariant = variation?.filter((item, index) => index !== deleteIndex);
    formik.setFieldValue("area_and_price_details", updateVariant);
  };
  console.log(formik?.values, "projplot");
  return (
    <div>
    <div className={` my-3 d-flex align-items-center`}>
      <h5 className={`light_blue_color fs_18 me-3`}>Features Variation</h5>
      <hr className={`py-1 flex-grow-1 border-primary`} />
    </div>
    <div className={`fs_15 me-3 color_323D5A d-flex justify-content-between`}>
      <span>
        Variant {index + 1} - {" "}
        <span className="pagination_text_color">{formik?.values?.area_and_price_details?.[index]?.number_of_units_in_this_variation} Units</span>
      </span>
      <span>
      {formik?.values?.area_and_price_details?.length > 1 && (
          <span onClick={() => handelDeleteVarition(index)}>
            <img
              className="me-2"
              src={DeleteIcon}
              alt="Delete-icon"
              width={15}
            />
            Delete
          </span>
        )}
      </span>
    </div>
    <div className="mt-4">
        <div className="col-3">
          <UseFormikInputField
            placeholder="Ex: 2"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.area_and_price_details?.[index]?.no_of_units_in_variation}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.area_and_price_details?.[index]?.no_of_units_in_variation}
            formikTouched={formik?.touched?.area_and_price_details?.[index]?.no_of_units_in_variation}
            itemName={`area_and_price_details[${index}].no_of_units_in_variation`}
            labelName="Number of Units in this variation"
          />
        </div>
        <div className="d-flex flex-wrap gap-5 align-items-center">
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikPriceInputField
              placeholder="Enter plot length"
              customStyles={{ width: "100%" }}
              inputValue={formik?.values?.area_and_price_details?.[index]?.plot_length}
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={formik?.errors?.area_and_price_details?.[index]?.plot_length}
              formikTouched={formik?.touched?.area_and_price_details?.[index]?.plot_length}
              itemName={`area_and_price_details[${index}].plot_length`}
              label="Plot Length"
              length="ft"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikPriceInputField
              placeholder="Enter plot width"
              customStyles={{ width: "100%" }}
              inputValue={formik?.values?.area_and_price_details?.[index]?.plot_width}
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={formik?.errors?.area_and_price_details?.[index]?.plot_width}
              formikTouched={formik?.touched?.area_and_price_details?.[index]?.plot_width}
              itemName={`area_and_price_details[${index}].plot_width`}
              label="Plot Width"
              length="ft"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikPriceInputField
              placeholder="Enter plot area"
              customStyles={{ width: "100%" }}
              inputValue={formik?.values?.area_and_price_details?.[index]?.plot_area}
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={formik?.errors?.area_and_price_details?.[index]?.plot_area}
              formikTouched={formik?.touched?.area_and_price_details?.[index]?.plot_area}
              itemName={`area_and_price_details[${index}].plot_area`}
              label="Plot Area"
              length="sqft"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikPriceInputField
              placeholder="Enter property price"
              customStyles={{ width: "100%" }}
              inputValue={formik?.values?.area_and_price_details?.[index]?.expected_price}
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={formik?.errors?.area_and_price_details?.[index]?.expected_price}
              formikTouched={formik?.touched?.area_and_price_details?.[index]?.expected_price}
              itemName={`area_and_price_details[${index}].expected_price`}
              label="Expected Price"
              currency="₹"
            />
          </div>
        </div>
    </div>
    {typeof formik?.errors?.area_and_price_details === 'string' &&
      <div className="text-danger">{formik?.errors?.area_and_price_details}</div>
    }
  </div>
  );
};

export default FeaturesVariation;
