import React from "react";

const Leftarrowicon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73604 0H11.272C14.096 0 16 1.8168 16 4.528V11.464C16 14.176 14.096 16 11.272 16H4.73604C1.90404 16 0 14.176 0 11.464V4.528C0 1.8168 1.90404 0 4.73604 0ZM6.18398 8.6H11.264C11.4231 8.6 11.5758 8.53679 11.6883 8.42427C11.8008 8.31175 11.864 8.15913 11.864 8C11.864 7.84087 11.8008 7.68825 11.6883 7.57573C11.5758 7.46321 11.4231 7.4 11.264 7.4H6.18398L8.16797 5.424C8.27929 5.31076 8.34245 5.15878 8.34404 5C8.34075 4.84157 8.27784 4.69019 8.16797 4.576C8.05506 4.46442 7.90279 4.40183 7.74404 4.40183C7.5853 4.40183 7.43293 4.46442 7.32002 4.576L4.304 7.576C4.19612 7.69082 4.13604 7.84245 4.13604 8C4.13604 8.15755 4.19612 8.30918 4.304 8.424L7.32002 11.424C7.43293 11.5356 7.5853 11.5982 7.74404 11.5982C7.90279 11.5982 8.05506 11.5356 8.16797 11.424C8.27949 11.3094 8.3419 11.1559 8.3419 10.996C8.3419 10.8361 8.27949 10.6826 8.16797 10.568L6.18398 8.6Z"
        fill="#323D5A"
      />
    </svg>
  );
};

export default Leftarrowicon;
