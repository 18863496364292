import React from 'react'
import Navbar from "./navbar";

import SideBar from './side-bar'
import { Outlet } from 'react-router-dom'

const RequestLayout = () => {
  return (
    <div>
      <Navbar />
      <div className="d-flex">
        <div
          className={`${"sidebar_container vh-100 cursor_pointer d-lg-block d-none"}  pt-4 `}
        >
          <SideBar />
        </div>
        <div className={`w-100 overflow-x-scroll p-4 table_background_color_grey `}><Outlet /></div>
      </div>
    </div>
  )
}

export default RequestLayout