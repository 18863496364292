import React, { useEffect, useState } from "react";
import FeaturesVariation from "../../../components/edit-project/residential/residential-plot/FeaturesVariation";
import ResidentialFeatures from "../../../components/edit-project/residential/residential-plot/residential-features";
import { useFormik } from "formik";
import * as Yup from "yup";
import { amountTypeRegex, convertValuesToIntegers, convertValuesToIntegersObj, defaultAddress, inputRegex, removeUnderScore } from "../../../components/helper/helper";
import moment from "moment";
import PropertyBasicDetails from "../../../components/edit-property/property-basic-details";
import LocationAndSearch from "../../../components/map/locationSearch";
import OfficeAddPropertyPhoto from "../../../components/edit-property/commercial/office-space/office-add-property-photo";
import UseFormikInputField from "../../../components/form/useFormikInputField";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { editProjectPlotAction, projectFindOneAction } from "../../../redux/action/project-actions/residentialProjectsAction";
import { Spinner } from "react-bootstrap";
import successToast from "../../../components/helper-functions/successToast";
import errorToast from "../../../components/helper-functions/errorToast";
import styles from "../../../styles/property-details.module.css"
import Modal_property from "../../../components/ui/modals/modal_property";
import Leftarrowicon from "../../../assets/icons/left-arrow-icon";
import Primarybutton from "../../../components/ui/buttons/primary-button";
import isEqual from "lodash.isequal";
import BroucherUpload from "../../edit-property/broucherUpload";
import ResidentialProVillaFeatures from "../../../components/edit-project/residential/residential-plot/residential-features";
import ResidentialProPlotFeatures from "../../../components/edit-project/residential/residential-plot/residential-features";
import PropertyLocation from "../../../components/map/property-location";
import UseFormikSelectField from "../../../components/form/useFormikSelectField";
const ResidentialPlot = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [propertyStatus, setPropertyStatus] = useState("");
  const [mainPropertyType, setMainPropertyType] = useState("");
  const [modalButton, setModalButton] = useState("");
  const [amitities, setAmitities] = useState();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  // const [numVariations, setNumVariations] = useState(2);
  const [mapLoading, setMapLoading] = useState(false);
  const [multiImage, setMultiImage] = useState([]);
  const [projectDetails, setProjectDetails] = useState("");
  const [aminities, setAminities] = useState("");
  const [compareAminities, setCompareAminities] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [invalidId, setInvalidId] = useState("");
  const apartmentFeaturesData = projectDetails?.area_and_price_details ?? [];
  const [numVariations, setNumVariations] = useState(apartmentFeaturesData?.length > 0 ? apartmentFeaturesData?.length : 1);
  const [isClickedOnAme, setIsClickedOnAme] = useState(false);  
  let propertyId = null;
  if (projectDetails && projectDetails?.id) {
    propertyId = (projectDetails?.id).split("-");
  }
  
  useEffect(() => {
    setLoading(true);
    dispatch(
      projectFindOneAction(
        {
          project_id: params?.id,
          property_type: params?.type,
        },
        onProjectFindSuccess,
        onProjectFindError
      )
    );
  }, [reload]);
  const onProjectFindSuccess = (data) => {
    setInvalidId(data?.message);
    const projectDetailsData = data?.data;
    setLoading(false);
    setProjectDetails(projectDetailsData);
    setAminities(data?.data?.project_features[0]?.amenities);
    setMultiImage(data?.data?.property_photos);
    setInitialValues({
      project_id: projectDetailsData?.id,
      project_type: projectDetailsData?.project_type,
      no_of_units: projectDetailsData?.no_of_units,
      status_of_projects_construction: projectDetailsData?.status_of_projects_construction,
      // project_lunched_on: projectDetailsData?.project_lunched_on ?? "",
      // project_completed_on: projectDetailsData?.project_completed_on ?? "",
      // expected_completed_date: projectDetailsData?.expected_completed_date ?? "",
      date1: projectDetailsData?.project_lunched_on?.split("T")[0] ?? "",
      date2: projectDetailsData?.[projectDetailsData?.status_of_projects_construction === "completed"
          ? "project_completed_on"
          : "expected_completed_date"
      ]?.split("T")[0] ?? "",
      project_details: {
        name: projectDetailsData?.project_details?.name,
        address: projectDetailsData?.project_details?.address ? projectDetailsData?.project_details?.address : defaultAddress?.address_line_2,
        location: {
          lat: projectDetailsData?.project_details?.location?.lat ? projectDetailsData?.project_details?.location?.lat : defaultAddress?.lat,
          long: projectDetailsData?.project_details?.location?.long ? projectDetailsData?.project_details?.location?.long : defaultAddress?.lng,
        },
        project_or_society_name: projectDetailsData?.project_details?.project_or_society_name,
      },
      project_features:
        {
          floors_allowed: projectDetailsData?.project_features?.[0]?.floors_allowed,
          gated_colony: projectDetailsData?.project_features?.[0]?.gated_colony,
          boundary_wall_made: projectDetailsData?.project_features?.[0]?.boundary_wall_made,
          amenities: projectDetailsData ? projectDetailsData?.project_features[0]?.amenities : [],
        },
        area_and_price_details: Array.from({ length: projectDetailsData?.area_and_price_details?.length }, (_, i) => ({
          no_of_units_in_variation: projectDetailsData?.area_and_price_details[i]?.no_of_units_in_variation ?? projectDetailsData?.area_and_price_details[i]?.number_of_units_in_this_variation,
          plot_length: projectDetailsData?.area_and_price_details[i]?.plot_length,
          plot_width: projectDetailsData?.area_and_price_details[i]?.plot_width,
          expected_price: projectDetailsData?.area_and_price_details[i]?.expected_price,
          plot_area: projectDetailsData?.area_and_price_details[i]?.plot_area,
        })),
        g_location: {
          address_line_1: projectDetailsData?.g_location?.address_line_1 ? projectDetailsData?.g_location?.address_line_1 : defaultAddress?.address_line_1,
          address_line_2: projectDetailsData?.g_location?.address_line_2 ? projectDetailsData?.g_location?.address_line_2 : defaultAddress?.address_line_2,
          area: projectDetailsData?.g_location?.area ? projectDetailsData?.g_location?.area : defaultAddress?.area,
          city: projectDetailsData?.g_location?.city ? projectDetailsData?.g_location?.city : defaultAddress?.city,
          postCode: projectDetailsData?.g_location?.postCode ? projectDetailsData?.g_location?.postCode : defaultAddress?.postCode,
          state: projectDetailsData?.g_location?.state ? projectDetailsData?.g_location?.state : defaultAddress?.state,
          country: projectDetailsData?.g_location?.country ? projectDetailsData?.g_location?.country : defaultAddress?.country,
          lat: projectDetailsData?.g_location?.lat ? projectDetailsData?.g_location?.lat : defaultAddress?.lat,
          lng: projectDetailsData?.g_location?.lng ? projectDetailsData?.g_location?.lng : defaultAddress?.lng,
        },
      formetted_address: projectDetailsData?.formetted_address,
      property_photos: multiImage,
      brochure_upload: projectDetailsData?.brochure_upload,
      description: projectDetailsData?.description,
      terms_and_condition: true,
      lat: projectDetailsData?.g_location?.lat ? projectDetailsData?.g_location?.lat : defaultAddress?.lat,
      lng: projectDetailsData?.g_location?.lng ? projectDetailsData?.g_location?.lng : defaultAddress?.lng,
      project_or_society_name: projectDetails?.project_details?.project_or_society_name,
      formetted_address: projectDetailsData?.formetted_address,
      searchAddress: projectDetailsData?.formetted_address,
    })
  };

  const onProjectFindError = (data) => {setLoading(false)};
  // useEffect(()=>{
  //   setInitialValues({
  //     project_id: projectDetails?.id,
  //     project_type: projectDetails?.project_type,
  //     no_of_units: projectDetails?.no_of_units,
  //     status_of_projects_construction: projectDetails?.status_of_projects_construction,
  //     project_lunched_on: projectDetails?.project_lunched_on ?? "",
  //     project_completed_on: projectDetails?.project_completed_on ?? "",
  //     expected_completed_date: projectDetails?.expected_completed_date ?? "",
  //     project_details: {
  //       name: projectDetails?.project_details?.name,
  //       address: projectDetails?.project_details?.address ? projectDetails?.project_details?.address : defaultAddress?.address_line_2,
  //       location: {
  //         lat: projectDetails?.project_details?.location?.lat ? projectDetails?.project_details?.location?.lat : defaultAddress?.lat,
  //         long: projectDetails?.project_details?.location?.long ? projectDetails?.project_details?.location?.long : defaultAddress?.lng,
  //       },
  //       project_or_society_name:
  //         projectDetails?.project_details?.project_or_society_name,
  //     },
  //     project_features:
  //       {
  //         floors_allowed: projectDetails && projectDetails?.project_features?.[0]?.floors_allowed,
  //         gated_colony: projectDetails && projectDetails?.project_features?.[0]?.gated_colony,
  //         boundary_wall_made: projectDetails && projectDetails?.project_features?.[0]?.boundary_wall_made,
  //         amenities: projectDetails ? projectDetails?.project_features[0]?.amenities : [],
  //       },
  //       area_and_price_details: Array.from({ length: apartmentFeaturesData?.length }, (_, i) => ({
  //         number_of_units_in_this_variation: apartmentFeaturesData[i]?.number_of_units_in_this_variation,
  //         plot_length: apartmentFeaturesData[i]?.plot_length,
  //         plot_width: apartmentFeaturesData[i]?.plot_width,
  //         expected_price: apartmentFeaturesData[i]?.expected_price,
  //         plot_area: apartmentFeaturesData[i]?.plot_area,
  //       })),
  //       g_location: {
  //         address_line_1: projectDetails?.g_location?.address_line_1 ? projectDetails?.g_location?.address_line_1 : defaultAddress?.address_line_1,
  //         address_line_2: projectDetails?.g_location?.address_line_2 ? projectDetails?.g_location?.address_line_2 : defaultAddress?.address_line_2,
  //         area: projectDetails?.g_location?.area ? projectDetails?.g_location?.area : defaultAddress?.area,
  //         city: projectDetails?.g_location?.city ? projectDetails?.g_location?.city : defaultAddress?.city,
  //         postCode: projectDetails?.g_location?.postCode ? projectDetails?.g_location?.postCode : defaultAddress?.postCode,
  //         state: projectDetails?.g_location?.state ? projectDetails?.g_location?.state : defaultAddress?.state,
  //         country: projectDetails?.g_location?.country ? projectDetails?.g_location?.country : defaultAddress?.country,
  //         lat: projectDetails?.g_location?.lat ? projectDetails?.g_location?.lat : defaultAddress?.lat,
  //         lng: projectDetails?.g_location?.lng ? projectDetails?.g_location?.lng : defaultAddress?.lng,
  //       },
  //     formetted_address: projectDetails?.formetted_address,
  //     property_photos: multiImage,
  //     brochure_upload: "",
  //     description: "",
  //     terms_and_condition: true,
  //     lat: projectDetails?.g_location?.lat ? projectDetails?.g_location?.lat : defaultAddress?.lat,
  //     lng: projectDetails?.g_location?.lng ? projectDetails?.g_location?.lng : defaultAddress?.lng,
  //     project_or_society_name: "",
  //     formetted_address: projectDetails?.formetted_address,
  //     searchAddress: projectDetails?.formetted_address,
  //   })
  // },[loading])
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      area_and_price_details: Yup.array().of(
        Yup.object().shape({
          no_of_units_in_variation: Yup.string().matches(amountTypeRegex, "Numbers Only").required("Required"),
          plot_length: Yup.string().matches(amountTypeRegex, "Numbers Only").required("Required"),
          plot_width: Yup.string().matches(amountTypeRegex, "Numbers Only").required("Required"),
          expected_price: Yup.string().matches(amountTypeRegex, "Numbers Only").required("Required"),
          plot_area: Yup.string().matches(amountTypeRegex, "Numbers Only").required("Required"),
        })
      ).required().test("total-units-check", "Total units exceed the limit", function(value){
        const totalUnits = value.reduce((acc, item) => {
          return acc + parseInt(item.no_of_units_in_variation || "0", 10);
        }, 0);
        return totalUnits <= projectDetails?.no_of_units;
      }),
      project_features: Yup.object().shape({
        floors_allowed: Yup.string().matches(inputRegex, "Enter a valid number").required("Required"),
      }),
      g_location: Yup.object({
        city: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
      }),
      date1: Yup.date().required("Required"),
      date2: Yup.date()
      .required("Required")
      .test(
        "is-greater",
        "should be greater than Project Lunched On",
        function (value) {
          const { date1 } = this.parent;
          return value > date1;
        }
      ),
    }),
    onSubmit: (values) => {
      dispatch(
        editProjectPlotAction(
          {
            ...values,
            project_lunched_on: values.date1,
            [values.status_of_projects_construction === "completed"
              ? "project_completed_on"
              : "expected_completed_date"]: values.date2,
            area_and_price_details: Array.from({ length: values?.area_and_price_details?.length }, (_, i) => ({
              no_of_units_in_variation: parseInt(values?.area_and_price_details[i]?.no_of_units_in_variation),
              plot_length: parseInt(values?.area_and_price_details[i]?.plot_length),
              plot_width: parseInt(values?.area_and_price_details[i]?.plot_width),
              expected_price: parseInt(values?.area_and_price_details[i]?.expected_price),
              plot_area: parseInt(values?.area_and_price_details[i]?.plot_area),
            })),
            project_features:[
              {
                ...values?.project_features,
                floors_allowed: parseInt(values?.project_features?.floors_allowed),
                amenities: values?.project_features?.amenities,
              },
            ],
            property_photos: multiImage 
          },
          onEditSuccess,
          onEditError
        )
      );
      setIsClickedOnAme(true);
    },
  });
  const onEditSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
    console.log(data);
  };
  useEffect(() => {
    if (initialValues?.lat) {
      setMapLoading(true);
    }
  }, [initialValues?.lat]);

  // function convertValuesToIntegersObj(areaAndPrice) {
  //   return areaAndPrice.map((item) => {
  //     const updatedObject = {};
  //     for(const key in item){
  //       const value = item[key];
  //       const convertedValue = parseInt(value, 10);
  //       updatedObject[key] = convertedValue;
  //     }
  //     // ******************
  //     // for (const key in object) {
  //     //   if (object.hasOwnProperty(key)) {
  //     //     const value = object[key];
  //     //     const parsedValue = parseInt(value, 10);
  
  //     //     if (!isNaN(parsedValue)) {
  //     //       updatedObject[key] = parsedValue;
  //     //     } else {
  //     //       updatedObject[key] = value; // Keep the original value if it's not a valid integer.
  //     //     }
  //     //   }
  //     // }
  //     return updatedObject;
  //   });
  // }

  const initWithoutAmenitiesFea = { ...initialValues?.project_features }
  delete initWithoutAmenitiesFea?.amenities;
  delete initWithoutAmenitiesFea?.floors_allowed;
  const ValuesWithoutAmenitiesFea = { ...formik?.values?.project_features }
  delete ValuesWithoutAmenitiesFea?.amenities;
  delete ValuesWithoutAmenitiesFea?.floors_allowed;

  function valuesCompareFn(values) {
    const commonFeature = isEqual(initWithoutAmenitiesFea, ValuesWithoutAmenitiesFea);
    const areaAndPrice = isEqual(convertValuesToIntegersObj(initialValues?.area_and_price_details), convertValuesToIntegersObj(values?.area_and_price_details));
    const propertyPhotos = isEqual(initialValues?.property_photos, multiImage);
    const uploadDoc = isEqual(initialValues?.brochure_upload, formik?.values?.brochure_upload);
    const projectOrSociety = isEqual(initialValues?.project_details?.project_or_society_name, values?.project_details?.project_or_society_name);
    const lat = isEqual(initialValues?.g_location?.lat, values?.g_location?.lat);
    const gLocation = isEqual(initialValues?.g_location,values?.g_location);
    const description = isEqual(initialValues?.description, values?.description);
    const floorsAllowed = isEqual(parseInt(initialValues?.project_features?.floors_allowed), parseInt(values?.project_features?.floors_allowed));
    const date1 = isEqual(initialValues?.date1, values?.date1);
    const date2 = isEqual(initialValues?.date2, values?.date2);
    const projectStatus = isEqual(initialValues?.status_of_projects_construction, values?.status_of_projects_construction);
    console.log( areaAndPrice, propertyPhotos, uploadDoc, commonFeature, floorsAllowed, "priceDetails");
    if (areaAndPrice && lat && gLocation && uploadDoc && propertyPhotos && projectOrSociety && compareAminities && description && commonFeature && floorsAllowed && date1 && date2 && projectStatus) {
      return true;
    } else {
      return false;
    }
  };
  const propertyBasicDetails = [
    {
      label: "Property ID",
      value: propertyId && propertyId[0].toUpperCase(),
      display: true,
    },
    {
      label: "Property Type",
      value: projectDetails?.project_type === "residential" && "Residential Plot",
      display: true,
    },
    {
      label: "Property Price",
      value: projectDetails?.area_and_price_details?.[0]?.expected_price,
      display: true,
    },
    {
      label: "Subscription Plan",
      value: projectDetails?.SubscriptionPlanDetail?.plan_type ? projectDetails?.SubscriptionPlanDetail?.plan_type : "--",
      display: projectDetails?.SubscriptionPlanDetail?.plan_type ? true : false,
    },
    {
      label: "Validity",
      value: projectDetails?.SubscriptionPlanDetail?.plan_duration ? projectDetails?.SubscriptionPlanDetail?.plan_duration + " Months" : "--",
      display: projectDetails?.SubscriptionPlanDetail ? true : false,
    },
    {
      label: "Posted By",
      value: projectDetails?.name + ` (${removeUnderScore(projectDetails?.owner_type)})`,
      display: true,
    },
    {
      label: "Posted Date & Time",
      value: moment(projectDetails?.createdAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
    {
      label: "Updated Date & Time",
      value: moment(projectDetails?.updatedAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
  ];
  const handleDateChangeDate1 = (e) => {
    formik.setFieldValue("date1", e.target.value);
  };
  const handleDateChangeDate2 = (e) => {
    formik.setFieldValue("date2", e.target.value);
  };
  console.log(formik?.errors, "projploterror");
  return (
    <div>
      {loading ? <div className="vh-100 d-flex justify-content-center align-items-center"><Spinner /></div>
      :
      <form onSubmit={formik.handleSubmit}>
        {invalidId === "Invalid id" ?
         <div className="d-flex justify-content-center align-items-center" style={{height: "70vh"}}>Project Not Found</div>
         : <div className={` ${styles.border_color_gray} rounded-4 m-4 `}>
         <Modal_property
             show={show}
             mainPropertyType={mainPropertyType}
             handleclose={() => setShow(false)}
             background={modalButton === "Approve" ? "green" : "red"}
             btnText={modalButton === "Approve" ? "approve" : "reject"}
             propertyStatus={propertyStatus}
             navigateUrl={"/property-project/project-requests"}
             type="project"
           />
           <div className={`d-flex justify-content-between my-2 `}>
             <div
               className="d-flex align-items-center ms-4"
               style={{ cursor: "pointer" }}
               onClick={() => navigate(-1)}
             >
               <Leftarrowicon />
               <Primarybutton
                 btnText={"BACK"}
                 className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
               />
             </div>
             <div>
               {!valuesCompareFn(formik?.values) ? (
                 <button
                   className="btn btn-primary me-3"
                   type="submit"
                 >
                   submit
                 </button>
               ) : location.pathname.includes("live-projects")
               ? null : (
            // : location.pathname.includes("live-property-requests") &&
            //   propertyDetails.status === "approved" ? null : (
                 <>
                   <Primarybutton
                     btnText={"APPROVE"}
                     type="button"
                     onClick={() => {
                       setModalButton("Approve");
                       setShow(true);
                       setPropertyStatus("approved");
                     }}
                     className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
                   />
                   <Primarybutton
                     btnText={"REJECT"}
                     type="button"
                     onClick={() => {
                       setModalButton("Reject");
                       setShow(true);
                       setPropertyStatus("rejected");
                     }}
                     className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
                   />
                 </>
               )}
             </div>
           </div>
           <div className="border-bottom"></div>
           <div className="scrolling px-3" style={{ overflowY: "auto", overflowX: "hidden", height: "70vh" }}>
       <PropertyBasicDetails propertyBasicDetails={propertyBasicDetails} />
       <div className={` my-3 d-flex align-items-center`}>
         <h5 className={`light_blue_color fs_18 me-3`}>Property Location</h5>
         <hr className={`py-1 flex-grow-1 border-primary`} />
       </div>
       <div className="row position-relative">
         {/* <div className="col-7">
         {mapLoading ? <LocationAndSearch formik={formik} /> : <div className="d-flex justify-content-center align-items-center col-7 w-100" style={{ height: "20rem" }}><Spinner /></div>}
         </div> */}
         <div className="col-5">
         {formik?.values && <PropertyLocation formik={formik} mapLoading={mapLoading}/>}
         <div className="mt-3">
                    <UseFormikSelectField
                      label="Status of Projects Construction"
                      customStyles={{ width: "100%" }}
                      name="status_of_projects_construction"
                      formik={formik}
                      formikError={
                        formik?.errors?.status_of_projects_construction
                      }
                      formikTouched={
                        formik?.touched?.status_of_projects_construction
                      }
                      options={projectStatus}
                      defaultValue={
                        formik?.values?.status_of_projects_construction && {
                          label: removeUnderScore(
                            formik?.values?.status_of_projects_construction
                          ),
                          value:
                            formik?.values?.status_of_projects_construction,
                        }
                      }
                    />
                  </div>
         <div className="mt-3">
         <label className={`secondary_color fs_15`}>Project Lunched On</label>
            <div className={` border_radius_5px input_border focus_none mb-3`}>
              <input
                type="date"
                name="date1"
                className={`${styles.input_text} w-100 px-2 custom-date-picker  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
                id={"date1"}
                value={formik?.values?.date1}
                onChange={handleDateChangeDate1}
              />
            </div>
         </div>
         <div className="mt-3">
         <label className={`secondary_color fs_15`}>
          {formik?.values?.status_of_projects_construction === "completed" ? "Project Completion On" : "Expected Completion Date"}
        </label>
            <div className={` border_radius_5px input_border focus_none mb-3`}>
              <input
                type="date"
                name="date2"
                className={`${styles.input_text} w-100 px-2 custom-date-picker  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
                id={"date2"}
                value={formik?.values?.date2}
                onChange={handleDateChangeDate2}
              />
            </div>
         </div>
         {formik?.errors?.date2 && <span className="text-danger fs_14">{formik?.errors?.date2}</span>}
           <div className="mt-3">
           <UseFormikInputField
             placeholder="Enter Project/Society Name (optional)"
             inputValue={formik?.values?.project_details?.project_or_society_name}
             itemName="project_details.project_or_society_name"
             labelName="Project/Society Name"
             onChangeFn={formik.handleChange}
             onBlurFn={formik.handleBlur}
           />
           </div>
         </div>
       </div>
 
      {[...Array(formik?.values?.area_and_price_details?.length)].map((item, index) => {
         return <FeaturesVariation formik={formik} index={index} />;
       })}
 
       <ResidentialProPlotFeatures 
        formik={formik} 
        compareAminities={compareAminities} 
        setCompareAminities={setCompareAminities} 
        projectDetails={projectDetails}
        setIsClickedOnAme={setIsClickedOnAme}
        isClickedOnAme={isClickedOnAme}
      />
 
       <OfficeAddPropertyPhoto
         multiImage={multiImage}
         setMultiImage={setMultiImage}
       />
       <BroucherUpload formik={formik}/>

       <div className="discription">
               <div className={`mt-5 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>
                   Description
                  </h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                </div>
                  <UseFormikInputField
                    label_font_size=""
                labelName=""
                itemName={"description"}
                inputValue={formik.values.description}
                onChangeFn={formik.handleChange}
                onBlurFn={formik.handleBlur}
                formikError={formik.errors.description}
                formikTouched={formik.touched.description}
                placeholder="Describe your project"
                input={false}
                    TextArea={true}
                  />
            </div>
       </div>
       </div>
        }
        
      </form>
      }
      
    </div>
  );
};

export default ResidentialPlot;
const projectStatus = [
  { value: "launched", label: "Launched" },
  { value: "escalation", label: "Escalations" },
  { value: "foundation", label: "Foundation" },
  { value: "structure_implementation", label: "Structure Implementations" },
  {
    value: "mechanical_electrical_plumbing",
    label: "Mechanical Electrical and Plumbing",
  },
  {
    value: "insulation_waterproofing",
    label: "Insulation and Water Proofing",
  },
  { value: "completed", label: "Completed" },
];