import React from "react";

const PropertiesKeyLogo = ({ width = "10", height = "20", fill="#8E99A0" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillrule="evenodd"
        cliprule="evenodd"
        d="M13.509 11.34C13.6878 11.3393 13.8591 11.2679 13.9855 11.1415C14.1119 11.0151 14.1833 10.8438 14.184 10.665V9C14.1833 8.8212 14.1119 8.64991 13.9855 8.52348C13.8591 8.39705 13.6878 8.32571 13.509 8.325H8.38795C8.22598 7.78737 7.8761 7.32591 7.40215 7.02484C6.9282 6.72376 6.36177 6.60314 5.80627 6.685C5.25078 6.76686 4.74325 7.04573 4.37629 7.47073C4.00934 7.89573 3.80742 8.4385 3.80742 9C3.80742 9.5615 4.00934 10.1043 4.37629 10.5293C4.74325 10.9543 5.25078 11.2331 5.80627 11.315C6.36177 11.3969 6.9282 11.2762 7.40215 10.9752C7.8761 10.6741 8.22598 10.2126 8.38795 9.675H10.287V10.665C10.287 10.844 10.3581 11.0157 10.4847 11.1423C10.6113 11.2689 10.783 11.34 10.962 11.34C11.1411 11.34 11.3127 11.2689 11.4393 11.1423C11.5659 11.0157 11.637 10.844 11.637 10.665V9.675H12.834V10.665C12.8347 10.8438 12.9061 11.0151 13.0325 11.1415C13.159 11.2679 13.3302 11.3393 13.509 11.34ZM5.10304 0H12.897C15.948 0 18 2.14021 18 5.32531V12.6756C18 15.8607 15.948 18 12.897 18H5.10304C2.05204 18 0 15.8607 0 12.6756V5.32531C0 2.13931 2.05204 0 5.10304 0ZM5.16599 9C5.16599 8.8042 5.22409 8.61279 5.33287 8.44998C5.44166 8.28718 5.59626 8.16029 5.77716 8.08536C5.95806 8.01043 6.15707 7.99083 6.34911 8.02903C6.54115 8.06723 6.71756 8.16151 6.85602 8.29996C6.99447 8.43842 7.08874 8.61482 7.12694 8.80686C7.16514 8.9989 7.14562 9.19795 7.07069 9.37885C6.99576 9.55975 6.86886 9.71437 6.70605 9.82315C6.54325 9.93193 6.35177 9.99001 6.15597 9.99001C5.8934 9.99001 5.64158 9.8857 5.45592 9.70004C5.27026 9.51437 5.16599 9.26256 5.16599 9Z"
        fill={fill}
      />
    </svg>
  );
};

export default PropertiesKeyLogo;
