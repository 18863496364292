import { PROPERTY_UPDATE, PROPERTY_VIEW } from "../../type";

export const propertyViewAction = (id, onSuccess, onError) => {
  console.log(id, "aaaaa");
  console.log(id);
  return { type: PROPERTY_VIEW, id, onSuccess, onError };
};
export const propertyUpdateAction = (data, onSuccess, onError) => {
  return { type: PROPERTY_UPDATE, data, onSuccess, onError };
};
