import { call, takeLatest } from "redux-saga/effects";
import {
  COMMERCIAL_PROPERTY_VIEW,
  EDIT_COMMERCIAL_OFFICE_SPACE,
  EDIT_COMMERCIAL_PLOT,
  EDIT_COMMERCIAL_SHOP,
  EDIT_COMMERCIAL_SHOW_ROOM,
  EDIT_INDUSTRIAL_BUILDING,
  EDIT_INDUSTRIAL_PLOT,
  EDIT_INDUSTRIAL_SHED,
  EDIT_WARE_HOUSE,
} from "../../../type";
import {
  commercialPropertyViewApi,
  editCommercialIndusBuildingApi,
  editCommercialIndusPlotApi,
  editCommercialIndusShedApi,
  editCommercialOfficeSpaceApi,
  editCommercialPlotApi,
  editCommercialShopApi,
  editCommercialShowroomApi,
  editCommercialWareHouseApi,
} from "../../../api/edit-property-api/commercial/commercialApis";

export function* commercialPropertyViewWorker(action) {
  try {
    const res = yield call(commercialPropertyViewApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* editCommercialOfficeSpaceWorker(action) {
  try {
    const res = yield call(editCommercialOfficeSpaceApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editCommercialPlotWorker(action) {
  try {
    const res = yield call(editCommercialPlotApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editCommercialShopWorker(action) {
  try {
    const res = yield call(editCommercialShopApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editCommercialShowroomWorker(action) {
  try {
    const res = yield call(editCommercialShowroomApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editCommercialIndusBuildingWorker(action) {
  try {
    const res = yield call(editCommercialIndusBuildingApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editCommercialIndusPlotWorker(action) {
  try {
    const res = yield call(editCommercialIndusPlotApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editCommercialIndusShedWorker(action) {
  try {
    const res = yield call(editCommercialIndusShedApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editCommercialWareHouseWorker(action) {
  try {
    const res = yield call(editCommercialWareHouseApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}


export function* commercialPropertyViewWatcher() {
  yield takeLatest(COMMERCIAL_PROPERTY_VIEW, commercialPropertyViewWorker);
}
export function* editCommercialOfficeSpaceWatcher() {
  yield takeLatest(
    EDIT_COMMERCIAL_OFFICE_SPACE,
    editCommercialOfficeSpaceWorker
  );
}
export function* editCommercialPlotWatcher() {
  yield takeLatest(
    EDIT_COMMERCIAL_PLOT,
    editCommercialPlotWorker
  );
}
export function* editCommercialShopWatcher() {
  yield takeLatest(
    EDIT_COMMERCIAL_SHOP,
    editCommercialShopWorker
  );
}
export function* editCommercialShowroomWatcher() {
  yield takeLatest(
    EDIT_COMMERCIAL_SHOW_ROOM,
    editCommercialShowroomWorker
  );
}
export function* editCommercialIndusBuildingWatcher() {
  yield takeLatest(
    EDIT_INDUSTRIAL_BUILDING,
    editCommercialIndusBuildingWorker
  );
}
export function* editCommercialIndusPlotWatcher() {
  yield takeLatest(
    EDIT_INDUSTRIAL_PLOT,
    editCommercialIndusPlotWorker
  );
}
export function* editCommercialIndusShedWatcher() {
  yield takeLatest(
    EDIT_INDUSTRIAL_SHED,
    editCommercialIndusShedWorker
  );
}
export function* editCommercialWareHouseWatcher() {
  yield takeLatest(
    EDIT_WARE_HOUSE,
    editCommercialWareHouseWorker
  );
}
