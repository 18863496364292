import React, { useEffect, useState } from "react";
import styles from "../../styles/property-request.module.css";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-functions/loading-spinner";
import moment from "moment";
import { nFormatter } from "../../components/helper-functions/helperFunctions";
import { LivePropertyListAction } from "../../redux/action/property-actions/propertyRequestAction";
import TablePagination from "../../components/helper-functions/table-pagination";
import { removeUnderScore } from "../../components/helper/helper";

const LivePropertyList = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const router = useLocation();
  const navigate = useNavigate();
  const [Fetchdata, setFetchdata] = useState([]);
  const [pagination, setPagination] = useState();
  const [homePath, setHomePath] = useState();
  const pageParam = +searchParams.get("page");
  const search = searchParams.get("search") ?? "";
  const currentPage = pageParam && !isNaN(pageParam) ? pageParam : 1;
  useEffect(() => {
    setLoadingData(true);
    dispatch(
      LivePropertyListAction(
        {
          page_no: currentPage,
          search: search,
        },
        onFetchSuccess,
        onFetchError
      )
    );
  }, [router.search]);

  function onFetchSuccess(data) {
    setHomePath(router?.pathname?.includes("/"));
    setLoadingData(false);
    console.log(data?.data?.pages);
    setPagination({
      totalPages: data?.data?.pages,
      currentPage: currentPage,
    });
    setFetchdata(data?.data?.properties);
    console.log(data);
  }

  function onFetchError(data) {
    console.log(data);
  }

  const navigationFn = (id, property_type) => {
    if (property_type === "apartment") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "residential") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "plot") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "villa") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "office_space") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "commercial_shop") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "commercial_showroom") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "warehouse") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "industrial_building") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "industrial_shed") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "commercial_plot") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "industrial_plot") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "farm_house") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    } else if (property_type === "land") {
      navigate(
        `/property-project/live-property-requests/edit-property/${property_type}/${id}`
      );
    }
  };
  console.log(Fetchdata, "Fetchdata");
  return (
    <div
      className="rounded-4 border-1  bg-white py-3 position-relative"
      style={{ minHeight: "75vh" }}
    >
      <input
        className="w-50 mb-3 ms-4 rounded-3 py-2 border_color_grey table_background_color_grey ps-3 fs_14 fw_400"
        placeholder="Search here by property id, property type, posted by , user id ..."
        style={{ outline: "none" }}
        onChange={(e) => {
          setSearchParams({
            page: 1,
            search: e.target.value,
          });
        }}
      />

      <div
        className=" rounded-4  border_color mx-3"
        style={{ minHeight: "65vh" }}
      >
        <div
          className={`overflow-x-scroll  ${styles.scroll_decoration}`}
          style={{ minHeight: "65vh" }}
        >
          <table className="w-100">
            <thead>
              <tr style={{ borderBottom: "1px solid #E5ECFF" }}>
                {/* <th className="ps-3 pe-1 py-3 fs_14 fw_500 text_color_grey  text-nowrap position-sticky">
                      <input
                        type="checkbox"
                        onChange={(e) => functionFn(e)}
                      />
                    </th> */}
                <th
                  className="fs_14  fw_500 ps-4 py-3 text-nowrap text_color_grey"
                  style={{ minWidth: "2rem" }}
                >
                  #
                </th>
                <th
                  className="fs_14  fw_500 ps-4 py-3 text-nowrap text_color_grey"
                  style={{ minWidth: "8rem" }}
                >
                  Property ID
                </th>
                <th
                  className="fs_14  fw_500 ps-5  py-2 text-nowrap text_color_grey"
                  style={{ minWidth: "8rem" }}
                >
                  Property Type
                </th>
                <th
                  className="fs_14  fw_500 ps-5 py-2 text-nowrap text_color_grey"
                  style={{ minWidth: "8rem" }}
                >
                  Property Price
                </th>
                <th
                  className="fs_14  fw_500 ps-5 py-2 text-nowrap text_color_grey"
                  style={{ minWidth: "8rem" }}
                >
                  Post type
                </th>
                <th
                  className="fs_14  fw_500 ps-5 py-2 text-nowrap text_color_grey"
                  style={{ minWidth: "8rem" }}
                >
                  Posted By
                </th>

                <th
                  className="fs_14  fw_500 ps-5 py-2 text-nowrap text_color_grey"
                  style={{ minWidth: "8rem" }}
                >
                  Posted Date & Time
                </th>
                <th
                  className="fs_14  fw_500 ps-5 py-2 text-nowrap text_color_grey"
                  style={{ minWidth: "8rem" }}
                >
                  Updated Date & Time
                </th>

                <th
                  className="pe-5  ps-5 py-1 fs_14  fw_500 position-sticky bg-white text_color_grey rounded-5"
                  style={{
                    right: "0px",
                    width: "100%",
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>

            {loadingData === true ? (
              <LoadingSpinner />
            ) : (
              <>
                {Fetchdata?.length > 0 ? (
                  <tbody className="position-relative ">
                    {Fetchdata.map((item, index) => {
                      return (
                        <>
                          <tr style={{ borderBottom: "1px solid #E5ECFF" }}>
                            <React.Fragment key={index}>
                              {/* <td className="ps-3 pe-1 py-3 fs_14 fw_400   ">
                                <input
                                  type="checkbox"
                                  className="mt-2"
                                   checked={selectCheckbox} onChange={(e)=>functionFn(e)}
                                ></input>
                              </td> */}
                              <td className={` fw_400  ps-4 py-3 text-nowrap`}>
                                <span className={`fs_14 `}>
                                  {(currentPage - 1) * 10 + (index + 1)}
                                </span>
                              </td>
                              <td className={` fw_400  ps-4 py-3 text-nowrap`}>
                                <span className={`fs_14 `}>
                                  {item?.id ? item?.id : "---"}
                                </span>
                              </td>
                              <td className={` fw_400  ps-5 py-2 text-nowrap`}>
                                <span className={`fs_14 text-capitalize `}>
                                  {item?.property_type
                                    ? item?.property_type
                                    : "---"}
                                </span>
                              </td>
                              <td className={` fw_400  ps-5 py-2 text-nowrap`}>
                                <span className={`fs_14 `}>
                                  {item?.post_type == "rent" ? (
                                    item?.price_details?.rent_price ? (
                                      <>
                                        ₹{" "}
                                        {nFormatter(
                                          item?.price_details?.rent_price
                                        )}
                                      </>
                                    ) : (
                                      "--"
                                    )
                                  ) : item?.price_details?.expected_price ? (
                                    <>
                                      ₹{" "}
                                      {nFormatter(
                                        item?.price_details?.expected_price
                                      )}
                                    </>
                                  ) : (
                                    "--"
                                  )}
                                </span>
                              </td>{" "}
                              <td className={` fw_400  ps-5 py-2 text-nowrap`}>
                                <span className={`fs_14 text-capitalize`}>
                                  {item?.post_type}
                                </span>
                              </td>
                              <td
                                className={` fw_400 fs_14  ps-5 py-2 text-nowrap`}
                              >
                                {item?.user_id ? (
                                  <span className="text-capitalize ms-1">
                                    {item?.name}
                                    <span className="text-capitalize ms-1">
                                      {item?.owner_type && (
                                        <span>({item?.owner_type})</span>
                                      )}
                                    </span>
                                  </span>
                                ) : (
                                  "--"
                                )}
                              </td>
                              <td className={` fw_400  ps-5 py-2 text-nowrap`}>
                                <span className={`fs_14 `}>
                                  {item?.createdAt
                                    ? moment(item?.createdAt).format(
                                        "DD MMM YYYY, HH:MM A"
                                      )
                                    : "---"}
                                </span>
                              </td>
                              <td className={` fw_400  ps-5 py-2 text-nowrap`}>
                                <span className={`fs_14 `}>
                                  {item?.createdAt
                                    ? moment(item?.createdAt).format(
                                        "DD MMM YYYY, HH:MM A"
                                      )
                                    : "-"}
                                </span>
                              </td>
                              <div
                                className=" position-sticky bg-white rounded-4 mt-2 pt-2 py-1 ps-5"
                                style={{ right: "0px", width: "100%" }}
                              >
                                <td
                                  className={`fs_14 pt-1 fw_500  me-4 text_color_light_green  cursor_pointer`}
                                  // onClick={() => handlePropertyView(item?.id)}
                                  onClick={() =>
                                    navigationFn(item?.id, item?.property_type)
                                  }
                                >
                                  VIEW
                                </td>
                              </div>
                            </React.Fragment>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                ) : (
                  <>
                    <div
                      className=" text-nowrap empty_table_position position-absolute fs_30 fs_sm_22"
                      style={{ top: "50%", left: "35%" }}
                    >
                      --Table is Empty--
                    </div>
                  </>
                )}
              </>
            )}
          </table>
        </div>
      </div>

      <TablePagination
        pagination={pagination}
      />
    </div>
  );
};

export default LivePropertyList;
