import React, { useEffect, useState } from "react";
import styles from "../../../styles/edit-property/edit-property.module.css";
import UseFormikInputField from "../../form/useFormikInputField";
import UseFormikSelectField from "../../form/useFormikSelectField";
import { removeUnderScore } from "../../helper/helper";
import isEqual from "lodash.isequal";

const FlatApartmentFeature = ({ formik, propertyDetails, setCompareAminities, setApproveButton, isClickedOnAme, setIsClickedOnAme }) => {
 
  const aminitiesList = [
    { name: "elevators_lifts", label: "Elevators/Lifts", status: false },
    {
      name: "guest_parking_space",
      label: "Guest Parking Spaces",
      status: false,
    },
    { name: "gym", label: "Gym", status: false },
    { name: "cctv_surveillance", label: "CCTV Surveillance", status: false },
    { name: "swimming_pool", label: "Swimming Pool", status: false },
    { name: "play_ground", label: "Play Ground", status: false},
    { name: "kids_play_area", label: "Kids Play Area", status: false },
    {
      name: "community_clubhouse",
      label: "Community Clubhouse",
      status: false,
    },
    { name: "garden", label: "Garden", status: false },
    {
      name: "pet_washing_stations",
      label: "Pet Washing Stations",
      status: false,
    },
    {
      name: "rooftop_lounge_areas",
      label: "Rooftop Lounge Areas",
      status: false,
    },
    {
      name: "electronic_vehicle_charging_points",
      label: "Electronic Vehicle Charging Points",
      status: false,
    },
    { name: "barbecue_areas", label: "Barbecue Areas", status: false },
    
    
  ];
  
  // const initialAminities = propertyDetails?.property_features?.amenities;
  // const [oldObj, setOldObj] = useState([]);
  
  // // Now, updatedAminitiesList contains the updated status values
  // const [amenities, setAmenities] = useState([]);
  // useEffect(()=>{
  //   const updatedAminitiesList = aminitiesList?.map((aminity) => {
  //     const matchingBackendAminity = initialAminities?.find((backendAminity) => {return backendAminity.name === aminity.name;});
  
  //     if (matchingBackendAminity) {
  //       // Update the status if a matching backend aminity is found
  //       return { ...aminity, status: matchingBackendAminity.status };
  //     }
    
  //     // Return the original aminity if no matching backend aminity is found
  //     return aminity;
  //   });
  //   setAmenities([...updatedAminitiesList]);
  //   setOldObj([...updatedAminitiesList]);
  // },[]);
  // const handleCheckboxChange = (index) => {
  //   // Create a copy of the current amenitiesList
  //   const newUpdatedAminitiesList = [...amenities];
  
  //   // Toggle the status of the selected item
  //   newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
  
  //   // Update the state with the new copy of the array
  //   setAmenities([...newUpdatedAminitiesList]);
  //   const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
  //   // Update the formik field value
  //   formik.setFieldValue("property_features.amenities", filteredAmenities);
  //   setApproveButton(false);
  // };

  const initialAminities = propertyDetails?.property_features?.amenities;
  const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);
  
  const aminitiesAreEqual = (currentAminities, initialAminities)=>{
    const isAmentiesMatches = currentAminities?.every((currentItem, index) => initialAminities[index]?.status === currentItem?.status);
    if(isAmentiesMatches){
      setCompareAminities(true);
    }
    else{
      setCompareAminities(false);
    }
  }
  const updatedAminitiesList = aminitiesList?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {
      return backendAminity.name === aminity.name;
    });

    if (matchingBackendAminity) {
      // Update the status if a matching backend aminity is found
      return { ...aminity, status: matchingBackendAminity.status };
    }
  
    // Return the original aminity if no matching backend aminity is found
    return aminity;
  });
  
  // Now, updatedAminitiesList contains the updated status values
  const [amenities, setAmenities] = useState(updatedAminitiesList);
  const handleCheckboxChange = (index) => {
    // Create a copy of the current amenitiesList
    const newUpdatedAminitiesList = [...amenities];
  
    // Toggle the status of the selected item
    newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
  
    // Update the state with the new copy of the array
    setAmenities(newUpdatedAminitiesList);
    const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
    // Update the formik field value
    // aminitiesAreEqual(amenities, formik?.initialValues?.property_features?.amenities);
    formik.setFieldValue("property_features.amenities", filteredAmenities);
  };
  if(isClickedOnAme){
    aminitiesAreEqual(amenities, formik?.initialValues?.property_features?.amenities);
  }
  return (
    <>
      <div className={` my-3 d-flex align-items-center pt-3`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Property Features</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.total_floors}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.total_floors}
            formikTouched={formik?.touched?.property_features?.total_floors}
            itemName="property_features.total_floors"
            labelName="Total floors"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.floor_number}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.floor_number}
            formikTouched={formik?.touched?.property_features?.floor_number}
            itemName="property_features.floor_number"
            labelName="Floor number"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.bedrooms}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.bedrooms}
            formikTouched={formik?.touched?.property_features?.bedrooms}
            itemName="property_features.bedrooms"
            labelName="Bedroom"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.bathrooms}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.bathrooms}
            formikTouched={formik?.touched?.property_features?.bathrooms}
            itemName="property_features.bathrooms"
            labelName="Bathroom"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.car_parking_count}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.car_parking_count}
            formikTouched={
              formik?.touched?.property_features?.car_parking_count
            }
            itemName="property_features.car_parking_count"
            labelName="Car parking Count"
          />
        </div>
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center mt-3">
        <UseFormikSelectField
          label="Facing"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.facing"
          formik={formik}
          formikError={formik?.errors?.property_features?.facing}
          formikTouched={formik?.touched?.property_features?.facing}
          defaultValue={
            formik?.values?.property_features?.facing && {
              label: removeUnderScore(
                formik?.values?.property_features?.facing
              ),
              value: formik?.values?.property_features?.facing,
            }
          }
        />
        <UseFormikSelectField
          label="Furnishing Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.furnishing_status"
          formik={formik}
          formikError={formik?.errors?.property_features?.furnishing_status}
          formikTouched={formik?.touched?.property_features?.furnishing_status}
          defaultValue={
            formik?.values?.property_features?.furnishing_status && {
              label: removeUnderScore(
                formik?.values?.property_features?.furnishing_status
              ),
              value: formik?.values?.property_features?.furnishing_status,
            }
          }
        />
        <UseFormikSelectField
          label="Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.status"
          formik={formik}
          formikError={formik?.errors?.property_features?.status}
          formikTouched={formik?.touched?.property_features?.status}
          defaultValue={
            formik?.values?.property_features?.status && {
              label: removeUnderScore(
                formik?.values?.property_features?.status
              ),
              value: formik?.values?.property_features?.status,
            }
          }
        />
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Property Amenities</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        <div className="row g-0">
          {/* {suitableFor?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => handleCheckboxChange(index)}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.name ? removeUnderScore(item?.name) : "--"}
                  </div>
                </div>
              </div>
            );
          })} */}
          {amenities?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </>
  );
};

export default FlatApartmentFeature;
