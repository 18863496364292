import React, { useEffect, useState } from "react";
import styles from "../../styles/property-details.module.css";
import Leftarrowicon from "../../assets/icons/left-arrow-icon";
import Primarybutton from "../../components/ui/buttons/primary-button";
import { useNavigate } from "react-router-dom";
import { InputContainer } from "../../components/form/inputField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Close from "../../assets/icons/close";
import TickIcon from "../../assets/icons/tick-icon";
import { useDispatch, useSelector } from "react-redux";
import {
  LoactionSearchAction,
  propertyDataAction,
  requestCallBackDataAction,
} from "../../redux/action/export-action/exportAction";
import errorToast from "../../components/helper-functions/errorToast";
import Select from "react-select";
import { AsyncSelectSearch } from "../../components/dataFetching/asyncSelectComp";
import useScreenSize from "../../components/helper/useScreeenSize";
import SearchBar, {
  LocalitySearch,
} from "../../components/dataFetching/localitySearch";
import locationIcon from "../../assets/icons/locationIcon.svg";
import { selectStyles } from "../../components/form/selectField";
import successToast from "../../components/helper-functions/successToast";
const PropertyData = () => {
  const navigate = useNavigate();
  const disptach = useDispatch();
  const width = useScreenSize();

  const options = [
    { value: "residential", label: "Residential" },
    { value: "agriculture", label: "Agriculture" },
    { value: "commercial", label: "Commercial" },
    { value: "pg", label: "PGs" },
    { value: "residential_project", label: "Projects" },
  ];

  const [fetchedOptions, setFetchedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const locationData = useSelector((state) => state.locationDataReducer);
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState([]);
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setCity(locationData?.selectedOptions?.[0]?.value?.city);
    // Filter out null values from the locality array
    const filteredLocalities = (locationData?.selectedOptions || [])
      .map((item) => item?.value?.locality)
      .filter((locality) => locality !== null);

    setLocality(filteredLocalities);
  }, [locationData]);

  console.log(locality, "alknsdadasdlakdas");

  const formik = useFormik({
    initialValues: {
      post_type: "sale",
      city: city,
      locality: locality,
      property_type: [],
    },
    validationSchema: Yup.object({
      // fromDate: Yup.string().trim().required("required"),
      // toDate: Yup.string().trim().required("required"),
    }),
    onSubmit: (values) => {
      console.log(values, "ajsdakjda");
      disptach(
        propertyDataAction(
          {
            post_type: values?.post_type,
            location: {
              type: "city",
              city: city,
              locality: locality,
            },
            property_type: values?.property_type,
          },
          onSuccess,
          onError
        )
      );
    },
  });
  console.log(formik.values, "alknsdlakdas");

  const onSuccess = (data) => {
    console.log(data, "kasdadkaldka");
    // if (data && data?.data?.length > 0) {
    //   // Extract column headings from the first data object and add "name" and "phone_number" as headings
    //   const headings = Object.keys(data?.data[0]);
    //   headings.push("name", "phone_number");

    //   // Create a CSV-formatted string with headings and formatted data
    //   const csvData = [
    //     headings.join(","), // Convert headings array to a CSV line
    //     ...data?.data.map((item) => {
    //       // Extract User properties and format createdAt
    //       const userProperties = data?.data[0]?.User || {};
    //       const createdAt = new Date(item.createdAt);
    //       const formattedDate = createdAt.toLocaleDateString();
    //       const formattedTime = createdAt.toLocaleTimeString();

    //       // Combine date and time into a single field
    //       item.createdAt = `${formattedDate} ${formattedTime}`;

    //       // Generate a CSV line with the updated data object and User properties
    //       return [
    //         ...headings.map((key) => {
    //           if (key === "name") {
    //             return userProperties.name; // Include "name" value as a column
    //           } else if (key === "phone_number") {
    //             return userProperties.phone_number; // Include "phone_number" value as a column
    //           }
    //           return item[key];
    //         }),
    //       ].join(",");
    //     }),
    //   ].join("\n");

    //   // Create a Blob with the CSV data
    //   var type = "text/csv;charset=utf-8";
    //   var blob = new Blob([csvData], {
    //     type: type,
    //   });

    //   // Create a link to trigger the download
    //   var link = document.createElement("a");
    //   link.href = window.URL.createObjectURL(blob);
    //   link.download = "data.csv";

    //   // Trigger the download
    //   link.click();
    //   successToast("Exported Successfuly");
    // }

    if (data && data?.data?.length > 0) {
      // Extract column headings from the first data object
      const headings = Object.keys(data?.data[0]);

      // Create a CSV-formatted string with headings and formatted data
      const csvData = [
        headings.join(","), // Convert headings array to a CSV line
        ...data?.data.map((item) => {
          // Split the createdAt field into date and time
          const createdAt = new Date(item.createdAt);
          const formattedDate = createdAt.toLocaleDateString();
          const formattedTime = createdAt.toLocaleTimeString();

          // Combine date and time into a single field
          item.createdAt = `${formattedDate} ${formattedTime}`;

          // Generate a CSV line with the updated data object
          return headings.map((key) => item[key]).join(",");
        }),
      ].join("\n");

      // Create a Blob with the CSV data
      var type = "text/csv;charset=utf-8";
      var blob = new Blob([csvData], {
        type: type,
      });

      // Create a link to trigger the download
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "data.csv";

      // Trigger the download
      link.click();
      successToast("Exported Successfuly");
    }

    if (data?.data?.length === 0) {
      errorToast("No Data");
    }
  };

  const onError = (data) => {
    console.log("ERROR==================", data);
    errorToast("Failed to export data");
  };

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    formik.setFieldValue(
      "property_type",
      selectedValues?.map((item) => item?.value)
    );
  };
  return (
    <div className="font_family_poppins">
      <p className="fw_400 fs_16 text_color_grey ms-5 mt-3 ">
        ADMIN / Export / REQUEST CALLBACK DATA
      </p>
      <div
        style={{ minHeight: "70vh" }}
        className={` ${styles.border_color_gray} rounded-4 m-4 bg-white`}
      >
        <div className={`d-flex justify-content-between my-2 px-4 py-2`}>
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <Leftarrowicon />
            <Primarybutton
              btnText={"BACK"}
              className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
            />
          </div>
        </div>
        <hr />
        <div className="px-4 py-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7">
              <div className="d-flex gap-3">
                <div>
                  <input
                    type="radio"
                    id="sale"
                    name="post_type"
                    value="sale"
                    checked={formik.values.post_type === "sale"}
                    onChange={(e) => {
                      let event = {
                        target: {
                          name: "post_type",
                          value: e.target.value,
                        },
                      };
                      formik.handleChange(event);
                    }}
                  />{" "}
                  <label htmlFor="sale">Sell</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="rent"
                    name="post_type"
                    value="rent"
                    checked={formik.values.post_type === "rent"}
                    onChange={(e) => {
                      let event = {
                        target: {
                          name: "post_type",
                          value: e.target.value,
                        },
                      };
                      formik.handleChange(event);
                    }}
                  />{" "}
                  <label htmlFor="rent">Rent</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="pg"
                    name="post_type"
                    value="pg"
                    checked={formik.values.post_type === "pg"}
                    onChange={(e) => {
                      let event = {
                        target: {
                          name: "post_type",
                          value: e.target.value,
                        },
                      };
                      formik.handleChange(event);
                    }}
                  />{" "}
                  <label htmlFor="pg">PGs</label>
                </div>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="pb-2">city</span>
                <AsyncSelectSearch
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  setFetchedOptions={setFetchedOptions}
                  fetchedOptions={fetchedOptions}
                  isMulti={true}
                  placeholder="Select locality or city"
                />
              </div>

              <div className="d-flex flex-column mt-3 position-relative">
                <span className="pb-2">locality</span>
                <div className="mt-2">
                  <SearchBar
                    locationIcon={locationIcon}
                    showSearchFilter={showSearchFilter}
                    setShowSearchFilter={setShowSearchFilter}
                  />
                </div>
              </div>
              <div className="d-flex flex-column mt-5">
                <span className="py-2">property_type</span>
                <Select
                  isMulti
                  options={options}
                  styles={selectStyles}
                  value={selectedOptions}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex justify-content-end gap-3 mt-4">
                <button
                  onClick={() => {
                    formik.resetForm();
                    setSelectedOptions([]);
                  }}
                  style={{ backgroundColor: "rgba(219, 233, 242, 1)" }}
                  type="button"
                  className="text-black border-0 p-2 px-3 rounded-2 fs_14 fw_500 align-items-center d-flex"
                >
                  <Close height="10" width="10" className="me-2" />
                  CLEAR
                </button>
                <button
                  type="submit"
                  className="background_color_skyblue text-white border-0 p-2 px-3 rounded-2 fs_14 fw_500"
                >
                  <TickIcon className={"me-2"} />
                  Export
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PropertyData;
