import React from "react";

const CancelButton = ({
  btnText = "Cancel",
  className = "",
  text_class = "",
  disabled = false,
  customStyles = {},
  buttonWidth = "100%",
  type = "button",
  onClick = () => { },
  
}) => {
  return (
    <button
      type={type}
      style={{ width: buttonWidth, ...customStyles }}
      disabled={disabled}
      className={`fw_500 px-5 ${className}`}
      onClick={onClick}
    >
      <span className={`fs_20 ${text_class}`}> {btnText}</span>
    </button>
  );
};

export default CancelButton;
