import { api } from "../api";

export const PropertyRequestApi = (data, params) => {
  console.log(data, "kjihg");
  return api.get(
    `/api/admin/properties/approval-list?page_no=${data.page_no}&search=${data.search}`,
    params
  );
};
export const livePropertyListApi = (data, params) => {
  console.log(data, "kjihg");
  return api.get(
    `/api/admin/properties/approved-list?page_no=${data.page_no}&search=${data.search}`,
    params
  );
};
