import styled from "@emotion/styled";
import { Field } from "formik";
import React from "react";
import ErrorMsg from "./errorMsg";
import styles from "../../styles/components/components.module.css";
export const InputContainer = styled.div(
  {
    width: "50%",
    maxWidth: "100%",
    marginBottom: "10px",
    "@media (max-width: 576px)": {
      maxWidth: "100%",
      width: "100%",
    },
  },
  (props) => ({
    ...props?.customStyles,
  })
);
const InputField = ({
  customStyles = {},
  name = "",
  placeholder = "Enter Email ID",
  label = "E-Mail",
  labelClass = "secondary_color fs_15 mb-1",
  id = "",
  type = "text",
  disabled = "",
  inputClass = styles.comp_input,
  textareainputClass = styles.comp_textarea_input,
  errMsgContainerClass = "mt-1 justify-content-end justify-content-lg-start",
  input = true,
  textarea = "",
  errorMsg = true,
  maxLength = "9999",
  dateClassName = "",
  setCount = () => { },
  handleChange = () => { },
}) => {
  return (
    <InputContainer customStyles={customStyles}>
      <label className={labelClass} htmlFor={id ? id : name}>
        {label}
      </label>
      <Field name={name}>
        {({ field, meta }) => (
          <>
            {input && (
              <input
                type={type}
                {...field}
                placeholder={placeholder}
                id={id ? id : name}
                disabled={disabled}
                className={
                  meta.touched && meta.error
                    ? `w-100 ${inputClass} focus_none ${styles.comp_input__error} ${type === "date" && dateClassName} `
                    : `w-100   ${inputClass} focus_none ${type === "date" && dateClassName} `
                }
              />
            )}
            {textarea && (
              <textarea
                type={type}
                disabled={disabled}
                {...field}
                placeholder={placeholder}
                id={id ? id : name}
                className={
                  meta.touched && meta.error
                    ? `w-100 ${textareainputClass} resize_none w-100 ps-3 focus_none ${styles.comp_textarea_input__error} `
                    : `w-100   ${textareainputClass} focus_none  `
                }
                // onChange={(e) => {
                //   setCount(e.target.value.length);
                //   handleChange(e);
                // }}
                maxLength={maxLength}
              />
            )}
          </>
        )}
      </Field>
      {errorMsg ? (
        <ErrorMsg containerClass={errMsgContainerClass} name={name} />
      ) : (
        ""
      )}
    </InputContainer>
  );
};

export default InputField;
