import { call, takeLatest } from "redux-saga/effects";
import { LIVE_PROPERTY_LIST, PROPERTYREQUESTFINDALL } from "../../type";
import {
  PropertyRequestApi,
  livePropertyListApi,
} from "../../api/property-api/propertyRequestApi";

export function* propertyRequestWorker(action) {
  try {
    const response = yield call(PropertyRequestApi, action.data);
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* livePropertyListWorker(action) {
  try {
    const response = yield call(livePropertyListApi, action.data);
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* propertyRequestWatcher() {
  yield takeLatest(PROPERTYREQUESTFINDALL, propertyRequestWorker);
}
export function* livePropertyListWatcher() {
  yield takeLatest(LIVE_PROPERTY_LIST, livePropertyListWorker);
}
