import { all } from "redux-saga/effects";
import {
  editResidenApartmenttialWatch,
  residentialPropFindOneWatch,
  multiplePropertiesPhotoswatch,
  editResidenVillaWatch,
  editResidenHouseWatch,
  editResidenPlotWatch,
} from "../../edit-property-saga/residential/residential-saga";

export default function* editPropertResidentialRootSaga() {
  yield all([
    multiplePropertiesPhotoswatch(),
    residentialPropFindOneWatch(),
    editResidenApartmenttialWatch(),
    editResidenVillaWatch(),
    editResidenHouseWatch(),
    editResidenPlotWatch(),
  ]);
}
