import { all } from "redux-saga/effects";
import {
  editPgWatcher,
  livePgListWatcher,
  pgFindOneWacher,
  pgsRequestWatcher,
} from "../../property-saga/pg-list-saga";

export default function* pgListRootSaga() {
  yield all([pgsRequestWatcher(), livePgListWatcher(), pgFindOneWacher(), editPgWatcher()]);
}
