import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadProjectDocAction } from "../../redux/action/project-actions/projectRequestAction";
import errorToast from "../../components/helper-functions/errorToast";
import DocIcon from "../../assets/icons/doc-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon-without-backgrround.png";
import LoadingSpinner from "../../components/helper-functions/loading-spinner";
const BroucherUpload = ({ formik, setUploadBroucher }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.type === "application/pdf" && file.size <= 20 * 1024 * 1024) {
      setLoading(true);
      dispatch(
        uploadProjectDocAction(file, onFileUploadSuccess, onFileUploadError)
      );
    } else {
      formik.setFieldError(
        "brochure_upload",
        "Please upload a PDF file with a size less than 20 MB."
      );
    }
    event.target.value = null;
  };
  // 1. Prevent default behavior when dragging over the drop area
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  // 2. Handle the file drop event and read the dropped file
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file.type === "application/pdf" && file.size <= 20 * 1024 * 1024) {
      processFile(file);
    } else {
      formik.setFieldError(
        "brochure_upload",
        "Please upload a PDF file with a size less than 20 MB."
      );
    }
  };

  const processFile = (file) => {
    if (!file) return;
    dispatch(
      uploadProjectDocAction(file, onFileUploadSuccess, onFileUploadError)
    );
  };

  const onFileUploadSuccess = (data) => {
    console.log(data, "uploaddoc");
    formik.setFieldValue("brochure_upload", data.docPath);
    setLoading(false);
    const fileLink = data.docPath?.split("/");
    setFile(fileLink[fileLink.length - 1]);

    // formik.setFieldValue("brochure_upload", "broucher uploaded");
    // setUploadBroucher(data?.docPath)
  };
  const onFileUploadError = (data) => {
    setLoading(false);
    errorToast("failed to upload");
    setFile(null);
    formik.setFieldValue("brochure_upload", ""); // uncomment
    //
  };
  console.log(formik?.values, "formikbrouse");
  return (
    <div className="my-4">
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Broucher</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div
        className={`border_color_grey border_radius_10px position-relative p-5`}
        // onDragOver={(e) => { file ? () => undefined : handleDragOver(e); }}
        // onDrop={(e) => { file ? () => undefined : handleDrop(e); }}
        onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => handleDrop(e)}
      >
        {file || formik.values?.brochure_upload ? (
          <div className="w-100">
            <div className="d-flex justify-content-center">
              <div>
                <div className="d-flex justify-content-center">
                  <img src={DocIcon} alt="DocIcon" />
                </div>
                <div className="mt-2">
                  {file
                    ? file
                    : formik.values?.brochure_upload
                    ? formik.values.brochure_upload.split("/").pop()
                    : null}
                  <div className="text-center mt-3">
                    <span className="delete_button_border px-2 py-2 border_radius_7px">
                      <button
                        className="bg_transparent_color border-0 color_D91C30 fw_500"
                        type="button"
                        onClick={() => {
                          formik.setFieldValue("brochure_upload", ""); // uncomment
                          setFile(null);
                        }}
                      >
                        <img className="me-2" src={DeleteIcon} width={17} />
                        DELETE
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : loading ? (
          <>
            <div className="d-flex align-items-center mt-5 py-4">
              <LoadingSpinner />
            </div>
          </>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center ">
            <span className={`upload_text_1 text-center fs_14 fw_500`}>
              Drag & drop the brochure of <br /> your project
            </span>
            <span className={`upload_text_1 fs_12 fw_400`}>
              PDF file - max 20MB each
            </span>
            <span className={`upload_text_1 d-flex align-items-center`}>
              <hr
                className="me-3"
                style={{
                  color: "#BCC7CE",
                  backgroundColor: "#BCC7CE",
                  height: "3px",
                  width: "6rem",
                }}
              />
              OR
              <hr
                className="me-3"
                style={{
                  color: "#BCC7CE",
                  backgroundColor: "#BCC7CE",
                  height: "3px",
                  width: "6rem",
                }}
              />
            </span>
            <label
              htmlFor="upload"
              className={`upload_button my-1 fs_15 fw_600`}
              type="button"
              variant="contained"
              component="label"
            >
              Browse Document
            </label>
            <input
              id="upload"
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              accept=".pdf" // Accept only PDF files
              onChange={handleFileChange}
            />
          </div>
        )}
        {/* <button onClick={()=>formik.setFieldValue("brochure_upload", "")}>submit</button> */}
      </div>
      {/* {formik.errors.brochure_upload && (
        <p className="text-danger ms-1 mt-1 fs_12">
          {formik.errors.brochure_upload}
        </p>
      )} */}
    </div>
  );
};

export default BroucherUpload;
