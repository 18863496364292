import { AGRICULTURE_FARM_HOUSE, AGRICULTURE_LAND, AGRICULTURE_PROPERTY_VIEW } from "../../type";

export const agricultureFindOneAction = (data, onSuccess, onError) => {
  return {
    type: AGRICULTURE_PROPERTY_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const agricultureFarmHouseAction = (data, onSuccess, onError) => {
  return {
    type: AGRICULTURE_FARM_HOUSE,
    data,
    onSuccess,
    onError,
  };
};
export const agricultureLandHouseAction = (data, onSuccess, onError) => {
  return {
    type: AGRICULTURE_LAND,
    data,
    onSuccess,
    onError,
  };
};