// import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import DashboardIcon from "../../assets/icons/dashboard-icon";
// import PropertiesKeyLogo from "../../assets/icons/properties-key";
// import DropdownArrow from "../helper-functions/dropdown-arrow";
// import { func } from "prop-types";
// import { legacy_createStore } from "redux";
// import SubsribeLogo from "../../assets/icons/subscribe-assign";
// const SideBar = () => {
//   const router = useLocation();
//   const navigate = useNavigate();
//   const [showOptions, setShowOptions] = useState(false);

//   useEffect(() => {
//     if (router?.pathname.includes("/property-project")) {
//       setShowOptions(true);
//     }
//   }, [router?.pathname]);

//   console.log(router?.pathname.includes("/property-project"));
//   const sideBarData = [
//     {
//       logo: (
//         <DropdownArrow
//           showOptions={showOptions}
//           setShowOptions={setShowOptions}
//         />
//       ),
//       heading: "Properties",
//       image: (
//         <PropertiesKeyLogo
//           fill={
//             router?.pathname.includes("/property-project")
//               ? `#2820DA`
//               : `#8E99A0`
//           }
//           height="25"
//           width="20"
//         />
//       ),
//       subMenu: true,
//       mainPath: "/property-project",
//       defaultPath: "/property-project/live-property-requests",
//       list: [
//         {
//           title: "Live Properties",
//           path: "/property-project/live-property-requests",
//         },
//         {
//           title: "Property Requests",
//           path: "/property-project/property-requests",
//         },
//         {
//           title: "Live Projects",
//           path: "/property-project/live-project",
//         },

//         {
//           title: "Project Requests",
//           path: "/property-project/project-requests",
//         },
//         {
//           title: "Live PG",
//           path: "/property-project/live-pg",
//         },
//         {
//           title: "PGs Requests",
//           path: "/property-project/pg-requests",
//         },
//       ],
//     },
//     {
//       heading: "Assign Subscriptions",
//       subMenu: false,
//       path: "/assign-subscribe",
//       image: (
//         <SubsribeLogo
//           fill={router?.pathname == "/assign-subscribe" ? `#2820DA` : `#8E99A0`}
//           height="25"
//           width="20"
//         />
//       ),
//     },
//     {
//       logo: (
//         <DropdownArrow
//           showOptions={showOptions}
//           setShowOptions={setShowOptions}
//         />
//       ),
//       heading: "Export Data",
//       image: (
//         <PropertiesKeyLogo
//           fill={
//             router?.pathname.includes("/export-data") ? `#2820DA` : `#8E99A0`
//           }
//           height="25"
//           width="20"
//         />
//       ),
//       subMenu: true,
//       mainPath: "/export-data",
//       defaultPath: "/export-data/request-callback-data",
//       list: [
//         {
//           title: "Request Callback Data",
//           path: "/export-data/request-callback-data",
//         },
//         {
//           title: "Property Data",
//           path: "/export-data/property-data",
//         },
//       ],
//     },
//   ];

//   console.log(router?.pathname, "kasasal");
//   return (
//     <>
//       {sideBarData?.map((item) => {
//         return (
//           <>
//             <div className="ps-4 fw_400 fs_16">
//               {item?.subMenu ? (
//                 <div className="mt-3 ps-2">
//                   <div
//                     onClick={() => {
//                       navigate(item?.defaultPath);
//                     }}
//                   >
//                     <span>{item?.image}</span>
//                     <span
//                       className={
//                         router?.pathname.includes(item?.mainPath)
//                           ? "ps-3 text_color_blue cursor_pointer"
//                           : "ps-3 text_color_grey cursor_pointer"
//                       }
//                     >
//                       {item?.heading}
//                     </span>
//                     <span className="ms-5 ps-5"> {item?.logo}</span>
//                   </div>
//                   <span className="">
//                     {showOptions === true && item?.mainPath && (
//                       <div className="list-unstyled ms-4 ps-3 fs_14">
//                         {item?.list?.map((item) => {
//                           return (
//                             <>
//                               <div
//                                 className={
//                                   router?.pathname.includes(item?.path)
//                                     ? "mt-0 pt-3 text_color_blue cursor_pointer"
//                                     : "pt-3 text_color_grey cursor_pointer"
//                                 }
//                                 onClick={() => {
//                                   navigate(item?.path);
//                                 }}
//                               >
//                                 {item?.title}
//                               </div>
//                             </>
//                           );
//                         })}
//                       </div>
//                     )}
//                   </span>
//                 </div>
//               ) : (
//                 <div className="mt-3 ps-2">
//                   <span>{item?.image}</span>
//                   <span
//                     className={
//                       router?.pathname == item?.path
//                         ? "ps-3 text_color_blue cursor_pointer"
//                         : "ps-3 text_color_grey cursor_pointer"
//                     }
//                     onClick={() => {
//                       navigate(item?.path);
//                     }}
//                   >
//                     {item?.heading}
//                   </span>
//                 </div>
//               )}
//             </div>
//           </>
//         );
//       })}
//     </>
//   );
// };

// export default SideBar;
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropertiesKeyLogo from "../../assets/icons/properties-key";
import SubsribeLogo from "../../assets/icons/subscribe-assign";
import DropdownArrow from "../helper-functions/dropdown-arrow";
import DashboardIcon from "../../assets/icons/dashboard-icon";

const SideBar = () => {
  const router = useLocation();
  const navigate = useNavigate();
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  useEffect(() => {
    if (router?.pathname.includes("/property-project")) {
      setActiveSubMenu("/property-project");
    } else if (router?.pathname.includes("/export-data")) {
      setActiveSubMenu("/export-data");
    } else {
      setActiveSubMenu(null);
    }
  }, [router?.pathname]);

  const sideBarData = [
    {
      heading: "Dashboard",
      subMenu: false,
      path: "/dashboard",
      image: (
        <DashboardIcon
          fill={router?.pathname == "/dashboard" ? `#2820DA` : `#8E99A0`}
          height="19"
          width="19"
        />
      ),
    },
    {
      logo: (
        <DropdownArrow
          showOptions={activeSubMenu === "/property-project"}
          setShowOptions={() =>
            setActiveSubMenu(activeSubMenu === "/property-project" ? null : "/property-project")
          }
        />
      ),
      heading: "Properties",
      image: (
        <PropertiesKeyLogo
          fill={activeSubMenu === "/property-project" ? "#2820DA" : "#8E99A0"}
          height="25"
          width="20"
        />
      ),
      subMenu: true,
      mainPath: "/property-project",
      defaultPath: "/property-project/live-property-requests",
      list: [
        {
          title: "Live Properties",
          path: "/property-project/live-property-requests",
        },
        {
          title: "Property Requests",
          path: "/property-project/property-requests",
        },
        {
          title: "Live Projects",
          path: "/property-project/live-project",
        },
        {
          title: "Project Requests",
          path: "/property-project/project-requests",
        },
        {
          title: "Live PG",
          path: "/property-project/live-pg",
        },
        {
          title: "PGs Requests",
          path: "/property-project/pg-requests",
        },
      ],
    },
    {
      heading: "Assign Subscriptions",
      subMenu: false,
      path: "/assign-subscribe",
      image: (
        <SubsribeLogo
          fill={activeSubMenu === "/assign-subscribe" ? "#2820DA" : "#8E99A0"}
          height="25"
          width="20"
        />
      ),
    },
    {
      logo: (
        <DropdownArrow
          showOptions={activeSubMenu === "/export-data"}
          setShowOptions={() =>
            setActiveSubMenu(activeSubMenu === "/export-data" ? null : "/export-data")
          }
        />
      ),
      heading: "Export Data",
      image: (
        <PropertiesKeyLogo
          fill={activeSubMenu === "/export-data" ? "#2820DA" : "#8E99A0"}
          height="25"
          width="20"
        />
      ),
      subMenu: true,
      mainPath: "/export-data",
      defaultPath: "/export-data/request-callback-data",
      list: [
        {
          title: "Request Callback Data",
          path: "/export-data/request-callback-data",
        },
        {
          title: "Property Data",
          path: "/export-data/property-data",
        },
      ],
    },
  ];

  return (
    <>
      {sideBarData?.map((item) => (
        <div key={item.heading} className="ps-4 fw_400 fs_16">
          {item?.subMenu ? (
            <div className="mt-3 ps-2">
              <div
                onClick={() => {
                  setActiveSubMenu(activeSubMenu === item.mainPath ? null : item.mainPath);
                }}
              >
                <span>{item?.image}</span>
                <span
                  className={
                    router?.pathname.includes(item?.mainPath)
                      ? "ps-3 text_color_blue cursor_pointer"
                      : "ps-3 text_color_grey cursor_pointer"
                  }
                >
                  {item?.heading}
                </span>
                <span className="ms-5 ps-5"> {item?.logo}</span>
              </div>
              <span className="">
                {activeSubMenu === item.mainPath && (
                  <div className="list-unstyled ms-4 ps-3 fs_14">
                    {item?.list?.map((subItem) => (
                      <div
                        key={subItem.title}
                        className={
                          router?.pathname.includes(subItem?.path)
                            ? "mt-0 pt-3 text_color_blue cursor_pointer"
                            : "pt-3 text_color_grey cursor_pointer"
                        }
                        onClick={() => {
                          navigate(subItem?.path);
                        }}
                      >
                        {subItem?.title}
                      </div>
                    ))}
                  </div>
                )}
              </span>
            </div>
          ) : (
            <div className="mt-3 ps-2">
              <span>{item?.image}</span>
              <span
                className={
                  router?.pathname === item?.path
                    ? "ps-3 text_color_blue cursor_pointer"
                    : "ps-3 text_color_grey cursor_pointer"
                }
                onClick={() => {
                  navigate(item?.path);
                }}
              >
                {item?.heading}
              </span>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default SideBar;
