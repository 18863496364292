import React from "react";
import UseFormikSelectField from "../form/useFormikSelectField";
import { removeUnderScore } from "../helper/helper";

const EditPgFoodProvided = ({ formik }) => {
  console.log(formik?.values, "food");
  console.log(formik?.initialValues, "initialValuesfood");
  const foodProvided = [
    { value: "Veg", label: "Veg" },
    { value: "Non_Veg", label: "Non Veg" },
    { value: "Both", label: "Both" },
  ];
  const foodCharges = [
    { value: "included_in_rent", label: "Included in Rent" },
    { value: "per_meal_basis", label: "Per meal basis" },
    { value: "fixed_monthly_amount", label: "Fixed monthly amount" },
  ];
  return (
    <div>
      <div className={` my-3 d-flex align-items-center pt-3`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Food Provided</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row">
        <div className="col-3">
          <div className="d-flex align-items-center gap-2">
            <div className="mt-1">
              <input
                className=""
                type="checkbox"
                name={`food_provide.Breakfast`}
                checked={formik?.values?.food_provided?.Breakfast}
                onChange={(e) =>
                  formik?.setFieldValue(
                    "food_provided.Breakfast",
                    e?.target?.checked
                  )
                }
                style={{ width: "17px", height: "17px" }}
              />
            </div>
            <div className="d-inline-block fs_15 secondary_color">
              Breakfast
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="d-flex align-items-center gap-2">
            <div className="mt-1">
              <input
                className=""
                type="checkbox"
                name={`food_provide.Dinner`}
                checked={formik?.values?.food_provided?.Dinner}
                onChange={(e) =>
                  formik?.setFieldValue(
                    "food_provided.Dinner",
                    e?.target?.checked
                  )
                }
                style={{ width: "17px", height: "17px" }}
              />
            </div>
            <div className="d-inline-block fs_15 secondary_color">Dinner</div>
          </div>
        </div>
        <div className="col-3">
          <div className="d-flex align-items-center gap-2">
            <div className="mt-1">
              <input
                className=""
                type="checkbox"
                name={`food_provide.Lunch`}
                checked={formik?.values?.food_provided?.Lunch}
                onChange={(e) =>
                  formik?.setFieldValue(
                    "food_provided.Lunch",
                    e?.target?.checked
                  )
                }
                style={{ width: "17px", height: "17px" }}
              />
            </div>
            <div className="d-inline-block fs_15 secondary_color">Lunch</div>
          </div>
        </div>
      </div>
      <div className="mt-4 row">
        <div className="col-3">
          <div className="w-100">
            <UseFormikSelectField
              label="Veg/Non-Veg food provided"
              customStyles={{width:"80%"}}
              name="food_type_provided"
              options={foodProvided}
              formik={formik}
              formikError={formik?.errors?.food_type_provided}
              formikTouched={formik?.touched?.food_type_provided}
              defaultValue={
                formik?.values?.food_type_provided && {
                  label: removeUnderScore(formik?.values?.food_type_provided),
                  value: formik?.values?.food_type_provided,
                }
              }
            />
          </div>
        </div>
        <div className="col-3">
          <UseFormikSelectField
            label="Food Charges"
            // customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
            customStyles={{width:"80%"}}
            name="food_charges"
            options={foodCharges}
            formik={formik}
            formikError={formik?.errors?.food_charges}
            formikTouched={formik?.touched?.food_charges}
            defaultValue={
              formik?.values?.food_charges && {
                label: removeUnderScore(formik?.values?.food_charges),
                value: formik?.values?.food_charges,
              }
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EditPgFoodProvided;
