import { api } from "../api";

export const ProjectRequestApi = (data, params) => {
  console.log(data , "hut");
  return api.get(
    `/api/admin/project/approval-list?page_no=${data.page_no}&search=${data.search}`,
    params
  );
};
export const liveProjectListApi = (data, params) => {
  console.log(data, "oaga");
  return api.get(
    `/api/admin/project/approved-list?page_no=${data.page_no}&search=${data.search}`,
    params
  );
};
export const uploadProjectDocumentApi = (params) => {
  return api.post("/api/user/images/upload-document", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": false,
    },
  });
};
