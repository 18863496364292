import React from "react";
import UseFormikPriceInputField from "../../form/useFormikPriceInputField";

const AgricultureLandSalePriceDetails = ({ formik }) => {
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Price Details</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row">
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_area}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.plot_area}
            formikTouched={formik?.touched?.price_details?.plot_area}
            itemName="price_details.plot_area"
            label="Plot Area"
            length="Acre"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter property price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.expected_price}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.expected_price}
            formikTouched={formik?.touched?.price_details?.expected_price}
            itemName="price_details.expected_price"
            label="Expected Price"
            currency="₹"
          />
        </div>
      </div>
    </div>
  );
};

export default AgricultureLandSalePriceDetails;
