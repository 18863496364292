import React from "react";

const PropertyBasicDetails = ({ propertyBasicDetails }) => {
  return (
    <div className="mb-5">
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>
          Property Basic Details
        </h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row">
        {propertyBasicDetails
          ?.filter((item) => item?.display === true)
          .map((item) => {
            return (
              <div className="col-2 mb-3">
                <div className="text_muted_color">{item?.label}</div>
                <div>{item?.value ? item?.value : ""}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PropertyBasicDetails;
