import React, { useState } from "react";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikInputField from "../../../form/useFormikInputField";
import UseFormikRadioInput from "../../../form/useFormikRadioField";
import { removeUnderScore } from "../../../helper/helper";
// import UseFormikRadioInput from "../../../form/useFormikRadioField";

const ResidentialProVillaFeatures = ({ formik, compareAminities, setCompareAminities, projectDetails , isClickedOnAme, setIsClickedOnAme}) => {
  const suitableForList = [
    {
      name: "basic_amenities",
      label: "Basic Amenities",
      status: false,
    },
    {
      name: "electricity_line",
      label: "Electricity Line",
      status: false,
    },
    {
      name: "highway_access",
      label: "Highway Access",
      status: false,
    },
    { name: "main_road_access", label: "Main Road Access", status: false },
    {
      name: "underground_drainage_system",
      label: "Underground Drainage System",
      status: false,
    },
  ];
  // function handleCheckboxChange(index) {
  //   const updatedAmenities = [...formik.values.property_features.suitable_for];
  //   updatedAmenities[index].status = !updatedAmenities[index].status;
  //   formik.setFieldValue("property_features.suitable_for", updatedAmenities);
  // }
  const initialAminities = projectDetails && projectDetails?.project_features[0]?.amenities;
  const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);
  // const handleCheckboxChange = (index) => {
  //   const updatedSuitableFor = [...suitableFor];
  //   updatedSuitableFor[index].status = !updatedSuitableFor[index].status;
  //   setSuitableFor(updatedSuitableFor);
  //   formik.setFieldValue("project_features.amenities", updatedSuitableFor);
  // };
  // const aminitiesAreEqual = (currentAminities, initialAminities)=>{
  //   const getCurrentAminitieStatus = currentAminities?.filter((item)=> item?.status === true)?.length;
  //   const getInitialAminitieStatus = Array.isArray(initialAminities) && initialAminities?.filter((item)=> item?.status === true)?.length;
  //   if(getCurrentAminitieStatus === getInitialAminitieStatus){
  //     setCompareAminities(true);
  //   }
  //   else{
  //     setCompareAminities(false);
  //   }
  // }
  //  aminitiesAreEqual(suitableFor, formik?.initialValues?.project_features?.amenities);

  const aminitiesAreEqual = (currentProjAminities, initialProjAminities)=>{
    const isAmentiesMatches = currentProjAminities?.every((currentItem, index) => initialProjAminities?.[index]?.status === currentItem?.status);
    console.log(isAmentiesMatches, "isAmentiesMatches");
    if(isAmentiesMatches){
      setCompareAminities(true);
    }
    else{
      setCompareAminities(false);
    }
  }
  const updatedAminitiesList = suitableForList?.map((aminity) => {
    const matchingBackendAminity = Array.isArray(initialAminities) && initialAminities?.find((backendAminity) => {return backendAminity.name === aminity.name;});
    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
  
    return aminity;
  });
  const [amenities, setAmenities] = useState(updatedAminitiesList);

  const handleCheckboxChange = (index) => {
  const newUpdatedAminitiesList = [...amenities];
  newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
  setAmenities(newUpdatedAminitiesList);
  const filteredAmenities = newUpdatedAminitiesList.map((item) => { return { name: item.name, status: item.status }});
  formik.setFieldValue("project_features.amenities", filteredAmenities);
};
if(isClickedOnAme){
  aminitiesAreEqual(amenities, formik?.initialValues?.project_features?.amenities);
};
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Common Features</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.project_features?.floors_allowed}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.project_features?.floors_allowed}
            formikTouched={formik?.touched?.project_features?.floors_allowed}
            itemName={`project_features.floors_allowed`}
            labelName="Floors Allowed"
          />
        </div>
        <UseFormikRadioInput
          formik={formik}
          name={"project_features.boundary_wall_made"}
          title={"Boundary Wall made"}
        />

        <UseFormikRadioInput
          formik={formik}
          title="Gated Colony"
          name="project_features.gated_colony"
        />
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Property Amenities</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        <div className="row g-0">
          {amenities?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`project_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.name ? removeUnderScore(item?.name) : "-"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default ResidentialProVillaFeatures;
