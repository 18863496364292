import React from "react";
import "./index.css";
import { Provider } from "react-redux";
import Index from "./routes";
import store from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Provider store={store}>
        <Index />
      </Provider>
    </>
    
  );
}

export default App;