import { PROFILE_FETCH } from "../../type";

export const profileFetchActions = (onSuccess, onError) => {
  return {
    type: PROFILE_FETCH,

    onSuccess,
    onError,
  };
};
