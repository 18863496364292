import { LIVE_PROPERTY_LIST, PROPERTYREQUESTFINDALL } from "../../type";

export const PropertyRequestAction = (data, onSuccess, onError) => {
  return {
    type: PROPERTYREQUESTFINDALL,
    data,
    onSuccess,
    onError,
  };
};
export const LivePropertyListAction = (data, onSuccess, onError) => {
  return {
    type: LIVE_PROPERTY_LIST,
    data,
    onSuccess,
    onError,
  };
};
