import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  amountTypeRegex,
  defaultAddress,
  inputRegex,
  removeUnderScore,
} from "../../../../components/helper/helper";
import LocationAndSearch from "../../../../components/map/locationSearch";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResidentialPlotFeature from "../../../../components/edit-property/residential/residential-plot-features";
import ResidentialPlotPriceDetails from "../../../../components/edit-property/residential/residential-plot-price-details";
import OfficeAddPropertyPhoto from "../../../../components/edit-property/commercial/office-space/office-add-property-photo";
import {
  editResidentialPlotAction,
  residentialFindOneAction,
} from "../../../../redux/action/edit-property-actions/residential";
import { Spinner } from "react-bootstrap";
import successToast from "../../../../components/helper-functions/successToast";
import errorToast from "../../../../components/helper-functions/errorToast";
import moment from "moment";
import PropertyBasicDetails from "../../../../components/edit-property/property-basic-details";
import Primarybutton from "../../../../components/ui/buttons/primary-button";
import Leftarrowicon from "../../../../assets/icons/left-arrow-icon";
import Modal_property from "../../../../components/ui/modals/modal_property";
import styles from "../../../../styles/property-details.module.css";
import isEqual from "lodash.isequal";
import UseFormikInputField from "../../../form/useFormikInputField";
import PropertyLocation from "../../../map/property-location";

const EditResidentialPlot = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [multiImage, setMultiImage] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [propertyDetails, setpropertyDetails] = useState("");
  const [amitities, setAmitities] = useState();
  const [compareAminities, setCompareAminities] = useState(true);
  const [show, setShow] = useState(false);
  const [propertyStatus, setPropertyStatus] = useState("");
  const [mainPropertyType, setMainPropertyType] = useState("");
  const [modalButton, setModalButton] = useState("");
  const [approveButton, setApproveButton] = useState(true);
  const [isClickedOnAme, setIsClickedOnAme] = useState(false);
  const [initialValues, setInitialValues] = useState({
    property_id: "",
    post_type: "",
    property_type: "",
    property_details: {
      name: "",
      address: "",
      location: {
        lat: null,
        long: null,
      },
      project_name: "",
    },
    property_features: {
      floors_allowed_for_construction: null,
      boundary_wall_made: false,
      facing: "",
      no_of_open_sides: null,
      gated_colony: false,
      corner_site: false,
      any_construction_done: false,
      amenities: [],
    },
    price_details: {
      plot_length: null,
      plot_width: null,
      expected_price: null,
      plot_area: null,
    },
    formetted_address: "",
    g_location: {
      address_line_1: "",
      address_line_2: "",
      area: "",
      city: "",
      postCode: "",
      state: "",
      country: "",
      lat: null,
      lng: null,
    },
    property_photos: [],
    description: "",
    terms_and_condition: true,
    lat: null,
    lng: null,
    project_or_society_name: "",
    searchAddress: "",
  });
  let propertyId = null;
  if (propertyDetails && propertyDetails?.id) {
    propertyId = (propertyDetails?.id).split("-");
  }
  const aminitiesList = [
    { name: "basic_amenities", label: "Basic Amenities", status: false },
    {
      name: "electricity_line",
      label: "Electricity Line",
      status: false,
    },
    { name: "highway_access", label: "Highway Access", status: false },
    { name: "main_road_access", label: "Main Road Access", status: false },
    {
      name: "underground_drainage_system",
      label: "Underground Drainage System",
      status: false,
    },
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(
      residentialFindOneAction(
        { property_id: params?.id, property_type: params?.type },
        onFindSuccess,
        onFindError
      )
    );
  }, [reload]);
  const onFindSuccess = (data) => {
    setLoading(false);
    const propertyDetailsData = data?.data;
    setpropertyDetails(propertyDetailsData);
    setInitialValues({
      property_id: propertyDetailsData?.id,
      post_type: propertyDetailsData?.post_type,
      property_type: propertyDetailsData?.property_type,
      property_details: {
        name: propertyDetailsData?.property_details?.name,
        address: propertyDetailsData?.property_details?.address
          ? propertyDetailsData?.property_details?.address
          : defaultAddress?.address_line_2,
        location: {
          lat: propertyDetailsData?.property_details?.location?.lat
            ? propertyDetailsData?.property_details?.location?.lat
            : defaultAddress?.lat,
          long: propertyDetailsData?.property_details?.location?.long
            ? propertyDetailsData?.property_details?.location?.long
            : defaultAddress?.lng,
        },
        project_name: propertyDetailsData?.property_details?.project_name,
      },
      property_features: {
        floors_allowed_for_construction:
          propertyDetailsData?.property_features
            ?.floors_allowed_for_construction > 0
            ? propertyDetailsData?.property_features
                ?.floors_allowed_for_construction
            : "",
        boundary_wall_made:
          propertyDetailsData?.property_features?.boundary_wall_made,
        facing: propertyDetailsData?.property_features?.facing,
        no_of_open_sides:
          propertyDetailsData?.property_features?.no_of_open_sides > 0
            ? propertyDetailsData?.property_features?.no_of_open_sides
            : "",
        gated_colony: propertyDetailsData?.property_features?.gated_colony,
        corner_site: propertyDetailsData?.property_features?.corner_site,
        any_construction_done:
          propertyDetailsData?.property_features?.any_construction_done,
        amenities: propertyDetailsData?.property_features?.amenities ?? [],
      },
      price_details: {
        plot_length:
          propertyDetailsData?.price_details?.plot_length > 0
            ? propertyDetailsData?.price_details?.plot_length
            : "",
        plot_width:
          propertyDetailsData?.price_details?.plot_width > 0
            ? propertyDetailsData?.price_details?.plot_width
            : "",
        expected_price:
          propertyDetailsData?.price_details?.expected_price > 0
            ? propertyDetailsData?.price_details?.expected_price
            : "",
        plot_area:
          propertyDetailsData?.price_details?.plot_area > 0
            ? propertyDetailsData?.price_details?.plot_area
            : "",
      },
      formetted_address: propertyDetailsData?.formetted_address,
      g_location: {
        address_line_1: propertyDetailsData?.g_location?.address_line_1
          ? propertyDetailsData?.g_location?.address_line_1
          : defaultAddress?.address_line_1,
        address_line_2: propertyDetailsData?.g_location?.address_line_1
          ? propertyDetailsData?.g_location?.address_line_1
          : defaultAddress?.address_line_2,
        area: propertyDetailsData?.g_location?.area
          ? propertyDetailsData?.g_location?.area
          : defaultAddress?.area,
        city: propertyDetailsData?.g_location?.city
          ? propertyDetailsData?.g_location?.city
          : defaultAddress?.city,
        postCode: propertyDetailsData?.g_location?.postCode
          ? propertyDetailsData?.g_location?.postCode
          : defaultAddress?.postCode,
        state: propertyDetailsData?.g_location?.state
          ? propertyDetailsData?.g_location?.state
          : defaultAddress?.state,
        country: propertyDetailsData?.g_location?.country
          ? propertyDetailsData?.g_location?.country
          : defaultAddress?.country,
        lat: propertyDetailsData?.g_location?.lat
          ? propertyDetailsData?.g_location?.lat
          : defaultAddress?.lat,
        lng: propertyDetailsData?.g_location?.lng
          ? propertyDetailsData?.g_location?.lng
          : defaultAddress?.lng,
      },
      property_photos: propertyDetailsData?.property_photos,
      description: propertyDetailsData?.description,
      terms_and_condition: true,
      lat: propertyDetailsData?.g_location?.lat
        ? parseFloat(propertyDetailsData?.g_location?.lat)
        : defaultAddress?.lat,
      lng: propertyDetailsData?.g_location?.lng
        ? parseFloat(propertyDetailsData?.g_location?.lng)
        : defaultAddress?.lng,
      project_or_society_name:
        propertyDetailsData?.property_details?.project_name,
      formetted_address: propertyDetailsData?.formetted_address ?? "",
      searchAddress: propertyDetailsData?.formetted_address ?? "",
    });
    setMultiImage(data?.data?.property_photos);
    setAmitities(data?.data?.property_features?.amenities);
    setMainPropertyType(data?.data?.main_property_type);
  };
  const onFindError = (data) => {
    setLoading(false);
  };
  console.log(propertyDetails, "sdfsdbfkj");
  const propertyBasicDetails = [
    {
      label: "Property ID",
      value: propertyId && propertyId[0].toUpperCase(),
      display: true,
    },
    {
      label: "Property Type",
      value: propertyDetails?.property_type === "plot" && "Residential Plot",
      display: true,
    },
    {
      label: "Property Price",
      value:
        propertyDetails?.post_type === "sale"
          ? "₹ " + propertyDetails?.price_details?.expected_price
          : "₹ " + propertyDetails?.price_details?.rent_price,
      display: true,
    },
    {
      label: "Subscription Plan",
      value: "",
      display: propertyDetails?.subscription ? true : false,
    },
    {
      label: "Validity",
      value: "",
      display: propertyDetails?.subscription ? true : false,
    },
    {
      label: "Posted By",
      value:
        propertyDetails?.name +
        " " +
        `(${removeUnderScore(propertyDetails?.owner_type)})`,
      display: true,
    },
    {
      label: "Posted Date & Time",
      value: moment(propertyDetails?.createdAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
    {
      label: "Updated Date & Time",
      value: moment(propertyDetails?.updatedAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      property_features: Yup.object({
        floors_allowed_for_construction: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
        no_of_open_sides: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
        boundary_wall_made: Yup.bool(),
        gated_colony: Yup.bool(),
        corner_site: Yup.bool(),
        any_construction_done: Yup.bool(),
      }),
      price_details: Yup.object({
        plot_length: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        plot_width: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        plot_area: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        expected_price: Yup.string()
          .matches(amountTypeRegex, "Enter a valid number")
          .required("Required"),
      }),
      // searchAddress: Yup.string().required("Required"),
      g_location: Yup.object({
        city: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
      }),
    }),
    onSubmit: (values) => {
      dispatch(
        editResidentialPlotAction(
          {
            ...values,
            price_details: {
              plot_length: parseInt(values?.price_details?.plot_length),
              plot_width: parseInt(values?.price_details?.plot_width),
              plot_area: parseInt(values?.price_details?.plot_area),
              expected_price: parseInt(values?.price_details?.expected_price),
            },
            property_features: {
              ...values?.property_features,
              floors_allowed_for_construction: parseInt(
                values?.property_features?.floors_allowed_for_construction
              ),
              no_of_open_sides: parseInt(
                values?.property_features?.no_of_open_sides
              ),
              amenities: values?.property_features?.amenities,
            },
            property_photos: [...multiImage],
          },
          onEditSuccess,
          onEditError
        )
      );
      setApproveButton(true);
      setIsClickedOnAme(true);
    },
  });
  const onEditSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
  };

  useEffect(() => {
    if (initialValues?.lat) {
      setMapLoading(true);
    }
  }, [initialValues?.lat]);

  const initValuesWithoutAmenitiesFea = { ...initialValues?.property_features };
  delete initValuesWithoutAmenitiesFea?.amenities;

  const formikValuesWithoutAmenitiesFea = {
    ...formik?.values?.property_features,
  };
  delete formikValuesWithoutAmenitiesFea?.amenities;

  function valuesCompareFn(values) {
    const propertyDetails = isEqual(initialValues?.property_details?.project_name ?? "", formik?.values?.property_details?.project_name ?? "");
    const propertyFeature = isEqual(initValuesWithoutAmenitiesFea, formikValuesWithoutAmenitiesFea);
    const priceDetails = isEqual(convertValuesToIntegers(formik?.initialValues?.price_details), convertValuesToIntegers(values?.price_details));
    const propertyPhotos = isEqual(initialValues?.property_photos, multiImage);
    const lat = isEqual(initialValues?.g_location?.lat, values?.g_location?.lat);
    const gLocation = isEqual(initialValues?.g_location,values?.g_location);
    const description = isEqual(initialValues?.description, values?.description);
    console.log(priceDetails, propertyFeature, propertyDetails, propertyPhotos, compareAminities,"compareplot");
    if (propertyFeature && priceDetails && propertyDetails && propertyPhotos && gLocation && compareAminities && description) {
      return true;
    } else {
      return false;
    }
  }
  const convertValuesToIntegers = (values) => {
    const convertedValues = {};

    for (const key in values) {
      convertedValues[key] = parseInt(values[key], 10);
    }

    return convertedValues;
  };

  return (
    <div className="font_family_poppins" style={{ overflow: "hidden" }}>
      <p className="fw_400 fs_16 text_color_grey ms-5 mt-3 ">
        ADMIN / PROPERTIES / LIVE PROPERTIES / - VIEW
      </p>
      {loading ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className={` ${styles.border_color_gray} rounded-4 m-4 `}>
            <Modal_property
              show={show}
              mainPropertyType={mainPropertyType}
              handleclose={() => setShow(false)}
              background={modalButton === "Approve" ? "green" : "red"}
              btnText={modalButton === "Approve" ? "approve" : "reject"}
              propertyStatus={propertyStatus}
              navigateUrl={
                location.pathname.includes("live-property-requests")
                  ? "/property-project/live-property-requests"
                  : "/property-project/property-requests"
              }
            />
            <div className={`d-flex justify-content-between my-2 `}>
              <div
                className="d-flex align-items-center ms-4"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <Leftarrowicon />
                <Primarybutton
                  btnText={"BACK"}
                  className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
                />
              </div>
              <div>
                {!valuesCompareFn(formik?.values) ? (
                  <button
                    className="btn btn-primary me-3"
                    type="submit"
                  >
                    submit
                  </button>
                ) : location.pathname.includes("live-property-requests")
                ? null : (
             // : location.pathname.includes("live-property-requests") &&
             //   propertyDetails.status === "approved" ? null : (
                  <>
                    <Primarybutton
                      btnText={"APPROVE"}
                      type="button"
                      onClick={() => {
                        setModalButton("Approve");
                        setShow(true);
                        setPropertyStatus("approved");
                      }}
                      className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
                    />
                    <Primarybutton
                      btnText={"REJECT"}
                      type="button"
                      onClick={() => {
                        setModalButton("Reject");
                        setShow(true);
                        setPropertyStatus("rejected");
                      }}
                      className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="border-bottom"></div>
            <div
              className="scrolling  px-3"
              style={{ overflowY: "auto", overflowX: "hidden", height: "70vh" }}
            >
              <PropertyBasicDetails
                propertyBasicDetails={propertyBasicDetails}
              />
              <div className={` my-3 d-flex align-items-center`}>
                <h5 className={`light_blue_color fs_18 me-3`}>
                  Property Location
                </h5>
                <hr className={`py-1 flex-grow-1 border-primary`} />
              </div>
              <div className="row position-relative">
                {/* <div className="col-7">
                  {mapLoading ? (
                    <LocationAndSearch type="project_name" formik={formik} />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center col-7 w-100"
                      style={{ height: "20rem" }}
                    >
                      <Spinner />
                    </div>
                  )}
                </div> */}
                <div className="col-5">
                {formik?.values && <PropertyLocation formik={formik} />}
                  <div className="mt-3">
                    <UseFormikInputField
                      placeholder="Enter Project/Society Name (optional)"
                      inputValue={
                        formik?.values?.property_details?.project_name
                      }
                      onChangeFn={formik.handleChange}
                      onBlurFn={formik.handleBlur}
                      itemName="property_details.project_name"
                      labelName="Project/Society Name"
                    />
                  </div>
                  
                </div>
              </div>
              <ResidentialPlotFeature
                formik={formik}
                initialValues={initialValues}
                setCompareAminities={setCompareAminities}
                propertyDetails={propertyDetails}
                setIsClickedOnAme={setIsClickedOnAme}
                isClickedOnAme={isClickedOnAme}
              />
              <ResidentialPlotPriceDetails formik={formik} />
              <OfficeAddPropertyPhoto
                multiImage={multiImage}
                setMultiImage={setMultiImage}
              />
              <div className="discription">
                 <div className={`mt-5 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>
                   Description
                  </h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                  </div>
                  <UseFormikInputField
                    label_font_size=""
                    labelName=""
                    itemName={"description"}
                    inputValue={formik.values.description}
                    onChangeFn={formik.handleChange}
                    onBlurFn={formik.handleBlur}
                    formikError={formik.errors.description}
                    formikTouched={formik.touched.description}
                    placeholder="Describe your project"
                    input={false}
                    TextArea={true}
                  />
            </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditResidentialPlot;
