import { api } from "./api";

export const requestCallBackDataApi = (params) => {
  return api.post("/api/export/requestCallBack", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const propertyDataApi = (params) => {
  return api.post("/api/export/property-pg-project", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};

export const loactionSearchApi = (data, params) => {
  return api.post(
    `/api/search/auto-complete?page_no=${data.pageNo}`,
    JSON.stringify(params)
  );
};

export const searchAutocompletePropertyApi = (page_no, params) => {
  return api.post(`/api/search/auto-complete/?page_no=${page_no}`, params);
};