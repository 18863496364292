import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRouter = () => {
  return (
    <div>
      {localStorage.getItem("accessToken") ? <Outlet /> : <Navigate to="/" />}
    </div>
  );
};

export default PrivateRouter;
