import { selectStyles } from "../form/selectField";
import { locationUpdateAction } from "../../redux/action/export-action/exportAction";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
export const AsyncSelectSearch = ({
  city = "",
  charLimit = 15,
  placeholder = "Search...",
  isMulti = false,
  selectedValue = isMulti ? [] : null,
  handleChange = () => {},
  labelsLimit = 10,
  error = false,
  setSelectedOptions = () => {},
  fetchedOptions = [],
  setFetchedOptions = () => {},
  setInputValue = () => {},
  inputValue = "",
  onMenuCloseFn,
}) => {
  const dispatch = useDispatch();
  const isLoading = useRef(false);
  const isDataLoading = useRef(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const locationData = useSelector((state) => state.locationDataReducer);
  // const debounce = (func, wait) => {
  //   let timeout;
  //   return (...args) => {
  //     clearTimeout(timeout);
  //     timeout = setTimeout(() => func.apply(this, args), wait);
  //   };
  // };

  console.log(locationData, "aknsdakld");

  const MenuList = (props) => {
    const listRef = useRef(null);
    const handleScroll = throttle(async (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const targetProperties = { scrollTop, scrollHeight, clientHeight };
      const threshold = 10;
      if (
        targetProperties.scrollHeight - targetProperties.scrollTop <=
        targetProperties.clientHeight + threshold
      ) {
        const nextPage = currentPage + 1;
        if (nextPage <= totalPages) {
          setScrollPosition(scrollTop);
          setCurrentPage(nextPage); // Update the currentPage
          isDataLoading.current = true;
          try {
            if (inputValue) {
              await loadOptions(inputValue, nextPage);
            } else {
              await loadOptions("", nextPage);
            }
          } finally {
            isDataLoading.current = false;
          }
        }
      }
    }, 300);

    useEffect(() => {
      const currentListRef = listRef.current;
      if (currentListRef) {
        currentListRef.addEventListener("scroll", handleScroll);
        return () => {
          currentListRef.removeEventListener("scroll", handleScroll);
        };
      }
    }, [listRef]);
    useLayoutEffect(() => {
      const currentListRef = listRef.current;
      if (currentListRef) {
        currentListRef.scrollTop = scrollPosition;
      }
    }, [fetchedOptions]);
    return (
      <div ref={listRef} style={{ overflow: "auto", maxHeight: "300px" }}>
        {props.children}
        {isLoading.current && (
          <div
            className="fs_14"
            style={{ textAlign: "center", padding: "5px" }}
          >
            Loading...
          </div>
        )}
      </div>
    );
  };

  const onSuccessAutoSearch = (response, page) => {
    console.log(response, "aj,dskadsdad");
    // const localityList = response?.data?.locality.map((item) => {
    //   return {
    //     value: {
    //       city: item.city_name,
    //       locality: item.locality_name,
    //       // id: item.id,
    //     },
    //     label: `${item.locality_name ? item.locality_name + "," : ""} ${item.city_name
    //       }`,
    //   };
    // });
    // const selectedLocality = locationData?.selectedOptions.map(
    //   (item) => item.label
    // );
    // const filteredLocality = localityList.filter(
    //   (item) => !selectedLocality.includes(item.label)
    // );
    const locationList = [
      {
        label: "Cities",
        options: response?.data?.city.map((item) => {
          return {
            value: {
              city: item.city_name,
              locality: item.locality_name,
              // id: item.id,
            },
            label: `${item.city_name}`,
          };
        }),
      },
      // {
      //   label: "Localities",
      //   options: filteredLocality,
      // },
    ];
    if (locationData?.selectedOptions[0]?.value?.city) {
      locationList.shift(); //removes city options if city is selected
    }
    setTotalPages(response?.data.pages);
    if (page > 0) {
      if (locationList.length > 1) {
        setFetchedOptions((prev) => [
          {
            label: "Cities",
            options: [...prev[0]?.options, ...locationList[0]?.options],
          },
          // {
          //   label: "Localities",
          //   options: [...prev[1]?.options, ...locationList[1]?.options],
          // },
        ]);
      } else {
        setFetchedOptions((prev) => [
          {
            label: "Localities",
            options: [...prev[0]?.options, ...locationList[0]?.options],
          },
        ]);
      }
    } else {
      setFetchedOptions(locationList);
    }
    return locationList;
  };

  const loadOptions = async (inputValue, currentPage = 0) => {
    isLoading.current = true;
    try {
      const response = await fetch(
        `https://api.zameensquare.com/api/search/auto-complete/?page_no=${
          currentPage + ""
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({
          //   city_name: locationData?.selectedOptions[0]?.value?.city
          //     ? locationData?.selectedOptions[0]?.value?.city
          //     : "",
          //   search_string: inputValue ?? "",
          // }),
          body: JSON.stringify({
            city_name: "",
            search_string: "",
          }),
        }
      );
      const data = await response.json();
      onSuccessAutoSearch(data, currentPage);
    } catch (error) {
    } finally {
      isLoading.current = false;
    }
  };
  useEffect(() => {
    setCurrentPage(0);
    loadOptions(inputValue);
  }, [inputValue]);
  useEffect(() => {
    setCurrentPage(0);
    loadOptions();
  }, [locationData?.selectedOptions[0]?.value?.city]);
  const handleInputChange = useCallback((value, actionMeta) => {
    if (actionMeta.action === "input-change") {
      setInputValue(value);
    }
  }, []);

  //
  //
  //

  console.log(locationData.selectedOptions, "jdnadsad");
  return (
    <Select
      isMulti={true}
      options={fetchedOptions}
      // error={error}
      styles={selectStyles}
      placeholder={placeholder}
      value={locationData.selectedOptions?.[0]}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      {...(onMenuCloseFn ? { onMenuClose: onMenuCloseFn } : {})}
      onChange={(e) => {
        setInputValue("");

        dispatch(locationUpdateAction("", {}, e));
        console.log(e, "ssssssssssss");
        handleChange(e);
      }}
      // onMenuClose={() => setScrollPosition(0)}
      components={{
        MenuList,
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function (...args) {
    if (!lastRan) {
      func.apply(this, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(this, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};
