import React from "react";
import styles from "../../styles/property-details.module.css";
import Leftarrowicon from "../../assets/icons/left-arrow-icon";
import Primarybutton from "../../components/ui/buttons/primary-button";
import { useNavigate } from "react-router-dom";
import { InputContainer } from "../../components/form/inputField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Close from "../../assets/icons/close";
import TickIcon from "../../assets/icons/tick-icon";
import { useDispatch } from "react-redux";
import { requestCallBackDataAction } from "../../redux/action/export-action/exportAction";
import errorToast from "../../components/helper-functions/errorToast";
import successToast from "../../components/helper-functions/successToast";

const RequestCallBackData = () => {
  const navigate = useNavigate();
  const disptach = useDispatch();

  const formik = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object({
      fromDate: Yup.date()
        .max(Yup.ref("toDate"), "From date must be before the 'To Date'")
        .max(new Date(), "From date cannot be greater than today")
        .required("From date is required"),
      toDate: Yup.date()
        .min(Yup.ref("fromDate"), "To date must be after the 'From Date'")
        .required("To date is required"),
    }),
    onSubmit: (values) => {
      console.log(values, "ajsdakjda");
      disptach(
        requestCallBackDataAction(
          {
            from_date: values?.fromDate,
            to_date: values?.toDate,
          },
          onSuccess,
          onError
        )
      );
    },
  });

  const onSuccess = (data) => {
    if (data && data?.data?.length > 0) {
      // Extract column headings from the first data object
      const headings = Object.keys(data?.data[0]);

      // Create a CSV-formatted string with headings and formatted data
      const csvData = [
        headings.join(","), // Convert headings array to a CSV line
        ...data?.data.map((item) => {
          // Split the createdAt field into date and time
          const createdAt = new Date(item.createdAt);
          const formattedDate = createdAt.toLocaleDateString();
          const formattedTime = createdAt.toLocaleTimeString();

          // Combine date and time into a single field
          item.createdAt = `${formattedDate} ${formattedTime}`;

          // Generate a CSV line with the updated data object
          return headings.map((key) => item[key]).join(",");
        }),
      ].join("\n");

      // Create a Blob with the CSV data
      var type = "text/csv;charset=utf-8";
      var blob = new Blob([csvData], {
        type: type,
      });

      // Create a link to trigger the download
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "data.csv";

      // Trigger the download
      link.click();
      successToast("Exported Successfuly");
    }
    if (data?.data?.length === 0) {
      errorToast("No Data");
    }
  };

  const onError = (data) => {
    console.log("ERROR==================", data);
    errorToast("Failed to export data");
  };

  console.log(formik.values, "asbdkajd");
  return (
    <div className="font_family_poppins">
      <p className="fw_400 fs_16 text_color_grey ms-5 mt-3 ">
        ADMIN / Export / REQUEST CALLBACK DATA
      </p>
      <div
        style={{ minHeight: "70vh" }}
        className={` ${styles.border_color_gray} rounded-4 m-4 bg-white`}
      >
        <div className={`d-flex justify-content-between my-2 px-4 py-2`}>
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <Leftarrowicon />
            <Primarybutton
              btnText={"BACK"}
              className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
            />
          </div>
        </div>
        <hr />
        <div className="px-4 py-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7">
              <div className="d-flex flex-column">
                <span>From</span>
                <input
                  className={`w-100 ${
                    formik.errors.fromDate && formik.touched.fromDate
                      ? styles.request_call_date_input_error
                      : styles.request_call_date_input
                  } p-2 focus_none mt-2`}
                  name="fromDate"
                  id="fromDate"
                  type="Date"
                  value={formik.values.fromDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.fromDate && formik.touched.fromDate && (
                  <sapn className="pt-1" style={{ color: "red" }}>
                    {formik.errors.fromDate}
                  </sapn>
                )}
              </div>
              <div className="d-flex flex-column mt-4">
                <span>To</span>
                <input
                  className={`w-100 ${
                    formik.errors.toDate && formik.touched.toDate
                      ? styles.request_call_date_input_error
                      : styles.request_call_date_input
                  } p-2 focus_none mt-2`}
                  name="toDate"
                  id="toDate"
                  type="Date"
                  value={formik.values.toDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.toDate && formik.touched.toDate && (
                  <sapn className="pt-1" style={{ color: "red" }}>
                    {formik.errors.toDate}
                  </sapn>
                )}
              </div>
              <div className="d-flex justify-content-end gap-3 mt-4">
                <button
                  onClick={() => {
                    formik.resetForm();
                  }}
                  style={{ backgroundColor: "rgba(219, 233, 242, 1)" }}
                  type="button"
                  className="text-black border-0 p-2 px-3 rounded-2 fs_14 fw_500 align-items-center d-flex"
                >
                  <Close height="10" width="10" className="me-2" />
                  CLEAR
                </button>
                <button
                  type="submit"
                  className="background_color_skyblue text-white border-0 p-2 px-3 rounded-2 fs_14 fw_500"
                >
                  <TickIcon className={"me-2"} />
                  Export
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestCallBackData;
