export const PROPERTYREQUESTFINDALL = "PROPERTYREQUESTFINDALL";
export const LIVE_PROPERTY_LIST = "LIVE_PROPERTY_LIST";
export const PROJECTREQUESTFINDALL = "PROJECTREQUESTFINDALL";
export const LIVE_PROJECT_LIST_ACTION = "LIVE_PROJECT_LIST_ACTION";
export const PROPERTY_VIEW = "PROPERTY_VIEW";
export const PROJECT_VIEW = "PROJECT_VIEW";
export const LOGIN = "LOGIN";
export const PROFILE_FETCH = "PROFILE_FETCH";
export const LOGOUT = "LOGOUT";
export const PROPERTY_UPDATE = "PROPERTY_UPDATE";
export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const MULTIPLE_PROPERTY_PHOTOS = "MULTIPLE_PROPERTY_PHOTOS";
export const SUBSCRIBE_USER = "SUBSCRIBE_USER";
export const SUBSCRIBE_PROJECTS = "SUBSCRIBE_PROJECTS";
export const SUBSCRIPTION_ASSIGN = "SUBSCRIPTION_ASSIGN";
export const PROPERTY_FIND_ONE = "PROPERTY_FIND_ONE";
export const UPLOAD_PROJECT_DOCUMENT = "UPLOAD_PROJECT_DOCUMENT";

//RESIDENTIAL PROPERTIES TYPES
export const FLAT_APARTMENT_EDIT = "FLAT_APARTMENT_EDIT";
export const VILLA_EDIT = "VILLA_EDIT";
export const HOUSE_EDIT = "HOUSE_EDIT";
export const PLOT_EDIT = "PLOT_EDIT";

//COMMERCIAL

export const COMMERCIAL_PROPERTY_VIEW = "COMMERCIAL_PROPERTY_VIEW";
export const EDIT_COMMERCIAL_OFFICE_SPACE = "EDIT_COMMERCIAL_OFFICE_SPACE";
export const EDIT_COMMERCIAL_PLOT = "EDIT_COMMERCIAL_PLOT";
export const EDIT_COMMERCIAL_SHOP = "EDIT_COMMERCIAL_SHOP";
export const EDIT_COMMERCIAL_SHOW_ROOM = "EDIT_COMMERCIAL_SHOW_ROOM";
export const EDIT_INDUSTRIAL_BUILDING = "EDIT_INDUSTRIAL_BUILDING";
export const EDIT_INDUSTRIAL_PLOT = "EDIT_INDUSTRIAL_PLOT";
export const EDIT_INDUSTRIAL_SHED = "EDIT_INDUSTRIAL_SHED";
export const EDIT_WARE_HOUSE = "EDIT_WARE_HOUSE";

//AGRICULTURE TYPES
export const AGRICULTURE_PROPERTY_VIEW = "AGRICULTURE_PROPERTY_VIEW";
export const AGRICULTURE_FARM_HOUSE = "AGRICULTURE_FARM_HOUSE";
export const AGRICULTURE_LAND = "AGRICULTURE_LAND";

//RESIDENTIAL PROJECTS TYPES

export const PROJECT_FIND_ONE = "PROJECT_FIND_ONE";
export const PROJECT_APARTMENT_EDIT = "PROJECT_APARTMENT_EDIT";
export const PROJECT_VILLA_EDIT = "PROJECT_VILLA_EDIT";
export const PROJECT_PLOT_EDIT = "PROJECT_PLOT_EDIT";

//PGS
export const PGSREQUESTFINDALL = "PGSREQUESTFINDALL";
export const LIVE_PG_LIST = "LIVE_PG_LIST";
export const PG_FIND_ONE = "PG_FIND_ONE";
export const EDIT_PG = "EDIT_PG";


export const DASHBOARD="DASHBOARD"
// export
export const REQUEST_CALLBACK_EXPORT = "REQUEST_CALLBACK_EXPORT";
export const PROPERTY_DATA_EXPORT = "PROPERTY_DATA_EXPORT";
export const LOACTION_SEARCH = "LOACTION_SEARCH";
export const LOCATION_UPDATE = "LOCATION_UPDATE";
export const REMOVE_CITY_NAME_DATA = "REMOVE_CITY_NAME_DATA";
export const SEARCH_AUTOCOMPLETE = "SEARCH_AUTOCOMPLETE";
