import React from "react";
import ArrowDown from "../../assets/icons/arrow-down";
import ArrowUp from "../../assets/icons/arrow-up";

const DropdownArrow = ({ setShowOptions, showOptions }) => {
  return (
    <>
      <span className="cursor_pointer">
        {showOptions ? (
          <ArrowUp height="30" width="20" />
        ) : (
          <ArrowDown height="30" width="20" />
        )}
      </span>
    </>
  );
};

export default DropdownArrow;
