export const inputRegex = /^[0-9]{0,}$/;
export const amountTypeRegex = /^[0-9,.]{0,}$/;
export const removeUnderScore = (str) => {
    return (
      String(str)?.charAt(0)?.toUpperCase() +
      String(str)?.slice(1)?.replace(/_/g, " ")
    );
  };
export const convertValuesToIntegers = (values, keys) => {
  let convertedValues = {};

  if (values && keys) {
    convertedValues = { ...values };
    for (const key of keys) {
      if (values[key]) {
        convertedValues[key] = Number(values[key], 10);
      }
    }
  } else if (values) {
    for (const key in values) {
      if (values[key]) {
        convertedValues[key] = Number(values[key], 10);
      }
    }
  }

  return convertedValues;
};

export const convertValuesToIntegersObj = (areaAndPrice) => {
  if(areaAndPrice){
    return areaAndPrice.map((item) => {
      const updatedObject = {};
      if(item){
        for(const key in item){
          const value = item[key];
          const convertedValue = parseInt(value, 10);
          updatedObject[key] = convertedValue;
        }
      }
      
      // for (const key in object) {
      //   if (object.hasOwnProperty(key)) {
      //     const value = object[key];
      //     const parsedValue = parseInt(value, 10);
  
      //     if (!isNaN(parsedValue)) {
      //       updatedObject[key] = parsedValue;
      //     } else {
      //       updatedObject[key] = value; // Keep the original value if it's not a valid integer.
      //     }
      //   }
      // }
      return updatedObject;
    });
  }
  
}

// export const objectsAreEqual = (obj1, obj2) => {
//   const keys1 = Object.keys(obj1);
//   const keys2 = Object.keys(obj2);

//   if (keys1.length !== keys2.length) {
//     return false;
//   }

//   for (let key of keys1) {
//     if (obj1[key] !== obj2[key]) {
//       return false;
//     }
//   }

//   return true;
// };
export const objectsAreEqual = (obj1, obj2) => {
  if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};
export const defaultAddress = {
  address: "15/11, KG Halli, D' Souza Layout, Ashok Nagar, Bengaluru, Karnataka 560001, India",
  address_line_1: "15/11, KG Halli, D' Souza Layout, Ashok Nagar, Bengaluru, Karnataka 560001, India",
  address_line_2: "Ashok Nagar Bengaluru Urban 560001 Karnataka India",
  area: "Ashok Nagar",
  city: "Bengaluru Urban",
  country: "India",
  lat: 12.9715987,
  lng: 77.5945627,
  locality: "",
  postCode: "560001",
  state: "Karnataka"
}
export function nFormatter(num) {
  // if (num >= 1000000000) {
  //   return (num / 1000000000).toFixed(1).replace(/\.1$/, "") + "G";
  // }
  if (num >= 10000000) {
    return (num / 10000000).toFixed(1).replace(/\.1$/, "") + "CR";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.1$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.1$/, "") + "K";
  }
  return num;
}