import {
  COMMERCIAL_PROPERTY_VIEW,
  EDIT_COMMERCIAL_OFFICE_SPACE,
  EDIT_COMMERCIAL_PLOT,
  EDIT_COMMERCIAL_SHOP,
  EDIT_COMMERCIAL_SHOW_ROOM,
  EDIT_INDUSTRIAL_BUILDING,
  EDIT_INDUSTRIAL_PLOT,
  EDIT_INDUSTRIAL_SHED,
  EDIT_WARE_HOUSE,
} from "../../type";

export const commercialFindOneAction = (data, onSuccess, onError) => {
  return {
    type: COMMERCIAL_PROPERTY_VIEW,
    data,
    onSuccess,
    onError,
  };
};
export const editCommercialOfficeSpaceAction = (data, onSuccess, onError) => {
  return {
    type: EDIT_COMMERCIAL_OFFICE_SPACE,
    data,
    onSuccess,
    onError,
  };
};
export const editCommercialPlotAction = (data, onSuccess, onError) => {
  return {
    type: EDIT_COMMERCIAL_PLOT,
    data,
    onSuccess,
    onError,
  };
};
export const editCommercialShopAction = (data, onSuccess, onError) => {
  return {
    type: EDIT_COMMERCIAL_SHOP,
    data,
    onSuccess,
    onError,
  };
};
export const editCommercialShowroomAction = (data, onSuccess, onError) => {
  return {
    type: EDIT_COMMERCIAL_SHOW_ROOM,
    data,
    onSuccess,
    onError,
  };
};
export const editIndustrialBuildingAction = (data, onSuccess, onError) => {
  return {
    type: EDIT_INDUSTRIAL_BUILDING,
    data,
    onSuccess,
    onError,
  };
};
export const editIndustrialPlotAction = (data, onSuccess, onError) => {
  return {
    type: EDIT_INDUSTRIAL_PLOT,
    data,
    onSuccess,
    onError,
  };
};
export const editIndustrialShedAction = (data, onSuccess, onError) => {
  return {
    type: EDIT_INDUSTRIAL_SHED,
    data,
    onSuccess,
    onError,
  };
};
export const editWareHouseAction = (data, onSuccess, onError) => {
  return {
    type: EDIT_WARE_HOUSE,
    data,
    onSuccess,
    onError,
  };
};
