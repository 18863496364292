import { all } from "redux-saga/effects";
import {
  subscribeUserWatcher,
  subscribepProjectsWatcher,
  subscriptionAssignWatcher,
} from "../subscribeAssignSagas";

export default function* subscribeAssignSaga() {
  yield all([
    subscribeUserWatcher(),
    subscribepProjectsWatcher(),
    subscriptionAssignWatcher(),
  ]);
}
