import React from "react";

const Primarybutton = ({  
  btnText = "next",  
  onClick = () => { },
  buttonWidth="",
  className = "",  
  link = "",  
  type = "button", 
  customStyles = {},  
  textClass = "",}) => 
  {
  return (
    <button
      customStyles={customStyles}
      type={type}
      className={` fs_14  fw_400  ${className} `}
      onClick={onClick}
      style={{ width: buttonWidth, ...customStyles }}

      
    >
    <span className={`lh-sm${textClass}`}> {btnText}</span> 
    </button>
  );
};

export default Primarybutton;
