import React from 'react'

const Close = ({ fill = "#323D5A", weight = "12", height = "12", className }) => {
    return (
        <svg className={className} width={weight} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9964 1.01953L1 10.9992" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.9964 10.9964L1 1" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}

export default Close