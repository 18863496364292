import { call, takeLatest } from "redux-saga/effects";
import {
  SUBSCRIBE_PROJECTS,
  SUBSCRIBE_USER,
  SUBSCRIPTION_ASSIGN,
} from "../type";
import {
  subscribeProjectsApi,
  subscribeUserApi,
  subscriptionAssignApi,
} from "../api/subscribeApi";

export function* subscribeUserWorker(action) {
  try {
    const response = yield call(subscribeUserApi);
    console.log(response, "aaa");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* subscribepProjectsWorker(action) {
  try {
    const response = yield call(subscribeProjectsApi, action.data);
    console.log(response, "aaab");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* subscriptionAssignWorker(action) {
  console.log(action, "aaaa");
  try {
    const response = yield call(subscriptionAssignApi, action.data);
    console.log(response, "aaaa");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* subscribeUserWatcher() {
  yield takeLatest(SUBSCRIBE_USER, subscribeUserWorker);
}
export function* subscribepProjectsWatcher() {
  yield takeLatest(SUBSCRIBE_PROJECTS, subscribepProjectsWorker);
}
export function* subscriptionAssignWatcher() {
  yield takeLatest(SUBSCRIPTION_ASSIGN, subscriptionAssignWorker);
}
