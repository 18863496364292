import React from 'react'

const EyeShowIcon = ({fill="#50BF97"}) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.10028 8C6.09612 8.77223 6.32131 9.52832 6.74731 10.1724C7.17332 10.8165 7.78097 11.3197 8.49323 11.6181C9.20548 11.9165 9.99033 11.9967 10.7482 11.8486C11.5061 11.7005 12.203 11.3307 12.7505 10.7862C13.298 10.2416 13.6715 9.54671 13.8237 8.78961C13.9759 8.03252 13.9 7.24727 13.6054 6.53342C13.3109 5.81956 12.811 5.20923 12.1692 4.77975C11.5274 4.35028 10.7725 4.12101 10.0003 4.121C9.48944 4.11929 8.98325 4.21831 8.51068 4.41238C8.03811 4.60646 7.60843 4.89178 7.24622 5.25204C6.884 5.61231 6.59634 6.04045 6.39972 6.51196C6.2031 6.98348 6.10133 7.48913 6.10028 8ZM15.7393 2.04599C17.5941 3.54962 19.0388 5.49762 19.9393 7.70901C19.9785 7.79928 19.9987 7.89661 19.9987 7.995C19.9987 8.09338 19.9785 8.19074 19.9393 8.28101C17.8543 13.11 14.1373 16 10.0003 16H9.9903C5.8633 16 2.14633 13.11 0.0593262 8.28101C0.0201897 8.19074 0 8.09338 0 7.995C0 7.89661 0.0201897 7.79928 0.0593262 7.70901C2.14633 2.88001 5.8593 0 9.9903 0H10.0003C12.0885 0.0164208 14.11 0.737337 15.7373 2.04599H15.7393ZM10.0003 10.412C10.6419 10.412 11.2572 10.1571 11.7108 9.70349C12.1645 9.24984 12.4193 8.63457 12.4193 7.99301C12.4193 7.35145 12.1645 6.73615 11.7108 6.2825C11.2572 5.82885 10.6419 5.57401 10.0003 5.57401C9.88599 5.57331 9.77185 5.583 9.6593 5.603C9.63863 6.11944 9.41881 6.60785 9.04596 6.96579C8.6731 7.32373 8.17616 7.52342 7.6593 7.52301H7.61029C7.57911 7.67946 7.56269 7.83847 7.56128 7.99799C7.56577 8.64116 7.82502 9.25637 8.28217 9.70883C8.73931 10.1613 9.35711 10.4141 10.0003 10.412Z" fill={fill}/>
    </svg>
  )
}

export default EyeShowIcon