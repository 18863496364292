import React from "react";

const UseFormikRadioInput = ({
  name = "",
  title = "",
  lableClass = "mb-1 fs_15 ",
  label1 = "Yes",
  label2 = "No",
  formik,
}) => {
  const getValueFromPath = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  const isChecked = getValueFromPath(formik?.values, name);
  console.log(isChecked,"fsdjbfkb");
  return (
    <div className="d-flex flex-column">
      <p className={lableClass}>{title}</p>
      <div className="d-flex gap-4">
        <label className="d-flex align-items-center">
          <input
            style={{
              width: "15px",
              height: "15px",
            }}
            // defaultChecked={formik?.values?.[name]}
            type={"radio"}
            name={name}
            checked={isChecked === true} 
            onChange={() => {
              formik.setFieldValue(name, true);
            }}
          />

          <span className="ms-1">{label1}</span>
        </label>
        <label className="d-flex align-items-center">
          <input
            style={{
              width: "15px",
              height: "15px",
            }}
            type={"radio"}
            name={name}
            checked={isChecked === false}
            // defaultChecked={!formik.values[name]}
            onChange={() => {
              formik.setFieldValue(name, false);
            }}
          />

          <span className="ms-1">{label2}</span>
        </label>
      </div>
    </div>
  );
};

export default UseFormikRadioInput;
