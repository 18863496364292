import { api } from "../../api";

export const commercialPropertyViewApi = (data) => {
  return api.get(
    `/api/admin/properties/commercial-view?property_id=${data?.property_id}&property_type=${data?.property_type}`,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editCommercialOfficeSpaceApi = (params) => {
  return api.post("/api/admin/properties/edit-office-space", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editCommercialPlotApi = (params) => {
  return api.post("/api/admin/properties/edit-commercial-plot", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editCommercialShopApi = (params) => {
  return api.post("/api/admin/properties/edit-commercial-shop", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editCommercialShowroomApi = (params) => {
  return api.post("/api/admin/properties/edit-commercial-showroom", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editCommercialIndusBuildingApi = (params) => {
  return api.post("/api/admin/properties/edit-industrial-building", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editCommercialIndusPlotApi = (params) => {
  return api.post("/api/admin/properties/edit-industrial-plot", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editCommercialIndusShedApi = (params) => {
  return api.post("/api/admin/properties/edit-industrial-shed", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editCommercialWareHouseApi = (params) => {
  return api.post("/api/admin/properties/edit-warehouse", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};