import React, { useEffect, useState } from "react";
import { residentialImagesUploadAction } from "../../../../redux/action/edit-property-actions/multi-image-actions";
import { useDispatch } from "react-redux";
import UploadIcon from "../../../../assets/icons/image-upload-icon.png";
import DeleteIcon from "../../../../assets/icons/Delete-icon.png";
import LoadingSpinner from "../../../helper-functions/loading-spinner";
const OfficeAddPropertyPhoto = ({ multiImage, setMultiImage, setLoading }) => {
  const dispatch = useDispatch();
  const [uploadedImageCount, setUploadedImageCount] = useState(0);
  const [uploadedImage, setuploadedImage] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleImage = (e) => {
    const selectedFiles = e.target.files;
    console.log(selectedFiles, "selectedFiles");
    setUploadLoading(true);
    for (let i = 0; i < selectedFiles.length; i++) {
      dispatch(
        residentialImagesUploadAction(
          selectedFiles[i],
          onCompanyLogoSucess,
          onCompanyLogoError
        )
      );
    }
    e.target.value = null;
  };
  const onCompanyLogoSucess = (data) => {
    setuploadedImage((prev) => [...prev, data?.imagePath]);
    // setMultiImage(data?.imagePath)
    // setMultiImage((prev) => [...prev, data?.imagePath]);
    setUploadLoading(false);
    // setMultiImage((prev) => [...prev, data?.imagePath]);
    setUploadedImageCount((prev) => prev + 1);
  };

  const onCompanyLogoError = (data) => {
    console.log("imageerror");
    setLoading && setLoading(false);
    setUploadLoading(false);
  };

  // useEffect(()=>{
  //   if(multiImage?.length < 12){
  //     for(let i=0; i < uploadedImage?.length; i++){
  //       setMultiImage((prev) => [...prev, uploadedImage[i]]);
  //     }
  //   }
  //   setUploadedImageCount(0);

  // },[uploadedImage])

  useEffect(() => {
    if (multiImage?.length < 12) {
      for (let i = 0; i < uploadedImage.length && multiImage.length < 12; i++) {
        setMultiImage((prev) => [...prev, uploadedImage[i]]);
      }
    }
    setuploadedImage([]);
    setUploadedImageCount(0);
  }, [uploadedImageCount]);

  const onImageRemove = (value) => {
    setMultiImage(multiImage.filter((item) => item !== value));
  };
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>
          Project Photos({multiImage?.length}/12)
        </h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="border_radius_10px">
        <div className="row">
          {multiImage?.map((item, index) => {
            return (
              <div className="col-2 my-3 position-relative">
                <img
                  className="w-100 px-2"
                  style={{ height: "17rem" }}
                  src={item}
                  alt="property image"
                />

                <div className="position-absolute top-0 end-0">
                  <img
                    className="me-3"
                    src={DeleteIcon}
                    alt="Delete-icon"
                    onClick={() => onImageRemove(item)}
                  />
                </div>
              </div>
            );
          })}
          {uploadLoading && (
            <div
              style={{ height: "17rem" }}
              className="col-2 my-3 position-relative"
            >
              <LoadingSpinner />
            </div>
          )}
          {multiImage?.length > 0 && multiImage?.length < 12 && (
            <div
              className="col-2 border_radius_10px border_blue_color my-3 ms-4 d-flex justify-content-center align-items-center"
              style={{ height: "17rem", maxWidth: "15rem" }}
            >
              <label className="border d-flex justify-content-end">
                <input
                  disabled={multiImage?.length >= 12}
                  type={"file"}
                  style={{ visibility: "hidden", width: "0px" }}
                  id="logo"
                  name="img"
                  multiple
                  accept="image/png,image/jpeg,image/jpeg,capture=camera"
                  onChange={handleImage}
                />
                <span className={``}>
                  <img src={UploadIcon} />
                </span>
              </label>
            </div>
          )}
        </div>
      </div>
      {multiImage?.length >= 12 && (
        <span className="fs_14 fw_500">
          <span className="red_color">Note : </span>
          <span className="secondary_color">
            You have exceeded the limit of photos
          </span>
        </span>
      )}
      {multiImage?.length < 1 && (
        <div
          className={`${
            multiImage?.length < 1 &&
            "d-flex justify-content-center align-items-center border_color_grey border_radius_10px"
          }`}
          style={{ height: multiImage?.length < 1 && "20rem" }}
        >
          {multiImage?.length < 1 && (
            <div>
              <label className="border d-flex justify-content-end">
                <input
                  disabled={multiImage?.length >= 12}
                  type={"file"}
                  style={{ visibility: "hidden", width: "0px" }}
                  id="logo"
                  name="img"
                  multiple
                  accept="image/png,image/jpeg,image/jpeg,capture=camera"
                  onChange={handleImage}
                />
                <span
                  className={`border_radius_5px p-1 px-3  cursor_pointer ${
                    multiImage?.length >= 12 ? "disable_blue_bg" : "primary_bg"
                  }`}
                >
                  Upload
                </span>
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OfficeAddPropertyPhoto;
