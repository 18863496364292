import React, { useState } from "react";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikPriceInputField from "../../../form/useFormikPriceInputField";
import UseFormikSelectField from "../../../form/useFormikSelectField";
import { removeUnderScore } from "../../../helper/helper";

const ResidentialVillFeatures = ({formik, compareAminities, setCompareAminities, projectDetails, isClickedOnAme, setIsClickedOnAme}) => {
   const suitableForList = [
    {
        name: "private_parking_space",
        label: "Private Parking Space",
        status: true
    },
    {
        name: "guest_parking_space",
        label: "Guest Parking Space",
        status: true
    },
    {
        name: "play_ground",
        label: "Play Grounds",
        status: false
    },
    {
        name: "sauna_steam_room",
        label: "Sauna Steam Room",
        status: true
    },
    {
        name: "swimming_pool_private",
        label: "Swimming Pool(Private)",
        status: true
    },
    {
        name: "kids_play_area",
        label: "Kids Play Area",
        status: true
    },
    {
        name: "yoga_meditation_area",
        label: "Yoga Meditation Area",
        status: false
    },
    {
        name: "hardwood_floors",
        label: "Hardwood Floors",
        status: false
    },
    {
        name: "pet_wash_station",
        label: "Pet Wash Station",
        status: false
    },
    {
        name: "pet_room",
        label: "Pet Room",
        status: false
    },
    {
        name: "lounge_area",
        label: "Lounge Area",
        status: false
    },
    {
        name: "garden_area",
        label: "Garden Area",
        status: false
    },
    {
        name: "bbq_lounge_area",
        label: "BBQ Lounge Area",
        status: false
    },
    {
        name: "electric_Car_charging_stations",
        label: "Electric Car Charging Stations",
        status: false
    },
    {
        name: "barbecue_areas",
        label: "Barbecue Areas",
        status: false
    }
]
  // const initialAminities = projectDetails?.common_features?.amenities;
  // console.log(initialAminities, "initialAminities");
  // const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);

  // const handleCheckboxChange = (index) => {
  //   const updatedSuitableFor = [...suitableFor];
  //   updatedSuitableFor[index].status = !updatedSuitableFor[index].status;
  //   setSuitableFor(updatedSuitableFor);
  //   formik.setFieldValue("common_features.amenities", updatedSuitableFor);
  // };
  // const aminitiesAreEqual = (currentAminities, initialAminities)=>{
  //   const getCurrentAminitieStatus = currentAminities?.filter((item)=> item?.status === true)?.length;
  //   const getInitialAminitieStatus = Array.isArray(initialAminities) && initialAminities?.filter((item)=> item?.status === true)?.length;
  //   if(getCurrentAminitieStatus === getInitialAminitieStatus){
  //     setCompareAminities(true);
  //   }
  //   else{
  //     setCompareAminities(false);
  //   }
  // }
  //  aminitiesAreEqual(suitableFor, formik?.initialValues?.common_features?.amenities);

  const initialAminities = projectDetails?.common_features?.amenities;
  const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);
  
  const aminitiesAreEqual = (currentProjAminities, initialProjAminities)=>{
    const isAmentiesMatches = currentProjAminities?.every((currentItem, index) => initialProjAminities?.[index]?.status === currentItem?.status);
    console.log(isAmentiesMatches, "isAmentiesMatches");
    if(isAmentiesMatches){
      setCompareAminities(true);
    }
    else{
      setCompareAminities(false);
    }
  }
  const updatedAminitiesList = suitableForList?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {
      return backendAminity.name === aminity.name;
    });
    console.log(matchingBackendAminity, "matchingBackendAminity");
    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
  
    return aminity;
  });
  
  const [amenities, setAmenities] = useState(updatedAminitiesList);

  const handleCheckboxChange = (index) => {
  const newUpdatedAminitiesList = [...amenities];
  newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
  setAmenities(newUpdatedAminitiesList);
  const filteredAmenities = newUpdatedAminitiesList.map((item) => { return { name: item.name, status: item.status }});
  formik.setFieldValue("common_features.amenities", filteredAmenities);
};
if(isClickedOnAme){
  aminitiesAreEqual(amenities, formik?.initialValues?.common_features?.amenities);
};
  
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Common Features</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
       <div className="d-flex flex-wrap gap-5 align-items-center">
        <UseFormikSelectField
          label="Furnishing Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="common_features.furnishing_status"
          formik={formik}
          formikError={formik?.errors?.common_features?.furnishing_status}
          formikTouched={formik?.touched?.common_features?.furnishing_status}
          defaultValue={
            formik?.values?.common_features?.furnishing_status && {
              label: removeUnderScore(
                formik?.values?.common_features?.furnishing_status
              ),
              value: formik?.values?.common_features?.furnishing_status,
            }
          }
        />
        <UseFormikSelectField
          label="Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="common_features.status"
          formik={formik}
          formikError={formik?.errors?.common_features?.status}
          formikTouched={formik?.touched?.common_features?.status}
          defaultValue={
            formik?.values?.common_features?.status && {
              label: removeUnderScore(
                formik?.values?.common_features?.status
              ),
              value: formik?.values?.common_features?.status,
            }
          }
        />
           {/* <UseFormikSelectField
          label="Transaction"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          // name="property_features.facing"
          // formik={formik}
          // formikError={formik?.errors?.facing}
          // formikTouched={formik?.touched?.facing}
        /> */}
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Property Amenities</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        <div className="row g-0">
          {amenities?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default ResidentialVillFeatures;
