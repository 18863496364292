import React, { useEffect, useMemo, useState } from "react";
import styles from "../../styles/property-details.module.css";
import Select from "react-select";
import Leftarrowicon from "../../assets/icons/left-arrow-icon";
import Primarybutton from "../../components/ui/buttons/primary-button";
import { useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import TickIcon from "../../assets/icons/tick-icon";
import Close from "../../assets/icons/close";
import { useDispatch } from "react-redux";
import {
  subscribeAssignAction,
  subscribeProjectsAction,
  subscribeUserAction,
} from "../../redux/action/subscription-assign/subscribeAssign";
import successToast from "../../components/helper-functions/successToast";
import errorToast from "../../components/helper-functions/errorToast";

const SubscriptionAssign = () => {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  let filteredValues = [];
  const [filteredState, setFilteredState] = useState([]);
  const [userList, setUserList] = useState("");
  const [projectList, setProjectList] = useState([]);
  const dispatch = useDispatch();
  let maxProjectsOptions = 0;

  const planOptions = [
    { value: "e1160eab-a38d-4714-b0c9-3c787108254c", label: "Silver" },
    { value: "6a7d327c-ffd0-4b09-ab10-a75547074577", label: "Gold" },
    { value: "d59c1ffc-ccdf-4b3a-b6b9-467bcde47a59", label: "Diamond" },
  ];
  console.log(selectedPlan);
  function planTypeFn() {
    if (selectedPlan.label === "Silver") return (maxProjectsOptions = 2);
    else if (selectedPlan.label === "Gold") return (maxProjectsOptions = 4);
    else if (selectedPlan.label === "Diamond") return (maxProjectsOptions = 6);
  }

  let maxSelectedOptions = planTypeFn();
  const handleMultiSelectChange = (selected) => {
    console.log(selected);
    console.log(selected?.length <= maxSelectedOptions);
    if (selected.length <= maxSelectedOptions) {
      setSelectedOptions(selected);
      filteredValues = selected?.map((item) => item.value);
      setFilteredState(filteredValues);
    }
  };

  useEffect(() => {
    dispatch(subscribeUserAction(onUserFetchSuccess, onUserFetchError));
  }, []);

  const onUserFetchSuccess = (data) => {
    console.log(data?.data?.data);
    setUserList(
      data?.data?.map((user) => {
        return { label: user.id + " - " + user.name, value: user.id };
      })
    );
  };
  const onUserFetchError = () => { };
  useEffect(() => {
    dispatch(
      subscribeProjectsAction(
        { id: selectedUser?.value },
        onProjectFetchSuccess,
        onProjectFetchError
      )
    );
  }, [selectedUser]);

  const onProjectFetchSuccess = (data) => {
    console.log(data?.data);
    setProjectList(
      data?.data?.map((user) => {
        return {
          label: user?.project_details?.project_or_society_name,
          value: user?.id,
        };
      })
    );
  };
  const onProjectFetchError = () => { };

  const onSubmit = (values) => {
    values["projects"] = filteredState;
    console.log(values, "valuesss");
    dispatch(subscribeAssignAction(values, onSuccess, onError));
  };

  const onSuccess = (data) => {
    successToast(data?.message);
  };
  const onError = (data) => {
    errorToast(data?.message);
  };
  return (
    <div className="font_family_poppins">
      <p className="fw_400 fs_16 text_color_grey ms-5 mt-3 ">
        ADMIN / Assign Subscription
      </p>
      <div
        style={{ minHeight: "70vh" }}
        className={` ${styles.border_color_gray} rounded-4 m-4 bg-white`}
      >
        <div className={`d-flex justify-content-between my-2 px-4 py-2`}>
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <Leftarrowicon />
            <Primarybutton
              btnText={"BACK"}
              className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
            />
          </div>
        </div>
        <hr />
        <div className="px-4 py-2">
          <Formik
            initialValues={{
              user_id: "",
              plan_id: "",
              projects: "",
            }}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, values, resetForm }) => {
              const handleClear = () => {
                setFieldValue("user_id", "");
                setFieldValue("plan_id", "");
                setFieldValue("projects", []);
                setSelectedUser("");
                setSelectedPlan("");
                setSelectedOptions([]);
                setProjectList("");
              };
              return (
                <Form>
                  <div style={{ maxWidth: "600px" }}>
                    <div className="my-3">
                      <label className="color_323D5A">User</label>
                      <Field>
                        {({ form, meta, Field }) => {
                          console.log(form);
                          return (
                            <Select
                              options={userList}
                              onChange={(selectedOption) => {
                                setSelectedUser(selectedOption);
                                setFieldValue("user_id", selectedOption.value);
                              }}
                              value={
                                selectedUser
                                  ? {
                                    label: selectedUser.label,
                                    value: values.user_id,
                                  }
                                  : null
                              }
                              {...Field}
                              styles={customStyles}
                              placeholder="Select user"
                              name="user_id"
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="my-3">
                      <label className="color_323D5A">Plan</label>
                      <Select
                        options={planOptions}
                        onChange={(selectedOption) => {
                          setSelectedPlan(selectedOption);
                          setFieldValue("plan_id", selectedOption.value);
                        }}
                        value={
                          selectedPlan
                            ? {
                              label: selectedPlan.label,
                              value: values.user_id,
                            }
                            : null
                        }
                        styles={customStyles}
                        placeholder="Select plan"
                        name="plan_id"
                      />
                    </div>
                    <div className="my-3">
                      <label className="color_323D5A">Project</label>
                      <Select
                        options={projectList}
                        isMulti
                        value={selectedOptions}
                        onChange={handleMultiSelectChange}
                        styles={customStyles}
                        placeholder="Select project"
                        name="projects"
                      />
                    </div>
                    <div className="d-flex justify-content-end gap-3 mt-4">
                      <button
                        onClick={() => {
                          resetForm();
                          handleClear();
                        }}
                        style={{ backgroundColor: "rgba(219, 233, 242, 1)" }}
                        type="button"
                        className="text-black border-0 p-2 px-3 rounded-2 fs_14 fw_500 align-items-center d-flex"
                      >
                        <Close height="10" width="10" className="me-2" />
                        CLEAR
                      </button>
                      <button
                        type="submit"
                        className="background_color_skyblue text-white border-0 p-2 px-3 rounded-2 fs_14 fw_500"
                      >
                        <TickIcon className={"me-2"} />
                        ASSIGN
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAssign;

const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #DBE9F2",
    boxShadow: "none",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "500",
    padding: "4px",
    "&:hover": {
      borderColor: state.isFocused ? "1px solid #DBE9F2" : "1px solid #DBE9F2",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#50BF97",
    "&:hover": {
      color: state.isFocused ? "#50BF97" : "#50BF97",
    },
  }),
  singleValue: (base) => ({
    ...base,
    width: "fit-content",
    padding: "0px",
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#ACBBC5",
      fontWeight: "400",
    };
  },
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#EDF4F9",
    padding: "1px",
    borderRadius: "5px",
  }),
};
