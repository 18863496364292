import React from "react";
import UseFormikInputField from "../../../form/useFormikInputField";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikSelectField from "../../../form/useFormikSelectField";
import UseFormikRadioInput from "../../../form/useFormikRadioField";
import { removeUnderScore } from "../../../helper/helper";
const AgricultureFarmHouseFeatures = ({ formik }) => {
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h3 className={`light_blue_color fs_22 fs_md_20 fs_sm_18 me-4`}>
          Property Features
        </h3>
        <hr className={`py-1 flex-grow-1 border-primary `} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.total_floors}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.total_floors}
            formikTouched={formik?.touched?.property_features?.total_floors}
            itemName="property_features.total_floors"
            labelName="Total Floors"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.bedrooms}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.bedrooms}
            formikTouched={formik?.touched?.property_features?.bedrooms}
            itemName="property_features.bedrooms"
            labelName="Bedroom"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.bathrooms}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.bathrooms}
            formikTouched={formik?.touched?.property_features?.bathrooms}
            itemName="property_features.bathrooms"
            labelName="Bathrooms"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.balconies}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.balconies}
            formikTouched={formik?.touched?.property_features?.balconies}
            itemName="property_features.balconies"
            labelName="Balconies"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.no_of_open_sides}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.no_of_open_sides}
            formikTouched={formik?.touched?.property_features?.no_of_open_sides}
            itemName="property_features.no_of_open_sides"
            labelName="No of Open Sides"
          />
        </div>
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center mt-3">
        <UseFormikSelectField
          label="Facing"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.facing"
          formik={formik}
          formikError={formik?.errors?.property_features?.facing}
          formikTouched={formik?.touched?.property_features?.facing}
          defaultValue={
            formik?.values?.property_features?.facing && {
              label: removeUnderScore(formik?.values?.property_features?.facing),
              value: formik?.values?.property_features?.facing,
            }
          }
        />
        <UseFormikSelectField
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          label="Furnishing Status"
          name="property_features.furnishing_status"
          formik={formik}
          formikError={formik?.errors?.property_features?.furnishing_status}
          formikTouched={formik?.touched?.property_features?.furnishing_status}
          defaultValue={
            formik?.values?.property_features?.furnishing_status && {
              label: removeUnderScore(formik?.values?.property_features?.furnishing_status),
              value: formik?.values?.property_features?.furnishing_status,
            }
          }
        />
        <UseFormikSelectField
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          label="Status"
          name="property_features.status"
          formik={formik}
          formikError={formik?.errors?.property_features?.status}
          formikTouched={formik?.touched?.property_features?.status}
          defaultValue={
            formik?.values?.property_features?.status && {
              label: removeUnderScore(formik?.values?.property_features?.status),
              value: formik?.values?.property_features?.status,
            }
          }
        />
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.boundary_wall_made"}
          title={"Boundary Wall made"}
        />
      </div>
    </div>
  );
};

export default AgricultureFarmHouseFeatures;
