import React, { useMemo, useState } from "react";
import UseFormikInputField from "../../../form/useFormikInputField";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikSelectField from "../../../form/useFormikSelectField";
import UseFormikRadioInput from "../../../form/useFormikRadioField";
import { removeUnderScore } from "../../../helper/helper";
const CommercialPlotFeatures = ({ formik, setCompareAminities, propertyDetails, isClickedOnAme, setIsClickedOnAme, amenitiesType }) => {
 
  const commercialSuitableForList = [
    {
      name: "malls",
      label: "Malls",
      status: false,
    },
    {
      name: "office",
      label: "Office",
      status: false,
    },
    { name: "showrooms", label: "Showrooms", status: false },
    {
      name: "industrial_consutruction_for_any_factories",
      label: "Industrial Construction For Any Factories",
      status: false,
    },
    {
      name: "rent_or_lease_out_vacant_land",
      label: "Rent/Lease Out Vacant Land",
      status: false,
    },
    {
      name: "rent_lease_out_for_parking",
      label: "Rent/Lease Out For Parking",
      status: false,
    },
  ];
  const industrialSuitableForList = [
    {
      name: "commericial_or_industrial_consutruction_for_any_factories",
      label: "Commericial Or Industrial Consutruction For Any Factories",
      status: false,
    },
    {
      name: "rent_or_lease_out_vacant_land",
      label: "Rent/Lease Out Vacant Land",
      status: false,
    },
    {
      name: "rent_lease_out_for_parking",
      label: "Rent Lease Out For Parking",
      status: false,
    },
  ];
  const AmenitiesList = useMemo(()=>{
    if(amenitiesType === "induatrial_plot"){
      return industrialSuitableForList
    } else {
      return commercialSuitableForList
    }
  },[amenitiesType])
  const initialAminities = propertyDetails?.property_features?.suitable_for;
  const aminitiesAreEqual = (currentSuitableFor, initialSuitableFor) => {
    const getCurrentAminitieStatus = currentSuitableFor?.filter((item) => item?.status === true)?.length;
    const getInitialAminitieStatus = initialSuitableFor?.filter((item) => item?.status === true )?.length;
    const isAmentiesMatches = currentSuitableFor?.every((currentItem, index) => initialSuitableFor[index]?.status === currentItem?.status);
    if (isAmentiesMatches) {
      setCompareAminities(true);
    } else {
      setCompareAminities(false);
    }
  };
  const updatedAminitiesList = AmenitiesList?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {
      return backendAminity.name === aminity.name;
    });

    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
  
    return aminity;
  });
  const [suitableFor, setSuitableFor] = useState(updatedAminitiesList);
  const handleCheckboxChange = (index) => {
    const newUpdatedAminitiesList = [...suitableFor];
    newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
    setSuitableFor(newUpdatedAminitiesList);
    const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
    formik.setFieldValue("property_features.suitable_for", filteredAmenities);
  };
  if(isClickedOnAme){
    aminitiesAreEqual(suitableFor, formik?.initialValues?.property_features?.suitable_for);
  }
  
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h3 className={`light_blue_color fs_20 fs_sm_18 me-4`}>
          Property Features
        </h3>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={
              formik?.values?.property_features?.floors_allowed_for_construction
            }
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={
              formik?.errors?.property_features?.floors_allowed_for_construction
            }
            formikTouched={
              formik?.touched?.property_features
                ?.floors_allowed_for_construction
            }
            itemName="property_features.floors_allowed_for_construction"
            labelName="Floors Allowed for Construction"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.no_of_open_sides}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.no_of_open_sides}
            formikTouched={formik?.touched?.property_features?.no_of_open_sides}
            itemName="property_features.no_of_open_sides"
            labelName="No of Open Sides"
          />
        </div>
        <UseFormikSelectField
          label="Facing"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.facing"
          formik={formik}
          formikError={formik?.errors?.property_features?.facing}
          formikTouched={formik?.touched?.property_features?.facing}
          defaultValue={
            formik?.values?.property_features?.facing && {
              label: removeUnderScore(
                formik?.values?.property_features?.facing
              ),
              value: formik?.values?.property_features?.facing,
            }
          }
        />
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.any_construction_done"}
          title={"Any construction Done"}
        />
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.corner_site"}
          title={"Corner site"}
        />
        <UseFormikRadioInput
          formik={formik}
          name={"property_features.boundary_wall_made"}
          title={"Boundary Wall made"}
        />
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Suitable For</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        <div className="row g-0">
          {suitableFor?.map(
            (item, index) => {
              return (
                <div className="col-3 mb-3">
                  <label>
                  <div className="d-flex">
                    <div className="mt-1">
                      <input
                        className=""
                        type="checkbox"
                        name={`property_features.amenities[${index}].status`}
                        checked={item.status}
                        onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                        value={item?.name}
                        style={{ width: "17px", height: "17px" }}
                      />
                    </div>
                    <div className="ms-2 d-inline-block fs_15 secondary_color">
                      {item?.label}
                    </div>
                  </div>
                  </label>
                  
                </div>
              );
            }
          )}
        </div>
      </>
    </div>
  );
};

export default CommercialPlotFeatures;
