import React, { useEffect, useState } from "react";
import styles from "../../styles/property-request.module.css";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/helper-functions/loading-spinner";
import moment from "moment";
import TablePagination from "../../components/helper-functions/table-pagination";
import { LivePgListAction } from "../../redux/action/property-actions/pgListAction";

const LivePgList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectDataFetch, setProjectDataFetch] = useState([]);
  const search = searchParams.get("search") ?? "";
  const pageParam = +searchParams.get("page");
  const currentPage = pageParam && !isNaN(pageParam) ? pageParam : 1;
  const [pagination, setPagination] = useState();

  console.log(currentPage, "cur");
  useEffect(() => {
    setLoadingData(true);
    dispatch(
      LivePgListAction(
        {
          page_no: currentPage,
          search: search,
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, search]);

  function onSuccess(data) {
    setLoadingData(false);
    setProjectDataFetch(data?.data?.pgs);
    setPagination({
      totalPages: data?.data?.pages,
      currentPage: currentPage,
    });
  }
  function onError(data) {
    console.log(data);
  }

  function handleProjectView(id) {
    navigate(`/property-project/projectFindOne/${id}`);
  }
  // const navigationFn = (id, project_type) => {
  //   if (project_type === "apartment") {
  //     navigate(
  //       `/property-project/live-projects/edit-project/residential/flat-apartment/${project_type}/${id}`
  //     );
  //   } else if (project_type === "residential") {
  //     navigate(
  //       `/property-project/live-projects/edit-project/residential/residential-plot/${project_type}/${id}`
  //     );
  //   } else if (project_type === "villa") {
  //     navigate(
  //       `/property-project/live-projects/edit-project/residential/residential-villa/${project_type}/${id}`
  //     );
  //   }
  // };

  const navigationFn = (id, property_type) => {
    navigate(`/property-project/live-pg/edit-pg/${property_type}/${id}`);
  };
  const findEnabledAndRent = (obj) => {
    const keys = [
      "single_sharing",
      "double_sharing",
      "triple_sharing",
      "four_sharing",
      "other",
    ];
    for (const key of keys) {
      if (obj[key]?.enabled) {
        return obj[key]?.rent;
      }
    }
    return null;
  };
  return (
    <>
      <div
        className="rounded-4 border-1  bg-white py-3 position-relative"
        style={{ minHeight: "75vh" }}
      >
        <input
          className="w-50 mb-3 ms-4 rounded-3 py-2 border_color_grey table_background_color_grey ps-3 fs_14 fw_400"
          placeholder="Search here by project id, user id..."
          style={{ outline: "none" }}
          onChange={(e) => {
            setSearchParams({
              page: 1,
              search: e.target.value,
            });
          }}
        />

        <div
          className=" rounded-4 border_color mx-3 "
          style={{ minHeight: "65vh" }}
        >
          <div
            className={` overflow-x-scroll ${styles.scroll_decoration}`}
            style={{ minHeight: "65vh" }}
          >
            <table className="w-100">
              <thead>
                <tr style={{ borderBottom: "1px solid #E5ECFF" }}>
                  {/* <th className="ps-3 pe-1 py-3  fw_500   text-nowrap  ">
                      <input
                        type="checkbox"
                        onChange={(e) => functionFn(e)}
                        className="mt-1"
                      />
                    </th> */}
                  <th
                    className="fs_14  fw_500 ps-4 py-3 text-nowrap text_color_grey"
                    style={{ minWidth: "2rem" }}
                  >
                    #
                  </th>
                  <th className="fs_14 fw_500 ps-4 py-3 text-nowrap text_color_grey">
                    PGs ID
                  </th>
                  <th className="fs_14 fw_500 ps-5  py-2 text-nowrap text_color_grey">
                    Fees(per Month)
                  </th>
                  <th className="fs_14 fw_500 ps-5 py-2 text-nowrap text_color_grey">
                    Subscription Plan
                  </th>

                  <th className="fs_14 fw_500 ps-5 py-2 text-nowrap text_color_grey">
                    Validity
                  </th>
                  <th className="fs_14 fw_500 ps-5 py-2 text-nowrap text_color_grey">
                    Posted By
                  </th>
                  <th className="fs_14 fw_500 ps-5 py-2 text-nowrap text_color_grey">
                    Posted Date & Time
                  </th>
                  <th className="fs_14 fw_500 ps-5 py-2 text-nowrap text_color_grey">
                    Updated Date & Time
                  </th>
                  <th
                    className=" pe-5 ps-5  py-1 fs_14 color_707070 fw_500 position-sticky bg-white text_color_grey rounded-5"
                    style={{
                      right: "0px",
                      width: "100%",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>

              {loadingData === true ? (
                <LoadingSpinner />
              ) : (
                <>
                  {projectDataFetch?.length > 0 ? (
                    <tbody className="">
                      {projectDataFetch?.map((item, index) => {
                        console.log(item, "aaaaa");
                        return (
                          <tr style={{ borderBottom: "1px solid #E5ECFF" }}>
                            <React.Fragment key={index}>
                              {/* <td className="ps-3 pe-1 py-3 fs_14 fw_400   ">
                              <input
                                type="checkbox"
                                 checked={selectCheckbox}
                                onChange={(e)=>functionFn(e)}
                                className="mt-2"
                              ></input>
                            </td> */}
                              <td className={` fw_400  ps-4 py-3 text-nowrap`}>
                                <span className={`fs_14 `}>
                                  {(currentPage - 1) * 10 + (index + 1)}
                                </span>
                              </td>
                              <td
                                className={` fw_400 fs_14  ps-4 py-3 text-nowrap`}
                              >
                                {item?.id ? item?.id : "---"}
                              </td>
                              <td
                                className={`fs_14  fw_400  ps-5 py-2 text-nowrap`}
                              >
                                {findEnabledAndRent(item) !== null
                                  ? "₹" + findEnabledAndRent(item)
                                  : "--"}
                              </td>
                              <td
                                className={`fs_14 fw_400  ps-5 py-2 text-nowrap`}
                              >
                                <span>--</span>
                              </td>
                              <td
                                className={` fw_400  ps-5 py-2 text-nowrap text-capitalize`}
                              >
                                <span className={`fs_14 `}>--</span>
                              </td>
                              <td
                                className={` fw_400 fs_14 ps-5 py-2 text-nowrap`}
                              >
                                {item?.user_id ? (
                                  <span>
                                    {item?.name}
                                    <span className="text-capitalize ms-1">
                                      ({item?.owner_type})
                                    </span>
                                  </span>
                                ) : (
                                  "---"
                                )}
                              </td>
                              <td className={` fw_400  ps-5 py-2 text-nowrap`}>
                                <span className={`fs_14 `}>
                                  {item?.createdAt
                                    ? moment(item?.createdAt).format(
                                        "DD MMM YYYY, H:MM A"
                                      )
                                    : "---"}
                                </span>
                              </td>
                              <td className={` fw_400  ps-5 py-2 text-nowrap`}>
                                <span className={`fs_14 `}>
                                  {item?.updatedAt
                                    ? moment(item?.updatedAt).format(
                                        "DD MMM YYYY, H:MM A"
                                      )
                                    : "---"}
                                </span>
                              </td>

                              <div
                                className="rounded-4 position-sticky bg-white  pt-2 py-1 ps-5"
                                style={{ right: "0px", width: "100%" }}
                              >
                                <td
                                  className={`fs_14 py-2 fw_500 me-4   text_color_light_green cursor_pointer`}
                                  // onClick={() => handleProjectView(item?.id)}
                                  // onClick={() =>
                                  //   navigationFn(item?.id, item?.project_type)
                                  // }
                                  onClick={() =>
                                    navigationFn(
                                      item?.id,
                                      item?.main_property_type
                                    )
                                  }
                                >
                                  VIEW
                                </td>
                              </div>
                            </React.Fragment>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <>
                      <div
                        className=" text-nowrap empty_table_position position-absolute fs_30 fs_sm_22"
                        style={{ top: "50%", left: "35%" }}
                      >
                        --Table is Empty--
                      </div>
                    </>
                  )}
                </>
              )}
            </table>
          </div>
        </div>

        <TablePagination pagination={pagination} />
      </div>
    </>
  );
};

export default LivePgList;
