import {
  PROJECT_APARTMENT_EDIT,
  PROJECT_FIND_ONE,
  PROJECT_PLOT_EDIT,
  PROJECT_VILLA_EDIT,
} from "../../type";

export const projectFindOneAction = (data, onSuccess, onError) => {
  return {
    type: PROJECT_FIND_ONE,
    data,
    onSuccess,
    onError,
  };
};
export const editProjectApartmentAction = (data, onSuccess, onError) => {
  return {
    type: PROJECT_APARTMENT_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const editProjectVillaAction = (data, onSuccess, onError) => {
  return {
    type: PROJECT_VILLA_EDIT,
    data,
    onSuccess,
    onError,
  };
};
export const editProjectPlotAction = (data, onSuccess, onError) => {
  return {
    type: PROJECT_PLOT_EDIT,
    data,
    onSuccess,
    onError,
  };
};
