import React, { useEffect, useMemo, useRef, useState } from "react";
import UseFormikSelectField from "../form/useFormikSelectField";
import { removeUnderScore } from "../helper/helper";
import { useDispatch } from "react-redux";
import { searchAutocompleteAction } from "../../redux/action/export-action/exportAction.jsx";
import styles from "../../styles/post-property/postProperty.module.css";
// import styles from "../../../../styles/property-details.module.css";
import Select from "react-select";
import CancelIcon from "../../assets/icons/close-icon.svg";
import ArrowDown from "../../assets/icons/arrow-down";
import InfiniteScroll from "react-infinite-scroll-component";
const PropertyLocation = ({ formik, mapLoading }) => {
  const dispatch = useDispatch();
  const locDropDownRef = useRef(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [localityList, setLocalityList] = useState([]);
  const [localityOption, setLocalityOption] = useState([]);
  const [selectedLocality, setSelectedLocality] = useState({});
  const [filteredLocationData, setFilteredLocationData] = useState({});
  const [previousCity, setPreviousCity] = useState(null);
  const [onCityChange, setOnCityChange] = useState("");
  const [showLocality, setShowLocality] = useState(false);
  const [searchedItems, setSearchedItems] = useState("");
  const [componentRendered, setComponentRendered] = useState(false);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    setSelectedLocality({
      area_id: "",
      value: formik?.values?.g_location?.area,
      label: formik?.values?.g_location?.area,
      city_name: formik?.values?.g_location?.city,
    });
    setComponentRendered(true);
    dispatch(
      searchAutocompleteAction(
        0,
        {
          city_name: "",
          search_string: "",
        },
        onFirstLoadSuccess,
        onErrorAutoSearch
      )
    );
  }, [formik?.values?.g_location]);
  const onFirstLoadSuccess = (data) =>{
    // if(!formik?.values?.g_location?.city){
      setCities(data?.data?.city?.map((item)=> ({label: item?.city_name, value: item?.city_name})));
    // }
  }
  useEffect(() => {
    setPageNo(0);
    if (formik?.values?.g_location?.city) {
      dispatch(
        searchAutocompleteAction(
          0,
          {
            city_name: formik?.values?.g_location?.city,
            // city_name: "",
            search_string: searchedItems,
          },
          onSuccessAutoSearch,
          onErrorAutoSearch
        )
      );
    }
  }, [formik?.values?.g_location?.city, searchedItems]);
  
  const onSuccessAutoSearch = (data) => {
    setTotalPages(data?.data?.pages);
    if (previousCity === formik?.values?.g_location?.city) {
      setLocalityList((prev) => [...prev, data?.data?.locality].flat());
    } else {
      setPreviousCity(formik?.values?.g_location?.city);
      setSearchedItems("");
      setLocalityList([]);
      setLocalityList((prev) => [...prev, data?.data?.locality].flat());
    }
  };
  const onErrorAutoSearch = () => {};

  const filteredLocality = useMemo(() => {
    const filteredData = localityList?.filter((item) =>
      selectedLocality?.area_id?.includes(item?.id)
    );
    console.log(filteredData[0], "filteredData");
    setFilteredLocationData(filteredData[0]);
    if (selectedLocality && Object.keys(selectedLocality).length > 0 && selectedLocality?.area_id) {
      // console.log(filteredData[0], selectedLocality, "filteredLocationData");
      formik.setFieldValue("g_location.address_line_1", filteredData[0]?.address_line_1 ? filteredData[0]?.address_line_1 : formik?.g_location?.address_line_1 ?? "");
      formik.setFieldValue("g_location.address_line_2", filteredData[0]?.address_line_2 ? filteredData[0]?.address_line_2 : formik?.g_location?.address_line_2 ?? "");
      formik.setFieldValue("g_location.area",filteredData[0]?.locality_name ? filteredData[0]?.locality_name: formik?.g_location?.area ?? "");
      formik.setFieldValue("g_location.city",filteredData[0]?.city_name ? filteredData[0]?.city_name : formik?.g_location?.city ?? "");
      formik.setFieldValue("area",filteredData[0]?.locality_name ? filteredData[0]?.locality_name : formik?.g_location?.area ?? "");
      formik.setFieldValue("city",filteredData[0]?.city_name ? filteredData[0]?.city_name : formik?.g_location?.city ?? "");
      formik.setFieldValue("g_location.postCode",filteredData[0]?.postCode ? filteredData[0]?.postCode : formik?.g_location?.postCode ?? "");
      formik.setFieldValue("g_location.lat",filteredData[0]?.lat ? filteredData[0]?.lat : formik?.g_location?.lat ?? 12.3387332);
      formik.setFieldValue("g_location.lng",filteredData[0]?.long ? filteredData[0]?.long : formik?.g_location?.lng ?? 76.5982729);
      formik.setFieldValue("property_details.location.lat",filteredData[0]?.lat ? filteredData[0]?.lat : formik?.g_location?.lat ?? 12.3387332);
      formik.setFieldValue("property_details.location.long",filteredData[0]?.long ? filteredData[0]?.long : formik?.g_location?.lng ?? 76.5982729);
      formik.setFieldValue("lat",filteredData[0]?.lat ? filteredData[0]?.lat : formik?.g_location?.lat ?? 12.3387332);
      formik.setFieldValue("lng",filteredData[0]?.long ? filteredData[0]?.long : formik?.g_location?.lng ?? 76.5982729);
    }
    return filteredData[0];
  }, [selectedLocality]);

  const handleChange = (e) => {
    setSearchedItems(e.target.value);
  };
  useEffect(() => {
    pageNo !== 0 &&
      pageNo < totalPages &&
      dispatch(
        searchAutocompleteAction(
          pageNo,
          {
            city_name: formik?.values?.g_location?.city ?? "",
            search_string: "",
          },
          onSuccessAutoSearch,
          onErrorAutoSearch
        )
      );
  }, [pageNo]);
  useEffect(() => {
    if (localityList) {
      setLocalityOption(
        [...new Set((localityList || []).map((item) => item?.id))].map(
          (area_id) => {
            const item = localityList.find((loc) => loc?.id === area_id);
            return {
              area_id: area_id,
              value: item?.locality_name,
              label: item?.locality_name,
              city_name: item?.city_name,
            };
          }
        )
      );
    }
  }, [localityList]);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        locDropDownRef.current &&
        !locDropDownRef.current.contains(e.target)
      ) {
        setShowLocality(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showLocality]);

  return (
    <div>
      {componentRendered && 
      <div className="col-12">
      <div
        className={`${
          formik?.errors?.g_location?.city &&
          formik?.touched?.g_location?.city
            ? "fs_15 red_color"
            : "fs_15 secondary_color"
        }`}
      >
        Select City
      </div>
      <span>
        <Select
          className=""
          name={"g_location.city"}
          styles={selectStyles}
          error={
            formik?.errors?.g_location?.city &&
            formik?.touched?.g_location?.city
          }
          defaultValue={
            formik?.values?.g_location?.city && {
              label: formik?.values?.g_location?.city,
              value: formik?.values?.g_location?.city,
            }
          }
          placeholder={"select"}
          options={cities}
          onChange={(selectedOption) => {
            // setSelectedLocality(selectedOption);
            formik.setFieldValue("g_location.city", selectedOption.value);
            if (onCityChange !== selectedOption.value) {
              console.log(onCityChange, "onCityChangeselect");
              formik.setFieldValue("g_location.area", "");
              setSelectedLocality({});
            }
            setOnCityChange(selectedOption.value);
          }}
        />
      </span>
    </div>
      }
      
      {componentRendered && 
      <div className="col-12 mt-3 ">
      <div
        className={`${
          formik?.errors?.g_location?.area &&
          formik?.touched?.g_location?.area
            ? "fs_15 red_color"
            : "fs_15 secondary_color"
        }`}
      >
        Select Area
      </div>
      <div className="position-relative" ref={locDropDownRef}>
        <div
          className={`d-flex justify-content-between ${formik?.errors?.g_location?.area && formik?.touched?.g_location?.area
              ? `${styles.input_container__error}`
              : `${styles.input__container}`
          }`}
          onClick={() => setShowLocality(!showLocality)}
        >
          <div>
            {selectedLocality && Object.keys(selectedLocality).length > 0 && selectedLocality?.value ? (
              <>
                <span className="fs_14 secondary_color">
                  {selectedLocality?.label}
                </span>
                <span
                  onClick={() => {
                    setSelectedLocality({});
                    setSearchedItems("");
                    formik.setFieldValue("g_location.area", "");
                  }}
                >
                  <img className="ms-2" width={15} src={CancelIcon} />
                </span>
              </>
            ) : (
              // <span className="fs_12 text_muted">select</span>
              <input
                placeholder="select"
                className={`focus_none w-100 border-0 ${styles.input_search_field}`}
                value={searchedItems}
                onChange={handleChange}
              />
            )}
          </div>
          <div>
            <ArrowDown />
          </div>
        </div>
        {showLocality && (
          <div
            className={`position-absolute ${styles.select_locality_dropdown}`}
            id="scrollableSearchBar"
            style={{ height: localityOption?.length > 0 ? "12rem" : "3.3rem" }}
          >
            <div>
              <InfiniteScroll
                scrollableTarget={"scrollableSearchBar"}
                dataLength={localityOption?.length}
                hasMore={pageNo < totalPages}
                next={() => setPageNo((prev) => prev + 1)}
                loader={<p className="fs_14 fw_400">Loading...</p>}
                endMessage={
                  localityOption?.length > 0 ? (
                    localityOption?.length > 2 && (
                      <p
                        className="fs_14 fw_400"
                        style={{ textAlign: "center" }}
                      >
                        <b>You have seen it all</b>
                      </p>
                    )
                  ) : (
                    <p className="fs_14 fw_400 mt-3">
                      <b className="d-flex justify-content-center align-items-center">
                        No Options
                      </b>
                    </p>
                  )
                }
              >
                {localityOption?.filter((loc) => {
                    if (!searchedItems) {
                      return localityOption;
                    } else if (loc?.label?.toLocaleLowerCase().includes(searchedItems.toLocaleLowerCase())) {
                      return loc;
                    } else if (!loc?.label?.toLocaleLowerCase().includes(searchedItems.toLocaleLowerCase())) {
                      console.log("noitems", "noitems");
                      return "";
                    }
                  })?.map((item) => {
                    console.log(item, "itemloc");
                    return (
                      <>
                        <div
                          className={`mx-2 my-3 mb-2 fs_14 px-2 
                        ${selectedLocality?.area_id?.includes(item?.area_id) || selectedLocality?.label?.includes(item?.label)
                            ? `green_bg border_radius_5px py-1 text-white`
                            : ""
                        }`}
                          onClick={() => {
                            setSelectedLocality({
                              area_id: item?.area_id,
                              value: item?.value,
                              label: item?.label,
                              city_name: item?.city_name,
                            });
                            formik?.setFieldValue("g_location.area", item?.label);
                            formik?.setFieldValue("area", item?.label);
                            setSearchedItems("");
                            // handleCnacelAndSelect("add");
                            setShowLocality(false);
                          }}
                        >
                          {item?.label}
                        </div>
                      </>
                    );
                  })}
              </InfiniteScroll>
            </div>
          </div>
        )}
      </div>
    </div>
      }
      
    </div>
  );
};

export default PropertyLocation;

export const cityOptions = [
  { label: "Mysuru", value: "Mysuru" },
  // { label: "Bengaluru", value: "bengaluru" },
];
export const selectStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (base, state) => ({
    ...base,
    color: "#464646",
    fontWeight: "400",
    fontSize: "14px",
    padding: "1.5px",
    // background: "#F4F8FB",
    // match with the menu
    borderRadius: "5px",
    width: "100%",
    // Overwrittes the different states of border
    borderColor: state.isFocused
      ? state.selectProps.error
        ? "red"
        : "#1D72DB"
      : state.selectProps.error
      ? "red"
      : "#DBE9F2",

    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "#1D72DB" : "#F4F8FB",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: "2",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    backgroundColor: state.isSelected ? "#50bf97" : null,
    borderRadius: state.isSelected ? "5px" : null,
    borderBottom: "1px solid #F5F5F5",
    // paddingLeft: state.isSelected ? "10px" : null,
    marginLeft: state.isSelected ? "2%" : null,
    marginRight: state.isSelected ? "2%" : null,
    width: "96%",
    "&:hover": {
      // backgroundColor: state.isFocused ? "#ACBBC5" : "#ACBBC5",
      cursor: "pointer",
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#BCC7CE",
      fontSize: "13px",
      fontWeight: "300",
      opacity: "0.9",
      position: "absolute",
    };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    svg: {
      fill: state.selectProps.iconColor ?? "#50BF97",
    },
  }),

  multiValue: (provided) => ({
    ...provided,
    background: "#EDF4F9",
  }),
};
