import { call, takeEvery, takeLatest } from "redux-saga/effects";
import { editResidentialApartmentApi, editResidentialHouseApi, editResidentialPlotApi, editResidentialVillaApi, multiplePropertiesPhotosApi, newResidentialFindOneApi } from "../../../api/edit-property-api/residential/residential-api";
import { FLAT_APARTMENT_EDIT, HOUSE_EDIT, MULTIPLE_PROPERTY_PHOTOS, PLOT_EDIT, PROPERTY_FIND_ONE, VILLA_EDIT } from "../../../type";
// import { residentialFindOneApi } from "../../../api/edit-property-api/residential/residential-api"

export function* multiplePropertiesPhotosWorker(action) {
  console.log(action, "imagesaga");
  try {
    const file = new FormData();
    file.append("image", action.image);
    const res = yield call(multiplePropertiesPhotosApi, file);
    if (res.status === 200 && res.data.status === "success") {
      const imageLocation = res.data.data.location.split("/");
      yield action.onSuccess({
        res: res,
        imagePath: res.data.data.location,
      });
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    }
  } catch (error) { }
}
export function* residentialFindOneWorker(action) {
  try {
    const res = yield call(newResidentialFindOneApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* editResidentialApartmentWorker(action) {
  try {
    const res = yield call(editResidentialApartmentApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);

    } else if (res.status === 200 && res.data.status === "fail") {

      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {

    yield action.onError("Something Went Wrong");
  }
}
export function* editResidentialVillaWorker(action) {
  try {
    const res = yield call(editResidentialVillaApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);

    } else if (res.status === 200 && res.data.status === "fail") {

      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {

    yield action.onError("Something Went Wrong");
  }
}
export function* editResidentialHouseWorker(action) {
  try {
    const res = yield call(editResidentialHouseApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);

    } else if (res.status === 200 && res.data.status === "fail") {

      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {

    yield action.onError("Something Went Wrong");
  }
}
export function* editResidentialPlotWorker(action) {
  try {
    const res = yield call(editResidentialPlotApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);

    } else if (res.status === 200 && res.data.status === "fail") {

      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {

    yield action.onError("Something Went Wrong");
  }
}
export function* multiplePropertiesPhotoswatch() {
  yield takeEvery(MULTIPLE_PROPERTY_PHOTOS, multiplePropertiesPhotosWorker);
}
export function* residentialPropFindOneWatch() {
  yield takeLatest(PROPERTY_FIND_ONE, residentialFindOneWorker);
}
export function* editResidenApartmenttialWatch() {
  yield takeLatest(FLAT_APARTMENT_EDIT, editResidentialApartmentWorker);
}
export function* editResidenVillaWatch() {
  yield takeLatest(VILLA_EDIT, editResidentialVillaWorker);
}
export function* editResidenHouseWatch() {
  yield takeLatest(HOUSE_EDIT, editResidentialHouseWorker);
}
export function* editResidenPlotWatch() {
  yield takeLatest(PLOT_EDIT, editResidentialPlotWorker);
}