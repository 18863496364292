import React, { useEffect, useState } from "react";
import styles from "../styles/property-details.module.css";
import { useDispatch } from "react-redux";
import { dashboardAction } from "../redux/action/dashboard-action";
import Select from "react-select";
import { nFormatter } from "../components/helper-functions/helperFunctions";

const Dashboard = () => {
  const dropDownData = [
    { label: "Today's", value: "today" },
    { label: "This Week", value: "week" },
    { label: "This Month", value: "month" },
  ];
  const defaultValue = {
    label: "Today's",
    value: "today",
  };
  const dispatch = useDispatch();
  const [date_range, setDate_range] = useState(defaultValue);
  const [dashboardData, setDashboardData] = useState({});
  useEffect(() => {
    dispatch(
      dashboardAction(
        { date_range: date_range?.value },
        onDashboardDataSuccess,
        onDashboardDataError
      )
    );
  }, [date_range]);

  const onDashboardDataSuccess = (data) => {
    console.log(data);
    setDashboardData(data?.data);
  };

  const onDashboardDataError = (data) => {
    console.log(data);
  };
  console.log(dashboardData);

  const postedList = [
    {
      count: dashboardData?.residentialPropertiesPosted,
      color: "#1D72DB",
      title: "Residential Properties Posted",
    },
    {
      count: dashboardData?.commercialPropertiesPosted,
      color: "#50BF97",
      title: "Commercial Properties Posted",
    },
    {
      count: dashboardData?.agriculturalPropertiesPosted,
      color: "#24734E",
      title: "Agriculture Properties Posted",
    },
    {  count: dashboardData?.projectsPosted,color: "#013EA3", title: "Projects Posted" },
    { count: dashboardData?.pgsPosted, color: "#F77223", title: "PG's Posted" },
  ];
  const visitedList = [
    {
      count: dashboardData?.residentialPropertiesVisited,
      color: "#1D72DB",
      title: "Residential Properties Visited",
    },
    {
      count: dashboardData?.commercialPropertiesVisited,
      color: "#50BF97",
      title: "Commercial Properties Visited",
    },
    {
      count: dashboardData?.agriculturalPropertiesVisited,
      color: "#24734E",
      title: "Agriculture Properties Visited",
    },
    {
      count: dashboardData?.projectsVisited,
      color: "#013EA3",
      title: "Projects Visited",
    },
    {
      count: dashboardData?.pgsVisited,
      color: "#F77223",
      title: "PG's Visited",
    },
  ];
  return (
    <div className="font_family_poppins">
      <p className="fw_400 fs_16 text_color_grey ms-5 mt-3 ">
        ADMIN / DASHBOARD
      </p>
      <div
        style={{ minHeight: "70vh" }}
        className={`${styles.border_color_gray} rounded-4 m-4 bg-white p-3 pt-4`}
      >
        <div className="d-flex justify-content-between mx-3 mb-3">
          <h1 className="fs_24">Properties, Projects & PGs Analytics</h1>
          <span className="text-end" style={{ maxWidth: "18%" }}>
            <Select
              value={date_range}
              onChange={(selectedOption) => {
                setDate_range(selectedOption);
              }}
              options={dropDownData}
              styles={customStyles}
              defaultValue={defaultValue}
            />
          </span>
        </div>
        <div className="row g-0 gap-3">
          {postedList?.map((item) => {
            return (
              <div
                className={`border_1px_E5ECFF rounded-3 d-flex justify-content-center align-items-center ${styles.dashboard_cards}`}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h4 className="fs_20 fw_600" style={{ color: item.color }}>
                    {item?.count !== null ? nFormatter(item?.count) : "-"}
                  </h4>
                  <p className="m-0 fs_12" style={{ color: "#87929A" }}>
                    {date_range?.label}
                  </p>
                  <p className="w-75 text-center mt-3 color_323D5A fw_600">
                    {item?.title}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row g-0 gap-3 mt-3">
          {visitedList?.map((item) => {
            return (
              <div
                className={`border_1px_E5ECFF rounded-3 d-flex justify-content-center align-items-center ${styles.dashboard_cards}`}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h4 className="fs_20 fw_600" style={{ color: item.color }}>
                  {item?.count !== null ? nFormatter(item?.count) : "-"}
                  </h4>
                  <p className="m-0 fs_12" style={{ color: "#87929A" }}>
                    {date_range?.label}
                  </p>
                  <p className="w-75 text-center mt-3 color_323D5A fw_600">
                    {item?.title}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
const customStyles = {
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    backgroundColor: "#E5ECFFB2",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Poppins,sans-serif",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#50BF97",
  }),
  singleValue: (base) => ({
    ...base,
    width: "fit-content",
    padding: "0px",
  }),
  menu: (provided) => ({
    ...provided,
    textAlign: "left", // You can change 'left', 'center', or 'right' as needed
  }),
};
