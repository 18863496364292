import { api } from "./api";

export const subscribeUserApi = () => {
  return api.get(`/api/admin/subscription/users-list`);
};
export const subscribeProjectsApi = (params) => {
  console.log(params, "aparams");
  return api.get(`/api/admin/subscription/project-list?user_id=${params?.id}`);
};
export const subscriptionAssignApi = (data) => {
  return api.post("/api/admin/subscription/assign", JSON.stringify(data));
};
