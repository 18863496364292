import React from "react";
import UseFormikInputField from "../form/useFormikInputField";
import UseFormikPriceInputField from "../form/useFormikPriceInputField";

const PgRoomSharing = ({
  formik,
  floorInputName,
  floorInputValue,
  floorInputError,
  floorInputTouched,
  RentInputName,
  RentInputValue,
  RentInputError,
  RentInputTouched,
  DepositeInputName,
  DepositeInputValue,
  DepositeInputError,
  DepositeInputTouched,
  amenitiesList,
  amenitiesListName,
  imageName,
}) => {
  return (
    <div>
      <div className="row mt-3">
        <div className="col-3 mt-1">
          <div style={{maxWidth:"300px"}}>
            <UseFormikInputField
            placeholder="Enter No. of Rooms"
            inputValue={floorInputValue}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={floorInputError}
            formikTouched={floorInputTouched}
            itemName={floorInputName}
            labelName="No. of Single Rooms in PG"
            custClassName="p-2"
            />
          </div>
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter rent price"
            customStyles={{ width: "100%" }}
            inputValue={RentInputValue}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={RentInputError}
            formikTouched={RentInputTouched}
            itemName={RentInputName}
            label="Rent"
            currency="₹"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter security deposit"
            customStyles={{ width: "300px" }}
            inputValue={DepositeInputValue}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={DepositeInputError}
            formikTouched={DepositeInputTouched}
            itemName={DepositeInputName}
            label="Security Deposit"
            currency="₹"
          />
        </div>
      </div>
    </div>
  );
};

export default PgRoomSharing;
