import React from "react";
import styled from "@emotion/styled";
import { ErrorMessage } from "formik";
const ErrorMsg = ({
  name = "",
  containerClass = "mt-1 justify-content-end justify-content-lg-start",
  textClass = "fs_12",
  height = "1rem",
}) => {
  const Container = styled("div")`
    height: ${(props) => props.height};
  `;

  return (
    <Container
      height={height}
      className={`${containerClass}   d-flex align-items-center w-100  text-danger`}
    >
      <ErrorMessage name={name}>
        {(msg) => (
          <>
            <span
              className={`${textClass} ms-0 ms-lg-1  lh-sm fs_12  fw-normal`}
            >
              {msg + "!"}
            </span>
          </>
        )}
      </ErrorMessage>{" "}
    </Container>
  );
};

export default ErrorMsg;
