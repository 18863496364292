import { api } from "../../api";

export const agriculturePropertyViewApi = (data) => {
  return api.get(
    `/api/admin/properties/agriculture-view?property_id=${data?.property_id}&property_type=${data?.property_type}`,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editAgricultureFarmHouseApi = (params) => {
  return api.post("/api/admin/properties/edit-farm-house", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editAgricultureLandApi = (params) => {
  return api.post("/api/admin/properties/edit-farm-house", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};