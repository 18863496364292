import React from "react";
import styles from "../../styles/components/components.module.css";
import { InputContainer } from "../form/inputField";
import DropDownSelect from "../form/dropDown";

const UseFormikPriceInputField = ({
  length = "",
  isLengthDropdown = false,
  currency = "",
  customStyles = {},
  dropDownStyle = {
    container: { width: "80px", marginLeft: "8px" },
    option: { width: "65px" },
    input: { textAlign: "center" },
  },
  itemName = "",
  inputValue = "",
  placeholder = "Enter super area ",
  label = "E-Mail",
  labelClass = "secondary_color fs_15 mb-1",
  id = "",
  type = "text",
  inputClass = `${styles.comp_input} border-0 py-2`,
  errMsgContainerClass = "mt-1 justify-content-end justify-content-lg-start",
  defaultValue = {},
  options = [
    {
      label: "Acre",
      value: "Acre",
    },
    { label: "Gunta", value: "gunta" },
  ],
  formikError,
  formikTouched,
  onChangeFn,
  onBlurFn,
}) => {
  return (
    <InputContainer customStyles={customStyles}>
      {label && (
        <label
          htmlFor={id ? id : itemName}
          className={
            formikError && formikTouched
              ? `red_color ${labelClass} fs_15`
              : ` ${labelClass} secondary_color fs_15`
          }
        >
          {label}
        </label>
      )}

      <div
        className={`d-flex align-items-center bg-white ${
          formikTouched && formikError
            ? styles.comp_input_container__error
            : styles.comp_input__container
        }`}
      >
        {currency && (
          <>
            {" "}
            <span className="me-3 ms-1 fs_14 light_green_color">
              {currency}
            </span>
            <div
              style={{ background: "#DBE9F2", opacity: ".6" }}
              className="vr my-2"
            ></div>
          </>
        )}
        <input
          id={itemName}
          name={itemName}
          value={inputValue}
          type={type}
          onChange={onChangeFn}
          onBlur={onBlurFn}
          placeholder={placeholder}
          className={`w-100   ${inputClass} focus_none  `}
        />
        {(isLengthDropdown || length) && (
          <div
            style={{ background: "#DBE9F2", opacity: ".6" }}
            className="vr my-2"
          ></div>
        )}
        {length && (
          <span className="ms-3 fs_14 light_green_color">{length}</span>
        )}
        {isLengthDropdown && (
          <DropDownSelect
            disabled={true}
            isDropDownIcon={false}
            isInputDisabled={true}
            className="light_green_color fs_14"
            customStyles={dropDownStyle}
            placeholder=""
            options={options}
            defaultValue={
              defaultValue.label
                ? defaultValue
                : { label: "Acre", value: "Acre" }
            }
          />
        )}
      </div>
      <div className={`${styles.error_height} ${errMsgContainerClass}`}>
        {formikError && formikTouched && (
          <span className="red_color fs_12 fw_400">{formikError}</span>
        )}
      </div>
    </InputContainer>
  );
};

export default UseFormikPriceInputField;
