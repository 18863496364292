import { call, takeLatest } from "redux-saga/effects";
import {
  ProjectRequestApi,
  liveProjectListApi,
  uploadProjectDocumentApi,
} from "../../api/project-api/projectRequestApi";
import {
  LIVE_PROJECT_LIST_ACTION,
  PROJECTREQUESTFINDALL,
  UPLOAD_PROJECT_DOCUMENT,
} from "../../type";

export function* projectRequestWorker(action) {
  try {
    const response = yield call(ProjectRequestApi, action.data);
    console.log(response);
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* liveProjectListWorker(action) {
  try {
    const response = yield call(liveProjectListApi, action.data);
    console.log(response);
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* uploadProjectDocumentWorker(action) {
  try {
    const file = new FormData();
    file.append("image", action.document);
    const res = yield call(uploadProjectDocumentApi, file);

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess({
        res: res,
        docPath: res.data.data.location,
      });
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* projectRequestWatcher() {
  yield takeLatest(PROJECTREQUESTFINDALL, projectRequestWorker);
}
export function* liveProjectListWatcher() {
  yield takeLatest(LIVE_PROJECT_LIST_ACTION, liveProjectListWorker);
}
export function* uploadProjectDocumentWatcher() {
  yield takeLatest(UPLOAD_PROJECT_DOCUMENT, uploadProjectDocumentWorker);
}
