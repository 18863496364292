import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "../redux/store";
import Login from "../modules/auth/login";
// import PropertyFindOne from "../modules/propertyFindone";
import Propertyrequest from "../modules/property-request";
import MainLayout from "../components/layout/main-layout";
import Projectrequest from "../modules/project-request";
import ProjectFindone from "../modules/projectFindone";
import PrivateRouter from "./privateRouter";
import ProtectedRouter from "./protectedRouter";
import { ToastContainer } from "react-toastify";
import RequestLayout from "../components/layout/requestLayout";
import SubscriptionAssign from "../modules/subscription-assign/subscriptionAssign";
import LivePropertyList from "../modules/live-property-projects/live-property";
import LiveProjectList from "../modules/live-property-projects/live-projects";
import EditProjectFlatApartment from "../modules/edit-project/residential/flat-apartment";
import EditProjectResidentialPlot from "../modules/edit-project/residential/residential-plot";
import EditProjectResidentialVilla from "../modules/edit-project/residential/residential-villa";
import LiveEditViewPropertiy from "../modules/edit-property/LiveEditViewProperties";
import RequestEditViewProperties from "../modules/edit-property/RequestEditViewProperties";
import LivePgList from "../modules/live-property-projects/live-pg";
import PgRequest from "../modules/pg-request";
import PgEditView from "../modules/edit-pg/edit-pg";
import Dashboard from "../modules/dashboard";
import RequestCallBackData from "../modules/export-data/request-callBack-data";
import PropertyData from "../modules/export-data/property-data";

const Index = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route element={<PrivateRouter />}>
            <Route element={<MainLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/property-project/property-requests"
                element={<Propertyrequest />}
              />
              <Route
                path="/property-project/project-requests"
                element={<Projectrequest />}
              />
              <Route
                path="/property-project/live-property-requests"
                element={<LivePropertyList />}
              />
              <Route
                path="/property-project/live-project"
                element={<LiveProjectList />}
              />
              <Route
                path="/property-project/live-pg"
                element={<LivePgList />}
              />
              <Route
                path="/property-project/pg-requests"
                element={<PgRequest />}
              />
            </Route>
            <Route element={<RequestLayout />}>
              <Route
                path="/property-project/live-property-requests/edit-property/:type/:id"
                element={<LiveEditViewPropertiy />}
              />
              <Route
                path="/property-project/property-requests/edit-property/:type/:id"
                element={<RequestEditViewProperties />}
              />
              {/* <Route path="/property-project/project-requests/edit-project/:type/:id" element={<RequestEditProjects/>}/> */}
              {/* <Route path="/property-project/live-project-requests/edit-project/:type/:id" element={<LiveEditViewProjects/>}/> */}
              <Route
                path="/property-project/:section/edit-project/residential/flat-apartment/:type/:id"
                element={<EditProjectFlatApartment />}
              />
              <Route
                path="/property-project/:section/edit-project/residential/residential-plot/:type/:id"
                element={<EditProjectResidentialPlot />}
              />
              <Route
                path="/property-project/:section/edit-project/residential/residential-villa/:type/:id"
                element={<EditProjectResidentialVilla />}
              />
              <Route
                path="/property-project/pg-requests/edit-pg/:type/:id"
                element={<PgEditView />}
              />
              <Route
                path="/property-project/live-pg/edit-pg/:type/:id"
                element={<PgEditView />}
              />
              <Route path="/projectFindOne/:id" element={<ProjectFindone />} />
              <Route
                path="/property-project/projectFindOne/:id"
                element={<ProjectFindone />}
              />
              <Route
                path="/assign-subscribe"
                element={<SubscriptionAssign />}
              />
              <Route
                path="/export-data/request-callback-data"
                element={<RequestCallBackData />}
              />
              <Route
                path="/export-data/property-data"
                element={<PropertyData />}
              />
            </Route>
          </Route>

          <Route element={<ProtectedRouter />}>
            <Route path="/" element={<Login />} />
          </Route>
        </Routes>
        <ToastContainer limit={2} />
      </Router>
    </Provider>
  );
};

export default Index;
