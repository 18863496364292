import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  amountTypeRegex,
  defaultAddress,
  inputRegex,
  removeUnderScore,
} from "../../../../components/helper/helper";
import LocationAndSearch from "../../../../components/map/locationSearch";
import OfficeAddPropertyPhoto from "../../../../components/edit-property/commercial/office-space/office-add-property-photo";
import ResidentialRentPriceDetails from "../../../../components/edit-property/residential/residential-rent-price-details";
import ResidentialSellPriceDetails from "../../../../components/edit-property/residential/residential-sell-price-details";
import VillaFeatures from "../../../../components/edit-property/residential/villa-features";
import {
  editResidentialVillaAction,
  residentialFindOneAction,
} from "../../../../redux/action/edit-property-actions/residential";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import successToast from "../../../../components/helper-functions/successToast";
import errorToast from "../../../../components/helper-functions/errorToast";
import PropertyBasicDetails from "../../../../components/edit-property/property-basic-details";
import moment from "moment";
import Leftarrowicon from "../../../../assets/icons/left-arrow-icon";
import Primarybutton from "../../../../components/ui/buttons/primary-button";
import Modal_property from "../../../../components/ui/modals/modal_property";
import styles from "../../../../styles/property-details.module.css";
import isEqual from "lodash.isequal";
import UseFormikInputField from "../../../form/useFormikInputField";
import PropertyLocation from "../../../map/property-location";

const EditVilla = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [multiImage, setMultiImage] = useState([]);
  const [propertyDetails, setpropertyDetails] = useState("");
  const [mapLoading, setMapLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [propertyStatus, setPropertyStatus] = useState("");
  const [mainPropertyType, setMainPropertyType] = useState("");
  const [modalButton, setModalButton] = useState("");
  const [compareAminities, setCompareAminities] = useState(true);
  const [approveButton, setApproveButton] = useState(true);
  const [isClickedOnAme, setIsClickedOnAme] = useState(false);
  let propertyId = null;
  if (propertyDetails && propertyDetails?.id) {
    propertyId = (propertyDetails?.id).split("-");
  }

  const [initialValues, setInitialValues] = useState({
    property_id: "",
    post_type: "",
    property_type: "",
    property_details: {
      name: "",
      address: "",
      location: {
        lat: null,
        long: null,
      },
      villa_name: "",
    },
    property_features: {
      bedrooms: null,
      bathrooms: null,
      balconies: null,
      total_floors: null,
      no_of_open_sides: null,
      age_of_construction: null,
      facing: "",
      furnishing_status: "",
      status: "",
      amenities: [],
    },
    price_details: {
      deposite_price: null,
      super_area: null,
      carpet_area: null,
      rent_price: null,
      expected_price: null,
      maintainance_fees: null,
    },
    g_location: {
      address_line_1: "",
      address_line_2: "",
      area: "",
      city: "",
      postCode: null,
      state: "",
      country: "",
      lat: null,
      lng: null,
    },
    property_photos: [],
    description: "",
    terms_and_condition: true,
    lat: null,
    lng: null,
    project_or_society_name: "",
    formetted_address: "",
    searchAddress: "",
  });
  useEffect(() => {
    setLoading(true);
    dispatch(
      residentialFindOneAction(
        { property_id: params?.id, property_type: params?.type },
        onFindSuccess,
        onFindError
      )
    );
  }, [reload]);
  const onFindSuccess = (data) => {
    setLoading(false);
    const propertyDetailsData = data?.data;
    setpropertyDetails(propertyDetailsData);
    setInitialValues({
      property_id: propertyDetailsData?.id,
      post_type: propertyDetailsData?.post_type,
      property_type: propertyDetailsData?.property_type,
      property_details: {
        name: propertyDetailsData?.property_details?.name,
        address: propertyDetailsData?.property_details?.address
          ? propertyDetailsData?.property_details?.address
          : defaultAddress?.address_line_2,
        location: {
          lat: propertyDetailsData?.property_details?.location?.lat
            ? propertyDetailsData?.property_details?.location?.lat
            : defaultAddress?.lat,
          long: propertyDetailsData?.property_details?.location?.long
            ? propertyDetailsData?.property_details?.location?.long
            : defaultAddress?.lng,
        },
        villa_name: propertyDetailsData?.property_details?.villa_name,
      },
      property_features: {
        bedrooms:
          propertyDetailsData?.property_features?.bedrooms > 0
            ? propertyDetailsData?.property_features?.bedrooms
            : "",
        bathrooms:
          propertyDetailsData?.property_features?.bathrooms > 0
            ? propertyDetailsData?.property_features?.bathrooms
            : "",
        balconies:
          propertyDetailsData?.property_features?.balconies > 0
            ? propertyDetailsData?.property_features?.balconies
            : "",
        total_floors:
          propertyDetailsData?.property_features?.total_floors > 0
            ? propertyDetailsData?.property_features?.total_floors
            : "",
        no_of_open_sides:
          propertyDetailsData?.property_features?.no_of_open_sides > 0
            ? propertyDetailsData?.property_features?.no_of_open_sides
            : "",
        age_of_construction:
          propertyDetailsData?.property_features?.age_of_construction > 0
            ? propertyDetailsData?.property_features?.age_of_construction
            : "",
        facing: propertyDetailsData?.property_features?.facing,
        furnishing_status:
          propertyDetailsData?.property_features?.furnishing_status,
        status: propertyDetailsData?.property_features?.status,
        amenities: propertyDetailsData?.property_features?.amenities || [],
      },
      price_details: {
        deposite_price:
          propertyDetailsData?.price_details?.deposite_price > 0
            ? propertyDetailsData?.price_details?.deposite_price
            : "",
        super_area:
          propertyDetailsData?.price_details?.super_area > 0
            ? propertyDetailsData?.price_details?.super_area
            : "",
        carpet_area:
          propertyDetailsData?.price_details?.carpet_area > 0
            ? propertyDetailsData?.price_details?.carpet_area
            : "",
        rent_price:
          propertyDetailsData?.price_details?.rent_price > 0
            ? propertyDetailsData?.price_details?.rent_price
            : "",
        expected_price:
          propertyDetailsData?.price_details?.expected_price > 0
            ? propertyDetailsData?.price_details?.expected_price
            : "",
        maintainance_fees:
          propertyDetailsData?.price_details?.maintainance_fees > 0
            ? propertyDetailsData?.price_details?.maintainance_fees
            : "",
      },
      g_location: {
        address_line_1: propertyDetailsData?.g_location?.address_line_1
          ? propertyDetailsData?.g_location?.address_line_1
          : defaultAddress?.address_line_1,
        address_line_2: propertyDetailsData?.g_location?.address_line_1
          ? propertyDetailsData?.g_location?.address_line_1
          : defaultAddress?.address_line_2,
        area: propertyDetailsData?.g_location?.area
          ? propertyDetailsData?.g_location?.area
          : defaultAddress?.area,
        city: propertyDetailsData?.g_location?.city
          ? propertyDetailsData?.g_location?.city
          : defaultAddress?.city,
        postCode: propertyDetailsData?.g_location?.postCode
          ? propertyDetailsData?.g_location?.postCode
          : defaultAddress?.postCode,
        state: propertyDetailsData?.g_location?.state
          ? propertyDetailsData?.g_location?.state
          : defaultAddress?.state,
        country: propertyDetailsData?.g_location?.country
          ? propertyDetailsData?.g_location?.country
          : defaultAddress?.country,
        lat: propertyDetailsData?.g_location?.lat
          ? propertyDetailsData?.g_location?.lat
          : defaultAddress?.lat,
        lng: propertyDetailsData?.g_location?.lng
          ? propertyDetailsData?.g_location?.lng
          : defaultAddress?.lng,
      },
      property_photos: propertyDetailsData?.property_photos,
      description: propertyDetailsData?.description,
      terms_and_condition: true,
      lat: propertyDetailsData?.g_location?.lat
        ? parseFloat(propertyDetailsData?.g_location?.lat)
        : defaultAddress?.lat,
      lng: propertyDetailsData?.g_location?.lng
        ? parseFloat(propertyDetailsData?.g_location?.lng)
        : defaultAddress?.lng,
      project_or_society_name:
        propertyDetailsData?.property_details?.villa_name,
      formetted_address: propertyDetailsData?.formetted_address ?? "",
      searchAddress: propertyDetailsData?.formetted_address ?? "",
    });
    setMultiImage(data?.data?.property_photos);
    setMainPropertyType(data?.data?.main_property_type);
  };
  const onFindError = (data) => {
    setLoading(false);
  };

  const propertyBasicDetails = [
    {
      label: "Property ID",
      value: propertyId && propertyId[0].toUpperCase(),
      display: true,
    },
    {
      label: "Property Type",
      value: propertyDetails?.property_type === "villa" && "Villa",
      display: true,
    },
    {
      label: "Property Price",
      value:
        propertyDetails?.post_type === "sale"
          ? "₹ " + propertyDetails?.price_details?.expected_price
          : "₹ " + propertyDetails?.price_details?.rent_price,
      display: true,
    },
    {
      label: "Subscription Plan",
      value: "",
      display: propertyDetails?.subscription ? true : false,
    },
    {
      label: "Validity",
      value: "",
      display: propertyDetails?.subscription ? true : false,
    },
    {
      label: "Posted By",
      value:
        propertyDetails?.name +
        ` (${removeUnderScore(propertyDetails?.owner_type)})`,
      display: true,
    },
    {
      label: "Posted Date & Time",
      value: moment(propertyDetails?.createdAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
    {
      label: "Updated Date & Time",
      value: moment(propertyDetails?.updatedAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      property_features: Yup.object({
        total_floors: Yup.string().matches(inputRegex, "Enter a valid number"),
        floor_number: Yup.string().matches(inputRegex, "Enter a valid number"),
        bathrooms: Yup.string().matches(inputRegex, "Enter a valid number"),
        bedrooms: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
      }),
      price_details: Yup.object({
        built_up_area: Yup.string().matches(inputRegex, "Enter a valid number"),
        carpet_area: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        expected_price: Yup.string()
          .matches(amountTypeRegex, "Enter a valid number")
          .test("postTypeSale", "Required", function (value) {
            if (propertyDetails?.post_type === "sale") {
              return value;
            } else {
              return true;
            }
          }),
        rent_price: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .test("postTypeRent", "Required", function (value) {
            if (propertyDetails?.post_type === "rent") {
              return value;
            } else {
              return true;
            }
          }),
        maintainance_fees: Yup.string().matches(
          inputRegex,
          "Enter a valid number"
        ),
      }),
      // searchAddress: Yup.string().required("Required"),
      g_location: Yup.object({
        city: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
      }),
    }),
    onSubmit: (values) => {
      console.log(values, "aksjbdsjada");
      const updatedSuitableFor = values.property_features.amenities.map(
        (item) => ({ name: item.name, status: item.status })
      );
      dispatch(
        editResidentialVillaAction(
          {
            ...values,
            price_details: {
              deposite_price: parseInt(values?.price_details?.deposite_price),
              super_area: parseInt(values?.price_details?.super_area),
              carpet_area: parseInt(values?.price_details?.carpet_area),
              expected_price: parseInt(values?.price_details?.expected_price),
              maintainance_fees: parseInt(
                values?.price_details?.maintainance_fees
              ),
            },
            property_features: {
              ...values?.property_features,
              bedrooms: parseInt(values?.property_features?.bedrooms),
              bathrooms: parseInt(values?.property_features?.bathrooms),
              balconies: parseInt(values?.property_features?.balconies),
              total_floors: parseInt(values?.property_features?.total_floors),
              no_of_open_sides: parseInt(
                values?.property_features?.no_of_open_sides
              ),
              age_of_construction: parseInt(
                values?.property_features?.age_of_construction
              ),
              // amenities: updatedSuitableFor,
            },
            property_photos: [...multiImage],
          },
          onEditSuccess,
          onEditError
        )
      );
      setApproveButton(true);
      setIsClickedOnAme(true);
    },
  });
  const onEditSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
    console.log(data, "editError");
  };

  useEffect(() => {
    if (initialValues?.lat) {
      setMapLoading(true);
    }
  }, [initialValues?.lat]);
  const convertValuesToIntegers = (values) => {
    const convertedValues = {};

    for (const key in values) {
      convertedValues[key] = parseInt(values[key], 10);
    }

    return convertedValues;
  };

  const initValuesWithoutAmenitiesFea = { ...initialValues?.property_features };
  delete initValuesWithoutAmenitiesFea?.amenities;

  const formikValuesWithoutAmenitiesFea = {
    ...formik?.values?.property_features,
  };
  delete formikValuesWithoutAmenitiesFea?.amenities;
  function valuesCompareFn(values) {const propertyDetails = isEqual(initialValues?.property_details?.villa_name ?? "", formik?.values?.property_details?.villa_name ?? "");
    const propertyFeature = isEqual(initValuesWithoutAmenitiesFea, formikValuesWithoutAmenitiesFea);
    const priceDetails = isEqual(convertValuesToIntegers(formik?.initialValues?.price_details), convertValuesToIntegers(values?.price_details));
    const propertyPhotos = isEqual(initialValues?.property_photos, multiImage);
    const lat = isEqual(initialValues?.g_location?.lat,  values?.g_location?.lat);
    const gLocation = isEqual(initialValues?.g_location,values?.g_location);
    const description = isEqual( initialValues?.description, values?.description);
    console.log(
      priceDetails,
      propertyFeature,
      propertyDetails,
      propertyPhotos,
      "compareplot"
    );
    if (propertyFeature && priceDetails && propertyDetails && propertyPhotos && lat && gLocation && compareAminities && description) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div className="font_family_poppins" style={{ overflow: "hidden" }}>
      <p className="fw_400 fs_16 text_color_grey ms-5 mt-3 ">
        ADMIN / PROPERTIES / LIVE PROPERTIES / - VIEW
      </p>
      {loading ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className={` ${styles.border_color_gray} rounded-4 m-4`}>
            <Modal_property
              show={show}
              mainPropertyType={mainPropertyType}
              handleclose={() => setShow(false)}
              background={modalButton === "Approve" ? "green" : "red"}
              btnText={modalButton === "Approve" ? "approve" : "reject"}
              propertyStatus={propertyStatus}
              navigateUrl={
                location.pathname.includes("live-property-requests")
                  ? "/property-project/live-property-requests"
                  : "/property-project/property-requests"
              }
            />

            <div className={`d-flex justify-content-between my-2 px-2 `}>
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <Leftarrowicon />
                <Primarybutton
                  btnText={"BACK"}
                  className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
                />
              </div>
              <div>
                {!valuesCompareFn(formik?.values) ? (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // disabled={valuesCompareFn(formik?.values)}
                  >
                    submit
                  </button>
                ) : location.pathname.includes("live-property-requests")
                ? null : (
             // : location.pathname.includes("live-property-requests") &&
             //   propertyDetails.status === "approved" ? null : (
                  <>
                    <Primarybutton
                      btnText={"APPROVE"}
                      type="button"
                      onClick={() => {
                        setModalButton("Approve");
                        setShow(true);
                        setPropertyStatus("approved");
                      }}
                      className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
                    />
                    <Primarybutton
                      btnText={"REJECT"}
                      type="button"
                      onClick={() => {
                        setModalButton("Reject");
                        setShow(true);
                        setPropertyStatus("rejected");
                      }}
                      className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="border-bottom"></div>
            <div className="m-4">
              <div className="col-5">
                {/* {mapLoading ? (
                  <LocationAndSearch formik={formik} />
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center col-7 w-100"
                    style={{ height: "20rem" }}
                  >
                    <Spinner />
                  </div>
                )} */}
                {formik?.values && <PropertyLocation formik={formik} />}
                <div className="mt-3">
                  <UseFormikInputField
                    placeholder="Enter Project/Society Name (optional)"
                    inputValue={formik?.values?.property_details?.villa_name}
                    onChangeFn={formik.handleChange}
                    onBlurFn={formik.handleBlur}
                    itemName="property_details.villa_name"
                    labelName="Project/Society Name"
                  />
                </div>
              </div>
              <VillaFeatures
                formik={formik}
                setCompareAminities={setCompareAminities}
                propertyDetails={propertyDetails}
                setApproveButton={setApproveButton}
                setIsClickedOnAme={setIsClickedOnAme}
                isClickedOnAme={isClickedOnAme}
              />
              {propertyDetails?.post_type === "sale" ? (
                <ResidentialSellPriceDetails
                  formik={formik}
                  subPropertyType={"villa"}
                />
              ) : (
                <ResidentialRentPriceDetails
                  formik={formik}
                  subPropertyType={"villa"}
                />
              )}
              <OfficeAddPropertyPhoto
                multiImage={multiImage}
                setMultiImage={setMultiImage}
              />

              <div className="discription">
                <div className={`mt-5 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>Description</h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                </div>
                <UseFormikInputField
                  label_font_size=""
                  labelName=""
                  itemName={"description"}
                  inputValue={formik.values.description}
                  onChangeFn={formik.handleChange}
                  onBlurFn={formik.handleBlur}
                  formikError={formik.errors.description}
                  formikTouched={formik.touched.description}
                  placeholder="Describe your project"
                  input={false}
                  TextArea={true}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditVilla;
