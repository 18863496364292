import { call, takeLatest } from "redux-saga/effects";
import { LOGIN, LOGOUT } from "../../type";
import { loginApi } from "../../api/auth-api/authApi";


// LOGIN SAGA
export function* loginWorker(action) {
  try {
    const res = yield call(loginApi, action.data);
    if (res.status === 200 && res?.data?.status === "success") {
      yield action.onSuccess(res.data);
      localStorage.setItem("accessToken", res.data.data.access_token);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {
    console.log(error);
    yield action.onError(error.response);
  }
}
export function* loginWatcher() {
  yield takeLatest(LOGIN, loginWorker);
}


// LOGOUT SAGA
export function* logoutWorker(action) {
  try {
    yield localStorage.clear();
    yield window.location.reload();
  } catch (error) {
    yield action.onError(error.response);
    console.log(error);
  }
}
export function* logoutWatcher() {
  yield takeLatest(LOGOUT, logoutWorker);
}
