import { call, takeLatest , takeEvery} from "redux-saga/effects";
import {
  loactionSearchApi,
  propertyDataApi,
  requestCallBackDataApi,
  searchAutocompletePropertyApi,
} from "../api/exportApi";
import { LOACTION_SEARCH, PROPERTY_DATA_EXPORT, REQUEST_CALLBACK_EXPORT, SEARCH_AUTOCOMPLETE } from "../type";

export function* requestCallBackDataWorker(action) {
  console.log(action, "aaaa");
  try {
    const response = yield call(requestCallBackDataApi, action.data);
    console.log(response, "aaaa");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* propertyDataWorker(action) {
  console.log(action, "aaaa");
  try {
    const response = yield call(propertyDataApi, action.data);
    console.log(response, "aaaa");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* loactionSearchWorker(action) {
  console.log(action, "aaaa");
  try {
    const response = yield call(loactionSearchApi, action.data, action.data1);
    console.log(response, "aaaa");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* searchAutocompleteWorker(action) {
  try {
    const res = yield call(
      searchAutocompletePropertyApi,
      action.page_no,
      action?.data
    );

    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res.data);
    } else {
      yield action.onError("Something Went Wrong!");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong!!");
  }
}

export function* requestCallBackDataWatcher() {
  yield takeLatest(REQUEST_CALLBACK_EXPORT, requestCallBackDataWorker);
}
export function* propertyDataWatcher() {
  yield takeLatest(PROPERTY_DATA_EXPORT, propertyDataWorker);
}
export function* loactionSearchWatcher() {
  yield takeLatest(LOACTION_SEARCH, loactionSearchWorker);
}

export function* watchSearchAutocompleteWorker() {
  yield takeEvery(SEARCH_AUTOCOMPLETE, searchAutocompleteWorker);
}
