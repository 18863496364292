import Spinner from "react-bootstrap/Spinner";

function LoadingSpinner() {
  return (
    <div className="" style={{ position: "absolute", left: "50%", top: "50%" }}>
      <Spinner animation="border" role="status">
      </Spinner>
    </div>
  );
}

export default LoadingSpinner;
