import { api } from "../../api";

export const multiplePropertiesPhotosApi = (params) => {
  console.log(params);
  return api.post(`/api/property/images/upload-property-image`, params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": false,
    },
  });
};

export const newResidentialFindOneApi = (data) => {
  return api.get(`/api/admin/properties/residential-view?property_id=${data?.property_id}&property_type=${data?.property_type}`,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    });
};

export const editResidentialApartmentApi = (params) => {
  return api.post("/api/admin/properties/edit-appartment", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editResidentialVillaApi = (params) => {
  return api.post("/api/admin/properties/edit-villa", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editResidentialHouseApi = (params) => {
  return api.post("/api/admin/properties/edit-residential", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};
export const editResidentialPlotApi = (params) => {
  return api.post("/api/admin/properties/edit-plot", params,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};