import React from 'react'

const PropertyLabel = ({descText}) => {
  return (
    <>
          <div className="d-flex mb-3 px-3 mt-4 align-items-center">
            <h2 className="fw_500 fs_16 text_color_blue mt-1">
            {descText}
            </h2>
            <hr style={{ flexGrow: 1, marginLeft: 10 }} />
          </div>
    </>
  )
}

export default PropertyLabel