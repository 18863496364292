import React from "react";
import RightArrow from "../../assets/icons/right-arrow";
import LeftArrow from "../../assets/icons/left-arrow";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

function TablePagination({ pagination, setCurrentPage }) {
  const [_, setSearchParams] = useSearchParams();
  const handlePageChange = (value) => {
    if (value === "next") {
      setSearchParams((prevParams) => {
        return {
          ...Object.fromEntries(prevParams),
          page: pagination?.currentPage + 1,
        };
      });
      setCurrentPage && setCurrentPage(pagination?.currentPage + 1);
    } else if (value === "prev") {
      setSearchParams((prevParams) => {
        return {
          ...Object.fromEntries(prevParams),
          page: pagination?.currentPage - 1,
        };
      });
      setCurrentPage && setCurrentPage(pagination?.currentPage - 1);
    } else {
      setSearchParams((prevParams) => {
        return {
          ...Object.fromEntries(prevParams),
          page: value,
        };
      });
      setCurrentPage && setCurrentPage(value);
    }
  };
  const getPageNumbers = () => {
    if (pagination?.totalPages <= 5) {
      return Array.from(
        { length: pagination?.totalPages },
        (_, index) => index + 1
      );
    }
    if (pagination?.currentPage <= 2) {
      return [1, 2, 3, 4, 5];
    }
    if (pagination?.currentPage >= pagination?.totalPages - 1) {
      return [
        pagination?.totalPages - 4,
        pagination?.totalPages - 3,
        pagination?.totalPages - 2,
        pagination?.totalPages - 1,
        pagination?.totalPages,
      ];
    }
    const startPage = pagination?.currentPage - 2;
    const endPage = pagination?.currentPage + 2;
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };
  const pageNumbers = getPageNumbers();
  console.log(pagination, "aaaaaa");
  return (
    <>
      {pagination?.totalPages > 1 && (
        <div className="d-flex align-items-center justify-content-end gap-2 mt-3">
          <span className="color_707070 fw_500 fs_16">
            {pagination?.currentPage}/{pagination?.totalPages}
          </span>
          <div className="me-3" style={{ width: "28px" }}>
            <button
              disabled={pagination?.currentPage > 1 ? false : true}
              onClick={() => {
                handlePageChange("prev");
              }}
              className="border-0 bg-transparent "
            >
              <LeftArrow />
            </button>
          </div>
          {pageNumbers.map((pageNumber) => (
            <button
              onClick={() => {
                handlePageChange(pageNumber);
              }}
              key={pageNumber}
              className={`border-0  ${
                pagination?.currentPage === pageNumber
                  ? "pagination_background_color pagination_text_color"
                  : "bg-transparent color_030304"
              }   fw_600 fs_18 px-3 rounded-2 py-1`}
            >
              {pageNumber ?? pageNumber}
            </button>
          ))}

          <div>
            <button
              disabled={
                pagination?.currentPage < pagination?.totalPages ? false : true
              }
              onClick={() => {
                handlePageChange("next");
              }}
              className="border-0 bg-transparent "
            >
              <RightArrow />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default TablePagination;
