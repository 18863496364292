import React, { useState } from "react";
import styles from "../../../styles/edit-property/edit-property.module.css";
import UseFormikInputField from "../../form/useFormikInputField";
import UseFormikPriceInputField from "../../form/useFormikPriceInputField";
import UseFormikSelectField from "../../form/useFormikSelectField";
import { removeUnderScore } from "../../helper/helper";

const ResidentialHouseFeatures = ({ formik, setCompareAminities, propertyDetails, isClickedOnAme, setIsClickedOnAme }) => {

  const initialAminities = propertyDetails?.property_features?.amenities;
  const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);
  // const handleCheckboxChange = (index) => {
  //   const updatedSuitableFor = [...suitableFor];
  //   updatedSuitableFor[index].status = !updatedSuitableFor[index].status;
  //   setSuitableFor(updatedSuitableFor);
  //   formik.setFieldValue("property_features.aminities", updatedSuitableFor);
  // };

  const aminitiesAreEqual = (currentAminities, initialAminities)=>{
    const isAmentiesMatches = currentAminities?.every((currentItem, index) => initialAminities[index]?.status === currentItem?.status);
    if(isAmentiesMatches){
      setCompareAminities(true);
    }
    else{
      setCompareAminities(false);
    }
  }
  
   const aminitiesList = [
    {
      name: "private_parking_space",
      label: "Private Parking Space",
      status: false,
    },
    {
      name: "guest_parking_space",
      label: "Guest Parking Spaces",
      status: false,
    },
    { name: "garden_area", label: "Garden Area", status: false },
    { name: "play_ground", label: "Play Grounds", status: false },
    { name: "pet_room", label: "Pet Room", status: false },
    {
      name: "lounge_area",
      label: "Lounge Area",
      status: false,
    },
    {
      name: "kids_play_area",
      label: "Kids Play Area",
      status: false,
    },
    {
      name: "hardwood_floors",
      label: "Hardwood Floors",
      status: false,
    },
    {
      name: "barbecue_areas",
      label: "Barbecue Areas",
      status: false,
    },
  ];
  const updatedAminitiesList = aminitiesList?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {return backendAminity.name === aminity.name});
    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
    return aminity;
  });

  const [amenities, setAmenities] = useState(updatedAminitiesList);
  const handleCheckboxChange = (index) => {
    const newUpdatedAminitiesList = [...amenities];
  
    newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
    const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
    setAmenities(newUpdatedAminitiesList);
    formik.setFieldValue("property_features.amenities", filteredAmenities);
  };
  if(isClickedOnAme){
    aminitiesAreEqual(amenities, formik?.initialValues?.property_features?.amenities);
  }
  
  return (
    <>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Property Features</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.total_floors}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.total_floors}
            formikTouched={formik?.touched?.property_features?.total_floors}
            itemName="property_features.total_floors"
            labelName="Total Floors"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.bedrooms}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.bedrooms}
            formikTouched={formik?.touched?.property_features?.bedrooms}
            itemName="property_features.bedrooms"
            labelName="Bedroom"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.bathrooms}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.bathrooms}
            formikTouched={formik?.touched?.property_features?.bathrooms}
            itemName="property_features.bathrooms"
            labelName="Bathroom"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.balconies}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.balconies}
            formikTouched={formik?.touched?.property_features?.balconies}
            itemName="property_features.balconies"
            labelName="Balconies"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.no_of_open_sides}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.no_of_open_sides}
            formikTouched={formik?.touched?.property_features?.no_of_open_sides}
            itemName="property_features.no_of_open_sides"
            labelName="No of Open Sides"
          />
        </div>
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={
              formik?.values?.property_features?.floors_allowed_for_construction
            }
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={
              formik?.errors?.property_features?.floors_allowed_for_construction
            }
            formikTouched={
              formik?.touched?.property_features
                ?.floors_allowed_for_construction
            }
            itemName="property_features.floors_allowed_for_construction"
            labelName="Floors Allowed for Construction"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.age_of_construction}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.age_of_construction}
            formikTouched={
              formik?.touched?.property_features?.age_of_construction
            }
            itemName="property_features.age_of_construction"
            labelName="Age Of Construction"
          />
        </div>
        <UseFormikSelectField
          label="Facing"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.facing"
          formik={formik}
          formikError={formik?.errors?.facing}
          formikTouched={formik?.touched?.facing}
          defaultValue={
            formik?.values?.property_features?.facing && {
              label: removeUnderScore(
                formik?.values?.property_features?.facing
              ),
              value: formik?.values?.property_features?.facing,
            }
          }
        />
        <UseFormikSelectField
          label="Furnishing Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.furnishing_status"
          formik={formik}
          formikError={formik?.errors?.furnishing_status}
          formikTouched={formik?.touched?.furnishing_status}
          defaultValue={
            formik?.values?.property_features?.furnishing_status && {
              label: removeUnderScore(
                formik?.values?.property_features?.furnishing_status
              ),
              value: formik?.values?.property_features?.furnishing_status,
            }
          }
        />
        <UseFormikSelectField
          label="Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.status"
          formik={formik}
          formikError={formik?.errors?.status}
          formikTouched={formik?.touched?.status}
          defaultValue={
            formik?.values?.property_features?.status && {
              label: removeUnderScore(
                formik?.values?.property_features?.status
              ),
              value: formik?.values?.property_features?.status,
            }
          }
        />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center mt-3">
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Property Amenities</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        {/* <div className="row g-0">
          {suitableFor?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => handleCheckboxChange(index)}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.name ? removeUnderScore(item?.name) : "-"}
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}
        <div className="row g-0">
          {amenities?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </>
  );
};

export default ResidentialHouseFeatures;
