import React from "react";
// import UseFormikInputField from "../../../form/useFormikInputField";
import UseFormikPriceInputField from "../../../form/useFormikPriceInputField";

const OfficeSpaceSellPriceDetails = ({
  subPropertyType = "industrial-building",
  formik,
}) => {
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Price Details</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row">
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter super area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.super_area}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.super_area}
            formikTouched={formik?.touched?.price_details?.super_area}
            itemName="price_details.super_area"
            label="Super Area"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter carpet area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.carpet_area}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.carpet_area}
            formikTouched={formik?.touched?.price_details?.carpet_area}
            itemName="price_details.carpet_area"
            label="Carpet Area"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter expected price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.expected_price}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.expected_price}
            formikTouched={formik?.touched?.price_details?.expected_price}
            itemName="price_details.expected_price"
            label="Expected Price"
            currency="₹"
          />
        </div>
        {(subPropertyType !== "wearhouse" && subPropertyType !== "industrial-building" && subPropertyType !== "industrial-shed")  && 
        <div className="col-3">
        <UseFormikPriceInputField
          placeholder="Enter maintenance fees"
          customStyles={{ width: "100%" }}
          inputValue={formik?.values?.price_details?.maintainance_fees}
          onChangeFn={formik.handleChange}
          onBlurFn={formik.handleBlur}
          formikError={formik?.errors?.price_details?.maintainance_fees}
          formikTouched={formik?.touched?.price_details?.maintainance_fees}
          itemName="price_details.maintainance_fees"
          label="Maintenance Fees"
          currency="₹"
        />
      </div>
        }
          
      </div>
    </div>
  );
};

export default OfficeSpaceSellPriceDetails;
