import { all } from "redux-saga/effects";
import {
  liveProjectListWatcher,
  projectRequestWatcher,
  uploadProjectDocumentWatcher,
} from "../../project-saga/project-request-saga";

export default function* projectRequestRootSaga() {
  yield all([
    projectRequestWatcher(),
    liveProjectListWatcher(),
    uploadProjectDocumentWatcher(),
  ]);
}
