import React from "react";
import UseFormikPriceInputField from "../../form/useFormikPriceInputField";

const PlotRentPriceDetails = ({ formik }) => {
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Price Details</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row">
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot length"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_length}
            formikError={formik?.errors?.price_details?.plot_length}
            formikTouched={formik?.touched?.price_details?.plot_length}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            itemName="price_details.plot_length"
            label="Plot Length"
            length="Ft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot width"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_width}
            formikError={formik?.errors?.price_details?.plot_width}
            formikTouched={formik?.touched?.price_details?.plot_width}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            itemName="price_details.plot_width"
            label="Plot Width"
            length="Ft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_area}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.plot_area}
            formikTouched={formik?.touched?.price_details?.plot_area}
            itemName="price_details.plot_area"
            label="Plot Area"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter deposite price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.deposite_price}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.deposite_price}
            formikTouched={formik?.touched?.price_details?.deposite_price}
            itemName="price_details.deposite_price"
            label="Deposit Price"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter rent price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.rent_price}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.rent_price}
            formikTouched={formik?.touched?.price_details?.rent_price}
            itemName="price_details.rent_price"
            label="Rent Price"
            length="Sqft"
          />
        </div>
      </div>
    </div>
  );
};

export default PlotRentPriceDetails;
