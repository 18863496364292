import React from "react";

const LogoutExit = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1217 7.87954L15.2124 4.97029C14.9145 4.68254 14.4397 4.6908 14.1519 4.98875C13.8712 5.27938 13.8712 5.74018 14.1519 6.03081L17.0612 8.94006C17.1476 9.0282 17.2232 9.12643 17.2862 9.23256C17.2749 9.23256 17.2659 9.22655 17.2547 9.22655L5.49169 9.25056C5.07748 9.25056 4.7417 9.58634 4.7417 10.0006C4.7417 10.4148 5.07748 10.7505 5.49169 10.7505L17.2502 10.7265C17.2712 10.7265 17.2884 10.716 17.3087 10.7145C17.2422 10.8414 17.1578 10.9581 17.0582 11.061L14.1489 13.9703C13.851 14.258 13.8427 14.7328 14.1305 15.0308C14.4182 15.3287 14.893 15.337 15.191 15.0492C15.1972 15.0432 15.2034 15.037 15.2094 15.0308L18.1187 12.1215C19.2898 10.95 19.2898 9.05102 18.1187 7.87954H18.1217Z"
        fill="#323D5A"
      />
      <path
        d="M6.24999 17.5H4.74998C3.50734 17.5 2.49998 16.4926 2.49998 15.25V4.75001C2.49998 3.50738 3.50734 2.50001 4.74998 2.50001H6.24999C6.6642 2.50001 6.99998 2.16423 6.99998 1.75002C6.99998 1.33581 6.66423 1 6.24999 1H4.74998C2.67998 1.0025 1.0025 2.67998 1 4.75001V15.25C1.0025 17.32 2.67998 18.9975 4.75001 19H6.25002C6.66424 19 7.00001 18.6642 7.00001 18.25C7.00001 17.8358 6.66423 17.5 6.24999 17.5Z"
        fill="#323D5A"
      />
    </svg>
  );
};

export default LogoutExit;
