import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import EyeHideIcon from "../../assets/icons/Eye-hide-icon";
import EyeShowIcon from "../../assets/icons/Eye-show-icon";
import LoginImg from "../../assets/images/login_img.png";
import CancelButton from "../../components/ui/buttons/cancelButton";
// import EyeGreyIcon from "../../assets/icons/Eye-grey-icon";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import successToast from "../../components/helper-functions/successToast";
import errorToast from "../../components/helper-functions/errorToast";

import Navbar from "../../components/layout/navbar";
import { loginActions } from "../../redux/action/auth-actions/authAction";
const Login = () => {
  const [Show, setShow] = useState(false);
  const [password, setpassword] = useState("password");
  const [iconShow, setIconShow] = useState(true);
  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    Email: "",
    password: "",
  };
  const onSubmit = (values) => {
    dispatch(
      loginActions(
        {
          email: values?.Email,
          password: values?.password,
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = (data) => {
    navigate("/dashboard");
    successToast(data?.message);
    console.log(data);
  };
  const onError = (data) => {
    errorToast(data?.message);
    console.log(data);
  };

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      setIconShow(false);
      setShow(true);
    } else {
      setpassword("password");
      setIconShow(true);
      setShow(false);
    }
  };
  const validationSchema = yup.object({
    Email: yup.string().trim().required("Enter Email"),
    password: yup.string().trim().required("Enter password"),
  });
  console.log(iconShow, "aaaaaaaaaw");
  return (
    <>
      <Navbar />
      <div className="">
        <div className="row gx-0 ">
          <div className="col-lg-6 col-12 d-lg-block d-none ">
            <img src={LoginImg} alt="" className="vh-100" />
          </div>

          <div className="col-lg-6 col-12 vh-100 d-flex justify-content-center align-items-center">
            <div className="card border-0 rounded-4">
              <p className="fw_400 fs_28  ps-3 m-0  text_color_blue">
                Welcome to Zameen Square!
              </p>
              <p className="ps-3 fs_14 text_color_darkblue">
                Please enter your email and password below to access
                <br /> your account.
              </p>
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ values, errors }) => {
                    console.log(values);
                    return (
                      <Form>
                        <label
                          className={`text-start ps-1 fw_400 mb-1 fs_14 text_color_grey ${
                            values.Email.length === 0
                              ? " text_color_darkblue"
                              : "text_color_grey"
                          }`}
                        >
                          Email Address
                        </label>
                        <Field
                          type="email"
                          name="Email"
                          style={{ outline: "none" }}
                          placeholder="Enter your email address"
                          className={`w-100 fw_400 py-2 ps-3 rounded-2 fs_14 ${
                            !errors.Email
                              ? "border_color_grey text_color_darkblue fs_15"
                              : "border_color_red text_color_grey"
                          }`}
                        />
                        <div
                          className="text-danger fs_12 fw_600 ps-2 "
                          style={{ minHeight: "18px" }}
                        >
                          <ErrorMessage name="Email" />
                        </div>
                        <div className="mb-3">
                          <label
                            className={`fw_400 lh-lg ps-1 mt-4 fs_14 text_color_grey ${
                              values.password.length === 0
                                ? " text_color_darkblue"
                                : "text_color_grey"
                            }`}
                          >
                            Password
                          </label>
                          <div className="position-relative">
                            <Field
                              type={Show ? "text" : "password"}
                              name="password"
                              style={{ outline: "none" }}
                              placeholder="Enter your password"
                              className={`w-100  py-2 ps-3 rounded-2   fs_14 ${
                                !errors.password
                                  ? "border_color_grey text_color_darkblue fs_15"
                                  : "  border_color_red text_color_grey"
                              }`}
                            />

                            <span
                              onClick={Eye}
                              className={`position-absolute `}
                              style={{ right: "15px", top: "7px" }}
                            >
                              {iconShow ? (
                                <EyeShowIcon
                                  fill={`${
                                    values.password.length === 0
                                      ? `#ACBBC5`
                                      : `#50BF97`
                                  }`}
                                />
                              ) : (
                                <EyeHideIcon />
                              )}
                            </span>
                          </div>
                          <div
                            className="text-danger fs_12 fw_600 ps-2"
                            style={{ minHeight: "18px" }}
                          >
                            <ErrorMessage name="password" />
                          </div>
                        </div>

                        <CancelButton
                          type="submit"
                          btnText="LOGIN"
                          disabled={
                            values.Email.length === 0 &&
                            values.password.length === 0
                              ? true
                              : false
                          }
                          customStyles={{
                            backgroundColor: "rgba(29, 114, 219, 1)",
                          }}
                          className="text-white rounded-2 border-0 py-2 mt-3 cursor_pointer disabled_button"
                        />
                        {/* <button
                          type="submit"
                          className="px-4 mt-4 py-2 fw_500 w-100 rounded-2 border-0 background_color_skyblue text-white"
                        >
                          LOGIN
                        </button> */}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
