export const locationInitData = {
  loading: false,
  success: false,
  error: false,
  selectType: "",
  selectedOptions: [
    {
      label: "Bengaluru",
      value: {
        city: "Bengaluru",
        locality: null,
        id: null,
      },
    },
  ],
  data: {
    city: "Bengaluru",
    locality: null,
  },
};
