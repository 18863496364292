import { call, takeEvery, takeLatest } from "redux-saga/effects";
import { AGRICULTURE_FARM_HOUSE, AGRICULTURE_LAND, AGRICULTURE_PROPERTY_VIEW } from "../../../type";
import { agriculturePropertyViewApi, editAgricultureFarmHouseApi, editAgricultureLandApi } from "../../../api/edit-property-api/agriculture/agriculture-api";


export function* agriculturePropertyViewWorker(action) {
  console.log(action, "landfetchsaga");
  try {
    const res = yield call(agriculturePropertyViewApi, action.data);
    console.log(res, "landfetchsaga");
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* editAgricultureFarmWorker(action) {
  
  try {
    const res = yield call(editAgricultureFarmHouseApi, action.data);
    console.log(res, "farmsaga");
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editAgricultureLandWorker(action) {
  try {
    const res = yield call(editAgricultureLandApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);

    } else if (res.status === 200 && res.data.status === "fail") {

      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {

    yield action.onError("Something Went Wrong");
  }
}

export function* agriculturePropertyViewWatcher() {
  yield takeLatest(AGRICULTURE_PROPERTY_VIEW, agriculturePropertyViewWorker);
}
export function* editAgricultureFarmHouseWatch() {
  yield takeLatest(AGRICULTURE_FARM_HOUSE, editAgricultureFarmWorker);
}
export function* editAgricultureLandWatch() {
  yield takeLatest(AGRICULTURE_LAND, editAgricultureLandWorker);
}