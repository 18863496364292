import React from 'react'

const TickIcon = ({ fill = "white", width = "15", height = "10px", className }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.99634 4.99935L5.99085 8.9987L13.9963 1" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default TickIcon