import { call, takeLatest } from "redux-saga/effects";
import { PROJECT_UPDATE, PROJECT_VIEW } from "../../type";
import {
  projectUpdateApi,
  projectViewApi,
} from "../../api/project-api/projectViewApi";

export function* projectViewWorker(action) {
  console.log(action, "aaaa");
  try {
    const response = yield call(projectViewApi, action.id);
    console.log(response, "aaaa");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* projectViewWatcher() {
  yield takeLatest(PROJECT_VIEW, projectViewWorker);
}
export function* projectUpdateWorker(action) {
  console.log(action, "aaaa");
  try {
    const response = yield call(projectUpdateApi, action.data);
    console.log(response, "aaaa");
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* projectUpdateWatcher() {
  yield takeLatest(PROJECT_UPDATE, projectUpdateWorker);
}
