import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "../../../styles/property-details.module.css";
import Primarybutton from "../buttons/primary-button";
import CancelButton from "../buttons/cancelButton";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import successToast from "../../helper-functions/successToast";
import errorToast from "../../helper-functions/errorToast";
import { propertyUpdateAction } from "../../../redux/action/property-actions/propertyViewAction";
import { projectUpdateAction } from "../../../redux/action/project-actions/projectViewAction";
const Modal_property = ({
  show,
  handleclose,
  background,
  btnText,
  type = "property",
  propertyStatus,
  navigateUrl,
  mainPropertyType,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  // let status = "";
  const navigate = useNavigate();
  const propertyApprove = () => {
    dispatch(
      propertyUpdateAction(
        {
          property_id: params?.id,
          property_type: mainPropertyType,
          status: propertyStatus,
        },
        onPropertyUpdateSuccess,
        onPropertyUpdateError
      )
    );
  };
  const onPropertyUpdateSuccess = (data) => {
    console.log(data, "uuuu");
    successToast(data?.message);
    navigate(navigateUrl);
  };
  const onPropertyUpdateError = (data) => {
    console.log(data);
    errorToast(data?.message);
  };

  const projectApprove = () => {
    dispatch(
      projectUpdateAction(
        {
          project_id: params?.id,
          status: propertyStatus,
        },
        onProjectUpdateSuccess,
        onProjectUpdateError
      )
    );
  };
  const onProjectUpdateSuccess = (data) => {
    console.log(data);
    successToast(data?.message);
    navigate(navigateUrl);
  };
  const onProjectUpdateError = (data) => {
    console.log(data);
    errorToast(data?.message);
  };

  function propApproveFn() {
    if (type === "property") return propertyApprove();
    else if (type === "project") return projectApprove();
  }
  // const propApprove = propApproveFn();
  return (
    <Modal
      show={show}
      onHide={handleclose}
      centered
      contentClassName={`rounded-4`}
      size="md"
    >
      <Modal.Body>
        <div className="py-4 ps-4">
          <div className=" font_family_poppins">
            <h4 className="text_color_darkblue fs_24 fw_600">Are you sure?</h4>
            {btnText === "approve" ? (
              <h5 className="fs_14 fw_400 text_color_grey">
                Do you want to approve the {type}.
              </h5>
            ) : (
              <h5 className="fs_14 fw_400 text_color_grey">
                Do you want to reject the {type}.
              </h5>
            )}
          </div>

          <div className="d-flex  mt-4">
            {background === "green" ? (
              <Primarybutton
                type="button"
                buttonWidth="10rem"
                btnText={"Approve"}
                className={` py-1 text-white background_color_green rounded-3 border-0 fs_18`}
                onClick={() => {
                  propApproveFn();
                }}
              />
            ) : (
              <Primarybutton
                type="button"
                buttonWidth="10rem"
                btnText={"Reject"}
                className={` py-1 text-white background_color_red rounded-3 border-0 fs_18`}
                onClick={() => {
                  propApproveFn();
                }}
              />
            )}
            <CancelButton
              buttonWidth="10rem"
              btnText={"No"}
              text_class={`${styles.text_color_lightblue}`}
              className="rounded-2 ms-4 bg-white"
              customStyles={{
                border: "2px solid #1D72DB",
              }}
              onClick={() => handleclose()}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Modal_property;
