import { LOGIN, LOGOUT } from "../../type";

export const loginActions = (data, onSuccess, onError) => {
  return {
    type: LOGIN,
    data,
    onSuccess,
    onError,
  };
};

export const logoutActions = () => {
  return { type: LOGOUT };
};
