import React from 'react'
import { removeUnderScore } from '../helper/helper';

const EditPgCommonAmenities = ({formik}) => {
    const commonAmenities = { ...formik?.values?.common_area_amenities };
  const commonAmenitiesArr = [];
  
  for (const key in commonAmenities) {
    if (commonAmenities?.hasOwnProperty(key)) {
      commonAmenitiesArr.push({ key, value: commonAmenities[key] });
    }
  }
  const handleCheckboxChange = (key) => {
    const updatedSuitableFor = { ...commonAmenities };
    if (updatedSuitableFor.hasOwnProperty(key)) {
      updatedSuitableFor[key] = !updatedSuitableFor[key];
    }
    formik.setFieldValue(`common_area_amenities.${key}`, updatedSuitableFor[key]);
  };
  console.log(commonAmenitiesArr, "commonAmenities");
  return (
    <div>
        <div className={` my-3 d-flex align-items-center pt-3`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Common Amenities</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row g-0">
        {commonAmenitiesArr?.map((item, index) => {
          return (
            <div className="col-3 mb-3">
              <div className="d-flex align-items-center">
                <div className="mt-1">
                  <input
                    className=""
                    type="checkbox"
                    name={`pgRules.${item?.key}`}
                    checked={item.value}
                    onChange={() => handleCheckboxChange(item?.key)}
                    // value={item?.key}
                    style={{ width: "17px", height: "17px" }}
                  />
                </div>
                <div className="ms-2 d-inline-block fs_15 secondary_color">
                  {item?.key ? removeUnderScore(item?.key) : "--"}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default EditPgCommonAmenities