import React, { useState } from "react";
import UseFormikInputField from "../../../form/useFormikInputField";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikSelectField from "../../../form/useFormikSelectField";
import UseFormikRadioInput from "../../../form/useFormikRadioField";
import { removeUnderScore } from "../../../helper/helper";
const CommercialShopFeature = ({ formik, setCompareAminities, propertyDetails, isClickedOnAme, setIsClickedOnAme }) => {

  const suitableForList = [
    { name: "jewelry_mart", label: "Jewelry Mart", status: false },
    {
      name: "clothing_and_fashion_stores",
      label: "Clothing And Fashion Stores",
      status: false,
    },
    { name: "bakery", label: "Bakery", status: false },
    {
      name: "gyms",
      label: "Gyms",
      status: false,
    },
    {
      name: "clinics_and_polyclinics",
      label: "Clinics And Polyclinics",
      status: false,
    },
    {
      name: "optician",
      label: "Optician",
      status: false,
    },
    {
      name: "hardware_shop",
      label: "Hardware Shop",
      status: false,
    },
    {
      name: "nursing_home",
      label: "Nursing Home",
      status: false,
    },
    {
      name: "stationery",
      label: "Stationery",
      status: false,
    },
    {
      name: "beauty_parlor_or_hairdresser",
      label: "Beauty Parlor/Hairdresser",
      status: false,
    },
    {
      name: "book_shop",
      label: "Book Shop",
      status: false,
    },
    {
      name: "pet_shop_pet_clinic",
      label: "Pet Shop/ Pet Clinic",
      status: false,
    },
    {
      name: "hotel_or_restaurant",
      label: "Hotel/ Restaurant",
      status: false,
    },
  ];
  // const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);
  const initialAminities = propertyDetails?.property_features?.suitable_for;
  const aminitiesAreEqual = (currentSuitableFor, initialSuitableFor) => {
    const getCurrentAminitieStatus = currentSuitableFor?.filter((item) => item?.status === true)?.length;
    const getInitialAminitieStatus = initialSuitableFor?.filter((item) => item?.status === true )?.length;
    const isAmentiesMatches = currentSuitableFor?.every((currentItem, index) => initialSuitableFor[index]?.status === currentItem?.status);
    if (isAmentiesMatches) {
      setCompareAminities(true);
    } else {
      setCompareAminities(false);
    }
  };
  const updatedAminitiesList = suitableForList?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {
      return backendAminity.name === aminity.name;
    });

    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
  
    return aminity;
  });
  const [suitableFor, setSuitableFor] = useState(updatedAminitiesList);
  const handleCheckboxChange = (index) => {
    const newUpdatedAminitiesList = [...suitableFor];
    newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
    setSuitableFor(newUpdatedAminitiesList);
    const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
    formik.setFieldValue("property_features.suitable_for", filteredAmenities);
  };
  if(isClickedOnAme){
    aminitiesAreEqual(suitableFor, formik?.initialValues?.property_features?.suitable_for);
  }
  
  return (
    <>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Property Features</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.total_floors}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.total_floors}
            formikTouched={formik?.touched?.property_features?.total_floors}
            itemName="property_features.total_floors"
            labelName="Total Floors"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.floor_number}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.floor_number}
            formikTouched={formik?.touched?.property_features?.floor_number}
            itemName="property_features.floor_number"
            labelName="Floor number"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.washrooms}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.washrooms}
            formikTouched={formik?.touched?.property_features?.washrooms}
            itemName="property_features.washrooms"
            labelName="Washrooms"
          />
        </div>
        <div className={`${styles.property_feature_radiobox_container}`}>
          <UseFormikRadioInput
            formik={formik}
            name={"property_features.parking"}
            title={"Parking"}
          />
        </div>
        <div className={`${styles.property_feature_radiobox_container}`}>
          <UseFormikRadioInput
            formik={formik}
            name={"property_features.personal_washroom"}
            title={"Personal Washroom"}
          />
        </div>
        <div className={`${styles.property_feature_radiobox_container}`}>
          <UseFormikRadioInput
            formik={formik}
            name={"property_features.corner_shop"}
            title={"Corner Shop"}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center mt-3">
        <UseFormikSelectField
          label="Facing"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.facing"
          formik={formik}
          formikError={formik?.errors?.facing}
          formikTouched={formik?.touched?.facing}
          defaultValue={
            formik?.values?.property_features?.facing && {
              label: removeUnderScore(formik?.values?.property_features?.facing),
              value: formik?.values?.property_features?.facing,
            }
          }
        />
        <UseFormikSelectField
          label="Furnishing Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.furnishing_status"
          formik={formik}
          formikError={formik?.errors?.furnishing_status}
          formikTouched={formik?.touched?.furnishing_status}
          defaultValue={
            formik?.values?.property_features?.furnishing_status && {
              label: removeUnderScore(formik?.values?.property_features?.furnishing_status),
              value: formik?.values?.property_features?.furnishing_status,
            }
          }
        />
        <UseFormikSelectField
          label="Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.status"
          formik={formik}
          formikError={formik?.errors?.status}
          formikTouched={formik?.touched?.status}
          defaultValue={
            formik?.values?.property_features?.status && {
              label: removeUnderScore(formik?.values?.property_features?.status),
              value: formik?.values?.property_features?.status,
            }
          }
        />
        {/* <UseFormikRadioInput
          formik={formik}
          name={"property_features.boundary_wall_made"}
          title={"Boundary Wall made"}
        /> */}
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Property Amenities</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        <div className="row g-0">
          {suitableFor?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </>
  );
};

export default CommercialShopFeature;
