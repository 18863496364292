import { all } from "redux-saga/effects";
import projectDetailsRootSaga from "./root-saga/project-root-saga/project-details-root-saga";
import projectRequestRootSaga from "./root-saga/project-root-saga/project-request-root-saga";
import propertyRequestRootSaga from "./root-saga/property-root-saga/property-request-root-saga";
import propertyDetailsRootSaga from "./root-saga/property-root-saga/property-details-root-saga";
import AuthRootSaga from "./root-saga/auth-root-saga/auth-root-saga";
import editPropertResidentialRootSaga from "./root-saga/edit-property-root-saga/residential-root-saga";
import subscribeAssignSaga from "./root-saga/subscribe-root-saga";
import editPropertyCommercialRootSaga from "./root-saga/edit-property-root-saga/commercialRootSaga";
import editPropertyAgricultureRootSaga from "./root-saga/edit-property-root-saga/agricultureRootSaga";
import editProjectRootSaga from "./root-saga/project-root-saga/residential-project-root-saga";
import pgListRootSaga from "./root-saga/property-root-saga/pg-list-root-saga";
import { dashboardWatcher } from "./dashboard-saga";
import {
  loactionSearchWatcher,
  propertyDataWatcher,
  requestCallBackDataWatcher,
  watchSearchAutocompleteWorker,
} from "./exportSaga";

export default function* rootSaga() {
  yield all([
    propertyRequestRootSaga(),
    propertyDetailsRootSaga(),
    AuthRootSaga(),
    projectDetailsRootSaga(),
    projectRequestRootSaga(),
    editPropertResidentialRootSaga(),
    subscribeAssignSaga(),
    editPropertyCommercialRootSaga(),
    editPropertyAgricultureRootSaga(),
    editProjectRootSaga(),
    pgListRootSaga(),
    dashboardWatcher(),
    requestCallBackDataWatcher(),
    propertyDataWatcher(),
    loactionSearchWatcher(),
    watchSearchAutocompleteWorker(),
  ]);
}
