import {
  SUBSCRIBE_PROJECTS,
  SUBSCRIBE_USER,
  SUBSCRIPTION_ASSIGN,
} from "../../type";

export const subscribeUserAction = (onSuccess, onError) => {
  return {
    type: SUBSCRIBE_USER,
    onSuccess,
    onError,
  };
};
export const subscribeProjectsAction = (data, onSuccess, onError) => {
  return {
    type: SUBSCRIBE_PROJECTS,
    data,
    onSuccess,
    onError,
  };
};
export const subscribeAssignAction = (data, onSuccess, onError) => {
  return {
    type: SUBSCRIPTION_ASSIGN,
    data,
    onSuccess,
    onError,
  };
};
