import React from 'react'

const EyeHideIcon = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.80133 11.749C8.38897 12.1513 9.07572 12.3848 9.7868 12.4239C10.4979 12.463 11.2061 12.3063 11.8343 11.9708C12.4625 11.6354 12.9866 11.134 13.3497 10.5213C13.7127 9.90867 13.9008 9.20816 13.8934 8.49604C13.8918 7.70758 13.6577 6.93711 13.2203 6.28104L12.1564 7.35404C12.349 7.72722 12.4432 8.14344 12.4299 8.56321C12.4167 8.98298 12.2964 9.39239 12.0806 9.75266C11.8647 10.1129 11.5604 10.4121 11.1965 10.6218C10.8327 10.8316 10.4213 10.9449 10.0013 10.951C9.60707 10.9534 9.21826 10.8586 8.86932 10.675L7.80133 11.749ZM16.4274 3.04905C17.9504 4.49998 19.1475 6.25818 19.9393 8.20704C19.9785 8.29889 19.9987 8.39769 19.9987 8.49753C19.9987 8.59738 19.9785 8.69618 19.9393 8.78803C17.8513 13.688 14.1393 16.625 9.99731 16.625H9.98737C8.08734 16.6092 6.24041 15.9959 4.70831 14.872L2.81537 16.7821C2.74812 16.851 2.66767 16.9057 2.57886 16.943C2.49005 16.9803 2.39466 16.9993 2.29834 16.999C2.20186 17.0004 2.10612 16.9819 2.01715 16.9446C1.92818 16.9072 1.84794 16.8519 1.78137 16.7821C1.66259 16.6615 1.58897 16.5037 1.57294 16.3352C1.55691 16.1667 1.59944 15.9978 1.69336 15.857L1.72235 15.818L16.1544 1.25703C16.1731 1.23972 16.1892 1.21987 16.2023 1.19804C16.2213 1.18072 16.2378 1.16087 16.2513 1.13905L17.1683 0.21305C17.3068 0.0758006 17.4941 -0.000837017 17.689 6.89654e-06C17.884 0.00085081 18.0706 0.0790932 18.2078 0.217536C18.3451 0.355979 18.4217 0.543283 18.4209 0.738227C18.4201 0.933171 18.3418 1.11978 18.2034 1.25703L16.4274 3.04905ZM6.10132 8.50403C6.10407 8.75482 6.12718 9.00499 6.17035 9.25205L2.55933 12.894C1.52195 11.6609 0.678904 10.2766 0.0593262 8.78904C0.0201849 8.69719 0 8.59838 0 8.49854C0 8.3987 0.0201849 8.29989 0.0593262 8.20805C2.14733 3.30804 5.85937 0.381049 9.99237 0.381049H10.0013C11.392 0.383981 12.7623 0.714579 14.0013 1.34605L10.7424 4.63404C10.4973 4.59082 10.2492 4.56774 10.0004 4.56504C8.96106 4.5706 7.9665 4.98865 7.23535 5.7273C6.5042 6.46595 6.09627 7.46473 6.10132 8.50403Z" fill="#50BF97"/>
    </svg>
  )
}

export default EyeHideIcon