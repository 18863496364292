import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FeaturesVariation from "../../../components/edit-project/residential/flat-apartment/FeaturesVariation";
import ResidentialFeatures from "../../../components/edit-project/residential/flat-apartment/residentialFeatures";
import LocationAndSearch from "../../../components/map/locationSearch";
import UseFormikInputField from "../../../components/form/useFormikInputField";
import OfficeAddPropertyPhoto from "../../../components/edit-property/commercial/office-space/office-add-property-photo";
import PropertyBasicDetails from "../../../components/edit-property/property-basic-details";
import moment from "moment";
import {
  amountTypeRegex,
  convertValuesToIntegers,
  convertValuesToIntegersObj,
  defaultAddress,
  inputRegex,
  removeUnderScore,
} from "../../../components/helper/helper";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  editProjectApartmentAction,
  projectFindOneAction,
} from "../../../redux/action/project-actions/residentialProjectsAction";
import { Spinner } from "react-bootstrap";
import successToast from "../../../components/helper-functions/successToast";
import errorToast from "../../../components/helper-functions/errorToast";
import styles from "../../../styles/property-details.module.css";
import Modal_property from "../../../components/ui/modals/modal_property";
import Leftarrowicon from "../../../assets/icons/left-arrow-icon";
import Primarybutton from "../../../components/ui/buttons/primary-button";
import isEqual from "lodash.isequal";
import BroucherUpload from "../../edit-property/broucherUpload";
import PropertyLocation from "../../../components/map/property-location";
import UseFormikSelectField from "../../../components/form/useFormikSelectField";
const EditProjectFlatApartment = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);
  const [uploadBroucher, setUploadBroucher] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [mainPropertyType, setMainPropertyType] = useState("");
  const [modalButton, setModalButton] = useState("");
  // const [amitities, setAmitities] = useState();
  const [mapLoading, setMapLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState("");
  const [aminities, setAminities] = useState("");
  const [compareAminities, setCompareAminities] = useState(true);
  const [isClickedOnAme, setIsClickedOnAme] = useState(false);
  const [onSubmitUpdateSuitableFor, setOnSubmitUpdateSuitableFor] =
    useState(false);
  const apartmentFeaturesData = projectDetails?.project_features ?? [];
  const [numVariations, setNumVariations] = useState(
    apartmentFeaturesData?.length > 0 ? apartmentFeaturesData?.length : 1
  );
  // const [numVariations, setNumVariations] = useState(2);
  const [multiImage, setMultiImage] = useState([]);
  useEffect(() => {
    setLoading(true);
    dispatch(
      projectFindOneAction(
        {
          project_id: params?.id,
          property_type: params?.type,
        },
        onProjectFindSuccess,
        onProjectFindError
      )
    );
  }, [reload]);
  const onProjectFindSuccess = (data) => {
    const projectDetails = data?.data;
    setLoading(false);
    setProjectDetails(projectDetails);
    setInitialValues({
      project_id: projectDetails?.id,
      no_of_units: projectDetails?.no_of_units,
      status_of_projects_construction:
        projectDetails?.status_of_projects_construction,
      // project_lunched_on: projectDetails?.project_lunched_on ?? "",
      // project_completed_on: projectDetails?.project_completed_on ?? "",
      // expected_completed_date: projectDetails?.expected_completed_date ?? "",
      date1: projectDetails?.project_lunched_on?.split("T")[0] ?? "",
      date2:
        projectDetails?.[
          projectDetails?.status_of_projects_construction === "completed"
            ? "project_completed_on"
            : "expected_completed_date"
        ]?.split("T")[0] ?? "",
      project_type: projectDetails?.project_type,
      project_details: {
        name: projectDetails?.project_details?.name,
        address: projectDetails?.project_details?.address
          ? projectDetails?.project_details?.address
          : defaultAddress?.address_line_2,
        location: {
          lat: projectDetails?.project_details?.location?.lat
            ? projectDetails?.project_details?.location?.lat
            : defaultAddress?.lat,
          long: projectDetails?.project_details?.location?.long
            ? projectDetails?.project_details?.location?.long
            : defaultAddress?.lng,
        },
        project_or_society_name:
          projectDetails?.project_details?.project_or_society_name,
      },
      project_features: Array.from(
        { length: projectDetails?.project_features?.length },
        (_, i) => ({
          no_of_units_in_variation:
            projectDetails?.project_features[i]?.no_of_units_in_variation,
          bedrooms: projectDetails?.project_features[i]?.bedrooms,
          bathrooms: projectDetails?.project_features[i]?.bathrooms,
          balconies: projectDetails?.project_features[i]?.balconies,
          parking_count: projectDetails?.project_features[i]?.parking_count,
          built_up_area: projectDetails?.project_features[i]?.built_up_area,
          carpet_area: projectDetails?.project_features[i]?.carpet_area,
          expected_price: projectDetails?.project_features[i]?.expected_price,
          maintenance_fee: projectDetails?.project_features[i]?.maintenance_fee,
        })
      ),
      common_features: {
        total_floor: projectDetails?.common_features?.total_floor ?? "",
        car_parking_count:
          projectDetails?.common_features?.car_parking_count ?? "",
        furnishing_status:
          projectDetails?.common_features?.furnishing_status ?? "",
        status: projectDetails?.common_features?.status ?? "",
        transaction: projectDetails?.common_features?.transaction ?? "",
        amenities: projectDetails?.common_features?.amenities,
      },
      g_location: {
        address_line_1: projectDetails?.g_location?.address_line_1
          ? projectDetails?.g_location?.address_line_1
          : defaultAddress?.address_line_1,
        address_line_2: projectDetails?.g_location?.address_line_2
          ? projectDetails?.g_location?.address_line_2
          : defaultAddress?.address_line_2,
        area: projectDetails?.g_location?.area
          ? projectDetails?.g_location?.area
          : defaultAddress?.area,
        city: projectDetails?.g_location?.city
          ? projectDetails?.g_location?.city
          : defaultAddress?.city,
        postCode: projectDetails?.g_location?.postCode
          ? projectDetails?.g_location?.postCode
          : defaultAddress?.postCode,
        state: projectDetails?.g_location?.state
          ? projectDetails?.g_location?.state
          : defaultAddress?.state,
        country: projectDetails?.g_location?.country
          ? projectDetails?.g_location?.country
          : defaultAddress?.country,
        lat: projectDetails?.g_location?.lat
          ? projectDetails?.g_location?.lat
          : defaultAddress?.lat,
        lng: projectDetails?.g_location?.lng
          ? projectDetails?.g_location?.lng
          : defaultAddress?.lng,
      },
      formetted_address: projectDetails?.formetted_address,
      property_photos: projectDetails?.property_photos,
      brochure_upload: projectDetails?.brochure_upload,
      description: projectDetails?.description,
      terms_and_condition: true,
      lat: projectDetails?.g_location?.lat
        ? projectDetails?.g_location?.lat
        : defaultAddress?.lat,
      lng: projectDetails?.g_location?.lng
        ? projectDetails?.g_location?.lng
        : defaultAddress?.lng,
      formetted_address: projectDetails?.formetted_address,
      searchAddress: projectDetails?.formetted_address,
    });
    setMultiImage(data?.data?.property_photos);
    setAminities(data?.data?.common_features?.amenities);
    setMainPropertyType(data?.data?.main_property_type);
  };
  console.log(projectDetails, "projectDetailsflat");
  console.log(apartmentFeaturesData, "apartmentFeaturesData");
  const onProjectFindError = (data) => {
    setLoading(false);
  };
  // useEffect(() => {}, [loading]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      project_features: Yup.array()
        .of(
          Yup.object().shape({
            no_of_units_in_variation: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            bedrooms: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            bathrooms: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            balconies: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            parking_count: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            built_up_area: Yup.number()
              .typeError("Enter a valid number")
              .required("Required"),
            carpet_area: Yup.number()
              .typeError("Enter a valid number")
              .max(Yup.ref("built_up_area"), "less or equal to Built up area")
              .required("Required"),
            expected_price: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            maintenance_fee: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .nullable(),
          })
        )
        .required()
        .test(
          "total-units-check",
          "Total units exceed the limit",
          function (value) {
            const totalUnits = value.reduce((acc, item) => {
              return acc + parseInt(item.no_of_units_in_variation || "0", 10);
            }, 0);
            return totalUnits <= projectDetails?.no_of_units;
          }
        ),
      common_features: Yup.object().shape({
        total_floor: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        furnishing_status: Yup.string().required("Required"),
        status: Yup.string().required("Required"),
      }),
      // searchAddress: Yup.string().required("Required"),
      g_location: Yup.object({
        city: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
      }),
      status_of_projects_construction: Yup.string().trim().required("Required"),
      date1: Yup.date().required("Required"),
      date2: Yup.date()
        .required("Required")
        .test(
          "is-greater",
          "should be greater than Project Lunched On",
          function (value) {
            const { date1 } = this.parent;
            return value > date1;
          }
        ),
    }),
    onSubmit: (values) => {
      dispatch(
        editProjectApartmentAction(
          {
            ...values,
            project_lunched_on: values.date1,
            [values.status_of_projects_construction === "completed"
              ? "project_completed_on"
              : "expected_completed_date"]: values.date2,
            project_features: Array.from(
              { length: values?.project_features?.length },
              (_, i) => ({
                no_of_units_in_variation: parseInt(
                  values?.project_features[i]?.no_of_units_in_variation
                ),
                bedrooms: parseInt(values?.project_features[i]?.bedrooms),
                bathrooms: parseInt(values?.project_features[i]?.bathrooms),
                balconies: parseInt(values?.project_features[i]?.balconies),
                parking_count: parseInt(
                  values?.project_features[i]?.parking_count
                ),
                built_up_area: parseInt(
                  values?.project_features[i]?.built_up_area
                ),
                carpet_area: parseInt(values?.project_features[i]?.carpet_area),
                expected_price: parseInt(
                  values?.project_features[i]?.expected_price
                ),
                maintenance_fee: parseInt(
                  values?.project_features[i]?.maintenance_fee
                ),
              })
            ),
            common_features: {
              ...values?.common_features,
              total_floor: parseInt(values?.common_features?.total_floor),
              car_parking_count: parseInt(
                values?.common_features?.car_parking_count
              ),
            },
            property_photos: multiImage,
            formetted_address: values.project_details?.address,
          },
          onEditSuccess,
          onEditError
        )
      );
      setIsClickedOnAme(true);
    },
  });
  const onEditSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
    setOnSubmitUpdateSuitableFor(false);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
    setOnSubmitUpdateSuitableFor(false);
  };
  const currentValuesStatus =
    Array.isArray(aminities) && aminities?.map((item) => item?.status);
  const initialValuesStatus =
    formik?.initialValues?.common_features?.amenities?.map(
      (item) => item?.status
    );

  const initWithoutAmenitiesFea = { ...initialValues?.project_features };
  delete initWithoutAmenitiesFea?.amenities;
  const initValuesWithoutAmenitiesFea = { ...formik?.values?.project_features };
  delete initValuesWithoutAmenitiesFea?.amenities;

  const initCommonfeatures = { ...initialValues?.common_features };
  delete initCommonfeatures?.amenities;
  const valuesCommonfeatures = { ...formik?.values?.common_features };
  delete valuesCommonfeatures?.amenities;
  function valuesCompareFn(values) {
    const propertyFeature = isEqual(
      convertValuesToIntegersObj(formik?.initialValues?.project_features),
      convertValuesToIntegersObj(values?.project_features)
    );
    const commonfeatures = isEqual(initCommonfeatures, valuesCommonfeatures);
    const propertyPhotos = isEqual(
      formik?.initialValues?.property_photos?.length,
      multiImage?.length
    );
    const lat = isEqual(
      formik?.initialValues?.g_location?.lat,
      values?.g_location?.lat
    );
    const gLocation = isEqual(initialValues?.g_location, values?.g_location);
    const uploadDoc = isEqual(
      initialValues?.brochure_upload,
      formik?.values?.brochure_upload
    );
    const projectOrSociety = isEqual(
      initialValues?.project_details?.project_or_society_name,
      values?.project_details?.project_or_society_name
    );
    const description = isEqual(
      initialValues?.description,
      values?.description
    );
    const date1 = isEqual(initialValues?.date1, values?.date1);
    const date2 = isEqual(initialValues?.date2, values?.date2);
    const projectStatus = isEqual(initialValues?.status_of_projects_construction, values?.status_of_projects_construction);
    // console.log(propertyFeature, propertyPhotos, lat, compareAminities, commonfeatures,  "flatameprojisequal");
    if (
      propertyFeature &&
      propertyPhotos &&
      gLocation &&
      uploadDoc &&
      compareAminities &&
      commonfeatures &&
      projectOrSociety &&
      description &&
      date1 &&
      date2 && projectStatus
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (initialValues?.lat) {
      setMapLoading(true);
    }
  }, [initialValues?.lat]);
  let propertyId = null;
  let usetId = null;
  if (projectDetails && projectDetails?.id) {
    propertyId = (projectDetails?.id).split("-");
    usetId = (projectDetails?.User?.id).split("-");
  }
  const propertyBasicDetails = [
    {
      label: "Property ID",
      value: propertyId && propertyId[0]?.toUpperCase(),
      display: true,
    },
    {
      label: "Property Type",
      value: projectDetails?.project_type === "apartment" && "Flat/Apartment",
      display: true,
    },
    {
      label: "Property Price",
      value:
        projectDetails &&
        parseInt(projectDetails?.project_features?.[0]?.expected_price),
      display: true,
    },
    {
      label: "Subscription Plan",
      value: "",
      display: projectDetails?.SubscriptionPlanDetail ? true : false,
    },
    {
      label: "Validity",
      value: "",
      display: projectDetails?.SubscriptionPlanDetail ? true : false,
    },
    {
      label: "Posted By",
      value:
        projectDetails?.name +
        `(${removeUnderScore(projectDetails?.owner_type)})`,
      display: true,
    },
    {
      label: "Posted Date & Time",
      value: moment(projectDetails?.createdAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
    {
      label: "Updated Date & Time",
      value: moment(projectDetails?.updatedAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
  ];
  const handleDateChangeDate1 = (e) => {
    formik.setFieldValue("date1", e.target.value);
  };
  const handleDateChangeDate2 = (e) => {
    formik.setFieldValue("date2", e.target.value);
  };
  console.log(formik?.values, "projformik");
  return (
    <div>
      {loading ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className={` ${styles.border_color_gray} rounded-4 m-4 `}>
            <Modal_property
              show={show}
              mainPropertyType={mainPropertyType}
              handleclose={() => setShow(false)}
              background={modalButton === "Approve" ? "green" : "red"}
              btnText={modalButton === "Approve" ? "approve" : "reject"}
              propertyStatus={propertyStatus}
              navigateUrl={"/property-project/project-requests"}
              type="project"
            />
            <div className={`d-flex justify-content-between my-2 `}>
              <div
                className="d-flex align-items-center ms-4"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <Leftarrowicon />
                <Primarybutton
                  btnText={"BACK"}
                  className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
                />
              </div>

              <div>
                {!valuesCompareFn(formik.values) ? (
                  <button
                    className="btn btn-primary me-3"
                    type="submit"
                    onClick={() => setOnSubmitUpdateSuitableFor(true)}
                  >
                    submit
                  </button>
                ) : location.pathname.includes("live-projects") ? null : (
                  // : location.pathname.includes("live-property-requests") &&
                  //   propertyDetails.status === "approved" ? null : (
                  <>
                    <Primarybutton
                      btnText={"APPROVE"}
                      type="button"
                      onClick={() => {
                        setModalButton("Approve");
                        setShow(true);
                        setPropertyStatus("approved");
                      }}
                      className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
                    />
                    <Primarybutton
                      btnText={"REJECT"}
                      type="button"
                      onClick={() => {
                        setModalButton("Reject");
                        setShow(true);
                        setPropertyStatus("rejected");
                      }}
                      className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="border-bottom"></div>
            <div
              className="scrolling px-3"
              style={{ overflowY: "auto", overflowX: "hidden", height: "70vh" }}
            >
              <PropertyBasicDetails
                propertyBasicDetails={propertyBasicDetails}
              />
              <div className={` my-3 d-flex align-items-center`}>
                <h5 className={`light_blue_color fs_18 me-3`}>
                  Property Location
                </h5>
                <hr className={`py-1 flex-grow-1 border-primary`} />
              </div>
              <div className="row position-relative">
                {/* <div className="col-7">
                  {mapLoading ? (
                    <LocationAndSearch type="project_details" formik={formik} />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center col-7 w-100"
                      style={{ height: "20rem" }}
                    >
                      <Spinner />
                    </div>
                  )}
                </div> */}
                <div className="col-5">
                  {formik?.values && (
                    <PropertyLocation formik={formik} mapLoading={mapLoading} />
                  )}
                  <div className="mt-3">
                    <UseFormikSelectField
                      label="Status of Projects Construction"
                      customStyles={{ width: "100%" }}
                      name="status_of_projects_construction"
                      formik={formik}
                      formikError={
                        formik?.errors?.status_of_projects_construction
                      }
                      formikTouched={
                        formik?.touched?.status_of_projects_construction
                      }
                      options={projectStatus}
                      defaultValue={
                        formik?.values?.status_of_projects_construction && {
                          label: removeUnderScore(
                            formik?.values?.status_of_projects_construction
                          ),
                          value:
                            formik?.values?.status_of_projects_construction,
                        }
                      }
                    />
                  </div>
                  <div className="">
                    <label className={`secondary_color fs_15`}>
                      Project Lunched On
                    </label>
                    <div
                      className={` border_radius_5px input_border focus_none mb-3`}
                    >
                      <input
                        type="date"
                        name="date1"
                        className={`${styles.input_text} w-100 px-2 custom-date-picker  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
                        id={"date1"}
                        value={formik?.values?.date1}
                        onChange={handleDateChangeDate1}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <label className={`secondary_color fs_15`}>
                      {formik?.values?.status_of_projects_construction ===
                      "completed"
                        ? "Project Completion On"
                        : "Expected Completion Date"}
                    </label>
                    <div
                      className={` border_radius_5px input_border focus_none mb-3`}
                    >
                      <input
                        type="date"
                        name="date2"
                        className={`${styles.input_text} w-100 px-2 custom-date-picker  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
                        id={"date2"}
                        value={formik?.values?.date2}
                        onChange={handleDateChangeDate2}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <UseFormikInputField
                      placeholder="Enter Project/Society Name (optional)"
                      inputValue={
                        formik?.values?.project_details?.project_or_society_name
                      }
                      itemName="project_details.project_or_society_name"
                      labelName="Project/Society Name"
                      onChangeFn={formik.handleChange}
                      onBlurFn={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              {[...Array(formik?.values?.project_features?.length)].map(
                (item, index) => {
                  return <FeaturesVariation formik={formik} index={index} />;
                }
              )}

              <ResidentialFeatures
                formik={formik}
                onSubmitUpdateSuitableFor={onSubmitUpdateSuitableFor}
                compareAminities={compareAminities}
                setCompareAminities={setCompareAminities}
                projectDetails={projectDetails}
                setIsClickedOnAme={setIsClickedOnAme}
                isClickedOnAme={isClickedOnAme}
              />
              <OfficeAddPropertyPhoto
                multiImage={multiImage}
                setMultiImage={setMultiImage}
              />
              <BroucherUpload type="project_details" formik={formik} />
              <div className="discription">
                <div className={`mt-5 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>Description</h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                </div>
                <UseFormikInputField
                  label_font_size=""
                  labelName=""
                  itemName={"description"}
                  inputValue={formik.values.description}
                  onChangeFn={formik.handleChange}
                  onBlurFn={formik.handleBlur}
                  formikError={formik.errors.description}
                  formikTouched={formik.touched.description}
                  placeholder="Describe your project"
                  input={false}
                  TextArea={true}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditProjectFlatApartment;
const projectStatus = [
  { value: "launched", label: "Launched" },
  { value: "escalation", label: "Escalations" },
  { value: "foundation", label: "Foundation" },
  { value: "structure_implementation", label: "Structure Implementations" },
  {
    value: "mechanical_electrical_plumbing",
    label: "Mechanical Electrical and Plumbing",
  },
  {
    value: "insulation_waterproofing",
    label: "Insulation and Water Proofing",
  },
  { value: "completed", label: "Completed" },
];
