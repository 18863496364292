import { DASHBOARD } from "../type";

export const dashboardAction = (data, onSuccess, onError) => {
  return {
    type: DASHBOARD,
    data,
    onSuccess,
    onError,
  };
};
