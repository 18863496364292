import {
  EDIT_PG,
  LIVE_PG_LIST,
  PGSREQUESTFINDALL,
  PG_FIND_ONE,
} from "../../type";

export const PgRequestAction = (data, onSuccess, onError) => {
  return {
    type: PGSREQUESTFINDALL,
    data,
    onSuccess,
    onError,
  };
};
export const LivePgListAction = (data, onSuccess, onError) => {
  return {
    type: LIVE_PG_LIST,
    data,
    onSuccess,
    onError,
  };
};
export const pgFindOneAction = (data, onSuccess, onError) => {
  return {
    type: PG_FIND_ONE,
    data,
    onSuccess,
    onError,
  };
};

export const editPgAction = (data, onSuccess, onError) => {
  console.log(data, "pgedit");
  return {
    type: EDIT_PG,
    data,
    onSuccess,
    onError,
  };
};