import React from "react";

const NavbarOptionIcon = ({width="6",height="20", onClick=()=>{},className }) => {
  return (
    <svg
    onClick={onClick}
    className={className}
      width={width}
      height={height}
      viewBox="0 0 6 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 17C5 15.8954 4.10457 15 3 15C1.89543 15 1 15.8954 1 17C1 18.1046 1.89543 19 3 19C4.10457 19 5 18.1046 5 17Z"
        fill="#323D5A"
        stroke="#323D5A"
      />
      <path
        d="M5 10C5 8.89543 4.10457 8 3 8C1.89543 8 1 8.89543 1 10C1 11.1046 1.89543 12 3 12C4.10457 12 5 11.1046 5 10Z"
        fill="#323D5A"
        stroke="#323D5A"
      />
      <path
        d="M5 3C5 1.89543 4.10457 1 3 1C1.89543 1 1 1.89543 1 3C1 4.10457 1.89543 5 3 5C4.10457 5 5 4.10457 5 3Z"
        fill="#323D5A"
        stroke="#323D5A"
      />
    </svg>
  );
};

export default NavbarOptionIcon;
