import React, { useEffect, useState } from "react";
// import { residentialImagesUploadAction } from "../../../../redux/action/edit-property-actions/multi-image-actions";
import { useDispatch } from "react-redux";
import UploadIcon from "../../assets/icons/image-upload-icon.png";
import DeleteIcon from "../../assets/icons/Delete-icon.png";
import { residentialImagesUploadAction } from "../../redux/action/edit-property-actions/multi-image-actions";
const EditPgUploadImage = ({ 
    singlePhotos, 
    setSinglePhotos , 
    setLoading, 
    doublePhotos,
    setDoublePhotos,
    triplePhotos, 
    setTriplePhotos, 
    fourPhotos,
    setFourPhotos,
    pgDetails,
    otherPhotos,
    setOtherPhotos 
}) => {
  const dispatch = useDispatch();
  const [uploadedImageCount, setUploadedImageCount] = useState(0);
  const [uploadedImage, setUploadedImage] = useState("");
  const [isWhichRoomSharing, setisWhichRoomSharing] = useState("single_sharing");
  const handleImage = (e) => {
    const selectedFiles = e.target.files;
    console.log(selectedFiles, "selectedFiles");
    for (let i = 0; i < selectedFiles.length; i++) {
    dispatch(
      residentialImagesUploadAction(
        selectedFiles[i],
        onCompanyLogoSucess,
        onCompanyLogoError
      )
    );
    }
    e.target.value = null;
  };
  const onCompanyLogoSucess = (data) => {
    // if(isWhichRoomSharing === "single_sharing" && singlePhotos?.length < 12){
    //     setSinglePhotos((prev) => [...prev, data?.imagePath]);
    // } 
    // else if (isWhichRoomSharing === "double_sharing") {
    //     setDoublePhotos((prev) => [...prev, data?.imagePath]);
    //   }
    //   else if (isWhichRoomSharing === "triple_sharing") {
    //     setTriplePhotos((prev) => [...prev, data?.imagePath]);
    //   }
    //     else if (isWhichRoomSharing === "four_sharing") {
    //     setFourPhotos((prev) => [...prev, data?.imagePath]);
    //   }
    //   else if (isWhichRoomSharing === "other_sharing") {
    //     setOtherPhotos((prev) => [...prev, data?.imagePath]);
    //   }
    setUploadedImage((prev) => [...prev, data?.imagePath]);
    setUploadedImageCount((prev) => prev + 1);
  };
  useEffect(() => {
    if (isWhichRoomSharing === "single_sharing" && singlePhotos?.length < 12) {
      for (let i = 0; i < uploadedImage.length && singlePhotos?.length < 12; i++) {
        setSinglePhotos((prev) => [...prev, uploadedImage[i]]);
      }
    } else if(isWhichRoomSharing === "double_sharing" && doublePhotos.length < 12){
      for (let i = 0; i < uploadedImage.length && doublePhotos?.length < 12; i++) {
        setDoublePhotos((prev) => [...prev, uploadedImage[i]]);
      }
    } else if(isWhichRoomSharing === "triple_sharing" && triplePhotos?.length < 12){
      for (let i = 0; i < uploadedImage.length && triplePhotos?.length < 12; i++) {
        setTriplePhotos((prev) => [...prev, uploadedImage[i]]);
      }
    } else if(isWhichRoomSharing === "four_sharing" && fourPhotos?.length < 12){
      for (let i = 0; i < uploadedImage.length && fourPhotos?.length < 12; i++) {
        setFourPhotos((prev) => [...prev, uploadedImage[i]]);
      }
    } else if(isWhichRoomSharing === "other_sharing" && otherPhotos?.length < 12){
      for (let i = 0; i < uploadedImage.length && otherPhotos?.length < 12; i++) {
        setOtherPhotos((prev) => [...prev, uploadedImage[i]]);
      }
    }
    setUploadedImage([]);
    setUploadedImageCount(0);
  }, [uploadedImageCount]);
  console.log(singlePhotos, "singlePhotos");
  console.log(uploadedImage, "uploadedImage");
  const onCompanyLogoError = (data) => {
    console.log("imageerror");
    setLoading(false);
  };
  const onImageRemove = (value, type) => {
    console.log(value, "removeimage");
    if (type === "single_sharing"){
      console.log("isless", "singlePhotos");
        setSinglePhotos(singlePhotos.filter((item) => item !== value));
    } 
    else if (type === "double_sharing") {
        setDoublePhotos(doublePhotos.filter((item) => item !== value));
      }
      else if (type === "triple_sharing") {
        setTriplePhotos(triplePhotos.filter((item) => item !== value));
      }
      else if (isWhichRoomSharing === "four_sharing") {
        setFourPhotos(fourPhotos.filter((item) => item !== value));
      }
    else if (type === "other_sharing") {
        setOtherPhotos(otherPhotos.filter((item) => item !== value));
      }
  };
  console.log(singlePhotos, "singlePhotos");
  return (
    <div>
      
      {pgDetails?.single_sharing?.enabled && 
      <>
      {/* {pgDetails?.single_sharing?.enabled === true &&
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Project Photos({singlePhotos?.length}/12)</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      } */}
      
      <div className="border_radius_10px">
        <div className="row single_sharing">
          {singlePhotos?.map((item, index) => {
            return (
              <div className="col-2 my-3 position-relative">
                  <img
                    className="w-100 px-2"
                    style={{ height: "17rem" }}
                    src={item}
                    alt="property image"
                  />

                <div className="position-absolute top-0 end-0">
                  <img className="me-3" src={DeleteIcon} alt="Delete-icon" onClick={() => onImageRemove(item, "single_sharing")}/>
                </div>
              </div>
            );
          })}
          {singlePhotos?.length > 0 && singlePhotos?.length < 12 && 
           <div
           className="col-2 border_radius_10px border_blue_color my-3 ms-4 d-flex justify-content-center align-items-center"
           style={{ height: "17rem", maxWidth: "15rem" }}
           onClick={() => setisWhichRoomSharing("single_sharing")}
           
         >
           <label className="border d-flex justify-content-end">
             <input
               disabled={singlePhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span className={``}>
               <img src={UploadIcon} />
             </span>
           </label>
           </div>
         }
         
        </div>
      </div>
      {singlePhotos?.length >= 12 && (
        <span className="fs_14 fw_500">
          <span className="red_color">Note : </span>
            <span className="secondary_color">
                You have exceeded the limit of photos
            </span>
        </span>
      )}
      {singlePhotos?.length < 1 && 
       <div
       className={`${singlePhotos?.length < 1 && "d-flex justify-content-center align-items-center border_color_grey border_radius_10px"}`}
       style={{ height: singlePhotos?.length < 1 && "20rem" }}
     >
       {singlePhotos?.length < 1 && (
         <div onClick={() => setisWhichRoomSharing("single_sharing")}>
           <label className="border d-flex justify-content-end">
             <input
               disabled={singlePhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span
               className={`border_radius_5px p-1 px-3  cursor_pointer ${singlePhotos?.length >= 12 ? "disable_blue_bg" : "primary_bg"}`}
             >
               Upload
             </span>
           </label>
         </div>
       )}
       </div>
      }
      </>
      }

      {pgDetails?.double_sharing?.enabled && 
      <>
      <div className="border_radius_10px">
        <div className="row double_sharing">
          {doublePhotos?.map((item, index) => {
            return (
              <div className="col-2 my-3 position-relative">
                  <img
                    className="w-100 px-2"
                    style={{ height: "17rem" }}
                    src={item}
                    alt="property image"
                  />

                <div className="position-absolute top-0 end-0">
                  <img className="me-3" src={DeleteIcon} alt="Delete-icon" onClick={() => onImageRemove(item, "double_sharing")}/>
                </div>
              </div>
            );
          })}
          {doublePhotos?.length > 0 && doublePhotos?.length < 12 && 
           <div
           className="col-2 border_radius_10px border_blue_color my-3 ms-4 d-flex justify-content-center align-items-center"
           style={{ height: "17rem", maxWidth: "15rem" }}
           onClick={() => setisWhichRoomSharing("double_sharing")}
           
         >
           <label className="border d-flex justify-content-end">
             <input
               disabled={doublePhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span className={``}>
               <img src={UploadIcon} />
             </span>
           </label>
           </div>
         }
         
        </div>
      </div>
      {doublePhotos?.length >= 12 && (
        <span className="fs_14 fw_500">
          <span className="red_color">Note : </span>
            <span className="secondary_color">
                You have exceeded the limit of photos
            </span>
        </span>
      )}
      {doublePhotos?.length < 1 && 
       <div
       className={`${doublePhotos?.length < 1 && "d-flex justify-content-center align-items-center border_color_grey border_radius_10px"}`}
       style={{ height: doublePhotos?.length < 1 && "20rem" }}
     >
       {doublePhotos?.length < 1 && (
         <div onClick={() => setisWhichRoomSharing("double_sharing")}>
           <label className="border d-flex justify-content-end">
             <input
               disabled={doublePhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span
               className={`border_radius_5px p-1 px-3  cursor_pointer ${doublePhotos?.length >= 12 ? "disable_blue_bg" : "primary_bg"}`}
             >
               Upload
             </span>
           </label>
         </div>
       )}
       </div>
      }
      </>
      }
      {pgDetails?.triple_sharing?.enabled && 
      <>
      <div className="border_radius_10px">
        <div className="row triple_sharing">
          {triplePhotos?.map((item, index) => {
            return (
              <div className="col-2 my-3 position-relative">
                  <img
                    className="w-100 px-2"
                    style={{ height: "17rem" }}
                    src={item}
                    alt="property image"
                  />

                <div className="position-absolute top-0 end-0">
                  <img className="me-3" src={DeleteIcon} alt="Delete-icon" onClick={() => onImageRemove(item, "triple_sharing")}/>
                </div>
              </div>
            );
          })}
          {triplePhotos?.length > 0 && triplePhotos?.length < 12 && 
           <div
           className="col-2 border_radius_10px border_blue_color my-3 ms-4 d-flex justify-content-center align-items-center"
           style={{ height: "17rem", maxWidth: "15rem" }}
           onClick={() => setisWhichRoomSharing("triple_sharing")}
           
         >
           <label className="border d-flex justify-content-end">
             <input
               disabled={triplePhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span className={``}>
               <img src={UploadIcon} />
             </span>
           </label>
           </div>
         }
         
        </div>
      </div>
      {triplePhotos?.length >= 12 && (
        <span className="fs_14 fw_500">
          <span className="red_color">Note : </span>
            <span className="secondary_color">
                You have exceeded the limit of photos
            </span>
        </span>
      )}
      {triplePhotos?.length < 1 && 
       <div
       className={`${triplePhotos?.length < 1 && "d-flex justify-content-center align-items-center border_color_grey border_radius_10px"}`}
       style={{ height: triplePhotos?.length < 1 && "20rem" }}
     >
       {triplePhotos?.length < 1 && (
         <div onClick={() => setisWhichRoomSharing("triple_sharing")}>
           <label className="border d-flex justify-content-end">
             <input
               disabled={triplePhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span
               className={`border_radius_5px p-1 px-3  cursor_pointer ${triplePhotos?.length >= 12 ? "disable_blue_bg" : "primary_bg"}`}
             >
               Upload
             </span>
           </label>
         </div>
       )}
       </div>
      }
      </>
      }

      {pgDetails?.four_sharing?.enabled && 
      <>
      <div className="border_radius_10px">
        <div className="row four_sharing">
          {fourPhotos?.map((item, index) => {
            return (
              <div className="col-2 my-3 position-relative">
                  <img
                    className="w-100 px-2"
                    style={{ height: "17rem" }}
                    src={item}
                    alt="property image"
                  />

                <div className="position-absolute top-0 end-0">
                  <img className="me-3" src={DeleteIcon} alt="Delete-icon" onClick={() => onImageRemove(item, "four_sharing")}/>
                </div>
              </div>
            );
          })}
          {fourPhotos?.length > 0 && fourPhotos?.length < 12 && 
           <div
           className="col-2 border_radius_10px border_blue_color my-3 ms-4 d-flex justify-content-center align-items-center"
           style={{ height: "17rem", maxWidth: "15rem" }}
           onClick={() => setisWhichRoomSharing("four_sharing")}
           
         >
           <label className="border d-flex justify-content-end">
             <input
               disabled={fourPhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span className={``}>
               <img src={UploadIcon} />
             </span>
           </label>
           </div>
         }
         
        </div>
      </div>
      {fourPhotos?.length >= 12 && (
        <span className="fs_14 fw_500">
          <span className="red_color">Note : </span>
            <span className="secondary_color">
                You have exceeded the limit of photos
            </span>
        </span>
      )}
      {fourPhotos?.length < 1 && 
       <div
       className={`${fourPhotos?.length < 1 && "d-flex justify-content-center align-items-center border_color_grey border_radius_10px"}`}
       style={{ height: fourPhotos?.length < 1 && "20rem" }}
     >
       {fourPhotos?.length < 1 && (
         <div onClick={() => setisWhichRoomSharing("four_sharing")}>
           <label className="border d-flex justify-content-end">
             <input
               disabled={fourPhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span
               className={`border_radius_5px p-1 px-3  cursor_pointer ${fourPhotos?.length >= 12 ? "disable_blue_bg" : "primary_bg"}`}
             >
               Upload
             </span>
           </label>
         </div>
       )}
       </div>
      }
      </>
      }

      {pgDetails?.other?.enabled && 
      <>
      <div className="border_radius_10px">
        <div className="row other_sharing">
          {otherPhotos?.map((item, index) => {
            return (
              <div className="col-2 my-3 position-relative">
                  <img
                    className="w-100 px-2"
                    style={{ height: "17rem" }}
                    src={item}
                    alt="property image"
                  />

                <div className="position-absolute top-0 end-0">
                  <img className="me-3" src={DeleteIcon} alt="Delete-icon" onClick={() => onImageRemove(item, "other_sharing")}/>
                </div>
              </div>
            );
          })}
          {otherPhotos?.length > 0 && otherPhotos?.length < 12 && 
           <div
           className="col-2 border_radius_10px border_blue_color my-3 ms-4 d-flex justify-content-center align-items-center"
           style={{ height: "17rem", maxWidth: "15rem" }}
           onClick={() => setisWhichRoomSharing("other_sharing")}
           
         >
           <label className="border d-flex justify-content-end">
             <input
               disabled={otherPhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span className={``}>
               <img src={UploadIcon} />
             </span>
           </label>
           </div>
         }
         
        </div>
      </div>
      {otherPhotos?.length >= 12 && (
        <span className="fs_14 fw_500">
          <span className="red_color">Note : </span>
            <span className="secondary_color">
                You have exceeded the limit of photos
            </span>
        </span>
      )}
      {otherPhotos?.length < 1 && 
       <div
       className={`${otherPhotos?.length < 1 && "d-flex justify-content-center align-items-center border_color_grey border_radius_10px"}`}
       style={{ height: otherPhotos?.length < 1 && "20rem" }}
     >
       {otherPhotos?.length < 1 && (
         <div onClick={() => setisWhichRoomSharing("other_sharing")}>
           <label className="border d-flex justify-content-end">
             <input
               disabled={otherPhotos?.length >= 12}
               type={"file"}
               style={{ visibility: "hidden", width: "0px" }}
               id="logo"
               name="img"
               multiple
               accept="image/png,image/jpeg,image/jpeg,capture=camera"
               onChange={handleImage}
             />
             <span
               className={`border_radius_5px p-1 px-3  cursor_pointer ${otherPhotos?.length >= 12 ? "disable_blue_bg" : "primary_bg"}`}
             >
               Upload
             </span>
           </label>
         </div>
       )}
       </div>
      }
      </>
      }
    </div>
  );
};

export default EditPgUploadImage;
