import { MULTIPLE_PROPERTY_PHOTOS, PROPERTY_FIND_ONE } from "../../type";


export const residentialImagesUploadAction = (image, onSuccess, onError) => {
    console.log(image, "callimage");
    return {
      type: MULTIPLE_PROPERTY_PHOTOS,
      image,
      onSuccess,
      onError,
    };
  };