import React, { useState } from "react";
import UseFormikInputField from "../../../form/useFormikInputField";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikSelectField from "../../../form/useFormikSelectField";
import { removeUnderScore } from "../../../helper/helper";
const WearhouseFeatures = ({ formik, setCompareAminities, propertyDetails, isClickedOnAme, setIsClickedOnAme }) => {
  const suitableForList = [
    {
      name: "godown_for_any_dry_storage",
      label: "Godown for any dry storage",
      status: false,
    },
    {
      name: "godown_for_cold_storage",
      label: "Godown for cold storage",
      status: false,
    },
    {
      name: "godown_with_climate_control",
      label: "Godown with climate control",
      status: false,
    },
  ];
  const initialAminities = propertyDetails?.property_features?.suitable_for;
  const aminitiesAreEqual = (currentSuitableFor, initialSuitableFor) => {
    const getCurrentAminitieStatus = currentSuitableFor?.filter((item) => item?.status === true)?.length;
    const getInitialAminitieStatus = initialSuitableFor?.filter((item) => item?.status === true )?.length;
    const isAmentiesMatches = currentSuitableFor?.every((currentItem, index) => initialSuitableFor[index]?.status === currentItem?.status);
    if (isAmentiesMatches) {
      setCompareAminities(true);
    } else {
      setCompareAminities(false);
    }
  };
  const updatedAminitiesList = suitableForList?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {
      return backendAminity.name === aminity.name;
    });

    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
  
    return aminity;
  });
  const [suitableFor, setSuitableFor] = useState(updatedAminitiesList);
  const handleCheckboxChange = (index) => {
    const newUpdatedAminitiesList = [...suitableFor];
    newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
    setSuitableFor(newUpdatedAminitiesList);
    const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
    formik.setFieldValue("property_features.suitable_for", filteredAmenities);
  };
  if(isClickedOnAme){
    aminitiesAreEqual(suitableFor, formik?.initialValues?.property_features?.suitable_for);
  }
  
  console.log(suitableFor, "amenitiesvilla");
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h3 className={`light_blue_color fs_20 me-4`}>
          Property Features
        </h3>
        <hr
          className={`py-1 flex-grow-1 border-primary`}
        />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.total_floors}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.total_floors}
            formikTouched={formik?.touched?.property_features?.total_floors}
            itemName="property_features.total_floors"
            labelName="Total Floors"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.washrooms}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.washrooms}
            formikTouched={formik?.touched?.property_features?.washrooms}
            itemName="property_features.washrooms"
            labelName="Washrooms"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
             inputClass={styles.postprop_input}
             placeholder="Ex: 2"
             inputValue={formik?.values?.property_features?.number_of_open_sides}
             onChangeFn={formik.handleChange}
             onBlurFn={formik.handleBlur}
             formikError={formik?.errors?.property_features?.number_of_open_sides}
             formikTouched={formik?.touched?.property_features?.number_of_open_sides}
             itemName="property_features.number_of_open_sides"
             labelName="Number of Open Sides"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
             inputClass={styles.postprop_input}
             placeholder="Ex: 2"
             inputValue={
               formik?.values?.property_features?.floors_allowed_for_construction
             }
             onChangeFn={formik.handleChange}
             onBlurFn={formik.handleBlur}
             formikError={
               formik?.errors?.property_features?.floors_allowed_for_construction
             }
             formikTouched={
               formik?.touched?.property_features
                 ?.floors_allowed_for_construction
             }
             itemName="property_features.floors_allowed_for_construction"
             labelName="Floors Allowed for Construction"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
             placeholder="Ex: 2"
             customStyles={{ width: "100%" }}
             inputValue={
               formik?.values?.property_features?.width_of_road_facing_the_plot
             }
             onChangeFn={formik.handleChange}
             onBlurFn={formik.handleBlur}
             formikError={
               formik?.errors?.property_features?.width_of_road_facing_the_plot
             }
             formikTouched={
               formik?.touched?.property_features?.width_of_road_facing_the_plot
             }
             itemName="property_features.width_of_road_facing_the_plot"
             labelName="Width of Road Facing the Plot"
             length="meter"
          />
        </div>
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center mt-3">
      <UseFormikSelectField
          label="Facing"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.facing"
          formik={formik}
          formikError={formik?.errors?.property_features?.facing}
          formikTouched={formik?.touched?.property_features?.facing}
          defaultValue={
            formik?.values?.property_features?.facing && {
              label: removeUnderScore(formik?.values?.property_features?.facing),
              value: formik?.values?.property_features?.facing,
            }
          }
        />
        <UseFormikSelectField
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          label="Furnishing Status"
          name="property_features.furnishing_status"
          formik={formik}
          formikError={formik?.errors?.property_features?.status}
          formikTouched={formik?.touched?.property_features?.status}
          defaultValue={
            formik?.values?.property_features?.status && {
              label: removeUnderScore(formik?.values?.property_features?.status),
              value: formik?.values?.property_features?.status,
            }
          }
        />
        <UseFormikSelectField
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          label="Status"
          name="property_features.status"
          formik={formik}
          formikError={formik?.errors?.property_features?.status}
          formikTouched={formik?.touched?.property_features?.status}
          defaultValue={
            formik?.values?.property_features?.status && {
              label: removeUnderScore(formik?.values?.property_features?.status),
              value: formik?.values?.property_features?.status,
            }
          }
        />
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Suitable For</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        <div className="row g-0">
          {suitableFor?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                  {item?.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default WearhouseFeatures;
