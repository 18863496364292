import { call, takeLatest } from "redux-saga/effects";
import { EDIT_PG, LIVE_PG_LIST, PGSREQUESTFINDALL, PG_FIND_ONE } from "../../type";
import { PgRequestApi, editPgApi, livePgListApi, pgFindOneApi } from "../../api/property-api/pglist-api";

export function* pgRequestWorker(action) {
  try {
    const response = yield call(PgRequestApi, action.data);
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* livePgListWorker(action) {
  try {
    const response = yield call(livePgListApi, action.data);
    if (response.status === 200) {
      yield action.onSuccess(response.data);
    } else {
      yield action.onError(response.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* pgFindOneWorker(action) {
  try {
    const res = yield call(pgFindOneApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}

export function* editPgWorker(action) {
  try {
    const res = yield call(editPgApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);

    } else if (res.status === 200 && res.data.status === "fail") {

      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {

    yield action.onError("Something Went Wrong");
  }
}
export function* pgsRequestWatcher() {
  yield takeLatest(PGSREQUESTFINDALL, pgRequestWorker);
}
export function* livePgListWatcher() {
  yield takeLatest(LIVE_PG_LIST, livePgListWorker);
}
export function* pgFindOneWacher() {
  yield takeLatest(PG_FIND_ONE, pgFindOneWorker);
}
export function* editPgWatcher() {
  yield takeLatest(EDIT_PG, editPgWorker);
}