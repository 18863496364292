import { all } from "redux-saga/effects";
import {
  commercialPropertyViewWatcher,
  editCommercialIndusBuildingWatcher,
  editCommercialIndusPlotWatcher,
  editCommercialIndusShedWatcher,
  editCommercialOfficeSpaceWatcher,
  editCommercialPlotWatcher,
  editCommercialShopWatcher,
  editCommercialShowroomWatcher,
  editCommercialWareHouseWatcher,
} from "../../edit-property-saga/commercial/commercialSagas";

export default function* editPropertyCommercialRootSaga() {
  yield all([
    commercialPropertyViewWatcher(),
    editCommercialOfficeSpaceWatcher(),
    editCommercialPlotWatcher(),
    editCommercialShopWatcher(),
    editCommercialShowroomWatcher(),
    editCommercialIndusBuildingWatcher(),
    editCommercialIndusPlotWatcher(),
    editCommercialIndusShedWatcher(),
    editCommercialWareHouseWatcher(),
  ]);
}
