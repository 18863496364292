import { FLAT_APARTMENT_EDIT, HOUSE_EDIT, PLOT_EDIT, PROPERTY_FIND_ONE, VILLA_EDIT } from "../../type";

export const residentialFindOneAction = (data, onSuccess, onError) => {
    return {
      type: PROPERTY_FIND_ONE,
      data,
      onSuccess,
      onError,
    };
  };
  export const editResidentialApartmentAction = (data, onSuccess, onError) => {
    return {
      type: FLAT_APARTMENT_EDIT,
      data,
      onSuccess,
      onError,
    };
  };
  export const editResidentialVillaAction = (data, onSuccess, onError) => {
    return {
      type: VILLA_EDIT,
      data,
      onSuccess,
      onError,
    };
  };
  export const editResidentialHouseAction = (data, onSuccess, onError) => {
    return {
      type: HOUSE_EDIT,
      data,
      onSuccess,
      onError,
    };
  };
  export const editResidentialPlotAction = (data, onSuccess, onError) => {
    return {
      type: PLOT_EDIT,
      data,
      onSuccess,
      onError,
    };
  };