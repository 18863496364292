import { call, takeLatest } from "redux-saga/effects";
import { PROFILE_FETCH } from "../../type";
import { profileFtechApi } from "../../api/auth-api/profileFecthApi";
export function* profileFetchWorker(action) {
  try {
    const res = yield call(profileFtechApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res.data);
    }
  } catch (error) {
    console.log(error);
    yield action.onError(error.response);
  }
}
export function* profileFetchWatcher() {
  yield takeLatest(PROFILE_FETCH, profileFetchWorker);
}
