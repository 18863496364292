import React from "react";
import { useParams } from "react-router-dom";
import EditFlatApartment from "../../components/edit-property/main-pages/residential/flat-apartment";
import EditVilla from "../../components/edit-property/main-pages/residential/villa";
import EditResidentialHouse from "../../components/edit-property/main-pages/residential/residential-house";
import EditResidentialPlot from "../../components/edit-property/main-pages/residential/residential-plot";
import EditOfficeSpace from "../../components/edit-property/main-pages/commercial/office-space";

import EditWearhouse from "../../components/edit-property/main-pages/commercial/wearhouse";
import IndustrialPlot from "../../components/edit-property/main-pages/commercial/industrial-plot";
import IndustrialShed from "../../components/edit-property/main-pages/commercial/industrial-shed";
import CommercialPlot from "../../components/edit-property/main-pages/commercial/commercial-plot";
import IndustrialBuilding from "../../components/edit-property/main-pages/commercial/industrial-building";
import AgricultureFarmHouse from "../../components/edit-property/main-pages/agriculture/agriculture-farm-house";
import EditAgricultureLand from "../../components/edit-property/main-pages/agriculture/agriculture-land";
import EditCommercialShowroom from "../../components/edit-property/main-pages/commercial/commercial-showroom";
import EditCommercialShop from "../../components/edit-property/main-pages/commercial/commercial-shop";

const LiveEditViewPropertiy = () => {
  const params = useParams();
  return (
    <div>
      {params.type == "apartment" && <EditFlatApartment />}
      {params.type == "villa" && <EditVilla />}
      {params.type == "plot" && <EditResidentialPlot />}
      {params.type == "residential" && <EditResidentialHouse />}

      {params.type == "office_space" && <EditOfficeSpace />}
      {params.type == "commercial_shop" && <EditCommercialShop />}
      {params.type == "commercial_showroom" && <EditCommercialShowroom />}
      {params.type == "warehouse" && <EditWearhouse />}
      {params.type == "industrial_plot" && <IndustrialPlot />}
      {params.type == "industrial_shed" && <IndustrialShed />}
      {params.type == "commercial_plot" && <CommercialPlot />}
      {params.type == "industrial_building" && <IndustrialBuilding />}

      {params.type == "farm_house" && <AgricultureFarmHouse />}
      {params.type == "land" && <EditAgricultureLand />}
    </div>
  );
};

export default LiveEditViewPropertiy;
