import React from "react";
import UseFormikPriceInputField from "../../../form/useFormikPriceInputField";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikInputField from "../../../form/useFormikInputField";
import DeleteIcon from "../../../../assets/icons/delete-icon-without-backgrround.png";
const FeaturesVariation = ({ formik, index }) => {
  const handelDeleteVarition = (deleteIndex) => {
    const variation = formik?.values?.project_features;
    const updateVariant = variation?.filter((item, index) => index !== deleteIndex);
    formik.setFieldValue("project_features", updateVariant);
  };
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Features Variation</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className={`fs_15 me-3 color_323D5A d-flex justify-content-between`}>
        <span>
          Variant {index + 1} -{" "}
          <span className="pagination_text_color">
            {
              formik?.values?.project_features?.[index]
                ?.no_of_units_in_variation
            }{" "}
            Units
          </span>
        </span>
        {formik?.values?.project_features?.length > 1 && (
          <span onClick={() => handelDeleteVarition(index)}>
            <img
              className="me-2"
              src={DeleteIcon}
              alt="Delete-icon"
              width={15}
            />
            Delete
          </span>
        )}
      </div>
      <div className="mt-4" key={index}>
        <div className="col-3">
          <UseFormikInputField
            placeholder="Ex: 2"
            customStyles={{ width: "100%" }}
            inputValue={
              formik?.values?.project_features?.[index]?.no_of_units_in_variation
            }
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={
              formik?.errors?.project_features?.[index]?.no_of_units_in_variation
            }
            formikTouched={
              formik?.touched?.project_features?.[index]?.no_of_units_in_variation
            }
            itemName={`project_features[${index}].no_of_units_in_variation`}
            labelName="Number of Units in this variation"
          />
        </div>
        <div className="d-flex flex-wrap gap-5 align-items-center">
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikInputField
              placeholder="Ex: 2"
              customStyles={{ width: "100%" }}
              inputValue={formik?.values?.project_features?.[index]?.bedrooms}
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={formik?.errors?.project_features?.[index]?.bedrooms}
              formikTouched={
                formik?.touched?.project_features?.[index]?.bedrooms
              }
              itemName={`project_features[${index}].bedrooms`}
              labelName="Bedrooms"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikInputField
              placeholder="Ex: 2"
              customStyles={{ width: "100%" }}
              inputValue={formik?.values?.project_features?.[index]?.bathrooms}
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={formik?.errors?.project_features?.[index]?.bathrooms}
              formikTouched={
                formik?.touched?.project_features?.[index]?.bathrooms
              }
              itemName={`project_features[${index}].bathrooms`}
              labelName="Bathrooms"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikInputField
              placeholder="Ex: 2"
              customStyles={{ width: "100%" }}
              inputValue={formik?.values?.project_features?.[index]?.balconies}
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={formik?.errors?.project_features?.[index]?.balconies}
              formikTouched={
                formik?.touched?.project_features?.[index]?.balconies
              }
              itemName={`project_features[${index}].balconies`}
              labelName="Balconies"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikInputField
              placeholder="Ex: 2"
              customStyles={{ width: "100%" }}
              inputValue={
                formik?.values?.project_features?.[index]?.parking_count
              }
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={
                formik?.errors?.project_features?.[index]?.parking_count
              }
              formikTouched={
                formik?.touched?.project_features?.[index]?.parking_count
              }
              itemName={`project_features[${index}].parking_count`}
              labelName="Parking Count"
            />
          </div>
        </div>
        <div className="d-flex flex-wrap gap-5 align-items-center">
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikPriceInputField
              placeholder="Enter super area"
              customStyles={{ width: "100%" }}
              inputValue={
                formik?.values?.project_features?.[index]?.built_up_area
              }
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={
                formik?.errors?.project_features?.[index]?.built_up_area
              }
              formikTouched={
                formik?.touched?.project_features?.[index]?.built_up_area
              }
              itemName={`project_features[${index}].built_up_area`}
              label="Build-up Area"
              length="sqft"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikPriceInputField
              placeholder="Enter carpet area"
              customStyles={{ width: "100%" }}
              inputValue={
                formik?.values?.project_features?.[index]?.carpet_area
              }
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={
                formik?.errors?.project_features?.[index]?.carpet_area
              }
              formikTouched={
                formik?.touched?.project_features?.[index]?.carpet_area
              }
              itemName={`project_features[${index}].carpet_area`}
              label="Carpet Area"
              length="sqft"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikPriceInputField
              placeholder="Enter property price"
              customStyles={{ width: "100%" }}
              inputValue={
                formik?.values?.project_features?.[index]?.expected_price
              }
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={
                formik?.errors?.project_features?.[index]?.expected_price
              }
              formikTouched={
                formik?.touched?.project_features?.[index]?.expected_price
              }
              itemName={`project_features[${index}].expected_price`}
              label="Expected Price"
              currency="₹"
            />
          </div>
          <div className={`${styles.property_feature_input_container}`}>
            <UseFormikPriceInputField
              placeholder="Enter maintenance fees(Optional)"
              customStyles={{ width: "100%" }}
              inputValue={
                formik?.values?.project_features?.[index]?.maintenance_fee
              }
              onChangeFn={formik.handleChange}
              onBlurFn={formik.handleBlur}
              formikError={
                formik?.errors?.project_features?.[index]?.maintenance_fee
              }
              formikTouched={
                formik?.touched?.project_features?.[index]?.maintenance_fee
              }
              itemName={`project_features[${index}].maintenance_fee`}
              label="Maintenance Fees"
              currency="₹"
            />
          </div>
        </div>
      </div>
      {typeof formik?.errors?.project_features === "string" &&
      <div className="text-danger">{formik?.errors?.project_features}</div>
      }
    </div>
  );
};

export default FeaturesVariation;
