import React, { useEffect } from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { useRef } from "react";

const ArrowDownIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L6.442 5.79401C6.59763 5.92671 6.79547 5.9996 7 5.9996C7.20453 5.9996 7.40237 5.92671 7.558 5.79401L12 2"
      stroke="#323D5A"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
);
export const DropDownSelect = ({
  options = [],
  placeholder = "select the option",
  onChange = () => {},
  isInputDisabled = false,
  defaultValue = { label: "", value: "" },
  noOptionMsg = "No option available",
  noMatchMsg = "No match found",
  DropDownIcon = ArrowDownIcon,
  isDropDownIcon = true,
  className = "",
  disabled = false,
  inputClass = "",
  customStyles = {
    container: {},
    option: {},
    input: {},
  },
}) => {
  const mainRef = useRef("menu");
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });
  const [inputValue, setInputValue] = useState(defaultValue.label);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && mainRef.current && !mainRef.current.contains(e.target)) {
        setIsActive(false);
        setInputValue(inputValue);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive]);
  return (
    <Container
      className={className}
      customStyles={customStyles.container}
      ref={mainRef}
    >
      {" "}
      <div
        onClick={() => {
          !disabled && setIsActive(!isActive);
        }}
        className={`d-flex align-items-center gap-2 mx-3`}
      >
        {" "}
        <span>{inputValue}</span>
        {/* <Input
          customStyles={customStyles.input}
          disabled={isInputDisabled}
          placeholder={placeholder}
          onFocus={() => setIsActive(true)}
          className={inputClass}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          type="text"
        />{" "} */}
        {isDropDownIcon && (
          <DropDownIcon
            onClick={() => {
              !disabled && setIsActive(true);
            }}
          />
        )}{" "}
      </div>{" "}
      {!disabled && isActive && (
        <DropDown customStyles={{}}>
          {" "}
          <FilteredOption
            optionStyles={customStyles.option}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setInputValue={setInputValue}
            setIsActive={setIsActive}
            options={options}
            inputValue={inputValue}
            onChange={onChange}
            noOptionMsg={noOptionMsg}
            noMatchMsg={noMatchMsg}
          />{" "}
        </DropDown>
      )}{" "}
    </Container>
  );
};
export default DropDownSelect;
const FilteredOption = ({
  optionStyles = {},
  options,
  inputValue,
  setSelectedOption,
  setInputValue,
  setIsActive,
  onChange,
  noOptionMsg,
  noMatchMsg,
  activeOptionStyle = {},
  selectedOption = {
    label: "",
    value: "",
  },
}) => {
  // let filterOutput = options.filter((item) => {
  //   return item.label.includes(inputValue);
  // });
  return options.length == 0 ? (
    <Option customStyles={optionStyles}>{noOptionMsg}</Option>
  ) : !inputValue ? (
    options.map((item, index) => {
      return (
        <Option
          customStyles={optionStyles}
          key={index}
          onClick={() => {
            setSelectedOption({ label: item.label, value: item.value });
            setInputValue(item.label);
            setIsActive(false);
            onChange(item);
          }}
        >
          {item.label}
        </Option>
      );
    })
  ) : (
    options.map((item, index) => {
      return (
        <Option
          customStyles={optionStyles}
          style={
            item.value === selectedOption.value
              ? {
                  backgroundColor: "#a5a5a5",
                  ...activeOptionStyle,
                }
              : {}
          }
          key={index}
          onClick={() => {
            setSelectedOption({ label: item.label, value: item.value });
            setInputValue(item.label);
            setIsActive(false);
            onChange(item);
          }}
        >
          {item.label}
        </Option>
      );
    })
  );

  // filterOutput.length > 0 ? (
  //   filterOutput.map((item, index) => {
  //     return (
  //       <Option
  //         customStyles={optionStyles}
  //         // style={
  //         //   item.value === selectedOption.value
  //         //     ? {
  //         //       backgroundColor: "#a5a5a5",
  //         //       ...activeOptionStyle,
  //         //     }
  //         //     : {}
  //         // }
  //         key={index}
  //         onClick={() => {
  //           setSelectedOption({ label: item.label, value: item.value });
  //           setInputValue(item.label);
  //           setIsActive(false);
  //           onChange(item);
  //         }}
  //       >
  //         {item.label}
  //       </Option>
  //     );
  //   })
  // ) : (
  //   <Option customStyles={optionStyles}>{noMatchMsg}</Option>
  // );
};
const DropDown = styled.ul((props) => ({
  boxShadow: "0px 0px 6px #00000029",
  zIndex: "10",
  position: "absolute",
  border: "none",
  width: "fit-content",
  minWidth: "100%",
  backgroundColor: "white",
  listStyle: "none",
  padding: "0",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "1.3rem",
  marginTop: "3px",
  overflowY: "auto",
  maxHeight: "200px",
  color: "blue",
  borderRadius: "5px",
  ...props?.customStyles,
}));
const Container = styled("div")((props) => {
  // return { position: "relative", width: "250px", ...props?.customStyles };
});
const Option = styled("li")((props) => {
  return {
    width: "100%",
    minHeight: "20px",
    padding: "5px",
    fontSize: "14px",
    color: "#112f6a",
    fontWeight: "400",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#a5a5a5",
    },
    ...props?.customStyles,
  };
});
const Input = styled("input")((props) => {
  return {
    border: "none",
    "&:focus": {
      outline: "none",
    },
    color: "currentColor",
    width: "100%",
    fontSize: "inherit",
    background: "white",

    ...props?.customStyles,
  };
});
