import { api } from "../api";

export const projectFindOneApi = (data) => {
  return api.get(
    `/api/admin/project/view?project_id=${data?.project_id}&property_type=${data?.property_type}`,
    {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
        "Content-Type": "application/json",
      },
    }
  );
};

export const editProjectApartmentApi = (params) => {
  return api.post("/api/admin/project/edit-apartment-project", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const editProjectVillaApi = (params) => {
  return api.post("/api/admin/project/edit-villa-project", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
export const editProjectPlotApi = (params) => {
  return api.post("/api/admin/project/edit-residential-plot", params, {
    headers: {
      "x-access-token": localStorage.getItem("accessToken"),
      "Content-Type": "application/json",
    },
  });
};
