import React from "react";
import UseFormikPriceInputField from "../../form/useFormikPriceInputField";

const ResidentialPlotPriceDetails = ({formik}) => {
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Price Details</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row">
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot length"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_length}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.plot_length}
            formikTouched={formik?.touched?.price_details?.plot_length}
            itemName="price_details.plot_length"
            label="Plot Length"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot width"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_width}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.plot_width}
            formikTouched={formik?.touched?.price_details?.plot_width}
            itemName="price_details.plot_width"
            label="Plot Width"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_area}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.plot_area}
            formikTouched={formik?.touched?.price_details?.plot_area}
            itemName="price_details.plot_area"
            label="Plot Area"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter property price"
            customStyles={{ width: "65%" }}
            inputValue={formik?.values?.price_details?.expected_price}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.expected_price}
            formikTouched={formik?.touched?.price_details?.expected_price}
            itemName="price_details.expected_price"
            label="Expected Price"
            currency="₹"
          />
        </div>
      </div>
    </div>
  );
};

export default ResidentialPlotPriceDetails;
