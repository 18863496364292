import React, { useEffect, useState } from "react";
import zameenSquare_logo from "../../assets/images/zameenSquare_logo.png";
import NavbarOptionIcon from "../../assets/icons/option-icon";
import LogoutExit from "../../assets/icons/logout-exit";
import MenuIcon from "../../assets/icons/menu-icon";
import { Offcanvas } from "react-bootstrap";
import CloseIcon from "../../assets/icons/close-icon";
import SideBar from "./side-bar";
import { useDispatch } from "react-redux";
import successToast from "../helper-functions/successToast";
import { useNavigate } from "react-router-dom";
import zameen_square_Z_logo from "../../assets/icons/zameen-square-Z-logo.png";
import { logoutActions } from "../../redux/action/auth-actions/authAction";
import { profileFetchActions } from "../../redux/action/auth-actions/profieFetchAction";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [profileData, setProfileData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(profileFetchActions(onFetchSuccess, onFetchError));
  }, []);
  const onFetchSuccess = (data) => {
    setProfileData(data?.data);
    console.log(data);
  };
  const onFetchError = (data) => {
    console.log(data);
  };
  console.log(profileData, "kjhg");
  function logoutFn() {
    dispatch(logoutActions());
    successToast("Logout Successfully");
  }
  
  return (
    <>
      <div className="border-bottom font_family_poppins border_color sticky-top bg-white">
        <div className="d-flex justify-content-between mx-lg-5 py-3 ps-3 align-items-center">
          <div className="d-flex">
            <div>
              <img
                src={zameenSquare_logo}
                className="zameen_square_logo_height cursor_pointer d-sm-block d-none"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="d-block d-sm-none">
              <img src={zameen_square_Z_logo} height={38} />
            </div>
          </div>
          {localStorage.getItem("accessToken") ? (
            <div className="d-flex align-items-center">
              <div>
                <img src={profileData?.profile_picture} height={38} />
              </div>

              <div className="fw-400 text_color_grey ps-2">
                {profileData?.email}{" "}
              </div>

              <NavbarOptionIcon
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
                className={"ms-2 d-none d-lg-block"}
                width="10px"
                height="15px"
              />
              <div className="position-relative  " onClick={() => logoutFn()}>
                {showMenu && (
                  <div
                    style={{
                      top: "1rem",
                      right: "-5px",
                      // border: "1px solid #000000",
                      boxShadow: "0px 0px 15px 0px #0000001A",
                    }}
                    className={` p-3 position-absolute bg-white text-nowrap rounded-2 mt-2 pe-5 cursor_pointer`}
                  >
                    <LogoutExit />
                    <span className="ps-3">Logout</span>
                  </div>
                )}
              </div>
              <div
                onClick={() => setShow(true)}
                className="d-lg-none ps-2 pe-3"
              >
                <MenuIcon style={{ height: "20px" }} />
              </div>
            </div>
          ) : null}

          <Offcanvas
            show={show}
            onHide={() => setShow(false)}
            placement="start"
            className="side-bar-container "
          >
            <Offcanvas.Body>
              <div className="text-end" onClick={() => setShow(false)}>
                <CloseIcon />
              </div>
              <div className=" ">
                <SideBar setShow={setShow} show={show} />
              </div>
              <div
                className="d-flex align-items-end text-center justify-content-center"
                style={{ height: "80%" }}
              >
                <div
                  style={{ boxShadow: "0px 0px 15px 0px #0000001A" }}
                  className="py-2 px-4 rounded-3 fw_400 cursor_pointer"
                  onClick={() => logoutFn()}
                >
                  <LogoutExit />
                  <span className="ps-3 fw_400">Logout</span>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </>
  );
};

export default Navbar;
