import React from "react";
import UseFormikPriceInputField from "../../form/useFormikPriceInputField";

const AgricultureLandRentPriceDetails = ({ formik }) => {
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Price Details</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row">
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_area}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.plot_area}
            formikTouched={formik?.touched?.price_details?.plot_area}
            itemName="price_details.plot_area"
            label="Plot Area"
            length="Acre"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter deposite price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.deposite_price}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.deposite_price}
            formikTouched={formik?.touched?.price_details?.deposite_price}
            itemName="price_details.deposite_price"
            label="Deposit Price"
            currency="₹"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter rent price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.rent_price}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.rent_price}
            formikTouched={formik?.touched?.price_details?.rent_price}
            itemName="price_details.rent_price"
            label="Rent Price"
            currency="₹"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter maintenance fees(Optional)"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.maintenance_fees}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.maintenance_fees}
            formikTouched={formik?.touched?.price_details?.maintenance_fees}
            itemName="price_details.maintenance_fees"
            label="Maintenance Fees"
            currency="₹"
          />
        </div>
      </div>
    </div>
  );
};

export default AgricultureLandRentPriceDetails;
