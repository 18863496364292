import React, { useEffect, useState } from "react";
import styles from "../styles/property-details.module.css";
import Modal_property from "../components/ui/modals/modal_property";
import Primarybutton from "../components/ui/buttons/primary-button";
import Leftarrowicon from "../assets/icons/left-arrow-icon";
import PropertyLabel from "../components/projectFindone/propertyLabel";
import { useDispatch } from "react-redux";
import ProjectFeatures from "../components/projectFindone/projectBasicDetails";
import { useNavigate, useParams } from "react-router-dom";
import successToast from "../components/helper-functions/successToast";
import errorToast from "../components/helper-functions/errorToast";
import { projectViewAction } from "../redux/action/project-actions/projectViewAction";

const ProjectFindone = () => {
  const [show, setShow] = useState(false);
  const [modalButton, setModalButton] = useState("");
  const [property_photos, setProperty_photos] = useState();
  const [variation, setVariation] = useState();
  const [common_features, setCommon_features] = useState();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [basicDeatails, setBasicDeatails] = useState();
  const [Subscription, setSubscription] = useState();
  const handleshow = () => {
    setShow(true);
  };
  const handleclose = () => {
    setShow(false);
  };
  useEffect(() => {
    dispatch(
      projectViewAction(
        {
          project_id: params?.id,
        },
        onSuccess,
        onError
      )
    );
  }, []);
  const onSuccess = (data) => {
    console.log(data?.data, "sss");
    console.log(data?.data?.common_features, "vvv");
    setBasicDeatails(data?.data);
    setSubscription(data?.data?.SubscriptionPlanDetail);
    setProperty_photos(data?.data?.property_photos);
    setVariation(data?.data?.project_features);
    setCommon_features(data?.data?.common_features);
    successToast(data?.message);
  };
  const onError = (data) => {
    console.log(data);
    errorToast(data?.message);
  };

  console.log(variation, "ooo");
  console.log(common_features, "asd");

  return (
    <>
      <div className="font_family_poppins">
        <p className="fw_400 fs_16 text_color_grey ms-5 mt-3 ">
          ADMIN / PROPERTIES / LIVE PROPERTIES /{basicDeatails?.id} - VIEW
        </p>
        <div className={` ${styles.border_color_gray} rounded-4 m-4 `}>
          <Modal_property
            show={show}
            handleclose={handleclose}
            background={modalButton === "Approve" ? "green" : "red"}
            btnText={modalButton === "Approve" ? "approve" : "reject"}
          />
          <div className={`d-flex justify-content-between my-2 `}>
            <div
              className="d-flex align-items-center ms-4 ps-3"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <Leftarrowicon />
              <Primarybutton
                btnText={"BACK"}
                className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
              />
            </div>
            <div>
              <Primarybutton
                btnText={"APPROVE"}
                type="button"
                onClick={() => {
                  handleshow();
                  setModalButton("Approve");
                }}
                className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
              />
              <Primarybutton
                btnText={"REJECT"}
                type="button"
                onClick={() => {
                  handleshow();
                  setModalButton("Reject");
                }}
                className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
              />
            </div>
          </div>
          <div className="border-bottom"></div>
          <div className="mx-4">
            <PropertyLabel descText={"Project Basic Details"} />
            <ProjectFeatures data={basicDeatails} plan={Subscription} />
            {/*  <PropertyLabel descText={"Project Photos"} />
            <div className="ms-2">
              <ProjectImages property_photos={property_photos} />
            </div>
            <PropertyLabel descText={"Boucher"} />
            <BroucherDownload data={basicDeatails} />
            <PropertyLabel descText={"Property Location"} />
            <GoogleMap_component />

            <PropertyLabel descText={"Features Variation"} />
            <ProjectFeaturesVariation data={variation} /> */}
            {/* <ProjectFeaturesVariation data={basicDeatails} /> */}

            {/* <PropertyLabel descText={"Common Features"} />
            <ProjectCommonFeatures data={common_features} />
            <PropertyLabel descText={"Project Amenities "} />
            <ProjectAmenities data={basicDeatails} />
            <PropertyLabel descText={"Property Description"} /> */}
            {/* <div className="fs_14 fw_400 ms-4 text-capitalize">
              {basicDeatails?.description}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectFindone;
