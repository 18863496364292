import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRouter = () => {
  return (
    <div>
      {!localStorage.getItem("accessToken") ? (
        <Outlet />
      ) : (
        <Navigate to="/property-project/live-property-requests" />
      )}
    </div>
  );
};

export default ProtectedRouter;
