import { call, takeLatest } from "redux-saga/effects";
import {
  PROJECT_APARTMENT_EDIT,
  PROJECT_FIND_ONE,
  PROJECT_PLOT_EDIT,
  PROJECT_VILLA_EDIT,
} from "../../type";
import {
  editProjectApartmentApi,
  editProjectPlotApi,
  editProjectVillaApi,
  projectFindOneApi,
} from "../../api/project-api/residentialProjectApi";

export function* projectsFindOneWorker(action) {
  try {
    const res = yield call(projectFindOneApi, action.data);
    if (res.status === 200) {
      yield action.onSuccess(res.data);
    } else {
      yield action.onError(res?.data);
    }
  } catch (error) {
    yield action.onError(error?.response);
  }
}
export function* editProjectApartmentWorker(action) {
  try {
    const res = yield call(editProjectApartmentApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editProjectVillaWorker(action) {
  try {
    const res = yield call(editProjectVillaApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}
export function* editProjectPlotWorker(action) {
  try {
    const res = yield call(editProjectPlotApi, action.data);
    if (res.status === 200 && res.data.status === "success") {
      yield action.onSuccess(res.data);
    } else if (res.status === 200 && res.data.status === "fail") {
      yield action.onError(res);
    } else {
      yield action.onError("Something Went Wrong");
    }
  } catch (err) {
    yield action.onError("Something Went Wrong");
  }
}

export function* projectFindOneWatch() {
  yield takeLatest(PROJECT_FIND_ONE, projectsFindOneWorker);
}
export function* editProjectApartmentWatch() {
  yield takeLatest(PROJECT_APARTMENT_EDIT, editProjectApartmentWorker);
}
export function* editProjectVillaWatch() {
  yield takeLatest(PROJECT_VILLA_EDIT, editProjectVillaWorker);
}
export function* editProjectPlotWatch() {
  yield takeLatest(PROJECT_PLOT_EDIT, editProjectPlotWorker);
}
