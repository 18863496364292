import React from "react";
import UseFormikPriceInputField from "../../../form/useFormikPriceInputField";

const AgricultureFarmHouseRentPriceDetails = ({ formik }) => {
  return (
    <div>
      <div className={`d-flex align-items-center my-3`}>
        <h3 className={`light_blue_color fs_22 fs_md_20 fs_sm_18 me-4 `}>
          Price Details
        </h3>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="row">
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter super area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.super_area}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.super_area}
            formikTouched={formik?.touched?.price_details?.super_area}
            itemName="price_details.super_area"
            label="Super Area"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter carpet area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.carpet_area}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.carpet_area}
            formikTouched={formik?.touched?.price_details?.carpet_area}
            itemName="price_details.carpet_area"
            label="Carpet Area"
            length="Sqft"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter plot area"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.plot_area}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.plot_area}
            formikTouched={formik?.touched?.price_details?.plot_area}
            itemName="price_details.plot_area"
            label="Plot Area"
            length="Acre"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter deposite price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.deposite_price}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.deposite_price}
            formikTouched={formik?.touched?.price_details?.deposite_price}
            itemName="price_details.deposite_price"
            label="Deposit Price"
            currency="₹"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter rent price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.rent_price}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.price_details?.rent_price}
            formikTouched={formik?.touched?.price_details?.rent_price}
            itemName="price_details.rent_price"
            label="Rent Price"
            currency="₹"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter maintenance fees(Optional)"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.price_details?.maintenance_fees}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.price_details?.maintenance_fees}
            formikTouched={formik?.touched?.price_details?.maintenance_fees}
            itemName="price_details.maintenance_fees"
            label="Maintenance Fees"
            currency="₹"
          />
        </div>
      </div>
    </div>
  );
};

export default AgricultureFarmHouseRentPriceDetails;
