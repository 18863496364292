import React, { useEffect, useState } from "react";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikPriceInputField from "../../../form/useFormikPriceInputField";
import UseFormikSelectField from "../../../form/useFormikSelectField";
import UseFormikInputField from "../../../map/useFormikInputField";
import { removeUnderScore } from "../../../helper/helper";

const ResidentialFeatures = ({formik, projectDetails, setCompareAminities, isClickedOnAme, setIsClickedOnAme}) => {
  const suitableForList = [
    {
      name: "elevators_lifts",
      label: "Elevators/Lifts",
      status: false,
    },
    {
      name: "guest_parking_space",
      label: "Guest Parking Spaces",
      status: false,
    },
    { name: "cctv_surveillance", label: "CCTV Surveillance", status: false },
    {
      name: "swimming_pool",
      label: "Swimming Pool",
      status: false,
    },
    {
      name: "kids_play_area",
      label: "Kids Play Area",
      status: false,
    },
    {
      name: "community_clubhouse",
      label: "Community Clubhouse",
      status: false,
    },
    {
      name: "pet_washing_stations",
      label: "Pet Washing Stations",
      status: false,
    },

    {
      name: "rooftop_lounge_areas",
      label: "Rooftop Lounge Areas",
      status: false,
    },
    {
      name: "electronic_vehicle_charging_points",
      label: "Electronic Vehicle Charging Points",
      status: false,
    },
    {
      name: "barbecue_areas",
      label: "Barbecue Areas",
      status: false,
    },
    {
      name: "gym",
      label: "Gym",
      status: false,
    },
    {
      name: "garden",
      label: "Garden",
      status: false,
    },
    {
      name: "play_ground",
      label: "Play Grounds",
      status: false,
    },
  ];
  // const initialAminities = projectDetails?.common_features?.amenities;
  // const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);
  // const handleCheckboxChange = (index) => {
  //   const updatedSuitableFor = [...suitableFor];
  //   updatedSuitableFor[index].status = !updatedSuitableFor[index].status;
  //   setSuitableFor(updatedSuitableFor);
  //   formik.setFieldValue("common_features.amenities", updatedSuitableFor);
  // };
  
  // const aminitiesAreEqual = (currentAminities, initialAminities)=>{
  //   const getCurrentAminitieStatus = currentAminities?.filter((item)=> item?.status === true)?.length;
  //   const getInitialAminitieStatus = initialAminities?.filter((item)=> item?.status === true)?.length;
  //   if(getCurrentAminitieStatus === getInitialAminitieStatus){
  //     setCompareAminities(true);
  //   }
  //   else{
  //     setCompareAminities(false);
  //   }
  // }
  //  aminitiesAreEqual(suitableFor, formik?.initialValues?.common_features?.amenities);

  const initialAminities = projectDetails?.common_features?.amenities;
  const [suitableFor, setSuitableFor] = useState(initialAminities ? JSON.parse(JSON.stringify(initialAminities)) : []);
  
  const aminitiesAreEqual = (currentProjAminities, initialProjAminities)=>{
    const isAmentiesMatches = currentProjAminities?.every((currentItem, index) => initialProjAminities?.[index]?.status === currentItem?.status);
    if(isAmentiesMatches){
      setCompareAminities(true);
    }
    else{
      setCompareAminities(false);
    }
  }
  const updatedAminitiesList = suitableForList?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {
      return backendAminity.name === aminity.name;
    });
    console.log(matchingBackendAminity, "matchingBackendAminity");
    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
  
    return aminity;
  });
  
  // const [amenities, setAmenities] = useState(updatedAminitiesList);
  // const handleCheckboxChange = (index) => {
  //   const newUpdatedAminitiesList = [...amenities];
  //   newUpdatedAminitiesList[index]?.status = !newUpdatedAminitiesList[index]?.status;
  //   setAmenities(newUpdatedAminitiesList);
  //   const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
  //   formik.setFieldValue("common_features.amenities", filteredAmenities);
  // };
  const [amenities, setAmenities] = useState(updatedAminitiesList);

  const handleCheckboxChange = (index) => {
  const newUpdatedAminitiesList = [...amenities];
  newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
  setAmenities(newUpdatedAminitiesList);
  const filteredAmenities = newUpdatedAminitiesList.map((item) => { return { name: item.name, status: item.status }});
  formik.setFieldValue("common_features.amenities", filteredAmenities);
};
if(isClickedOnAme){
  aminitiesAreEqual(amenities, formik?.initialValues?.common_features?.amenities);
};
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Common Features</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            placeholder="Ex: 2"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.common_features?.total_floor}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.common_features?.total_floor}
            formikTouched={formik?.touched?.common_features?.total_floor}
            itemName="common_features.total_floor"
            labelName="Total Floors"
          />
        </div>
        <UseFormikSelectField
          label="Furnishing Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="common_features.furnishing_status"
          formik={formik}
          formikError={formik?.errors?.common_features?.furnishing_status}
          formikTouched={formik?.touched?.common_features?.furnishing_status}
          defaultValue={
            formik?.values?.common_features?.furnishing_status && {
              label: removeUnderScore(
                formik?.values?.common_features?.furnishing_status
              ),
              value: formik?.values?.common_features?.furnishing_status,
            }
          }
        />
        <UseFormikSelectField
          label="Status"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="common_features.status"
          formik={formik}
          formikError={formik?.errors?.common_features?.status}
          formikTouched={formik?.touched?.common_features?.status}
          defaultValue={
            formik?.values?.common_features?.status && {
              label: removeUnderScore(
                formik?.values?.common_features?.status
              ),
              value: formik?.values?.common_features?.status,
            }
          }
        />
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Property Amenities</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        <div className="row g-0">
          {amenities?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      // name={`common_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() =>{ handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.label}
                    {/* {item?.name} */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="row g-0">
          {Array.isArray(aminities) && aminities?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`common_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => handleCheckboxChange(index)}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.name ? removeUnderScore(item?.name) : "-"}
                  </div>
                </div>
              </div>
            );
          })}
        </div> */}
      </>
    </div>
  );
};

export default ResidentialFeatures;
