import { all } from "redux-saga/effects";
import {
  agriculturePropertyViewWatcher,
  editAgricultureFarmHouseWatch,
  editAgricultureLandWatch
} from "../../edit-property-saga/agriculture/agriculture-saga";

export default function* editPropertyAgricultureRootSaga() {
  yield all([agriculturePropertyViewWatcher(), editAgricultureFarmHouseWatch(), editAgricultureLandWatch()]);
}
