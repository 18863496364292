import { call, takeLatest } from "redux-saga/effects";
import { dashboardApi } from "../api/dashboard-api";
import { DASHBOARD } from "../type";

export function* dashboardWorker(action) {
    try {
      const response = yield call(dashboardApi, action.data);
      console.log(response, "aaab");
      if (response.status === 200) {
        yield action.onSuccess(response.data);
      } else {
        yield action.onError(response.data);
      }
    } catch (error) {
      yield action.onError(error?.response);
    }
  }
  export function* dashboardWatcher() {
    yield takeLatest(DASHBOARD, dashboardWorker);
  }