import React, { useState } from "react";
import UseFormikInputField from "../../../form/useFormikInputField";
import styles from "../../../../styles/edit-property/edit-property.module.css";
import UseFormikSelectField from "../../../form/useFormikSelectField";
import { removeUnderScore } from "../../../helper/helper";
const IndustrialBuildingFeatures = ({ formik, setCompareAminities, propertyDetails, isClickedOnAme, setIsClickedOnAme }) => {
 
  const indShedSuitableForList = [
    {
      name: "agricultural_or_farm_sheds",
      label: "Agricultural/ Farm Sheds",
      status: false,
    },
    {
      name: "workshop_or_manufacturing_shed",
      label: "Workshop/ Manufacturing Shed",
      status: false,
    },
    {
      name: "machinery_storage_unit",
      label: "Machinery Storage Unit",
      status: false,
    },
  ];

  const indBuildingSuitableForList = [
    {
      name: "industries_for_the_production_of_power",
      label: "Industries For The Production Of Power",
      status: false,
    },
    {
      name: "manufacturing_industries",
      label: "Manufacturing Industries",
      status: false,
    },
    { name: "textile_industries", label: "Textile Industries", status: false },
    {
      name: "raw_materials_mining_industries",
      label: "Raw Materials Mining Industries",
      status: false,
    },
    {
      name: "petroleum_products_industries",
      label: "Petroleum Products Industries",
      status: false,
    },
    {
      name: "industries_of_wood_and_paper_products",
      label: "Industries Of Wood And Paper Products",
      status: false,
    },
    {
      name: "industries_of_chemicals_plastics_and_metals ",
      label: "Industries Of Chemicals, Plastics, And Metals",
      status: false,
    },
  ];
  const isIndShedOrIndBuildibg = propertyDetails?.property_type === "industrial_shed" ? indShedSuitableForList : indBuildingSuitableForList
  const initialAminities = propertyDetails?.property_features?.suitable_for;
  const aminitiesAreEqual = (currentFuitableFor, initialFuitableFor) => {
    const getCurrentAminitieStatus = currentFuitableFor?.filter((item) => item?.status === true)?.length;
    const getInitialAminitieStatus = initialFuitableFor?.filter((item) => item?.status === true )?.length;
    const isAmentiesMatches = currentFuitableFor?.every((currentItem, index) => initialFuitableFor[index]?.status === currentItem?.status);
    // const isAmentiesMatches = currentFuitableFor?.every((currentItem, index) => {
    //   const initialItem = initialFuitableFor[index];
    //   return currentItem?.status === initialItem?.status;
    // });
    console.log(isAmentiesMatches, "isAmentiesMatches");
    if (isAmentiesMatches) {
      setCompareAminities(true);
    } else {
      setCompareAminities(false);
    }
  };
  const updatedAminitiesList = isIndShedOrIndBuildibg?.map((aminity) => {
    const matchingBackendAminity = initialAminities?.find((backendAminity) => {
      return backendAminity.name === aminity.name;
    });

    if (matchingBackendAminity) {
      return { ...aminity, status: matchingBackendAminity.status };
    }
  
    return aminity;
  });
  const [suitableFor, setSuitableFor] = useState(updatedAminitiesList);
  const handleCheckboxChange = (index) => {
    const newUpdatedAminitiesList = [...suitableFor];
    newUpdatedAminitiesList[index].status = !newUpdatedAminitiesList[index].status;
    setSuitableFor(newUpdatedAminitiesList);
    const filteredAmenities = newUpdatedAminitiesList?.map((item) => { return { name: item?.name, status: item?.status}});
    formik.setFieldValue("property_features.suitable_for", filteredAmenities);
  };
  if(isClickedOnAme){
    aminitiesAreEqual(suitableFor, formik?.initialValues?.property_features?.suitable_for);
  }
  
  return (
    <div>
      <div className={` my-3 d-flex align-items-center`}>
        <h3 className={`light_blue_color fs_20 me-4`}>Suitable For</h3>
        <hr className={`py-1 flex-grow-1 border-primary`} />
      </div>
      <div className="d-flex flex-wrap gap-5 align-items-center">
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.total_floors}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.total_floors}
            formikTouched={formik?.touched?.property_features?.total_floors}
            itemName="property_features.total_floors"
            labelName="Total Floors"
          />
        </div>
        <div className={`${styles.property_feature_input_container}`}>
          <UseFormikInputField
            inputClass={styles.postprop_input}
            placeholder="Ex: 2"
            inputValue={formik?.values?.property_features?.floors_allowed_for_construction}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.property_features?.floors_allowed_for_construction}
            formikTouched={formik?.touched?.property_features?.floors_allowed_for_construction}
            itemName="property_features.floors_allowed_for_construction"
            labelName="Floors Allowed for Construction"
          />
        </div>
        <UseFormikSelectField
          label="Facing"
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          name="property_features.facing"
          formik={formik}
          formikError={formik?.errors?.property_features?.facing}
          formikTouched={formik?.touched?.property_features?.facing}
          defaultValue={
            formik?.values?.property_features?.facing && {
              label: removeUnderScore(formik?.values?.property_features?.facing),
              value: formik?.values?.property_features?.facing,
            }
          }
        />
        <UseFormikSelectField
          customStyles={{ maxWidth: "264px", marginBottom: "0px" }}
          label="Status"
          name="property_features.status"
          formik={formik}
          formikError={formik?.errors?.property_features?.status}
          formikTouched={formik?.touched?.property_features?.status}
          defaultValue={
            formik?.values?.property_features?.status && {
              label: removeUnderScore(formik?.values?.property_features?.status),
              value: formik?.values?.property_features?.status,
            }
          }
        />
      </div>
      <>
        <div className={` my-3 d-flex align-items-center`}>
          <h5 className={`light_blue_color fs_18 me-3`}>Property Amenities</h5>
          <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
        <div className="row g-0">
          {suitableFor?.map((item, index) => {
            return (
              <div className="col-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="mt-1">
                    <input
                      className=""
                      type="checkbox"
                      name={`property_features.amenities[${index}].status`}
                      checked={item.status}
                      onChange={() => {handleCheckboxChange(index); setIsClickedOnAme(true)}}
                      value={item?.name}
                      style={{ width: "17px", height: "17px" }}
                    />
                  </div>
                  <div className="ms-2 d-inline-block fs_15 secondary_color">
                    {item?.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default IndustrialBuildingFeatures;
