import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import SideBar from "./side-bar";
import { Outlet } from "react-router";

const MainLayout = () => {
  return (
    <div>
      <Navbar />
      <div className="d-flex">
        <div
          className={`${"sidebar_container   d-lg-block d-none position-sticky" }  mt-4 `} 
        >
          <SideBar />
        </div>
        <div
          className={`w-100 overflow-x-scroll main_scrollbar  p-4 table_background_color_grey h-100`}
          style={{ minHeight: "90vh" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
