import React, { useEffect, useState } from "react";
import PgRoomSharing from "./pg-room-sharing";
import styles from "../../styles/property-details.module.css";
import EditPgUploadImage from "./edit-upoad-photo";
import { removeUnderScore } from "../helper/helper";
import UseFormikPriceInputField from "../form/useFormikPriceInputField";
import UseFormikInputField from "../form/useFormikInputField";
const EditPgFeatures = ({
  formik,
  pgDetails,
  singlePhotos,
  setSinglePhotos,
  doublePhotos,
  setDoublePhotos,
  triplePhotos,
  setTriplePhotos,
  fourPhotos,
  setFourPhotos,
  otherPhotos,
  setOtherPhotos,
  setIsOneAmeMathces,
  setIsTwoAmeMatches,
  setIsTripleAmeMatches,
  setIsFourAmeMatches,
  setIsOtherMatches
}) => {
  const singleAmenities = pgDetails && pgDetails?.single_sharing?.amenities;
  const doubleAmenities = pgDetails && pgDetails?.double_sharing?.amenities;
  const tripleAmenities = pgDetails && pgDetails?.triple_sharing?.amenities;
  const fourAmenities = pgDetails && pgDetails?.four_sharing?.amenities;
  const otherAmenities = pgDetails && pgDetails?.other?.amenities;
  const [initDoubleAmenities, setInitDoubleAmenities] = useState(doubleAmenities ? JSON.parse(JSON.stringify(doubleAmenities)) : []);
  const [initSingleAmenities, setInitSingleAmenities] = useState(singleAmenities ? JSON.parse(JSON.stringify(singleAmenities)) : []);
  const [initTripleAmenities, setInitTripleAmenities] = useState(tripleAmenities ? JSON.parse(JSON.stringify(tripleAmenities)) : []);
  const [initFourAmenities, setInitFourAmenities] = useState(fourAmenities ? JSON.parse(JSON.stringify(fourAmenities)) : []);
  const [initOtherAmenities, setInitOtherAmenities] = useState(otherAmenities ? JSON.parse(JSON.stringify(otherAmenities)) : []);

  // function handleSingleAmenitiesChange(index) {
  //   const updatedAmenities = [...formik?.values?.single_sharing?.amenities];
  //   updatedAmenities[index].status = !updatedAmenities[index].status;
  //   formik?.setFieldValue("single_sharing.amenities", updatedAmenities);
  // }

  // function handleDoubleAmenitiesChange(index) {
  //   const updatedAmenities = [...formik?.values?.double_sharing?.amenities];
  //   updatedAmenities[index].status = !updatedAmenities[index].status;
  //   formik?.setFieldValue("double_sharing.amenities", updatedAmenities);
  // }

  // function handleTripleAmenitiesChange(index) {
  //   const updatedAmenities = [...formik?.values?.triple_sharing?.amenities];
  //   updatedAmenities[index].status = !updatedAmenities[index].status;
  //   formik?.setFieldValue("triple_sharing.amenities", updatedAmenities);
  // }

  // function handleFourAmenitiesChange(index) {
  //   const updatedAmenities = [...formik?.values?.four_sharing?.amenities];
  //   updatedAmenities[index].status = !updatedAmenities[index].status;
  //   formik?.setFieldValue("four_sharing.amenities", updatedAmenities);
  // }

  // function handleOtherAmenitiesChange(index, values, setFieldValue) {
  //   const updatedAmenities = [...formik?.values?.other?.amenities];
  //   updatedAmenities[index].status = !updatedAmenities[index].status;
  //   formik?.setFieldValue("other.amenities", updatedAmenities);
  // }

  function handleSingleAmenitiesChange(index) {
    const updatedAmenities = [...initSingleAmenities];
    updatedAmenities[index].status = !updatedAmenities[index].status;
    setInitSingleAmenities(updatedAmenities);
    formik?.setFieldValue("single_sharing.amenities", updatedAmenities);
  }

  function handleDoubleAmenitiesChange(index) {
    const updatedAmenities = [...initDoubleAmenities];
    updatedAmenities[index].status = !updatedAmenities[index].status;
    setInitDoubleAmenities(updatedAmenities);
    formik?.setFieldValue("double_sharing.amenities", updatedAmenities);
  }
   function handleTripleAmenitiesChange(index) {
    const updatedAmenities = [...initTripleAmenities];
    updatedAmenities[index].status = !updatedAmenities[index].status;
    setInitTripleAmenities(updatedAmenities);
    formik?.setFieldValue("triple_sharing.amenities", updatedAmenities);
  }
  function handleFourAmenitiesChange(index) {
    const updatedAmenities = [...initFourAmenities];
    updatedAmenities[index].status = !updatedAmenities[index].status;
    setInitFourAmenities(updatedAmenities);
    formik?.setFieldValue("four_sharing.amenities", updatedAmenities);
  }
  function handleOtherAmenitiesChange(index, values, setFieldValue) {
    const updatedAmenities = [...initOtherAmenities];
    updatedAmenities[index].status = !updatedAmenities[index].status;
    setInitOtherAmenities(updatedAmenities);
    formik?.setFieldValue("other.amenities", updatedAmenities);
  }

  const singleAminitiesAreEqual = (currentSingleAminities, initialSingleAminities)=>{
    const isAmentiesMatches = currentSingleAminities?.every((currentItem, index) => initialSingleAminities[index]?.status === currentItem?.status);
    if(isAmentiesMatches){
      setIsOneAmeMathces(true);
    }
    else{
      setIsOneAmeMathces(false);
    }
  }
  singleAminitiesAreEqual(formik?.values?.single_sharing?.amenities, formik?.initialValues?.single_sharing?.amenities);

  const doubleAminitiesAreEqual = (currentSingleAminities, initialDoubleAminities)=>{
    const isAmentiesMatches = currentSingleAminities?.every((currentItem, index) => initialDoubleAminities[index]?.status === currentItem?.status);
    if(isAmentiesMatches){
      setIsTwoAmeMatches(true);
    }
    else{
      setIsTwoAmeMatches(false);
    }
  }
  doubleAminitiesAreEqual(formik?.values?.double_sharing?.amenities, formik?.initialValues?.double_sharing?.amenities);

  const tripleAminitiesAreEqual = (currentTripleAminities, initialTripleAminities)=>{
    const isAmentiesMatches = currentTripleAminities?.every((currentItem, index) => initialTripleAminities[index]?.status === currentItem?.status);
    if(isAmentiesMatches){
      setIsTripleAmeMatches(true);
    }
    else{
      setIsTripleAmeMatches(false);
    }
  }
  tripleAminitiesAreEqual(formik?.values?.triple_sharing?.amenities, formik?.initialValues?.triple_sharing?.amenities);
  
  const fourAminitiesAreEqual = (currentFourAminities, initialFourAminities)=>{
    const isAmentiesMatches = currentFourAminities?.every((currentItem, index) => initialFourAminities[index]?.status === currentItem?.status);
    if(isAmentiesMatches){
      setIsFourAmeMatches(true);
    }
    else{
      setIsFourAmeMatches(false);
    }
  }
  fourAminitiesAreEqual(formik?.values?.four_sharing?.amenities, formik?.initialValues?.four_sharing?.amenities);

  const otherAminitiesAreEqual = (currentOtherAminities, initialOtherAminities)=>{
    const isAmentiesMatches = currentOtherAminities?.every((currentItem, index) => initialOtherAminities[index]?.status === currentItem?.status);
    if(isAmentiesMatches){
      setIsOtherMatches(true);
    }
    else{
      setIsOtherMatches(false);
    }
  }
  otherAminitiesAreEqual(formik?.values?.other?.amenities, formik?.initialValues?.other?.amenities);

  return (
    <div>
      <div className="single_sharing">
        <label className={`${styles.selectpaymeth_checkbox_label} me-3`}>
          <input
            style={{ width: "17px", height: "17px" }}
            type="checkbox"
            checked={formik?.values?.single_sharing?.enabled}
            onChange={(e) => {
              formik?.setFieldValue(
                "single_sharing.enabled",
                e?.target?.checked
              );
            }}
            name="single_sharing.enabled"
          />
          <span className={`${styles.selectpaymeth_checkbox_span}`}></span>
          <span className="ms-2 d-inline-block fs_15 text-capitalize">
            Single Sharing Room
          </span>
        </label>

        {formik?.values?.single_sharing?.enabled && (
          <>
            <PgRoomSharing
              formik={formik}
              floorInputName={"single_sharing.number"}
              floorInputValue={formik?.values?.single_sharing?.number}
              floorInputError={formik?.errors?.single_sharing?.number}
              floorInputTouched={formik?.touched?.single_sharing?.number}
              RentInputName={"single_sharing.rent"}
              RentInputValue={formik?.values?.single_sharing?.rent}
              RentInputError={formik?.errors?.single_sharing?.rent}
              RentInputTouched={formik?.touched?.single_sharing?.rent}
              DepositeInputName={"single_sharing.security_deposite"}
              DepositeInputValue={
                formik?.values?.single_sharing?.security_deposite
              }
              DepositeInputError={
                formik?.errors?.single_sharing?.security_deposite
              }
              DepositeInputTouched={
                formik?.touched?.single_sharing?.security_deposite
              }
              amenitiesList={formik?.values?.single_sharing?.amenities}
              amenitiesListName={"single_sharing.amenities"}
              imageName={"single_sharing.photos"}
            />
            <div className="row">
              {formik?.values?.single_sharing?.amenities?.map((item, index) => {
                return (
                  <>
                    <div className="col-3 mb-3">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            name={`amenities.[${index}].status`}
                            checked={item.status}
                            onChange={() => handleSingleAmenitiesChange(index)}
                            value={item?.name}
                            style={{ width: "17px", height: "17px" }}
                          />
                          <span className="ms-2 d-inline-block fs_15 text-capitalize">
                            {removeUnderScore(item?.name)}
                          </span>
                        </label>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div>
             <div className={` my-3 d-flex align-items-center`}>
             <h5 className={`light_blue_color fs_18 me-3`}>Project Photos({singlePhotos?.length}/12)</h5>
             <hr className={`py-1 flex-grow-1 border-primary`} />
            </div>
          <EditPgUploadImage
            singlePhotos={singlePhotos}
            setSinglePhotos={setSinglePhotos}
            pgDetails={formik?.values}
          />
        </div>
          </>
        )}
      </div>

      <div className="double_sharing mt-5">
      <label className={`${styles.selectpaymeth_checkbox_label} me-3`}>
          <input
            style={{ width: "17px", height: "17px" }}
            type="checkbox"
            checked={formik?.values?.double_sharing?.enabled}
            onChange={(e) => {formik?.setFieldValue("double_sharing.enabled", e?.target?.checked);}}
            name="double_sharing.enabled"
          />
          <span className={`${styles.selectpaymeth_checkbox_span}`}></span>
          <span className="ms-2 d-inline-block fs_15 text-capitalize">
            Double Sharing Room
          </span>
        </label>
        {formik?.values?.double_sharing?.enabled && (
          <>
            <PgRoomSharing
              formik={formik}
              floorInputName={"double_sharing.number"}
              floorInputValue={formik?.values?.double_sharing?.number}
              floorInputError={formik?.errors?.double_sharing?.number}
              floorInputTouched={formik?.touched?.double_sharing?.number}
              RentInputName={"double_sharing.rent"}
              RentInputValue={formik?.values?.double_sharing?.rent}
              RentInputError={formik?.errors?.double_sharing?.rent}
              RentInputTouched={formik?.touched?.double_sharing?.rent}
              DepositeInputName={"double_sharing.security_deposite"}
              DepositeInputValue={formik?.values?.double_sharing?.security_deposite}
              DepositeInputError={formik?.errors?.double_sharing?.security_deposite}
              DepositeInputTouched={formik?.touched?.double_sharing?.security_deposite
              }
              amenitiesList={formik?.values?.double_sharing?.amenities}
              amenitiesListName={"double_sharing.amenities"}
              imageName={"double_sharing.photos"}
            />
            {/* <div className="row mt-3">
        <div className="col-3 mt-1">
          <div style={{maxWidth:"300px"}}>
            <UseFormikInputField
            placeholder="Enter No. of Rooms"
            inputValue={formik?.values?.double_sharing?.number}
            onChangeFn={formik.handleChange}
            onBlurFn={formik.handleBlur}
            formikError={formik?.errors?.double_sharing?.number}
            formikTouched={formik?.touched?.double_sharing?.number}
            itemName={"double_sharing.number"}
            labelName="No. of Single Rooms in PG"
            custClassName="p-2"
            />
          </div>
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter rent price"
            customStyles={{ width: "100%" }}
            inputValue={formik?.values?.double_sharing?.rent}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.double_sharing?.rent}
            formikTouched={formik?.touched?.double_sharing?.rent}
            itemName={"double_sharing.rent"}
            label="Rent"
            currency="₹"
          />
        </div>
        <div className="col-3">
          <UseFormikPriceInputField
            placeholder="Enter security deposit"
            customStyles={{ width: "300px" }}
            inputValue={formik?.values?.double_sharing?.security_deposite}
            onChangeFn={formik?.handleChange}
            onBlurFn={formik?.handleBlur}
            formikError={formik?.errors?.double_sharing?.security_deposite}
            formikTouched={formik?.touched?.double_sharing?.security_deposite}
            itemName={"double_sharing.security_deposite"}
            label="Security Deposit"
            currency="₹"
          />
        </div>
           </div> */}
            <div className="row">
              {initDoubleAmenities?.map((item, index) => {
                return (
                  <>
                    <div className="col-3 mb-3">
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            // name={`amenities.[${index}].status`}
                            checked={item.status}
                            onChange={() => handleDoubleAmenitiesChange(index)}
                            value={item?.name}
                            style={{ width: "17px", height: "17px" }}
                          />
                          <span className="ms-2 d-inline-block fs_15 text-capitalize">
                            {removeUnderScore(item?.name)}
                          </span>
                        </label>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div>
        <div className={` my-3 d-flex align-items-center`}>
        <h5 className={`light_blue_color fs_18 me-3`}>Project Photos({doublePhotos?.length}/12)</h5>
        <hr className={`py-1 flex-grow-1 border-primary`} />
        </div>
          <EditPgUploadImage
            doublePhotos={doublePhotos}
            setDoublePhotos={setDoublePhotos}
            pgDetails={formik?.values}
          />
        </div>
          </>
        )}
      </div>

      <div className="trible_sharing mt-4">
        <label className={`${styles.selectpaymeth_checkbox_label} me-3`}>
          <input
            style={{ width: "17px", height: "17px" }}
            type="checkbox"
            checked={formik?.values?.triple_sharing?.enabled}
            onChange={(e) => {
              // handleSingleRoomChange(e);
              formik?.setFieldValue(
                "triple_sharing.enabled",
                e?.target?.checked
              );
            }}
            name="triple_sharing.enabled"
          />
          <span className={`${styles.selectpaymeth_checkbox_span}`}></span>
          <span className="ms-2 d-inline-block fs_15 text-capitalize">
            Trible Sharing Room
          </span>
        </label>
          {formik?.values?.triple_sharing?.enabled && (
            <>
              <PgRoomSharing
                formik={formik}
                floorInputName={"triple_sharing.number"}
                floorInputValue={formik?.values?.triple_sharing?.number}
                floorInputError={formik?.errors?.triple_sharing?.number}
                floorInputTouched={formik?.touched?.triple_sharing?.number}
                RentInputName={"triple_sharing.rent"}
                RentInputValue={formik?.values?.triple_sharing?.rent}
                RentInputError={formik?.errors?.triple_sharing?.rent}
                RentInputTouched={formik?.touched?.triple_sharing?.rent}
                DepositeInputName={"triple_sharing.security_deposite"}
                DepositeInputValue={
                  formik?.values?.triple_sharing?.security_deposite
                }
                DepositeInputError={
                  formik?.errors?.triple_sharing?.security_deposite
                }
                DepositeInputTouched={
                  formik?.touched?.triple_sharing?.security_deposite
                }
                amenitiesList={formik?.values?.triple_sharing?.amenities}
                amenitiesListName={"triple_sharing.amenities"}
                imageName={"triple_sharing.photos"}
              />

              <div className="row">
                {formik?.values?.triple_sharing?.amenities?.map(
                  (item, index) => {
                    return (
                      <>
                        <div className="col-3 mb-3">
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                name={`amenities.[${index}].status`}
                                checked={item.status}
                                onChange={() =>
                                  handleTripleAmenitiesChange(index)
                                }
                                value={item?.name}
                                style={{ width: "17px", height: "17px" }}
                              />
                              <span className="ms-2 d-inline-block fs_15 text-capitalize">
                                {removeUnderScore(item?.name)}
                              </span>
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
             
            <div>
            <div className={` my-3 d-flex align-items-center`}>
             <h5 className={`light_blue_color fs_18 me-3`}>Project Photos({triplePhotos?.length}/12)</h5>
             <hr className={`py-1 flex-grow-1 border-primary`} />
            </div>
            <EditPgUploadImage
              triplePhotos={triplePhotos}
              setTriplePhotos={setTriplePhotos}
              pgDetails={formik?.values}
            />
            </div>
            </>
          )}
        
      </div>

      <div className="four_sharing mt-4">
        <label className={`${styles.selectpaymeth_checkbox_label} me-3`}>
          <input
            style={{ width: "17px", height: "17px" }}
            type="checkbox"
            checked={formik?.values?.four_sharing?.enabled}
            onChange={(e) => {
              formik?.setFieldValue("four_sharing.enabled", e?.target?.checked);
            }}
            name="four_sharing.enabled"
          />
          <span className={`${styles.selectpaymeth_checkbox_span}`}></span>
          <span className="ms-2 d-inline-block fs_15 text-capitalize">
            Four Sharing Room
          </span>
        </label>
          {formik?.values?.four_sharing?.enabled && (
            <>
            <PgRoomSharing
              formik={formik}
              floorInputName={"four_sharing.number"}
              floorInputValue={formik?.values?.four_sharing?.number}
              floorInputError={formik?.errors?.four_sharing?.number}
              floorInputTouched={formik?.touched?.four_sharing?.number}
              RentInputName={"four_sharing.rent"}
              RentInputValue={formik?.values?.four_sharing?.rent}
              RentInputError={formik?.errors?.four_sharing?.rent}
              RentInputTouched={formik?.touched?.four_sharing?.rent}
              DepositeInputName={"four_sharing.security_deposite"}
              DepositeInputValue={
                formik?.values?.four_sharing?.security_deposite
              }
              DepositeInputError={
                formik?.errors?.four_sharing?.security_deposite
              }
              DepositeInputTouched={
                formik?.touched?.four_sharing?.security_deposite
              }
              amenitiesList={formik?.values?.four_sharing?.amenities}
              amenitiesListName={"four_sharing.amenities"}
              imageName={"four_sharing.photos"}
            />
               <div className="row">
                {formik?.values?.four_sharing?.amenities?.map(
                  (item, index) => {
                    return (
                      <>
                        <div className="col-3 mb-3">
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                name={`amenities.[${index}].status`}
                                checked={item.status}
                                onChange={() =>
                                  handleFourAmenitiesChange(index)
                                }
                                value={item?.name}
                                style={{ width: "17px", height: "17px" }}
                              />
                              <span className="ms-2 d-inline-block fs_15 text-capitalize">
                                {removeUnderScore(item?.name)}
                              </span>
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>

               <div>
                 <div className={` my-3 d-flex align-items-center`}>
                 <h5 className={`light_blue_color fs_18 me-3`}>Project Photos({fourPhotos?.length}/12)</h5>
                 <hr className={`py-1 flex-grow-1 border-primary`} />
                 </div>
               <EditPgUploadImage
                fourPhotos={fourPhotos}
                setFourPhotos={setFourPhotos}
                pgDetails={formik?.values}
               />
              </div>
            </>
          )}
      </div>

      <div className="others_sharing mt-4">
        <label className={`${styles.selectpaymeth_checkbox_label} me-3`}>
          <input
            style={{ width: "17px", height: "17px" }}
            type="checkbox"
            checked={formik?.values?.other?.enabled}
            onChange={(e) => {
              // handleSingleRoomChange(e);
              formik?.setFieldValue("other.enabled", e?.target?.checked);
            }}
            name="other.enabled"
          />
          <span className={`${styles.selectpaymeth_checkbox_span}`}></span>
          <span className="ms-2 d-inline-block fs_15 text-capitalize">
            Other Sharing Room
          </span>
        </label>
        <div>
          {formik?.values?.other?.enabled && (
            <>
            <PgRoomSharing
              formik={formik}
              floorInputName={"other.number"}
              floorInputValue={formik?.values?.other?.number}
              floorInputError={formik?.errors?.other?.number}
              floorInputTouched={formik?.touched?.other?.number}
              RentInputName={"other.rent"}
              RentInputValue={formik?.values?.other?.rent}
              RentInputError={formik?.errors?.other?.rent}
              RentInputTouched={formik?.touched?.other?.rent}
              DepositeInputName={"other.security_deposite"}
              DepositeInputValue={formik?.values?.other?.security_deposite}
              DepositeInputError={formik?.errors?.other?.security_deposite}
              DepositeInputTouched={formik?.touched?.other?.security_deposite}
              amenitiesList={formik?.values?.other?.amenities}
              amenitiesListName={"other.amenities"}
              imageName={"other.photos"}
            />
            <div className="row">
                {formik?.values?.other?.amenities?.map(
                  (item, index) => {
                    return (
                      <>
                        <div className="col-3 mb-3">
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                name={`amenities.[${index}].status`}
                                checked={item.status}
                                onChange={() =>
                                  handleOtherAmenitiesChange(index)
                                }
                                value={item?.name}
                                style={{ width: "17px", height: "17px" }}
                              />
                              <span className="ms-2 d-inline-block fs_15 text-capitalize">
                                {removeUnderScore(item?.name)}
                              </span>
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
            </div>
            <div>
            <div className={` my-3 d-flex align-items-center`}>
                 <h5 className={`light_blue_color fs_18 me-3`}>Project Photos({otherPhotos?.length}/12)</h5>
                 <hr className={`py-1 flex-grow-1 border-primary`} />
            </div>
            <EditPgUploadImage
              otherPhotos={otherPhotos}
              setOtherPhotos={setOtherPhotos}
              pgDetails={formik?.values}
            />
            </div>
            </>
            
          )}
        </div>
        
      </div>
    </div>
  );
};

export default EditPgFeatures;
