import React, { useEffect, useState } from "react";
import FeaturesVariation from "../../../components/edit-project/residential/residential-villa/Features-variation";
import ResidentialFeatures from "../../../components/edit-project/residential/residential-villa/residential-features";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  amountTypeRegex,
  convertValuesToIntegersObj,
  defaultAddress,
  inputRegex,
  removeUnderScore,
} from "../../../components/helper/helper";
import moment from "moment";
import PropertyBasicDetails from "../../../components/edit-property/property-basic-details";
import LocationAndSearch from "../../../components/map/locationSearch";
import UseFormikInputField from "../../../components/form/useFormikInputField";
import OfficeAddPropertyPhoto from "../../../components/edit-property/commercial/office-space/office-add-property-photo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editProjectVillaAction,
  projectFindOneAction,
} from "../../../redux/action/project-actions/residentialProjectsAction";
import successToast from "../../../components/helper-functions/successToast";
import errorToast from "../../../components/helper-functions/errorToast";
import { Spinner } from "react-bootstrap";
import isEqual from "lodash.isequal";
import Modal_property from "../../../components/ui/modals/modal_property";
import Leftarrowicon from "../../../assets/icons/left-arrow-icon";
import Primarybutton from "../../../components/ui/buttons/primary-button";
import styles from "../../../styles/property-details.module.css";
import BroucherUpload from "../../edit-property/broucherUpload";
import ResidentialVillFeatures from "../../../components/edit-project/residential/residential-villa/residential-features";
import PropertyLocation from "../../../components/map/property-location";
import UseFormikSelectField from "../../../components/form/useFormikSelectField";
const ResidentialVilla = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);

  const [propertyStatus, setPropertyStatus] = useState("");
  const [mainPropertyType, setMainPropertyType] = useState("");
  const [modalButton, setModalButton] = useState("");
  const [amitities, setAmitities] = useState();
  // const [numVariations, setNumVariations] = useState(2);
  const [multiImage, setMultiImage] = useState([]);
  const [projectDetails, setProjectDetails] = useState("");
  const [aminities, setAminities] = useState("");
  const [compareAminities, setCompareAminities] = useState(true);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const [invalidId, setInvalidId] = useState("");
  const [isClickedOnAme, setIsClickedOnAme] = useState(false);
  const apartmentFeaturesData = projectDetails?.project_features ?? [];
  const [numVariations, setNumVariations] = useState(
    apartmentFeaturesData?.length > 0 ? apartmentFeaturesData?.length : 1
  );
  const [initialValues, setInitialValues] = useState({
    project_id: "",
    no_of_units: null,
    status_of_projects_construction: "",
    project_lunched_on: "",
    project_completed_on: "",
    expected_completed_date: "",
    project_type: "",
    project_details: {
      name: "",
      address: defaultAddress?.address_line_2 || "",
      location: {
        lat: defaultAddress?.lat || null,
        long: defaultAddress?.lng || null,
      },
      project_or_society_name: "",
    },
    project_features: Array.from(
      { length: apartmentFeaturesData?.length || 0 },
      () => ({
        no_of_units_in_variation: null,
        bedrooms: null,
        bathrooms: null,
        balconies: null,
        total_floors: null,
        super_area: null,
        carpet_area: null,
        expected_price: null,
        maintenance_fee: null,
      })
    ),
    common_features: {
      no_of_open_sides: "",
      furnishing_status: "",
      status: "",
      amenities: [],
    },
    g_location: {
      address_line_1: "",
      address_line_2: "",
      area: "",
      city: "",
      postCode: null,
      state: "",
      country: "",
      lat: null,
      lng: null,
    },
    property_photos: [],
    brochure_upload: "",
    description: "",
    terms_and_condition: true,
    lat: null,
    lng: null,
    project_or_society_name: "",
    formetted_address: "",
    searchAddress: "",
  });
  // console.log(apartmentFeaturesData?.length, "apartmentFeaturesData");
  // console.log(numVariations, "apartmentFeaturesData26");
  // useEffect(() => {
  //   setProjectDetails({
  //     project_id: "3037320b-b0d9-42d1-a321-a4d61e225aeb",
  //     project_type: "plot",
  //     no_of_units: 20,
  //     status_of_projects_construction: "structure_implementation",
  //     project_lunched_on: "2023-04-01 11:36:46.703 +0530",
  //     project_completed_on: "2023-05-01 11:36:46.703 +0530",
  //     expected_completed_date: "2023-06-01 11:36:46.703 +0530",
  //     project_details: {
  //       name: "Mysuru",
  //       address: "vijayanagar 3rd stage",
  //       location: {
  //         lat: parseFloat(12.3344765),
  //         long: parseFloat(76.6276287),
  //       },
  //       project_or_society_name: "",
  //     },
  //     "project_features": [
  //       {
  //           "no_of_units_in_variation": 5,
  //           "bedrooms": 2,
  //           "bathrooms": 1,
  //           "balconies": 3,
  //           "total_floors": 4,
  //           "built_up_area": 5,
  //           "super_area": 2,
  //           "carpet_area": 2,
  //           "expected_price": 12342,
  //           "maintenance_fee": 15000
  //       },
  //       {
  //           "no_of_units_in_variation": 10,
  //           "bedrooms": 4,
  //           "bathrooms": 3,
  //           "balconies": 2,
  //           "total_floors": 4,
  //           "built_up_area": 5,
  //           "super_area": 2,
  //           "carpet_area": 2,
  //           "expected_price": 12342,
  //           "maintenance_fee": 15000
  //       }
  //   ],
  //   "common_features": {
  //     "no_of_open_sides": 3,
  //     "furnishing_status": "furnished",
  //     "status": "under_construction",
  //     "transaction": "new",
  //     "amenities": [
  //         {
  //             "name": "private_parking_space",
  //             "status": true
  //         },
  //         {
  //             "name": "guest_parking_space",
  //             "status": true
  //         },
  //         {
  //             "name": "play_ground",
  //             "status": false
  //         },
  //         {
  //             "name": "sauna_steam_room",
  //             "status": true
  //         },
  //         {
  //             "name": "swimming_pool_private",
  //             "status": true
  //         },
  //         {
  //             "name": "kids_play_area",
  //             "status": true
  //         },
  //         {
  //             "name": "yoga_meditation_area",
  //             "status": false
  //         },
  //         {
  //             "name": "hardwood_floors",
  //             "status": false
  //         },
  //         {
  //             "name": "pet_wash_station",
  //             "status": false
  //         },
  //         {
  //             "name": "pet_room",
  //             "status": false
  //         },
  //         {
  //             "name": "lounge_area",
  //             "status": false
  //         },
  //         {
  //             "name": "garden_area",
  //             "status": false
  //         },
  //         {
  //             "name": "bbq_lounge_area",
  //             "status": false
  //         },
  //         {
  //             "name": "electric_Car_charging_stations",
  //             "status": false
  //         },
  //         {
  //             "name": "barbecue_areas",
  //             "status": false
  //         }
  //     ]
  // },
  //     g_location: {
  //       address_line_1: "vijayanagar 3rd stage",
  //       address_line_2: "vijayanagar 3rd stage",
  //       area: "vijayanagar",
  //       city: "Mysore",
  //       postCode: 571463,
  //       state: "Karnataka",
  //       country: "India",
  //       lat: 12.3344765,
  //       lng: 76.6276287,
  //     },
  //     property_photos: multiImage,
  //     brochure_upload: "https://s3.ap-south-1.amazonaws.com/zs2.0/Documents/207b43a5-146a-41cb-afa5-51a8006f4ea9.pdf",
  //     description: "",
  //     terms_and_condition: true,
  //     lat: parseFloat(12.3344765),
  //     lng: parseFloat(76.6276287),
  //     project_or_society_name: "",
  //     formetted_address: "581/1, 4th Stage, 1st Phase, Vijay Nagar 3rd Stage, Vijayanagar, Mysuru, Karnataka 570030, India",
  //     searchAddress: "581/1, 4th Stage, 1st Phase, Vijay Nagar 3rd Stage, Vijayanagar, Mysuru, Karnataka 570030, India",
  //   });
  // }, []);
  useEffect(() => {
    setLoading(true);
    dispatch(
      projectFindOneAction(
        {
          project_id: params?.id,
          property_type: params?.type,
        },
        onProjectFindSuccess,
        onProjectFindError
      )
    );
  }, [reload]);
  const onProjectFindSuccess = (data) => {
    setInvalidId(data?.message);
    const projectDetailsData = data?.data;
    setLoading(false);
    setInitialValues({
      project_id: projectDetailsData?.id,
      no_of_units: projectDetailsData?.no_of_units,
      status_of_projects_construction:
        projectDetailsData?.status_of_projects_construction,
      // project_lunched_on: projectDetailsData?.project_lunched_on ?? "",
      // project_completed_on: projectDetailsData?.project_completed_on ?? "",
      // expected_completed_date:
      //   projectDetailsData?.expected_completed_date ?? "",
      date1: projectDetailsData?.project_lunched_on?.split("T")[0] ?? "",
      date2: projectDetailsData?.[projectDetailsData?.status_of_projects_construction === "completed"
          ? "project_completed_on"
          : "expected_completed_date"
      ]?.split("T")[0] ?? "",
      project_type: projectDetailsData?.project_type,
      project_details: {
        name: projectDetailsData?.project_details?.name,
        address: projectDetailsData?.project_details?.address
          ? projectDetailsData?.project_details?.address
          : defaultAddress?.address_line_2,
        location: {
          lat: projectDetailsData?.project_details?.location?.lat
            ? projectDetailsData?.project_details?.location?.lat
            : defaultAddress?.lat,
          long: projectDetailsData?.project_details?.location?.long
            ? projectDetailsData?.project_details?.location?.long
            : defaultAddress?.lng,
        },
        project_or_society_name: projectDetailsData?.project_details?.project_or_society_name,
      },
      project_features: Array.from({ length: projectDetailsData?.project_features?.length },(_, i) => ({
          no_of_units_in_variation: projectDetailsData?.project_features[i]?.no_of_units_in_variation,
          bedrooms: projectDetailsData?.project_features[i]?.bedrooms,
          bathrooms: projectDetailsData?.project_features[i]?.bathrooms,
          balconies: projectDetailsData?.project_features[i]?.balconies,
          total_floors: projectDetailsData?.project_features[i]?.total_floors,
          super_area: projectDetailsData?.project_features[i]?.super_area,
          carpet_area: projectDetailsData?.project_features[i]?.carpet_area,
          expected_price: projectDetailsData?.project_features[i]?.expected_price,
          maintenance_fee: projectDetailsData?.project_features[i]?.maintenance_fee,
        })
      ),
      common_features: {
        no_of_open_sides:
          projectDetailsData?.common_features?.no_of_open_sides ?? "",
        furnishing_status:
          projectDetailsData?.common_features?.furnishing_status ?? "",
        status: projectDetailsData?.common_features?.status ?? "",
        amenities: projectDetailsData?.common_features?.amenities ?? [],
      },
      g_location: {
        address_line_1: projectDetailsData?.g_location?.address_line_1
          ? projectDetailsData?.g_location?.address_line_1
          : defaultAddress?.address_line_1,
        address_line_2: projectDetailsData?.g_location?.address_line_2
          ? projectDetailsData?.g_location?.address_line_2
          : defaultAddress?.address_line_2,
        area: projectDetailsData?.g_location?.area
          ? projectDetailsData?.g_location?.area
          : defaultAddress?.area,
        city: projectDetailsData?.g_location?.city
          ? projectDetailsData?.g_location?.city
          : defaultAddress?.city,
        postCode: projectDetailsData?.g_location?.postCode
          ? projectDetailsData?.g_location?.postCode
          : defaultAddress?.postCode,
        state: projectDetailsData?.g_location?.state
          ? projectDetailsData?.g_location?.state
          : defaultAddress?.state,
        country: projectDetailsData?.g_location?.country
          ? projectDetailsData?.g_location?.country
          : defaultAddress?.country,
        lat: projectDetailsData?.g_location?.lat
          ? projectDetailsData?.g_location?.lat
          : defaultAddress?.lat,
        lng: projectDetailsData?.g_location?.lng
          ? projectDetailsData?.g_location?.lng
          : defaultAddress?.lng,
      },
      property_photos: projectDetailsData?.property_photos,
      brochure_upload: projectDetailsData?.brochure_upload,
      description: projectDetailsData?.description,
      terms_and_condition: true,
      lat: projectDetailsData?.g_location?.lat
        ? projectDetailsData?.g_location?.lat
        : defaultAddress?.lat,
      lng: projectDetailsData?.g_location?.lng
        ? projectDetailsData?.g_location?.lng
        : defaultAddress?.lng,
      formetted_address: projectDetailsData?.formetted_address,
      searchAddress: projectDetailsData?.formetted_address,
    });
    setProjectDetails(data?.data);
    setAminities(data?.data?.common_features?.amenities);
    setMultiImage(data?.data?.property_photos);
    setMainPropertyType(data?.data?.main_property_type);
  };

  const onProjectFindError = (data) => {
    setLoading(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      project_features: Yup.array()
        .of(
          Yup.object().shape({
            no_of_units_in_variation: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            bedrooms: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            bathrooms: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            balconies: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            total_floors: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            super_area: Yup.number()
              .typeError("Enter a valid number")
              .required("Required"),
            // super_area: Yup.number().typeError("Enter a valid number").required("Required"),
            carpet_area: Yup.number()
              .typeError("Enter a valid number")
              .required("Required"),
            expected_price: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .required("Required"),
            maintenance_fee: Yup.string()
              .matches(amountTypeRegex, "Numbers Only")
              .nullable(),
          })
        )
        .required()
        .test(
          "total-units-check",
          "Total units exceed the limit",
          function (value) {
            const totalUnits = value.reduce((acc, item) => {
              return acc + parseInt(item.no_of_units_in_variation || "0", 10);
            }, 0);
            return totalUnits <= projectDetails?.no_of_units;
          }
        ),
      common_features: Yup.object().shape({
        no_of_open_sides: Yup.string()
          .matches(inputRegex, "Enter a valid number")
          .required("Required"),
        furnishing_status: Yup.string().required("Required"),
        status: Yup.string().required("Required"),
      }),
      // searchAddress: Yup.string().required("Required"),
      g_location: Yup.object({
        city: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
      }),
      date1: Yup.date().required("Required"),
      date2: Yup.date()
      .required("Required")
      .test(
        "is-greater",
        "should be greater than Project Lunched On",
        function (value) {
          const { date1 } = this.parent;
          return value > date1;
        }
      ),
    }),
    onSubmit: (values) => {
      console.log(values, "aksjbdsjada");
      dispatch(
        editProjectVillaAction(
          {
            ...values,
            project_lunched_on: values.date1,
            [values.status_of_projects_construction === "completed"
              ? "project_completed_on"
              : "expected_completed_date"]: values.date2,
            project_features: Array.from(
              { length: formik?.values?.project_features?.length },
              (_, i) => ({
                no_of_units_in_variation: parseInt(
                  values?.project_features[i]?.no_of_units_in_variation
                ),
                bedrooms: parseInt(values?.project_features[i]?.bedrooms),
                bathrooms: parseInt(values?.project_features[i]?.bathrooms),
                balconies: parseInt(values?.project_features[i]?.balconies),
                total_floors: parseInt(
                  values?.project_features[i]?.total_floors
                ),
                super_area: parseInt(
                  values?.project_features[i]?.super_area
                ),
                carpet_area: parseInt(values?.project_features[i]?.carpet_area),
                expected_price: parseInt(
                  values?.project_features[i]?.expected_price
                ),
                maintenance_fee: parseInt(
                  values?.project_features[i]?.maintenance_fee
                ),
              })
            ),
            common_features: {
              ...values?.common_features,
              no_of_open_sides: parseInt(
                values?.common_features?.no_of_open_sides
              ),
            },
            property_photos: multiImage,
          },
          onEditSuccess,
          onEditError
        )
      );
      setIsClickedOnAme(true);
    },
  });
  const onEditSuccess = (data) => {
    successToast(data?.message);
    setReload(!reload);
  };
  const onEditError = (data) => {
    errorToast(data?.data?.message);
    console.log(data);
  };
  useEffect(() => {
    if (initialValues?.lat) {
      setMapLoading(true);
    }
  }, [initialValues?.lat]);
  let propertyId = null;
  if (projectDetails && projectDetails?.id) {
    propertyId = (projectDetails?.id).split("-");
  }
  const propertyBasicDetails = [
    {
      label: "Property ID",
      value: propertyId && propertyId[0]?.toUpperCase(),
      display: true,
    },
    {
      label: "Property Type",
      value: projectDetails?.project_type === "villa" && "Residential Villa",
      display: true,
    },
    {
      label: "Property Price",
      value:
        projectDetails &&
        parseInt(projectDetails?.project_features?.[0]?.expected_price),
      display: true,
    },
    {
      label: "Subscription Plan",
      value: "",
      display: projectDetails?.SubscriptionPlanDetail ? true : false,
    },
    {
      label: "Validity",
      value: "",
      display: projectDetails?.SubscriptionPlanDetail ? true : false,
    },
    {
      label: "Posted By",
      value:
        projectDetails?.name +
        `(${removeUnderScore(projectDetails?.owner_type)})`,
      display: true,
    },
    {
      label: "Posted Date & Time",
      value: moment(projectDetails?.createdAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
    {
      label: "Updated Date & Time",
      value: moment(projectDetails?.updatedAt).format("DD MMM YYYY hh:mm A"),
      display: true,
    },
  ];
  const initCommonfeatures = { ...initialValues?.common_features }
  delete initCommonfeatures?.amenities;
  const valuesCommonfeatures = { ...formik?.values?.common_features }
  delete valuesCommonfeatures?.amenities;
  function valuesCompareFn(values) {
    const propertyFeature = isEqual(convertValuesToIntegersObj(formik?.initialValues?.project_features), convertValuesToIntegersObj(values?.project_features));
    const commonfeatures = isEqual(initCommonfeatures, valuesCommonfeatures);
    const propertyPhotos = isEqual(formik?.initialValues?.property_photos, multiImage);
    const uploadDoc = isEqual(initialValues?.brochure_upload, formik?.values?.brochure_upload);
    const lat = isEqual(formik?.initialValues?.g_location?.lat, values?.g_location?.lat);
    const gLocation = isEqual(initialValues?.g_location,values?.g_location);
    const projectOrSociety = isEqual(initialValues?.project_details?.project_or_society_name, values?.project_details?.project_or_society_name);
    const description = isEqual(initialValues?.description, values?.description);
    const date1 = isEqual(initialValues?.date1, values?.date1);
    const date2 = isEqual(initialValues?.date2, values?.date2);
    const projectStatus = isEqual(initialValues?.status_of_projects_construction, values?.status_of_projects_construction);
    // console.log(propertyFeature, propertyPhotos, lat, uploadDoc, "priceDetails");
    if (propertyFeature && propertyPhotos && lat && uploadDoc && compareAminities && commonfeatures && gLocation && projectOrSociety && description && date1 && date2 && projectStatus) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (formik?.values?.lat) {
      setMapLoading(true);
    }
  }, [formik?.values?.lat]);
  const handleDateChangeDate1 = (e) => {
    formik.setFieldValue("date1", e.target.value);
  };
  const handleDateChangeDate2 = (e) => {
    formik.setFieldValue("date2", e.target.value);
  };
  console.log(formik?.values, "proflatvilla");
  console.log(formik?.errors, "projvillaerror");
  return (
    <div>
      {loading ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {invalidId === "Invalid id" ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "70vh" }}
            >
              Project Not Found
            </div>
          ) : (
            <div className={` ${styles.border_color_gray} rounded-4 m-4 `}>
              <Modal_property
                show={show}
                mainPropertyType={mainPropertyType}
                handleclose={() => setShow(false)}
                background={modalButton === "Approve" ? "green" : "red"}
                btnText={modalButton === "Approve" ? "approve" : "reject"}
                propertyStatus={propertyStatus}
                navigateUrl={"/property-project/project-requests"}
                type="project"
              />
              <div className={`d-flex justify-content-between my-2 `}>
                <div
                  className="d-flex align-items-center ms-4 ps-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  <Leftarrowicon />
                  <Primarybutton
                    btnText={"BACK"}
                    className={`border-0 bg-white text_color_darkblue fw_500 fs_18 text-center`}
                  />
                </div>
                <div>
                  {!valuesCompareFn(formik?.values) ? (
                    <button
                      className="btn btn-primary"
                      type="submit"
                      // disabled={valuesCompareFn(formik?.values)}
                    >
                      submit
                    </button>
                  ) : location.pathname.includes("live-projects")
                  ? null : (
               // : location.pathname.includes("live-property-requests") &&
               //   propertyDetails.status === "approved" ? null : (
                    <>
                      <Primarybutton
                        btnText={"APPROVE"}
                        type="button"
                        onClick={() => {
                          setModalButton("Approve");
                          setShow(true);
                          setPropertyStatus("approved");
                        }}
                        className={`px-3 py-2 text-white background_color_green rounded-2 border-0  me-3`}
                      />
                      <Primarybutton
                        btnText={"REJECT"}
                        type="button"
                        onClick={() => {
                          setModalButton("Reject");
                          setShow(true);
                          setPropertyStatus("rejected");
                        }}
                        className={`px-3 py-2  me-3 text-white  background_color_red rounded-2 border-0 `}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="border-bottom"></div>
              <div
                className="scrolling px-3"
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "70vh",
                }}
              >
                <PropertyBasicDetails
                  propertyBasicDetails={propertyBasicDetails}
                />
                <div className={` my-3 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>
                    Property Location
                  </h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                </div>
                <div className="row position-relative">
                  {/* <div className="col-7">
                    {mapLoading ? (
                      <LocationAndSearch
                        type="project_details"
                        formik={formik}
                      />
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center col-7 w-100"
                        style={{ height: "20rem" }}
                      >
                        <Spinner />
                      </div>
                    )}
                  </div> */}
                  <div className="col-5">
                  {formik?.values && <PropertyLocation formik={formik} mapLoading={mapLoading}/>}
                  <div className="mt-3">
         <label className={`secondary_color fs_15`}>Project Lunched On</label>
            <div className={` border_radius_5px input_border focus_none mb-3`}>
              <input
                type="date"
                name="date1"
                className={`${styles.input_text} w-100 px-2 custom-date-picker  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
                id={"date1"}
                value={formik?.values?.date1}
                onChange={handleDateChangeDate1}
              />
            </div>
         </div>
         <div className="mt-3">
         <label className={`secondary_color fs_15`}>
          {formik?.values?.status_of_projects_construction === "completed" ? "Project Completion On" : "Expected Completion Date"}
        </label>
            <div className={` border_radius_5px input_border focus_none mb-3`}>
              <input
                type="date"
                name="date2"
                className={`${styles.input_text} w-100 px-2 custom-date-picker  border_radius_5px  border_none background_none focus_none ps-2 p-1`}
                id={"date2"}
                value={formik?.values?.date2}
                onChange={handleDateChangeDate2}
              />
            </div>
         </div>
         {formik?.errors?.date2 && <span className="text-danger fs_14">{formik?.errors?.date2}</span>}
         <div className="mt-3">
                    <UseFormikSelectField
                      label="Status of Projects Construction"
                      customStyles={{ width: "100%" }}
                      name="status_of_projects_construction"
                      formik={formik}
                      formikError={
                        formik?.errors?.status_of_projects_construction
                      }
                      formikTouched={
                        formik?.touched?.status_of_projects_construction
                      }
                      options={projectStatus}
                      defaultValue={
                        formik?.values?.status_of_projects_construction && {
                          label: removeUnderScore(
                            formik?.values?.status_of_projects_construction
                          ),
                          value:
                            formik?.values?.status_of_projects_construction,
                        }
                      }
                    />
                  </div>
                    <div className="mt-3">
                      <UseFormikInputField
                        placeholder="Enter Project/Society Name (optional)"
                        inputValue={
                          formik?.values?.project_details
                            ?.project_or_society_name
                        }
                        itemName="project_details.project_or_society_name"
                        labelName="Project/Society Name"
                        onChangeFn={formik.handleChange}
                        onBlurFn={formik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
                {[...Array(formik?.values?.project_features?.length)].map(
                  (item, index) => {
                    return <FeaturesVariation formik={formik} index={index} />;
                  }
                )}

                <ResidentialVillFeatures
                  formik={formik}
                  compareAminities={compareAminities}
                  setCompareAminities={setCompareAminities}
                  projectDetails={projectDetails}
                  setIsClickedOnAme={setIsClickedOnAme}
                  isClickedOnAme={isClickedOnAme}
                />
                <OfficeAddPropertyPhoto
                  multiImage={multiImage}
                  setMultiImage={setMultiImage}
                />
                <BroucherUpload formik={formik} />
                <div>
            <div className="discription">
               <div className={`mt-5 d-flex align-items-center`}>
                  <h5 className={`light_blue_color fs_18 me-3`}>
                   Description
                  </h5>
                  <hr className={`py-1 flex-grow-1 border-primary`} />
                </div>
                  <UseFormikInputField
                    label_font_size=""
                labelName=""
                itemName={"description"}
                inputValue={formik.values.description}
                onChangeFn={formik.handleChange}
                onBlurFn={formik.handleBlur}
                formikError={formik.errors.description}
                formikTouched={formik.touched.description}
                placeholder="Describe your project"
                input={false}
                    TextArea={true}
                  />
            </div>
          </div>
              </div>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default ResidentialVilla;
const projectStatus = [
  { value: "launched", label: "Launched" },
  { value: "escalation", label: "Escalations" },
  { value: "foundation", label: "Foundation" },
  { value: "structure_implementation", label: "Structure Implementations" },
  {
    value: "mechanical_electrical_plumbing",
    label: "Mechanical Electrical and Plumbing",
  },
  {
    value: "insulation_waterproofing",
    label: "Insulation and Water Proofing",
  },
  { value: "completed", label: "Completed" },
];